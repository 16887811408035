import { NewIncidentFormInputBuilder } from './NewIncidentFormInputBuilder';
import { Datetime } from '@/components/Datetime';
import { Button } from '@/components/ui/button';
import { CardContent, CardFooter } from '@/components/ui/card';
import { Form } from '@/components/ui/form';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import {
  type DataSchema,
  incidentTypeDefaultValues,
  incidentTypeSchema,
  type UISchema,
} from '@/lib/incidentType';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { type Infer } from 'superstruct';
import { useMutation } from 'urql';

const UpdateIncidentDetailsGql = graphql(`
  mutation UpdateIncidentDetailsGql($input: UpdateIncidentDetailsInput!) {
    updateIncidentDetails(input: $input) {
      incident {
        id
        additionalData
        subject
        description
        updatedAt
        incidentUpdates {
          nodes {
            id
            type
            message
            createdAt
            isSystemMessage
            performedByMember {
              id
              fullName
              displayName
              avatarUrl
            }
            attachments {
              nodes {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`);

type IncidentDetailEditFormProps = {
  readonly additionalData: Object;
  readonly createdAt: string;
  readonly dataSchema: DataSchema;
  readonly description: string;
  readonly incidentId: string;
  readonly setIsEditing: (value: boolean) => void;
  readonly subject: string;
  readonly submittedByMember?:
    | {
        avatarUrl?: string | null | undefined;
        displayName?: string | null | undefined;
        fullName: string;
        id: string;
      }
    | null
    | undefined;
  readonly uiSchema: UISchema;
};

const IncidentDetailEditForm = ({
  additionalData,
  createdAt,
  dataSchema,
  description,
  incidentId,
  setIsEditing,
  subject,
  submittedByMember,
  uiSchema,
}: IncidentDetailEditFormProps) => {
  const [{ error, fetching }, updateIncident] = useMutation(
    UpdateIncidentDetailsGql,
  );

  const schema = incidentTypeSchema(dataSchema);
  const defaultValues = incidentTypeDefaultValues(dataSchema);
  defaultValues['description'] = description;
  defaultValues['subject'] = subject;
  if (schema.schema.additionalData) {
    defaultValues['additionalData'] = additionalData;
  }

  const form = useForm<Infer<typeof schema>>({
    defaultValues,
    resolver: superstructResolver(schema),
  });

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await updateIncident({
      input: {
        additionalData: values.additionalData ? values.additionalData : {},
        description: values.description,
        incidentId,
        subject: values.subject,
      },
    });

    if (!response.error) {
      setIsEditing(false);
    }
  };

  const handleReset = () => {
    setIsEditing(false);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <CardContent className="grid gap-6">
          <NewIncidentFormInputBuilder
            control={form.control}
            dataSchema={dataSchema}
            schema={uiSchema}
          />

          <MutationError error={error} />
        </CardContent>
        <CardFooter className="flex flex-row items-center justify-between border-t bg-muted/50 px-6 py-3">
          <div className="text-sm text-muted-foreground">
            <span>
              Submitted by{' '}
              <Link
                className="hover:underline"
                to={`/members/${submittedByMember?.id}`}
              >
                {submittedByMember?.displayName ??
                  submittedByMember?.fullName ??
                  'Unknown Member'}
              </Link>
            </span>
            <span className="mx-1.5 text-muted-foreground/50">&#x25CF;</span>
            <span className="whitespace-nowrap">
              <Datetime
                datetime={createdAt}
                side="right"
                variant="distance"
              />
            </span>
          </div>
          <div className="flex items-center gap-2">
            <Button
              className="h-8"
              disabled={fetching}
              onClick={() => handleReset()}
              type="button"
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              className="h-8"
              disabled={fetching}
              type="submit"
            >
              {fetching && <Loader className="h-4 w-4 animate-spin mr-1" />}
              Save
            </Button>
          </div>
        </CardFooter>
      </form>
    </Form>
  );
};

export { IncidentDetailEditForm };
