import { TableCell } from './TableCell';
import { TableRow } from './TableRow';
import { forwardRef, type HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

const makePlaceholderRows = (rows: number, cols: number = 0) => {
  const placeholderRows = Array.from({ length: rows }).map((_, index) => index);
  const placeholderCols = Array.from({ length: cols }).map((_, index) => index);

  return placeholderRows.map((row) => (
    <TableRow key={row}>
      {cols > 0 ? (
        placeholderCols.map((col) => <TableCell key={col} />)
      ) : (
        <TableCell colSpan={10} />
      )}
    </TableRow>
  ));
};

type TableBodyProps = HTMLAttributes<HTMLTableSectionElement> & {
  cols?: number;
  empty?: boolean;
  loading?: boolean;
  loadingCount?: number;
};

const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
  (props, ref) => {
    const {
      children,
      className,
      loading = false,
      loadingCount = 10,
      cols,
      ...extra
    } = props;

    return (
      <tbody
        {...extra}
        className={twMerge(
          'divide-y divide-gray-200 dark:divide-gray-700',
          className,
        )}
        ref={ref}
      >
        {loading ? makePlaceholderRows(loadingCount, cols) : children}
      </tbody>
    );
  },
);

export { TableBody };
