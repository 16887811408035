import { type Size } from '../constants';
import { type InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const inputSizes = {
  lg: 'py-3 px-4 sm:p-5',
  md: 'py-3 px-4',
  sm: 'py-2 px-3',
  xl: 'py-3 px-4 sm:p-5',
  xs: 'py-1.5 px-2.5',
} as const;

type TextInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  loading?: boolean;
  size?: Size;
};

const NumberInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const {
      disabled,
      className,
      loading,
      size = 'md',
      readOnly,
      value,
      ...extra
    } = props;

    return (
      <div className={twMerge('relative w-full', className)}>
        <input
          {...extra}
          className={twMerge(
            'block w-full border-gray-200 rounded-md text-sm',
            'focus:border-blue-500 focus:ring-blue-500',
            'dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400',
            inputSizes[size],
            disabled && 'opacity-60 pointer-events-none',
          )}
          disabled={disabled}
          readOnly={readOnly || loading}
          ref={ref}
          type="number"
          value={value}
        />
      </div>
    );
  },
);

export { NumberInput };
