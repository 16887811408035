import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { graphql } from '@/gql';
import { cn } from '@/lib/utils';
import { Inbox } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'urql';

const NotificationsMenuItemGql = graphql(`
  query NotificationsMenuItemGql {
    currentUser {
      id
      userNotifications(condition: { isRead: false }) {
        totalCount
      }
      invitations {
        totalCount
      }
    }
  }
`);

type Props = {
  readonly isCollapsed?: boolean;
};

const NotificationsMenuItem = ({ isCollapsed }: Props) => {
  const pathname = useLocation().pathname;

  const [{ data }] = useQuery({
    query: NotificationsMenuItemGql,
  });

  const totalCount =
    (data?.currentUser?.userNotifications.totalCount ?? 0) +
    (data?.currentUser?.invitations.totalCount ?? 0);

  if (isCollapsed) {
    return (
      <Link
        className={cn(
          'flex items-center gap-4 px-2.5',
          pathname === '/account/notifications'
            ? 'text-foreground'
            : 'text-muted-foreground hover:text-foreground',
        )}
        to="/account/notifications"
      >
        <Inbox className="h-5 w-5" />
        <span className={totalCount > 0 ? 'text-destructive' : ''}>
          {totalCount > 0 ? totalCount.toString() : undefined} Notifications
        </span>
      </Link>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Link
          className={cn(
            'relative flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8',
            pathname === '/account/notifications'
              ? 'bg-primary text-primary-foreground'
              : 'text-muted-foreground hover:text-foreground',
          )}
          to="/account/notifications"
        >
          <Inbox className="h-5 w-5" />
          <span className="sr-only">
            {totalCount > 0 ? totalCount.toString() : undefined} Notifications
          </span>
          {totalCount > 0 && (
            <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75" />
              <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500" />
            </span>
          )}
        </Link>
      </TooltipTrigger>
      <TooltipContent side="right">
        {totalCount > 0 ? totalCount.toString() : undefined} Notifications
      </TooltipContent>
    </Tooltip>
  );
};

export { NotificationsMenuItem };
