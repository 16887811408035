import IncidentsStartedSfx from '@/assets/sounds/guardme-started.mp3';
import { type DocumentType, graphql } from '@/gql';
import { GraphqlIncidentsUpdatesEvent } from '@/gql/graphql';
import { toast } from '@/lib/toast';
import { useAppStore } from '@/stores';
import { type Cache } from '@urql/exchange-graphcache';
import { Howl } from 'howler';
import { type NavigateFunction } from 'react-router-dom';
import { useSubscription } from 'urql';

const IncidentsUpdatesQuery = graphql(`
  subscription IncidentsUpdatesQuery($organizationId: String!) {
    incidentsUpdates(input: { organizationId: $organizationId }) {
      incident {
        id
        shortId
        subject
        status
        priority
        createdAt
        updatedAt
        organizationId
        organization {
          id
          name
        }
      }
      event
      relatedIds
    }
  }
`);

const incidentsUpdatesResolver =
  (navigate: NavigateFunction) =>
  (
    result: DocumentType<typeof IncidentsUpdatesQuery>,
    _args: never,
    cache: Cache,
  ) => {
    if (!result?.incidentsUpdates?.incident) {
      // no incidents on object, do nothing
      return;
    }

    if (!result.incidentsUpdates.incident.organization) {
      // unknown organization
      return;
    }

    const event = result.incidentsUpdates.event ?? 'updated';
    const incidentId = result.incidentsUpdates.incident.id;

    if (event === GraphqlIncidentsUpdatesEvent.Created) {
      const sound = new Howl({
        src: IncidentsStartedSfx,
      });

      const soundId = sound.play();

      toast.info(`New Incident (${result.incidentsUpdates.incident.shortId})`, {
        action: {
          label: 'View',
          onClick: () => {
            useAppStore.setState((state) => ({
              activeMembership:
                state.memberships.find(
                  (org) =>
                    org.id ===
                    result.incidentsUpdates?.incident?.organizationId,
                ) ?? state.activeMembership,
            }));
            navigate(`/incidents/${incidentId}`);
          },
        },
        description: result.incidentsUpdates.incident.subject,
      });

      setTimeout(() => {
        sound.stop(soundId);
      }, 3_000);

      // invalidate incident query results to cause refetch with new data
      for (const field of cache
        .inspectFields('Query')
        .filter((query) => query.fieldName === 'incidents')) {
        cache.invalidate('Query', 'incidents', field.arguments);
      }
    }
  };

type IncidentsUpdatesProps = { readonly organizationId: string };

const IncidentsUpdates = ({ organizationId }: IncidentsUpdatesProps) => {
  useSubscription({
    query: IncidentsUpdatesQuery,
    variables: {
      organizationId,
    },
  });

  return null;
};

export {
  IncidentsUpdates,
  type IncidentsUpdatesProps,
  incidentsUpdatesResolver,
};
