import { IncidentDataBuilder } from './IncidentDataBuilder';
import { IncidentDetailEditForm } from './IncidentDetailEditForm';
import { Datetime } from '@/components/Datetime';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { type IncidentType } from '@/gql/graphql';
import { type DataSchema, type UISchema } from '@/lib/incidentType';
import { Pencil } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

type IncidentDetailProps = {
  readonly additionalData: Record<string, unknown>;
  readonly canEditIncident: boolean;
  readonly createdAt: string;
  readonly dataSchema: DataSchema;
  readonly description: string;
  readonly incidentId: string;
  readonly incidentType?: Pick<IncidentType, 'name' | 'iconSvg'> | null;
  readonly subject: string;
  readonly submittedByMember?:
    | {
        avatarUrl?: string | null | undefined;
        displayName?: string | null | undefined;
        fullName: string;
        id: string;
      }
    | null
    | undefined;
  readonly uiSchema: UISchema;
};

const IncidentDetail = ({
  additionalData,
  canEditIncident,
  createdAt,
  dataSchema,
  description,
  incidentId,
  incidentType,
  subject,
  submittedByMember,
  uiSchema,
}: IncidentDetailProps) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-3xl">{subject}</CardTitle>
        <CardDescription className="flex items-center gap-2">
          <img
            alt={incidentType?.name}
            className="h-4 w-4"
            src={`data:image/svg+xml;base64,${incidentType?.iconSvg}`}
          />
          {incidentType?.name}
        </CardDescription>
      </CardHeader>

      {isEditing ? (
        <IncidentDetailEditForm
          additionalData={additionalData}
          createdAt={createdAt}
          dataSchema={dataSchema}
          description={description}
          incidentId={incidentId}
          setIsEditing={setIsEditing}
          subject={subject}
          submittedByMember={submittedByMember}
          uiSchema={uiSchema}
        />
      ) : (
        <>
          <CardContent>
            <IncidentDataBuilder
              data={{
                additionalData,
                description,
                subject,
              }}
              dataSchema={dataSchema}
              uiSchema={uiSchema}
            />
          </CardContent>
          <CardFooter className="flex flex-row items-center justify-between border-t bg-muted/50 px-6 py-3">
            <div className="text-sm text-muted-foreground">
              <span>
                Submitted by{' '}
                <Link
                  className="hover:underline"
                  to={`/members/${submittedByMember?.id}`}
                >
                  {submittedByMember?.displayName ??
                    submittedByMember?.fullName ??
                    'Unknown Member'}
                </Link>
              </span>
              <span className="mx-1.5 text-muted-foreground/50">&#x25CF;</span>
              <span className="whitespace-nowrap">
                <Datetime
                  datetime={createdAt}
                  side="right"
                  variant="distance"
                />
              </span>
            </div>
            {canEditIncident && (
              <div className="flex items-center gap-2">
                <Button
                  className="h-8"
                  onClick={() => setIsEditing(true)}
                  size="sm"
                  variant="outline"
                >
                  <Pencil className="h-4 w-4 mr-2" />
                  Edit
                </Button>
              </div>
            )}
          </CardFooter>
        </>
      )}
    </Card>
  );
};

export { IncidentDetail };
