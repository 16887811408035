import { JoinOrganization } from '../components/JoinOrganization';
import { MembershipList } from '../components/MembershipList';
import { Loader } from '@/components/Loader';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { graphql } from '@/gql';
import { useQuery } from 'urql';

const MembershipsGql = graphql(`
  query MembershipsGql {
    currentUser {
      id
      memberships {
        totalCount
        nodes {
          createdAt
          id
          organization {
            id
            logoUrl
            name
          }
          organizationRole
        }
      }
      invitations {
        totalCount
        nodes {
          id
          key
          organizationRole
          organizationName
          organization {
            id
            logoUrl
          }
        }
      }
      membershipRequests {
        totalCount
        nodes {
          id
          organizationName
          organizationId
          createdAt
        }
      }
    }
  }
`);

const Memberships = () => {
  const [{ data, error, fetching }] = useQuery({
    query: MembershipsGql,
  });

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Join an organization</CardTitle>
          <CardDescription>
            Use a Membership Code to request access to an organization.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <JoinOrganization />
        </CardContent>
      </Card>

      <Loader
        isLoading={fetching}
        loadingError={error}
      >
        <MembershipList
          invitations={data?.currentUser?.invitations.nodes ?? []}
          membershipRequests={data?.currentUser?.membershipRequests.nodes ?? []}
          memberships={data?.currentUser?.memberships.nodes ?? []}
        />
      </Loader>
    </>
  );
};

export { Memberships };
