import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores';
import { normalizeMemberRole } from '@/utils';
import { Plus } from 'lucide-react';
import { type ComponentPropsWithoutRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

type AccountMenuProps = ComponentPropsWithoutRef<typeof DropdownMenuTrigger> & {
  readonly isCollapsed: boolean;
};

const AccountMenu = ({ className, isCollapsed }: AccountMenuProps) => {
  const [currentUser, activeMembership, memberships, setActiveMembership] =
    useAppStore(
      useShallow((state) => [
        state.currentUser,
        state.activeMembership,
        state.memberships,
        state.setActiveMembership,
      ]),
    );

  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu
      onOpenChange={setOpen}
      open={open}
    >
      <DropdownMenuTrigger asChild>
        <button
          aria-expanded={open}
          aria-label="Select a organization"
          className={cn(
            'flex items-center bg-transparent group',
            isCollapsed ? 'mx-auto' : 'w-full',
            className,
          )}
          type="button"
        >
          {activeMembership ? (
            <Avatar
              className={cn('h-8 w-8 rounded', isCollapsed ? '' : 'mr-2')}
            >
              <AvatarImage
                alt={activeMembership?.name}
                src={activeMembership?.logoUrl}
              />
              <AvatarFallback>
                {activeMembership?.name.slice(0, 1)}
              </AvatarFallback>
            </Avatar>
          ) : (
            <Avatar
              className={cn('h-8 w-8 rounded', isCollapsed ? '' : 'mr-2')}
            >
              <AvatarImage
                alt={currentUser.fullName}
                src={currentUser.avatarUrl}
              />
              <AvatarFallback>
                {currentUser.fullName.slice(0, 2)}
              </AvatarFallback>
            </Avatar>
          )}
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        className="md:w-[300px] py-2 z-2048"
      >
        {activeMembership && (
          <>
            <DropdownMenuLabel className="px-2 flex items-center gap-2">
              <Avatar className="h-8 w-8 rounded">
                <AvatarImage
                  alt={activeMembership?.name}
                  src={activeMembership?.logoUrl}
                />
                <AvatarFallback>
                  {activeMembership?.name.slice(0, 1)}
                </AvatarFallback>
              </Avatar>

              <div className="text-left">
                <div className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
                  {activeMembership?.name}
                </div>
                <div className="font-normal text-xs">
                  {normalizeMemberRole(activeMembership?.role)}
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
          </>
        )}

        <DropdownMenuGroup>
          <div className="md:hidden">
            {memberships
              .filter((item) => item.id !== activeMembership?.id)
              .map((membership) => (
                <DropdownMenuItem
                  className="pl-4 flex items-center gap-2"
                  key={membership.id}
                  onClick={() => {
                    setActiveMembership(membership.id);
                    setOpen(false);
                  }}
                >
                  <Avatar className="h-8 w-8 rounded">
                    <AvatarImage
                      alt={membership.name}
                      src={membership.logoUrl}
                    />
                    <AvatarFallback>
                      {membership.name.slice(0, 1)}
                    </AvatarFallback>
                  </Avatar>

                  <div className="text-left">
                    <div className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
                      {membership.name}
                    </div>
                    <div className="font-normal text-xs">
                      {normalizeMemberRole(membership.role)}
                    </div>
                  </div>
                </DropdownMenuItem>
              ))}
          </div>

          {memberships
            .filter((item) => item.id !== activeMembership?.id)
            .map((membership) => (
              <DropdownMenuItem
                className="px-2 flex items-center gap-2"
                key={membership.id}
                onClick={() => {
                  setActiveMembership(membership.id);
                  setOpen(false);
                }}
              >
                <Avatar className="h-8 w-8 rounded">
                  <AvatarImage
                    alt={membership.name}
                    src={membership.logoUrl}
                  />
                  <AvatarFallback>{membership.name.slice(0, 1)}</AvatarFallback>
                </Avatar>

                <div className="text-left">
                  <div className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
                    {membership.name}
                  </div>
                  <div className="font-normal text-xs">
                    {normalizeMemberRole(membership.role)}
                  </div>
                </div>
              </DropdownMenuItem>
            ))}
        </DropdownMenuGroup>

        <DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem asChild>
            <Link
              className="flex items-center gap-2"
              to="/organization/new"
            >
              <Plus className="h-4 w-4" />
              <span>New Organization...</span>
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { AccountMenu };
