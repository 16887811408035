import { EditPlaceForm } from '../components/EditPlaceForm';
import { Loader } from '@/components';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import {
  NotFoundError,
  OrganizationNotFoundError,
  PermissionDeniedError,
} from '@/utils';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const PlaceEditQuery = graphql(`
  query PlaceEditQuery($placeId: String!) {
    place(id: $placeId) {
      id
      name
      description
      updatedAt
      createdAt
      radius
      spatialData {
        geojson
      }
      center {
        latitude
        longitude
      }
    }
  }
`);

const PlaceEdit = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);
  const { placeId } = useParams() as { placeId: string };

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  if (!isAdmin) {
    throw new PermissionDeniedError();
  }

  if (!placeId) {
    throw new NotFoundError('Place does not exist');
  }

  const [{ data, error, fetching }] = useQuery({
    query: PlaceEditQuery,
    requestPolicy: 'cache-and-network',
    variables: {
      placeId,
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Edit Place</CardTitle>
        <CardDescription>
          Places allow your organization to geofence and name a particular area.
          These can be configured to identify and tag key locations for a Check
          In, Incident, or GuardMe.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Loader
          isLoading={fetching}
          loadingError={error}
        >
          {data?.place && (
            <EditPlaceForm
              description={data.place.description}
              geoJson={{
                ...data.place.spatialData.geojson,
                radius: data.place.radius,
              }}
              name={data.place.name}
              placeId={placeId}
            />
          )}
        </Loader>
      </CardContent>
    </Card>
  );
};

export { PlaceEdit };
