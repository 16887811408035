import { AdvancedMarker, Map, MapProvider } from '@/components/Map';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { type Place } from '@/gql/graphql';

type IncidentLocationProps = {
  readonly coordinates: {
    x: number;
    y: number;
  };
  readonly fetching: boolean;
  readonly geocode?:
    | {
        address: string;
        plusCode: string;
      }
    | null
    | undefined;
  readonly places?: {
    nodes: Array<Pick<Place, 'id' | 'name'>>;
  };
};

const IncidentLocation = ({
  coordinates,
  fetching,
  geocode,
  places,
}: IncidentLocationProps) => {
  return (
    <Card>
      {fetching && <Skeleton className="h-96 w-full" />}

      <MapProvider>
        {coordinates && (
          <Map
            className="h-96"
            defaultCenter={{
              lat: coordinates.y,
              lng: coordinates.x,
            }}
            defaultZoom={14}
          >
            <AdvancedMarker
              position={{
                lat: coordinates.y,
                lng: coordinates.x,
              }}
            />
          </Map>
        )}
      </MapProvider>

      <CardContent className="p-6 text-sm">
        <div className="grid gap-3">
          <div className="font-semibold">Incident Address (approximate)</div>
          <address className="grid gap-0.5 not-italic text-muted-foreground">
            {geocode?.address
              .split(',')
              .map((item) => <span key={item}>{item}</span>) ?? (
              <span>---</span>
            )}
          </address>
        </div>

        <Separator className="my-4" />

        <div className="grid gap-3">
          <div className="font-semibold">Coordinates</div>
          <ul className="grid gap-1">
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Latitude</span>
              <span>{coordinates?.y}</span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Longitude</span>
              <span>{coordinates?.x}</span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Google Plus Code</span>
              <span>{geocode?.plusCode ?? '---'}</span>
            </li>
          </ul>
        </div>

        {(places?.nodes.length ?? 0) > 0 && (
          <>
            <Separator className="my-4" />

            <div className="grid gap-3">
              <div className="font-semibold">Related Places</div>
              <ul className="flex items-center gap-1 flex-wrap">
                {places?.nodes.map((place) => (
                  <li key={place.id}>
                    <Badge variant="secondary">{place.name}</Badge>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export { IncidentLocation };
