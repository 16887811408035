type BooleanFiedDefinition = {
  defaultValue: boolean;
  isPrivate: boolean;
  isRequired: boolean;
  name: string;
  type: 'BOOLEAN';
};

type NumberFieldDefinition = {
  defaultValue: number | null;
  isPrivate: boolean;
  isRequired: boolean;
  name: string;
  type: 'NUMBER';
};

type StringFieldDefinition = {
  defaultValue: string | null;
  isPrivate: boolean;
  isRequired: boolean;
  name: string;
  type: 'STRING';
};

type MetadataFieldDefinition =
  | BooleanFiedDefinition
  | NumberFieldDefinition
  | StringFieldDefinition;

type MetadataFieldDefinitions = {
  [name: string]: MetadataFieldDefinition;
};

type ExtendedMemberMetadata = {
  [name: string]:
    | {
        isPrivate: boolean;
        isRequired: boolean;
        name: string;
        type: 'BOOLEAN';
        value: boolean;
      }
    | {
        isPrivate: boolean;
        isRequired: boolean;
        name: string;
        type: 'NUMBER';
        value: number | undefined;
      }
    | {
        isPrivate: boolean;
        isRequired: boolean;
        name: string;
        type: 'STRING';
        value: string | undefined;
      };
};

type MemberMetadata = {
  [name: string]: boolean | number | string;
};

type MetadataToArrayParameters = {
  member?: MemberMetadata;
  organization?: MetadataFieldDefinitions;
};

const memberMetadataFieldsToArray = (
  memberMetadataFields: MetadataFieldDefinitions | null | undefined,
) => {
  const privateFields: MetadataFieldDefinition[] = [];
  const publicFields: MetadataFieldDefinition[] = [];

  for (const entry of Object.entries(memberMetadataFields ?? {})) {
    const [, value] = entry;

    if (value.isPrivate) {
      privateFields.push(value);
    } else {
      publicFields.push(value);
    }
  }

  return {
    privateFields,
    publicFields,
  };
};

const metadataToArray = ({
  organization = {},
  member,
}: MetadataToArrayParameters) => {
  const publicObject: ExtendedMemberMetadata = {};
  const privateObject: ExtendedMemberMetadata = {};

  for (const [key, value] of Object.entries(organization)) {
    switch (value.type) {
      case 'BOOLEAN':
        if (value.isPrivate) {
          privateObject[value.name] = {
            isPrivate: true,
            isRequired: value.isRequired,
            name: value.name,
            type: 'BOOLEAN',
            value: member?.[key]
              ? (member[key] as boolean)
              : value.defaultValue,
          };
        } else {
          publicObject[value.name] = {
            isPrivate: false,
            isRequired: value.isRequired,
            name: value.name,
            type: 'BOOLEAN',
            value: member?.[key]
              ? (member[key] as boolean)
              : value.defaultValue,
          };
        }

        break;
      case 'NUMBER':
        if (value.isPrivate) {
          privateObject[value.name] = {
            isPrivate: true,
            isRequired: value.isRequired,
            name: value.name,
            type: 'NUMBER',
            value: member?.[key]
              ? (member[key] as number)
              : value.defaultValue ?? undefined,
          };
        } else {
          publicObject[value.name] = {
            isPrivate: false,
            isRequired: value.isRequired,
            name: value.name,
            type: 'NUMBER',
            value: member?.[key]
              ? (member[key] as number)
              : value.defaultValue ?? undefined,
          };
        }

        break;
      case 'STRING':
        if (value.isPrivate) {
          privateObject[value.name] = {
            isPrivate: true,
            isRequired: value.isRequired,
            name: value.name,
            type: 'STRING',
            value: member?.[key]
              ? (member[key] as string)
              : value.defaultValue ?? undefined,
          };
        } else {
          publicObject[value.name] = {
            isPrivate: false,
            isRequired: value.isRequired,
            name: value.name,
            type: 'STRING',
            value: member?.[key]
              ? (member[key] as string)
              : value.defaultValue ?? undefined,
          };
        }

        break;
      default:
      // noop
    }
  }

  return {
    privateMetadata: Object.values(privateObject).map((item) => {
      return item;
    }),
    publicMetadata: Object.values(publicObject).map((item) => {
      return item;
    }),
  };
};

const metadataFromArray = (
  ...args: Array<{ name: string; value: boolean | number | string }>
): MemberMetadata => {
  const metadataObject: MemberMetadata = {};
  for (const item of args) {
    metadataObject[item.name] = item.value;
  }

  return metadataObject;
};

export {
  type ExtendedMemberMetadata,
  type MemberMetadata,
  memberMetadataFieldsToArray,
  type MetadataFieldDefinition,
  type MetadataFieldDefinitions,
  metadataFromArray,
  metadataToArray,
};
