import { GuardMeStatus } from '../components/GuardMeStatus';
import { useGuardMeTableControls } from '../hooks/useGuardMeTableControls';
import { DateRangePicker } from '@/components/DateRangePicker/DateRangePicker';
import { Datetime } from '@/components/Datetime';
import { TableSearch } from '@/components/TableSearch';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  TablePagnination,
  TableRowSkeleton,
} from '@/components/ui/TableWrapper';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { type Guardme, type Member } from '@/gql/graphql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { Circle } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

type RowElementProps = Pick<
  Guardme,
  'status' | 'id' | 'shortId' | 'createdAt' | 'batteryLevel' | 'completedAt'
> & {
  readonly member?:
    | Pick<Member, 'fullName' | 'displayName' | 'avatarUrl'>
    | null
    | undefined;
};

const RowElement = ({
  completedAt,
  createdAt,
  id,
  member,
  shortId,
  status,
}: RowElementProps) => {
  const navigate = useNavigate();

  return (
    <TableRow
      onClick={() => {
        navigate(`/guardme/${id}`);
      }}
    >
      <TableCell className="align-top">
        <GuardMeStatus status={status} />
      </TableCell>
      <TableCell className="align-top">
        <Link
          className="font-bold hover:underline"
          to={`/guardme/${id}`}
        >
          Session {shortId}
        </Link>
        <div className="mt-2 flex items-center gap-2">
          <Avatar className="h-6 w-6">
            <AvatarImage src={member?.avatarUrl ?? undefined} />
            <AvatarFallback>{member?.fullName.slice(0, 2)}</AvatarFallback>
          </Avatar>
          <div className="text-sm">
            {member?.displayName ?? member?.fullName}
          </div>
        </div>
      </TableCell>
      <TableCell className="align-top">
        <Datetime datetime={createdAt} />
      </TableCell>
      <TableCell className="align-top">
        {completedAt && <Datetime datetime={completedAt} />}
      </TableCell>
    </TableRow>
  );
};

const GuardMesGql = graphql(`
  query GuardMesGql(
    $first: Int!
    $offset: Int!
    $organizationId: String!
    $search: String
    $status: GuardMesStatusConditionInput
    $createdAt_gte: Datetime
    $createdAt_lte: Datetime
    $memberId: String
  ) {
    guardmes(
      first: $first
      offset: $offset
      condition: {
        memberId: $memberId
        createdAt: { gte: $createdAt_gte, lte: $createdAt_lte }
        organizationId: $organizationId
        search: $search
        status: $status
      }
      orderBy: ID_DESC
    ) {
      totalCount
      nodes {
        id
        shortId
        status
        createdAt
        completedAt
        endsAt
        memberId
        member {
          id
          fullName
          displayName
          avatarUrl
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

const GuardMes = () => {
  const [organizationId, memberId] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.memberId,
  ]);

  if (!organizationId || !memberId) {
    throw new OrganizationNotFoundError();
  }

  const controls = useGuardMeTableControls();

  const [{ data, error, fetching }] = useQuery({
    query: GuardMesGql,
    requestPolicy: 'cache-and-network',
    variables: {
      createdAt_gte: controls.from?.toISOString(),
      createdAt_lte: controls.to?.toISOString(),
      first: controls.pageSize,
      memberId: undefined,
      offset: controls.offset,
      organizationId,
      search: controls.search,
      status: controls.hasFilter
        ? {
            concluded: controls.concluded,
            expired: controls.expired,
            inPanic: controls.inPanic,
            inProgress: controls.inProgress,
          }
        : null,
    },
  });

  return (
    <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
      <div className="grid">
        <h1 className="mb-4 scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">
          GuardMe Sessions
        </h1>

        <TableSearch
          containerClassName="md:max-w-full mb-2"
          placeholder="Search by Id..."
          setSearchValue={controls.setSearch}
        />

        <div className="flex items-center">
          <div className="flex items-center gap-1">
            <ToggleGroup
              // eslint-disable-next-line react/jsx-handler-names
              onValueChange={controls.setFilterToggle}
              type="multiple"
              value={controls.filter}
              variant="outline"
            >
              <ToggleGroupItem
                aria-label="Toggle in-progress filter"
                className="flex gap-2 [&>svg]:data-[state=on]:fill-info-foreground [&>svg]:data-[state=on]:text-info-foreground data-[state=on]:bg-background data-[state=on]:text-foreground"
                value="inProgress"
              >
                <Circle className="h-4 w-4" />
                In Progress
              </ToggleGroupItem>
              <ToggleGroupItem
                aria-label="Toggle in-panic filter"
                className="flex gap-2 [&>svg]:data-[state=on]:fill-destructive-foreground [&>svg]:data-[state=on]:text-destructive-foreground data-[state=on]:bg-background data-[state=on]:text-foreground"
                value="inPanic"
              >
                <Circle className="h-4 w-4" />
                In Panic
              </ToggleGroupItem>
              <ToggleGroupItem
                aria-label="Toggle expired filter"
                className="flex gap-2 [&>svg]:data-[state=on]:fill-warning-foreground [&>svg]:data-[state=on]:text-warning-foreground data-[state=on]:bg-background data-[state=on]:text-foreground"
                value="expired"
              >
                <Circle className="h-4 w-4" />
                Expired
              </ToggleGroupItem>
              <ToggleGroupItem
                aria-label="Toggle concluded filter"
                className="flex gap-2 [&>svg]:data-[state=on]:fill-secondary-foreground/30 [&>svg]:data-[state=on]:text-secondary-foreground/30 data-[state=on]:bg-background data-[state=on]:text-foreground"
                value="concluded"
              >
                <Circle className="h-4 w-4" />
                Concluded
              </ToggleGroupItem>
            </ToggleGroup>
            <DateRangePicker
              dateRange={controls.dateRange}
              setDateRange={controls.setDateRange}
            />
          </div>
          <div className="ml-auto flex items-center gap-2">
            <div className="mx-auto md:mx-0 text-sm text-muted-foreground">
              Showing
              <span className="mx-1 font-medium text-foreground">
                {(controls.page - 1) * controls.pageSize + 1}-
                {Math.min(
                  (controls.page - 1) * controls.pageSize + controls.pageSize,
                  data?.guardmes?.totalCount ?? 0,
                )}
              </span>
              of
              <span className="ml-1 font-medium text-foreground">
                {data?.guardmes?.totalCount}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <Card>
            <CardContent className="p-0">
              <Table>
                <TableHeader>
                  <TableRow className="hover:bg-inherit">
                    <TableHead>Status</TableHead>
                    <TableHead>Details</TableHead>
                    <TableHead>Created</TableHead>
                    <TableHead>Completed</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <MutationError error={error} />
                  {fetching && (
                    <TableRowSkeleton
                      cols={4}
                      rows={controls.pageSize / 3}
                    />
                  )}
                  {!fetching && data?.guardmes?.nodes.length === 0 && (
                    <TableRow className="last:border-none hover:bg-inherit">
                      <TableCell
                        className="text-center h-24"
                        colSpan={8}
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                  {!fetching &&
                    data?.guardmes?.nodes.map((item) => (
                      <RowElement
                        {...item}
                        key={item.id}
                      />
                    ))}
                </TableBody>
              </Table>
            </CardContent>
            <CardFooter className="p-4 border-t">
              <TablePagnination
                currentPage={controls.page}
                getSearchParameters={controls.getPageSearchParameters}
                pageSize={controls.pageSize}
                totalCount={data?.guardmes?.totalCount}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export { GuardMes };
