import { type AppStore, type SettingsSlice } from './types';
import { type StateCreator } from 'zustand';

const createSettingsSlice: StateCreator<AppStore, [], [], SettingsSlice> = (
  set,
) => ({
  isSidebarOpen: false,
  setIsSidebarOpen: (value) =>
    set((state) => ({
      ...state,
      isSidebarOpen: value,
    })),
});

export { createSettingsSlice };
