type LatLng = {
  lat: () => number;
  lng: () => number;
};

type Path = {
  getArray: () => LatLng[];
};

type PolygonShape = {
  getPath: () => Path;
};

type CircleShape = {
  getCenter: () => LatLng | null;
  getRadius: () => number;
};

type Shape = CircleShape | PolygonShape;
type Geometry =
  | {
      coordinates: number[];
      radius: number;
      type: 'Point';
    }
  | {
      coordinates: number[][][];
      radius: 0;
      type: 'Polygon';
    };

const geometryFromShape = (
  type: 'circle' | 'polygon',
  shape: Shape,
): Geometry => {
  if (type === 'circle') {
    const circle = shape as CircleShape;
    const center = circle.getCenter();
    if (center) {
      return {
        coordinates: [center.lng(), center.lat()],
        radius: Math.ceil(circle.getRadius()),
        type: 'Point',
      };
    }
  }

  if (type === 'polygon') {
    const polygon = shape as PolygonShape;
    return {
      coordinates: [
        polygon
          .getPath()
          .getArray()
          .map((item) => [item.lng(), item.lat()]),
      ],
      radius: 0,
      type: 'Polygon',
    };
  }

  throw new Error('unknown shape type specified');
};

export { type Geometry, geometryFromShape, type Shape };
