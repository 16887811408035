import { DeletePendingEmailAddress } from './DeletePendingEmailAddress';
import { DeletePendingPhoneNumber } from './DeletePendingPhoneNumber';
import { ResendPendingEmailAddressCode } from './ResendPendingEmailAddressCode';
import { ResendPendingPhoneNumberCode } from './ResendPendingPhoneNumberCode';
import { VerifyPendingEmailAddress } from './VerifyPendingEmailAddress';
import { VerifyPendingPhoneNumber } from './VerifyPendingPhoneNumber';
import { PhoneNumberInput } from '@/components/PhoneNumberInput';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { CardContent, CardFooter } from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { type User } from '@/gql/graphql';
import {
  emailAddress as emailAddressSchema,
  phoneNumber as PhoneNumberSchema,
} from '@/lib/superstruct';
import { fmtPhoneNumber } from '@/utils';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { type Infer, object } from 'superstruct';
import { useMutation } from 'urql';

const CreatePendingEmailAddressGql = graphql(`
  mutation CreatePendingEmailAddressGql(
    $input: CurrentUserUpdateEmailAddressInput!
  ) {
    currentUserUpdateEmailAddress(input: $input) {
      pendingEmailAddress {
        id
        userId
        emailAddress
      }
      query {
        currentUser {
          id
          pendingEmailAddress
        }
      }
    }
  }
`);

const CreatePendingPhoneNumberGql = graphql(`
  mutation CreatePendingPhoneNumberGql(
    $input: CurrentUserUpdatePhoneNumberInput!
  ) {
    currentUserUpdatePhoneNumber(input: $input) {
      pendingPhoneNumber {
        id
        userId
        phoneNumber
      }
      query {
        currentUser {
          id
          pendingPhoneNumber
        }
      }
    }
  }
`);

const schema = object({
  emailAddress: emailAddressSchema(),
  phoneNumber: PhoneNumberSchema(),
});

type ContactDetailsFormProps = {
  readonly emailAddress?: User['emailAddress'];
  readonly pendingEmailAddress?: User['pendingEmailAddress'];
  readonly pendingPhoneNumber?: User['pendingPhoneNumber'];
  readonly phoneNumber?: User['phoneNumber'];
};

const ContactDetailsForm = ({
  emailAddress,
  pendingEmailAddress,
  pendingPhoneNumber,
  phoneNumber,
}: ContactDetailsFormProps) => {
  const [{ error: emailError, fetching: emailFetching }, updateEmailAddress] =
    useMutation(CreatePendingEmailAddressGql);
  const [{ error: phoneError, fetching: phoneFetching }, updatePhoneNumber] =
    useMutation(CreatePendingPhoneNumberGql);
  const error = emailError || phoneError;
  const fetching = emailFetching || phoneFetching;

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      emailAddress: emailAddress ?? '',
      phoneNumber: phoneNumber ?? '',
    },
    resolver: superstructResolver(schema),
  });

  const onSubmit = async (values: Infer<typeof schema>) => {
    try {
      if (![phoneNumber, pendingPhoneNumber].includes(values.phoneNumber)) {
        const response = await updatePhoneNumber({
          input: { phoneNumber: values.phoneNumber },
        });

        if (response.error) {
          throw new Error('updatePhoneNumber mutation error');
        }
      }

      if (![emailAddress, pendingEmailAddress].includes(values.emailAddress)) {
        const response = await updateEmailAddress({
          input: { emailAddress: values.emailAddress },
        });

        if (response.error) {
          throw new Error('updateEmailAddress mutation error');
        }
      }

      form.reset();
    } catch {
      // noop, just prevents further graphql mutations
      // and form reset.
    }
  };

  const onCancel = () => {
    form.reset();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <CardContent className="space-y-6">
          <FormField
            control={form.control}
            name="emailAddress"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email Address</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    readOnly={Boolean(pendingEmailAddress)}
                  />
                </FormControl>
                <FormMessage />
                {pendingEmailAddress && (
                  <FormDescription className="flex items-center gap-2">
                    <Badge variant="info">Pending</Badge>
                    <span>{pendingEmailAddress}</span>

                    <span className="ml-auto flex items-center gap-2">
                      <DeletePendingEmailAddress />
                      <ResendPendingEmailAddressCode
                        pending={pendingEmailAddress}
                      />
                      <VerifyPendingEmailAddress
                        pending={pendingEmailAddress}
                      />
                    </span>
                  </FormDescription>
                )}
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Phone Number</FormLabel>
                <FormControl>
                  <PhoneNumberInput
                    {...field}
                    readOnly={Boolean(pendingPhoneNumber)}
                  />
                </FormControl>
                <FormMessage />
                {pendingPhoneNumber && (
                  <FormDescription className="flex items-center gap-2">
                    <Badge variant="info">Pending</Badge>
                    <span>{fmtPhoneNumber(pendingPhoneNumber)}</span>

                    <span className="ml-auto flex items-center gap-2">
                      <DeletePendingPhoneNumber />
                      <ResendPendingPhoneNumberCode
                        pending={pendingPhoneNumber}
                      />
                      <VerifyPendingPhoneNumber
                        loginRequired={Boolean(phoneNumber)}
                        pending={pendingPhoneNumber}
                      />
                    </span>
                  </FormDescription>
                )}
              </FormItem>
            )}
          />

          <MutationError error={error} />
        </CardContent>
        <CardFooter className="border-t px-6 py-4 gap-2">
          <Button
            disabled={fetching || !form.formState.isDirty}
            type="submit"
          >
            Save
          </Button>
          <Button
            disabled={fetching || !form.formState.isDirty}
            onClick={onCancel}
            type="reset"
            variant="ghost"
          >
            {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
            Reset
          </Button>
        </CardFooter>
      </form>
    </Form>
  );
};

export { ContactDetailsForm };
