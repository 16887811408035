type CharCount = 1 | 2;

const generateAvatarUrl = (name: string, charCount: CharCount = 1) => {
  let value = 0;

  for (const letter of name.replaceAll(' ', '')) {
    value += letter.codePointAt(0) ?? 0;
  }

  const initals = name.replaceAll(' ', '').slice(0, charCount);
  const color = value % 18;

  return `https://letters.noticeable.io/${initals.toUpperCase()}${color.toString()}.png`;
};

export { generateAvatarUrl };
