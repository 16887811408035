import { Slider } from './ui/slider';
import { cn } from '@/lib/utils';
import { type MutableRefObject, useState } from 'react';
import AvatarEditorComponent from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

type AvatarEditorProps = {
  readonly blobRef: MutableRefObject<AvatarEditorComponent | null>;
  readonly circle?: boolean;
  readonly className?: string;
  readonly defaultValue?: string;
  readonly onValueChange: () => void;
};

const AvatarEditor = ({
  blobRef,
  circle,
  className,
  defaultValue,
  onValueChange,
}: AvatarEditorProps) => {
  const [scale, setScale] = useState(1);
  const [image, setImage] = useState<string | File | undefined>(defaultValue);

  return (
    <Dropzone
      noClick
      noKeyboard
      onDrop={([dropped]) => setImage(dropped)}
    >
      {({ getInputProps, getRootProps }) => (
        <div
          className={cn('w-full', className)}
          {...getRootProps()}
        >
          {Boolean(image) && (
            <>
              <AvatarEditorComponent
                border={0}
                borderRadius={circle ? 9_999 : 0}
                height={256}
                image={image}
                onImageChange={() => {
                  onValueChange();
                }}
                onLoadSuccess={() => {
                  if (defaultValue === undefined) {
                    onValueChange();
                  }
                }}
                ref={blobRef}
                scale={scale}
                width={256}
              />
              <Slider
                className={cn('my-2 w-[256px]', className)}
                defaultValue={[100]}
                max={2}
                min={1}
                onValueChange={([newScale]) => {
                  setScale(newScale);
                }}
                step={0.01}
                value={[scale]}
              />
            </>
          )}

          <input {...getInputProps()} />
          {!image && (
            <div className="px-4 py-6 border bg-muted/20">
              Drag and drop an image here, or{' '}
              <label className="text-primary font-semibold hover:underline hover:cursor-pointer">
                <input
                  accept="image/*"
                  className="sr-only"
                  // eslint-disable-next-line react/jsx-handler-names
                  onChange={getInputProps().onChange}
                  type="file"
                />
                click
              </label>{' '}
              to select a file
            </div>
          )}
          {Boolean(image) && (
            <div className="mt-2">
              <label className="text-sm text-primary hover:underline hover:cursor-pointer">
                <input
                  accept="image/*"
                  className="sr-only"
                  // eslint-disable-next-line react/jsx-handler-names
                  onChange={getInputProps().onChange}
                  type="file"
                />
                Change Image
              </label>
            </div>
          )}
        </div>
      )}
    </Dropzone>
  );
};

export { AvatarEditor };

export { type default as AvatarEditorType } from 'react-avatar-editor';
