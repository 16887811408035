import { type Size } from '../constants';
import { type InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const radioSizes: Record<Size, string> = {
  lg: 'w-7 h-7',
  md: 'w-6 h-6',
  sm: 'w-5 h-5',
  xl: 'w-8 h-8',
  xs: 'w-4 h-4',
};

const textSizes: Record<Size, string> = {
  lg: 'text-md',
  md: 'text-md',
  sm: 'text-sm',
  xl: 'text-lg',
  xs: 'text-sm',
};

type RadioInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'Size'> & {
  description?: string;
  label: string;
  size?: Size;
};

const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  (props, ref) => {
    const {
      onChange,
      readOnly,
      size = 'md',
      description,
      disabled,
      name,
      label,
      className,
      ...extra
    } = props;

    if (description) {
      return (
        <div className={twMerge('relative flex items-start w-full', className)}>
          <div className="flex items-center h-5 mt-1">
            <input
              {...extra}
              aria-describedby={`${name}-description`}
              className={twMerge(
                'border-gray-200 rounded-full text-blue-600',
                'focus:ring-blue-500 dark:bg-gray-800',
                'dark:border-gray-700 dark:checked:bg-blue-500',
                'dark:checked:border-blue-500 dark:focus:ring-offset-gray-800',
                disabled && 'opacity-60 pointer-events-none',
                radioSizes[size],
              )}
              disabled={disabled}
              id={name}
              name={name}
              onChange={readOnly ? undefined : onChange}
              readOnly={readOnly}
              ref={ref}
              type="radio"
            />
          </div>
          <label
            className={twMerge(
              'ml-3',
              textSizes[size],
              disabled && 'opacity-60',
            )}
            htmlFor={name}
          >
            <span className="block text-sm font-semibold text-gray-800 dark:text-gray-300">
              {label}
            </span>
            <span
              className="block text-sm text-gray-600 dark:text-gray-500"
              id={`${name}-description`}
            >
              {description}
            </span>
          </label>
        </div>
      );
    }

    return (
      <div className={twMerge('relative flex items-start w-full', className)}>
        <input
          {...extra}
          className={twMerge(
            'shrink-0 mt-0.5 border-gray-200 rounded-full',
            'text-blue-600',
            'focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700',
            'dark:checked:bg-blue-500 dark:checked:border-blue-500',
            'dark:focus:ring-offset-gray-800',
            disabled && 'opacity-60 pointer-events-none',
            radioSizes[size],
          )}
          disabled={disabled}
          id={name}
          name={name}
          onChange={readOnly ? undefined : onChange}
          readOnly={readOnly}
          ref={ref}
          type="radio"
        />
        <label
          className={twMerge(
            textSizes[size],
            disabled && 'opacity-60',
            'ml-3 block text-gray-800 dark:text-gray-300',
          )}
          htmlFor={name}
        >
          {label}
        </label>
      </div>
    );
  },
);

export { RadioInput, type RadioInputProps };
