type Config = {
  AUTH0_AUDIENCE: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_DOMAIN: string;
  AUTH0_REDIRECT_URI: string;
  AUTH0_SCOPE: 'openid profile email offline_access';
  GOOGLE_MAPS_KEY: string;
  GOOGLE_MAPS_MAP_ID_DARKMODE: string;
  GOOGLE_MAPS_MAP_ID_LIGHTMODE: string;
  GRAPHQL_URL: string;
  KEY_PREFIX: string;
  LOG_LEVEL: 'debug' | 'info' | 'warning';
  SENTRY_DSN: string;
  SENTRY_TRACES_SAMPLE_RATE: string;
  STAGE: string;
  STRIPE_KEY: string;
  VERSION: string;
  VERSION_UPDATE_CHECK: boolean;
  WEBSOCKET_URL: string;
  WEB_URL: string;
};

let environment: Config = {
  AUTH0_AUDIENCE: 'https://dev.visualc3.com',
  AUTH0_CLIENT_ID: 'NFZGLkjlMp34b3iyD07w2qLzVXMM0aAV',
  AUTH0_DOMAIN: 'cloudscann-dev.us.auth0.com',
  AUTH0_REDIRECT_URI: 'http://localhost:3000/authorize',
  AUTH0_SCOPE: 'openid profile email offline_access',
  GOOGLE_MAPS_KEY: 'AIzaSyDyQR55qA1O9Vbf9GViqzFBt5J7wOZgQfA',
  GOOGLE_MAPS_MAP_ID_DARKMODE: 'e5b618dd37c08d35',
  GOOGLE_MAPS_MAP_ID_LIGHTMODE: 'c7c2622d77bfa0e2',
  GRAPHQL_URL: 'https://api.dev.seeitsendit.com/graphql',
  KEY_PREFIX: 'dev_seeitsendit_com_',
  LOG_LEVEL: 'debug',
  SENTRY_DSN: '',
  SENTRY_TRACES_SAMPLE_RATE: '0.5',
  STAGE: 'local',
  STRIPE_KEY:
    'pk_test_51KWrAzE2BNDdEec0x1eTJoMHdythliV8gVhgJQqrm7RZy7DzXvhA11sQ759WjmiZuY1bz0A1jFJqOjbujPhzh2Yq00LPLJgoXk',
  VERSION: import.meta.env.VITE_VERSION ?? 'Local-Dev',
  VERSION_UPDATE_CHECK: false,
  WEB_URL: 'http://localhost:3000',
  WEBSOCKET_URL: 'wss://api.dev.seeitsendit.com/graphql',
};

if (import.meta.env.VITE_STAGE === 'development') {
  environment = {
    AUTH0_AUDIENCE: 'https://dev.visualc3.com',
    AUTH0_CLIENT_ID: 'NFZGLkjlMp34b3iyD07w2qLzVXMM0aAV',
    AUTH0_DOMAIN: 'cloudscann-dev.us.auth0.com',
    AUTH0_REDIRECT_URI: 'https://dev.seeitsendit.com/authorize',
    AUTH0_SCOPE: 'openid profile email offline_access',
    GOOGLE_MAPS_KEY: 'AIzaSyDyQR55qA1O9Vbf9GViqzFBt5J7wOZgQfA',
    GOOGLE_MAPS_MAP_ID_DARKMODE: 'e5b618dd37c08d35',
    GOOGLE_MAPS_MAP_ID_LIGHTMODE: 'c7c2622d77bfa0e2',
    GRAPHQL_URL: 'https://api.dev.seeitsendit.com/graphql',
    KEY_PREFIX: 'dev_seeitsendit_com_',
    LOG_LEVEL: 'debug',
    SENTRY_DSN:
      'https://219fcfe1c74c4ab4bf92ef435b6deb94@o439376.ingest.sentry.io/5507387',
    SENTRY_TRACES_SAMPLE_RATE: '0.5',
    STAGE: 'development',
    STRIPE_KEY:
      'pk_test_51KWrAzE2BNDdEec0x1eTJoMHdythliV8gVhgJQqrm7RZy7DzXvhA11sQ759WjmiZuY1bz0A1jFJqOjbujPhzh2Yq00LPLJgoXk',
    VERSION: import.meta.env.VITE_VERSION as string,
    VERSION_UPDATE_CHECK: true,
    WEB_URL: 'https://dev.seeitsendit.com',
    WEBSOCKET_URL: 'wss://api.dev.seeitsendit.com/graphql',
  };
}

if (import.meta.env.VITE_STAGE === 'staging') {
  environment = {
    AUTH0_AUDIENCE: 'https://beta.visualc3.com',
    AUTH0_CLIENT_ID: '3nuyk0zsZ1X47KtCrZWJNS6YyIUg10lm',
    AUTH0_DOMAIN: 'cloudscann-stage.us.auth0.com',
    AUTH0_REDIRECT_URI: 'https://beta.seeitsendit.com/authorize',
    AUTH0_SCOPE: 'openid profile email offline_access',
    GOOGLE_MAPS_KEY: 'AIzaSyDyQR55qA1O9Vbf9GViqzFBt5J7wOZgQfA',
    GOOGLE_MAPS_MAP_ID_DARKMODE: 'e5b618dd37c08d35',
    GOOGLE_MAPS_MAP_ID_LIGHTMODE: 'c7c2622d77bfa0e2',
    GRAPHQL_URL: 'https://api.beta.seeitsendit.com/graphql',
    KEY_PREFIX: 'beta_seeitsendit_com_',
    LOG_LEVEL: 'warning',
    SENTRY_DSN:
      'https://4f8a25c709494563a5ba72187f96b2f7@o439376.ingest.sentry.io/5507387',
    SENTRY_TRACES_SAMPLE_RATE: '0.7',
    STAGE: 'staging',
    STRIPE_KEY:
      'pk_test_51KWrAzE2BNDdEec0x1eTJoMHdythliV8gVhgJQqrm7RZy7DzXvhA11sQ759WjmiZuY1bz0A1jFJqOjbujPhzh2Yq00LPLJgoXk',
    VERSION: import.meta.env.VITE_VERSION as string,
    VERSION_UPDATE_CHECK: true,
    WEB_URL: 'https://beta.seeitsendit.com',
    WEBSOCKET_URL: 'wss://api.beta.seeitsendit.com/graphql',
  };
}

if (import.meta.env.VITE_STAGE === 'production') {
  environment = {
    AUTH0_AUDIENCE: 'https://app.visualc3.com',
    AUTH0_CLIENT_ID: '2JMg8QPUqK0tL0xlt2t8rJ1FceQiMAv5',
    AUTH0_DOMAIN: 'cloudscann-prod.us.auth0.com',
    AUTH0_REDIRECT_URI: 'https://app.seeitsendit.com/authorize',
    AUTH0_SCOPE: 'openid profile email offline_access',
    GOOGLE_MAPS_KEY: 'AIzaSyDyQR55qA1O9Vbf9GViqzFBt5J7wOZgQfA',
    GOOGLE_MAPS_MAP_ID_DARKMODE: 'e5b618dd37c08d35',
    GOOGLE_MAPS_MAP_ID_LIGHTMODE: 'c7c2622d77bfa0e2',
    GRAPHQL_URL: 'https://api.app.seeitsendit.com/graphql',
    KEY_PREFIX: 'app_seeitsendit_com_',
    LOG_LEVEL: 'warning',
    SENTRY_DSN:
      'https://d63eabf2b4554262b3a11f826c75e546@o439376.ingest.sentry.io/5507387',
    SENTRY_TRACES_SAMPLE_RATE: '0.3',
    STAGE: 'production',
    STRIPE_KEY:
      'pk_live_51KWrAzE2BNDdEec0ooyfbRfixnf25Mbh6i4IEm7hXoAZqallW84DuA2Fhh6C1hdhxGv04wOzP6E7PValYnD3dBMI00gfdGAN9c',
    VERSION: import.meta.env.VITE_VERSION as string,
    VERSION_UPDATE_CHECK: true,
    WEB_URL: 'https://app.seeitsendit.com',
    WEBSOCKET_URL: 'wss://api.app.seeitsendit.com/graphql',
  };
}

const config = environment;

export { config };
