import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { Loader } from 'lucide-react';
import { useMutation } from 'urql';

const ResendPendingEmailAddressCodeQuery = graphql(`
  mutation ResendPendingEmailAddressCode($emailAddress: String!) {
    currentUserUpdateEmailAddress(input: { emailAddress: $emailAddress }) {
      query {
        currentUser {
          id
          pendingEmailAddress
        }
      }
    }
  }
`);

type ResendPendingEmailAddressCodeProps = {
  readonly pending?: string | null;
};

const ResendPendingEmailAddressCode = ({
  pending,
}: ResendPendingEmailAddressCodeProps) => {
  const [{ fetching }, verifyEmailAddress] = useMutation(
    ResendPendingEmailAddressCodeQuery,
  );

  if (!pending) {
    return null;
  }

  const handleSubmit = async () => {
    const response = await verifyEmailAddress({
      emailAddress: pending,
    });

    if (response.error) {
      toast.error('Problem sending verification code!', {
        description: response.error.message,
      });
    } else {
      toast.success('Verification code sent');
    }
  };

  return (
    <Button
      className="h-8"
      disabled={fetching}
      onClick={handleSubmit}
      variant="outline"
    >
      {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
      Resend Code
    </Button>
  );
};

export { ResendPendingEmailAddressCode };
