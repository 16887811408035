import { graphql } from '@/gql';
import { useMutation } from 'urql';
import { GroupSelect } from '@/components/GroupSelect';

const EnableIncidentTypeWithGroupQuery = graphql(`
  mutation EnableIncidentTypeWithGroupQuery(
    $organizationId: String!
    $incidentTypeId: String!
    $groupId: String!
  ) {
    enableIncidentForOrganization(
      input: {
        organizationId: $organizationId
        incidentTypeId: $incidentTypeId
        defaultAssignedGroupId: $groupId
      }
    ) {
      organization {
        id
        enabledIncidentTypes {
          totalCount
          nodes {
            id
            organizationId
            incidentTypeId
            defaultAssignedGroup {
              id
              name
              members {
                totalCount
              }
            }
          }
        }
      }
    }
  }
`);

const UpdateIncidentTypeWithGroupQuery = graphql(`
  mutation UpdateIncidentTypeWithGroupQuery($id: String!, $groupId: String!) {
    updateEnabledIncidentTypeAssignedGroup(
      input: { id: $id, groupId: $groupId }
    ) {
      enabledIncidentType {
        id
        defaultAssignedGroup {
          id
          name
        }
      }
    }
  }
`);

type AssignDefaultGroupToIncidentTypeModalProps = {
  assignedGroupId?: string;
  disabled: boolean;
  enabledIncidentTypeId?: string;
  groups: Array<{ id: string; name: string }>;
  incidentTypeId: string;
  organizationId: string;
};

const AssignDefaultGroupToIncidentTypeModal = ({
  organizationId,
  enabledIncidentTypeId,
  disabled,
  assignedGroupId,
  groups,
  incidentTypeId,
}: AssignDefaultGroupToIncidentTypeModalProps) => {
  const [, updateIncidentType] = useMutation(UpdateIncidentTypeWithGroupQuery);
  const [, enableIncidentType] = useMutation(EnableIncidentTypeWithGroupQuery);

  if (disabled) {
    return null;
  }

  const handleOnChange = async (newValue: string) => {
    if (enabledIncidentTypeId) {
      await updateIncidentType({
        groupId: newValue === assignedGroupId ? '' : newValue,
        id: enabledIncidentTypeId,
      });
    } else {
      await enableIncidentType({
        groupId: newValue,
        incidentTypeId,
        organizationId,
      });
    }
  };

  return <GroupSelect
    className="h-8 w-64"
    onChange={handleOnChange}
    value={assignedGroupId ?? ''}
    allowEmpty
    groups={groups}
  />

};

export { AssignDefaultGroupToIncidentTypeModal };
