import { MemberRole } from '../gql/graphql';

const normalizeMemberRole = (role?: MemberRole) => {
  switch (role) {
    case MemberRole.Owner:
      return 'Owner';
    case MemberRole.Administrator:
      return 'Administrator';
    case MemberRole.Supervisor:
      return 'Supervisor';
    case MemberRole.Member:
      return 'Member';
    case MemberRole.ExternalMonitoringAgent:
      return 'External Agent';
    case MemberRole.Visualc3:
      return 'VisualC3 Support';
    default:
      return 'Member';
  }
};

export { normalizeMemberRole };
