import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { useAppStore } from '@/stores';
import { Loader } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

const PlaceDeleteQuery = graphql(`
  mutation PlaceDeleteQuery($placeId: String!) {
    deletePlace(input: { id: $placeId }) {
      place {
        id
        name
      }
    }
  }
`);

type PlaceDeleteProps = {
  placeId: string;
};

const PlaceDelete = ({ placeId }: PlaceDeleteProps) => {
  const navigate = useNavigate();

  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);

  const [{ fetching }, deletePlace] = useMutation(PlaceDeleteQuery);

  if (!isAdmin) {
    return null;
  }

  const handleDelete = async () => {
    const response = await deletePlace({ placeId });

    if (response.error) {
      toast.error('Problem deleting place!', {
        description: response.error.message,
      });
    } else {
      toast.success(
        `Place '${response.data?.deletePlace?.place?.name}' deleted`,
      );
      navigate(`/settings/places`);
    }
  };

  return (
    <Button
      disabled={fetching}
      onClick={handleDelete}
      type="button"
      variant="destructive"
    >
      {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
      Delete
    </Button>
  );
};

export { PlaceDelete };
