import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { CalendarDays } from 'lucide-react';
import { type HTMLAttributes } from 'react';
import { type DateRange } from 'react-day-picker';

type DateRangePickerProps = HTMLAttributes<HTMLDivElement> & {
  readonly dateRange?: DateRange;
  readonly setDateRange: (newState?: DateRange) => void;
};

const DateRangePicker = ({
  className,
  dateRange,
  setDateRange,
}: DateRangePickerProps) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className={cn('gap-2 justify-center', className)}
          id="date"
          variant="outline"
        >
          <CalendarDays className="h-4 w-4" />
          {dateRange?.from ? (
            dateRange.to ? (
              <>
                {format(dateRange.from, 'LLL dd, y')} -{' '}
                {format(dateRange.to, 'LLL dd, y')}
              </>
            ) : (
              format(dateRange.from, 'LLL dd, y')
            )
          ) : (
            <>All Time</>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="w-auto p-0"
      >
        <Calendar
          defaultMonth={dateRange?.from}
          footer={
            <Button
              className="w-full h-8 mt-2"
              onClick={() => setDateRange({ from: undefined, to: undefined })}
              variant="secondary"
            >
              Clear Filter
            </Button>
          }
          initialFocus
          mode="range"
          numberOfMonths={1}
          onSelect={setDateRange}
          selected={dateRange}
        />
      </PopoverContent>
    </Popover>
  );
};

export { DateRangePicker };
