// eslint-disable-next-line import/no-unassigned-import
import './index.css';
import { App } from './App';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.querySelector('#root') as HTMLElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
