import { forwardRef, type TdHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type TableCellProps = TdHTMLAttributes<HTMLTableCellElement> & {
  justify?: 'center' | 'end' | 'start';
};

const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  (props, ref) => {
    const { children, className, justify = 'start', ...extra } = props;

    return (
      <td
        {...extra}
        className={twMerge('h-px w-px whitespace-nowrap', className)}
        ref={ref}
      >
        <div
          className={twMerge(
            'py-2 flex items-center gap-2',
            justify === 'start' && 'pl-6 justify-start',
            justify === 'end' && 'pr-6 justify-end',
            justify === 'center' && 'px-3 justify-center',
          )}
        >
          {children}
        </div>
      </td>
    );
  },
);

export { TableCell };
