import { useTableControls } from '@/hooks/useTableControls';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useIncidentTableControls = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();
  const tableControls = useTableControls();

  const filter = searchParameters.getAll('filter');

  const open = filter.includes('open');
  const inProgress = filter.includes('inProgress');
  const closed = filter.includes('closed');

  const high = filter.includes('high');
  const medium = filter.includes('medium');
  const low = filter.includes('low');
  const urgent = filter.includes('urgent');

  const view = searchParameters.get('view') as
    | null
    | 'submittedByMe'
    | 'assignedToMyGroup';

  const setStatusFilter = useMemo(
    () => (status: 'closed' | 'inProgress' | 'open', value: boolean) => {
      setSearchParameters((previous) => {
        if (value) {
          previous.append('filter', status);
        } else {
          previous.delete('filter', status);
        }

        return previous;
      });
    },
    [setSearchParameters],
  );

  const setPriorityFilter = useMemo(
    () => (priority: 'high' | 'low' | 'medium' | 'urgent', value: boolean) => {
      setSearchParameters((previous) => {
        if (value) {
          previous.append('filter', priority);
        } else {
          previous.delete('filter', priority);
        }

        return previous;
      });
    },
    [setSearchParameters],
  );

  const setViewFilter = useMemo(
    () => (value: 'all' | 'submittedByMe' | 'assignedToMyGroup') => {
      setSearchParameters((previous) => {
        if (value === 'all') {
          previous.delete('view');
        } else {
          previous.set('view', value);
        }

        return previous;
      });
    },
    [setSearchParameters],
  );

  const clearFilter = useMemo(
    () => () => {
      setSearchParameters((previous) => {
        previous.delete('filter');
        return previous;
      });
    },
    [setSearchParameters],
  );

  return {
    ...tableControls,
    clearFilter,
    hasFilter: {
      priority: high || low || medium || urgent,
      status: closed || inProgress || open,
      view: view !== null,
    },
    priority: {
      high,
      low,
      medium,
      urgent,
    },
    setPriorityFilter,
    setStatusFilter,
    setViewFilter,
    status: {
      closed,
      inProgress,
      open,
    },
    view,
  };
};

export { useIncidentTableControls };
