import { createCurrentUserSlice } from './createCurrentUserSlice';
import { createMembershipsSlice } from './createMembershipsSlice';
import { createSettingsSlice } from './createSettingsSlice';
import { type AppStore } from './types';
import { config } from '@/config';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useAppStore = create<AppStore>()(
  persist(
    (...a) => ({
      ...createCurrentUserSlice(...a),
      ...createMembershipsSlice(...a),
      ...createSettingsSlice(...a),
    }),
    {
      migrate: (persistedState, version) => {
        let migratedState = persistedState;

        if (version === 0) {
          const state = persistedState as Pick<AppStore, 'activeMembership'>;
          // if the stored value is in version 0, we add
          // the missing defaultMapCenter and defaultMapZoom
          if (state.activeMembership) {
            state.activeMembership.defaultMapCenter = {
              latitude: 40.15,
              longitude: -95.25,
            };
            state.activeMembership.defaultMapZoom = 3;
          }

          migratedState = state;
        }

        return migratedState;
      },
      name: config.KEY_PREFIX,
      partialize: (state) => ({
        activeMembership: state.activeMembership,
      }),
      version: 1,
    },
  ),
);
