import {
  format as upstreamFormat,
  formatDistanceToNow,
  interval,
  intervalToDuration,
  parseISO,
  startOfISOWeek,
} from 'date-fns';

const toDatetime = (input?: Date | string | null) => {
  if (!input) {
    return new Date();
  }

  if (typeof input === 'string') {
    try {
      return parseISO(input);
    } catch {
      return new Date();
    }
  }

  return input;
};

const fromLocalDate = (input: string | null) => {
  if (!input) {
    return null;
  }

  const components = input.split('-');

  const year = Number.parseInt(components[0], 10);
  const month = Number.parseInt(components[1], 10) - 1;
  const day = Number.parseInt(components[2], 10);

  return new Date(year, month, day);
};

const formatDatetime = ({
  variant = 'datetime',
  datetime,
  format = 'MMM dd, yy, hh:mm aaa',
  end,
}: {
  datetime?: Date | string | null;
  end?: Date | string | null;
  format?: string;
  start?: Date | string | null;
  variant?: 'datetime' | 'distance' | 'duration';
}): string => {
  if (!datetime) {
    return '';
  }

  const parsedDatetime = toDatetime(datetime);

  if (variant === 'distance') {
    return formatDistanceToNow(parsedDatetime);
  }

  if (variant === 'duration') {
    const duration = intervalToDuration({
      end: toDatetime(end),
      start: parsedDatetime,
    });

    const components: string[] = [];
    if (duration.years && duration.years > 0) {
      components.push(`${duration.years}y`);
    }

    if (duration.months && duration.months > 0) {
      components.push(`${duration.months}m`);
    }

    if (duration.weeks && duration.weeks > 0) {
      components.push(`${duration.weeks}w`);
    }

    if (duration.days && duration.days > 0) {
      components.push(`${duration.days}d`);
    }

    if (duration.hours && duration.hours > 0) {
      components.push(`${duration.hours}h`);
    }

    if (duration.minutes && duration.minutes > 0) {
      components.push(`${duration.minutes}m`);
    }

    if (duration.seconds && duration.seconds > 0) {
      components.push(`${duration.seconds}s`);
    }

    return components.join(' ');
  }

  return upstreamFormat(parsedDatetime, format);
};

const formatTimer = ({
  start,
  end,
}: {
  end?: Date | string | null;
  start: Date | string;
}): string => {
  const duration = intervalToDuration({
    end: toDatetime(end),
    start: toDatetime(start),
  });

  let skipSeconds = false;
  const components: string[] = [];
  if (duration.years && duration.years > 0) {
    components.push(`${duration.years}y`);
    skipSeconds = true;
  }

  if (duration.months && duration.months > 0) {
    components.push(`${duration.months}m`);
    skipSeconds = true;
  }

  if (duration.weeks && duration.weeks > 0) {
    components.push(`${duration.weeks}w`);
    skipSeconds = true;
  }

  if (duration.days && duration.days > 0) {
    components.push(`${duration.days}d`);
    skipSeconds = true;
  }

  if (duration.hours && duration.hours > 0) {
    components.push(`${duration.hours}h`);
    skipSeconds = true;
  }

  if (duration.minutes && duration.minutes > 0) {
    components.push(`${duration.minutes}m`);
  }

  if (!skipSeconds && duration.seconds && duration.seconds > 0) {
    components.push(`${duration.seconds}s`);
  }

  return components.join(' ');
};

const startOfWeek = startOfISOWeek;

const daysFromStartOfWeek = () => {
  return (
    (intervalToDuration(interval(startOfWeek(Date.now()), Date.now())).days ??
      0) + 1
  );
};

export {
  daysFromStartOfWeek,
  formatDatetime,
  formatTimer,
  fromLocalDate,
  startOfWeek,
  toDatetime,
};
