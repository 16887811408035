import { useIncidentsAssignedToMeTableControls } from '../hooks/useIncidentsAssignedToMeTableControls';
import { ChangeIncidentPriorityMenu } from './ChangeIncidentPriorityMenu';
import { ChangeIncidentStatusMenu } from './ChangeIncidentStatusMenu';
import { Loader } from '@/components/Loader';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  ControlledTablePagnination,
  TableRowSkeleton,
} from '@/components/ui/TableWrapper';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { graphql } from '@/gql';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const IncidentsAssignedToMeGql = graphql(`
  query IncidentsAssignedToMeGql($offset: Int!, $first: Int!) {
    incidents(
      offset: $offset
      first: $first
      orderBy: [ID_DESC]
      condition: {
        assignedToMeOrMyGroups: true
        status: { closed: false, open: true, inProgress: true }
      }
    ) {
      totalCount
      nodes {
        id
        organization {
          id
          name
          logoUrl
        }
        shortId
        subject
        priority
        contactMemberId
        contactMember {
          id
          fullName
          displayName
        }
        incidentType {
          id
          name
          iconSvg
        }
        status
        updatedAt
      }
    }
  }
`);

const IncidentsAssignedToMe = () => {
  const controls = useIncidentsAssignedToMeTableControls();

  const [{ data, error, fetching }] = useQuery({
    query: IncidentsAssignedToMeGql,
    variables: {
      first: controls.pageSize,
      offset: controls.offset,
    },
  });

  return (
    <Card>
      <CardHeader className="p-4">
        <CardTitle className="text-lg flex flex-col md:flex-row md:items-center gap-2">
          Assigned Incidents
          {(data?.incidents?.totalCount ?? 0) > 0 && (
            <span className="text-sm font-normal text-muted-foreground">
              ({data?.incidents?.totalCount.toLocaleString()})
            </span>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent className="px-0">
        <Loader loadingError={error}>
          <Table className="text-xs">
            <TableHeader>
              <TableRow>
                <TableHead className="pl-6 h-8" />
                <TableHead className="h-8">Id</TableHead>
                <TableHead className="h-8">Subject</TableHead>
                <TableHead className="h-8">Status</TableHead>
                <TableHead className="h-8">Priority</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {fetching && (
                <TableRowSkeleton
                  cols={5}
                  rows={10}
                />
              )}
              {!fetching && data?.incidents?.nodes.length === 0 && (
                <TableRow className="last:border-none hover:bg-inherit">
                  <TableCell
                    className="text-center text-base"
                    colSpan={5}
                  >
                    No Assigned Incidents
                  </TableCell>
                </TableRow>
              )}
              {!fetching &&
                data?.incidents?.nodes.map((item) => (
                  <TableRow
                    className="last:border-none last:rounded-b-lg"
                    key={item.id}
                  >
                    <TableCell className="p-2 pl-6">
                      <Avatar className="w-8 h-8 rounded-none">
                        <AvatarImage
                          alt={item.organization?.name}
                          src={item.organization?.logoUrl ?? undefined}
                        />
                        <AvatarFallback>
                          {item.organization?.name.slice(0, 1)}
                        </AvatarFallback>
                      </Avatar>
                    </TableCell>
                    <TableCell className="p-2">
                      <Link
                        className="whitespace-nowrap font-bold hover:underline"
                        to={`/incidents/${item.id}`}
                      >
                        {item.shortId}
                      </Link>
                    </TableCell>
                    <TableCell className="p-2">
                      <div className="flex items-center">
                        <Badge
                          className="mr-2"
                          variant="secondary"
                        >
                          <img
                            alt={item.incidentType?.name}
                            className="h-4 w-4 mr-2"
                            src={`data:image/svg+xml;base64,${item.incidentType?.iconSvg}`}
                          />
                          {item.incidentType?.name}
                        </Badge>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <span className="font-bold overflow-hidden truncate max-w-20 lg:max-w-72 xl:max-w-md 2xl:max-w-lg">
                              {item.subject}
                            </span>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{item.subject}</p>
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell className="p-2">
                      <ChangeIncidentStatusMenu
                        incidentId={item.id}
                        status={item.status}
                      />
                    </TableCell>
                    <TableCell className="p-2">
                      <ChangeIncidentPriorityMenu
                        incidentId={item.id}
                        priority={item.priority}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Loader>
      </CardContent>
      <CardFooter>
        <ControlledTablePagnination
          currentPage={controls.page}
          pageSize={controls.pageSize}
          setPage={controls.setPage}
          totalCount={data?.incidents?.totalCount}
        />
      </CardFooter>
    </Card>
  );
};

export { IncidentsAssignedToMe };
