import { GuardMePanicDialog } from './GuardMePanicDialog';
import { Button } from '@/components/ui/button';
import { AlertTriangle } from 'lucide-react';

type Props = {
  guardmeId: string;
  organization?: {
    settingIsRapidsosOperatorFlowEnabled: boolean;
    settingIsRapidsosPanicButtonFlowEnabled: boolean;
    settingRapidsosSocPhoneNumber: string;
  } | null;
};

const GuardMePanicButton = ({ guardmeId, organization }: Props) => {
  return (
    <GuardMePanicDialog
      guardmeId={guardmeId}
      organization={organization}
    >
      <Button
        size="sm"
        variant="destructive"
      >
        <AlertTriangle className="h-5 w-5 mr-2" />
        Panic
      </Button>
    </GuardMePanicDialog>
  );
};

export { GuardMePanicButton };
