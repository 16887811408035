type ObjectWithStringIndex = {
  [name: string]: unknown;
};

type Diff = {
  key: string;
  new: string | null;
  old: string | null;
  type: string;
};

enum DiffType {
  VALUE_CREATED = 'created',
  VALUE_DELETED = 'deleted',
  VALUE_UNCHANGED = 'unchanged',
  VALUE_UPDATED = 'updated',
}

const isArray = (x: unknown): x is unknown[] => {
  return Array.isArray(x);
};

const isDate = (x: unknown): x is Date => {
  return x instanceof Date;
};

const isFunction = (x: unknown): x is Function => {
  return x instanceof Function;
};

const isObject = (x: unknown): x is ObjectWithStringIndex => {
  return x instanceof Object;
};

const isValue = (x: unknown): x is boolean | number | string => {
  return !isObject(x) && !isArray(x);
};

const compareValues = (value1: unknown, value2: unknown) => {
  if (value1 === value2) {
    return DiffType.VALUE_UNCHANGED;
  }

  if (
    isDate(value1) &&
    isDate(value2) &&
    value1.getTime() === value2.getTime()
  ) {
    return DiffType.VALUE_UNCHANGED;
  }

  if (value1 === undefined) {
    return DiffType.VALUE_CREATED;
  }

  if (value2 === undefined) {
    return DiffType.VALUE_DELETED;
  }

  return DiffType.VALUE_UPDATED;
};

// eslint-disable-next-line complexity
const deepDiffMapper = (
  object1: unknown,
  object2: unknown,
  parent = '',
): Diff[] => {
  if (isFunction(object1) || isFunction(object2)) {
    throw 'Invalid argument. Function given, object expected.';
  }

  const diffs: Diff[] = [];

  if (isObject(object1) && isObject(object2)) {
    // We need to all keys on object1
    for (const key in object1) {
      if (isFunction(object1[key])) {
        continue;
      }

      let value2;
      if (object2[key] !== undefined) {
        value2 = object2[key];
      }

      const record = deepDiffMapper(
        object1[key],
        value2,
        parent ? `${parent}.${key}` : key,
      );

      if (record) {
        for (const rec of record) {
          diffs.push(rec);
        }
      }
    }

    // We need to check all keys on objec1 in case a new key didn't exist on object1
    for (const key in object2) {
      if (isFunction(object2[key])) {
        continue;
      }

      let value2;
      if (object1[key] !== undefined) {
        value2 = object1[key];
      }

      const record = deepDiffMapper(
        object2[key],
        value2,
        parent ? `${parent}.${key}` : key,
      );

      if (record) {
        for (const rec of record) {
          if (diffs.findIndex((item) => item.key === rec.key) === -1)
            diffs.push(rec);
        }
      }
    }

    return diffs;
  }

  if (isValue(object1) || isValue(object2)) {
    const type = compareValues(object1, object2);
    if (type === DiffType.VALUE_UNCHANGED) {
      return [];
    } else {
      if (
        isObject(object1) ||
        isObject(object2) ||
        parent === 'additional_data' ||
        parent === 'additional_data'
      ) {
        return [];
      }

      return [
        {
          key: parent,
          new: object2?.toString() ?? null,
          old: object1?.toString() ?? null,
          type: compareValues(object1, object2),
        },
      ];
    }
  }

  return diffs;
};

export { deepDiffMapper, DiffType };
