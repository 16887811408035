import Logo from '@/assets/images/logo/sisi.png';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { config } from '@/config';
import { useTheme } from '@/providers/ThemeProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { Moon, Sun } from 'lucide-react';
import { useLocation } from 'react-router-dom';

const SignIn = () => {
  const location = useLocation();
  const { setTheme } = useTheme();

  const { loginWithRedirect } = useAuth0();

  const loginEmail = async () => {
    await loginWithRedirect({
      appState: {
        loginMethod: 'email',
        returnTo: location.pathname,
      },
      authorizationParams: {
        passwordless_method: 'email',
      },
    });
  };

  const loginSMS = async () => {
    await loginWithRedirect({
      appState: {
        loginMethod: 'sms',
        returnTo: location.pathname,
      },
      authorizationParams: {
        passwordless_method: 'sms',
      },
    });
  };

  return (
    <div className="flex flex-col md:min-h-screen outline-none pt-0">
      <main className="flex flex-col flex-1 items-center justify-center">
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <img
              alt="See It Send It"
              className="w-full mb-3"
              src={Logo}
            />
            <CardTitle className="text-2xl text-center">Sign In</CardTitle>
            <CardDescription>
              Empower your organization to protect the safety of your people and
              business.
            </CardDescription>
          </CardHeader>

          <CardContent>
            <div className="grid gap-4">
              <Button
                className="w-full"
                onClick={loginSMS}
              >
                Sign In with Phone Number
              </Button>
              <Button
                className="w-full"
                onClick={loginEmail}
                variant="outline"
              >
                Sign In with Email Address
              </Button>
            </div>
            <div className="mt-4 text-center text-sm">
              Don&apos;t have an account?{' '}
              <p className="mt-0.5">
                Sign in with your phone number or email address
                <br /> and an account will be made for you.
              </p>
            </div>
          </CardContent>
          <CardFooter className="bg-muted dark:bg-muted/30 p-4 flex-col text-sm">
            <p className="text-center">
              By signing in you accept and acknowledge
              <br /> the{' '}
              <a
                className="underline hover:text-primary"
                href="https://www.seeitsendit.com/terms-of-use"
              >
                Terms of Use
              </a>{' '}
              and{' '}
              <a
                className="underline hover:text-primary"
                href="https://www.seeitsendit.com/privacy-policy"
              >
                Privacy Policy
              </a>
            </p>
          </CardFooter>
        </Card>
      </main>

      <footer className="text-sm flex items-center justify-between md:mt-auto py-4 md:py-0">
        <div className="py-4 px-8 flex items-center justify-start">
          <span>v{config.VERSION}</span>
        </div>
        <div className="flex items-center py-4 px-8 gap-2 justify-center md:justify-end">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                className="h-6"
                size="icon"
                variant="ghost"
              >
                <Sun className="h-[1rem] w-[1rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
                <Moon className="absolute h-[1rem] w-[1rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
                <span className="sr-only">Toggle theme</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => setTheme('light')}>
                Light
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTheme('dark')}>
                Dark
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTheme('system')}>
                System
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <a
            className="block md:inline hover:text-primary underline"
            href="https://seeitsendit.com/contact"
            rel="noreferrer noopener"
            target="_blank"
          >
            Contact Us
          </a>
        </div>
      </footer>
    </div>
  );
};

export { SignIn };
