import { type TextareaHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const inputSizes = {
  lg: 'py-2 px-4 sm:p-5',
  md: 'py-2 px-4',
  sm: 'py-2 px-3',
} as const;

type TextareaInputProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  loading?: boolean;
  size?: keyof typeof inputSizes;
};

const TextareaInput = forwardRef<HTMLTextAreaElement, TextareaInputProps>(
  (props, ref) => {
    const {
      className,
      disabled,
      loading,
      placeholder,
      readOnly,
      size = 'md',
      value,
      ...extra
    } = props;

    return (
      <div className={twMerge('relative w-full', className)}>
        <textarea
          {...extra}
          className={twMerge(
            'block w-full border-gray-200 rounded-md text-sm',
            'focus:border-blue-500 focus:ring-blue-500',
            'dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400',
            inputSizes[size],
            disabled && 'opacity-70 pointer-events-none',
          )}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
          rows={4}
          value={loading ? 'Loading...' : value}
        />
      </div>
    );
  },
);

export { TextareaInput, type TextareaInputProps };
