import { Loader } from '@/components';
import { Datetime } from '@/components/Datetime';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { graphql } from '@/gql';
import { StripePlanInterval } from '@/gql/graphql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError, USDollar } from '@/utils';
import { Eye } from 'lucide-react';
import { useQuery } from 'urql';

const OrganizationBillingGql = graphql(`
  query OrganizationBillingGql($organizationId: String!, $after: String) {
    organization(id: $organizationId) {
      id
      createdAt
      plan {
        id
        name
        description
        featureList
        featureRapidsos
        isExternalBilling
      }
      subscription(after: $after) {
        id
        billingCycleAnchor
        createdAt
        currentPeriodEnd
        currentPeriodStart
        items {
          createdAt
          id
          planAmount
          planInterval
          priceUnitAmount
          quantity
        }
        hasMore
      }
    }
  }
`);

const Billing = () => {
  const [organizationId, isAdmin] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.isAdmin,
  ]);

  if (!organizationId || !isAdmin) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }] = useQuery({
    query: OrganizationBillingGql,
    variables: {
      after: null,
      organizationId,
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Billing</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <Loader
          isLoading={fetching}
          loadingError={error}
        >
          {data?.organization && (
            <>
              <div className="bg-muted/20 border p-4 rounded-xl">
                <div className="flex items-center justify-between">
                  <h3 className="text-xl font-bold">
                    {data.organization.plan?.name} Plan
                  </h3>
                </div>
                <p className="mt-0.5">{data.organization.plan?.description}</p>

                <ol className="mt-4 space-y-2">
                  {data.organization.plan?.featureList.map((item) => (
                    <li
                      className="flex items-center truncate mr-2"
                      key={item}
                    >
                      <Eye className="h-4 w-4 mr-2 text-primary" />
                      <p className="text-sm whitespace-nowrap truncate text-muted-foreground">
                        {item}
                      </p>
                    </li>
                  ))}
                </ol>
              </div>

              {data.organization.subscription &&
                !data.organization.plan?.isExternalBilling && (
                  <div className="flex items-center justify-between">
                    <h4 className="font-semibold">
                      Current Subscription Period
                    </h4>
                    <div>
                      <Datetime
                        datetime={
                          data.organization.subscription.currentPeriodStart
                        }
                        format="MMM dd, yyyy"
                      />
                      &nbsp;to&nbsp;
                      <Datetime
                        datetime={
                          data.organization.subscription.currentPeriodEnd
                        }
                        format="MMM dd, yyyy"
                      />
                    </div>
                  </div>
                )}
              {!data.organization.plan?.isExternalBilling && (
                <>
                  <h4 className="font-semibold">Subscription History</h4>
                  <ol className="divide-y">
                    {data.organization.subscription?.items.map((item) => (
                      <li
                        className="flex items-center justify-between"
                        key={item.id}
                      >
                        <Datetime
                          datetime={item.createdAt}
                          format="MMM dd, yyyy"
                        />
                        {item.quantity} Members @&nbsp;
                        {USDollar.format(item.planAmount / 100)} USD&nbsp;
                        {item.planInterval === StripePlanInterval.Month &&
                          'Monthly'}
                        {item.planInterval === StripePlanInterval.Year &&
                          'Yearly'}
                      </li>
                    ))}
                  </ol>
                </>
              )}
            </>
          )}
        </Loader>
      </CardContent>
    </Card>
  );
};

export { Billing };
