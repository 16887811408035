import { Button } from './ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { type Group } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { CheckCircleIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { forwardRef } from 'react';

type GroupSelectProps = {
  readonly allowEmpty?: boolean;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly groups?: Array<Pick<Group, 'name' | 'id'>> | null;
  readonly onChange: (newValue: string) => void | Promise<void>;
  readonly readonly?: boolean;
  readonly value: string;
};

const GroupSelect = forwardRef<HTMLButtonElement, GroupSelectProps>(
  (props, ref) => {
    const {
      allowEmpty = false,
      className,
      disabled = false,
      groups,
      onChange,
      readonly = false,
      value,
    } = props;

    const group = groups?.find((item) => item.id === value);

    if (readonly) {
      return (
        <div
          className={cn(
            'inline-flex items-center justify-center rounded-md text-sm font-medium h-9 rounded-md px-3 border border-input bg-background',
            className,
          )}
        >
          {group ? (
            <span className="flex items-center">{group.name}</span>
          ) : (
            <>Select group...</>
          )}
        </div>
      );
    }

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            className={cn('justify-between group', className)}
            disabled={disabled}
            ref={ref}
            size="sm"
            variant="outline"
          >
            {group ? (
              <span className="flex items-center">{group.name}</span>
            ) : (
              <>Select group...</>
            )}
            <ChevronUpDownIcon className="ml-4 h-4 w-4 shrink-0 opacity-25 group-hover:opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          className="p-0 PopoverContent"
          side="bottom"
        >
          <Command>
            <CommandInput placeholder="Filter groups..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {groups?.map((item) => (
                  <CommandItem
                    className={item.id === group?.id ? 'bg-accent/50' : ''}
                    key={item.id}
                    onSelect={async () => {
                      if (allowEmpty && item.id === group?.id) {
                        await onChange('');
                      } else {
                        await onChange(item.id);
                      }
                    }}
                    value={`${item.id}`}
                  >
                    <span>{item.name}</span>
                    {item.id === group?.id && (
                      <CheckCircleIcon className="ml-auto h-6 w-6 shrink-0 fill-primary" />
                    )}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

export { GroupSelect, type GroupSelectProps };
