import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { X } from 'lucide-react';
import { useMutation } from 'urql';

const CancelMembershipReqeustQueryDocument = graphql(`
  mutation CancelMembershipReqeustQueryDocument($id: String!) {
    cancelMembershipRequest(input: { id: $id }) {
      query {
        currentUser {
          id
          membershipRequests {
            totalCount
            nodes {
              id
              organizationName
              organizationId
            }
          }
        }
      }
    }
  }
`);

type CancelMembershipReqeustProps = {
  readonly membershipRequestId: string;
  readonly organizationName?: string | null;
};

const CancelMembershipReqeust = ({
  membershipRequestId,
  organizationName,
}: CancelMembershipReqeustProps) => {
  const [, cancelMembershipRequest] = useMutation(
    CancelMembershipReqeustQueryDocument,
  );

  if (!organizationName) {
    return null;
  }

  const handleClick = async () => {
    const response = await cancelMembershipRequest({ id: membershipRequestId });

    if (response.error) {
      toast.error('Problem canceling membership request!', {
        description: response.error.message,
      });
    } else {
      toast.success('Cancelled membership request');
    }
  };

  return (
    <Button
      className="h-8"
      onClick={handleClick}
      variant="destructive"
    >
      <X className="h-4 w-4 mr-2" />
      Cancel Request
    </Button>
  );
};

export { CancelMembershipReqeust };
