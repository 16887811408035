import { graphql } from '@/gql';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberComponentGql = graphql(`
  query MemberComponentGql($id: String!) {
    member(id: $id) {
      id
      fullName
      displayName
      avatarUrl
    }
  }
`);

type MemberProps = {
  readonly memberId?: string | null | undefined;
};

const Member = ({ memberId }: MemberProps) => {
  const [{ data, error, fetching }] = useQuery({
    pause: !memberId,
    query: MemberComponentGql,
    variables: {
      id: memberId ?? '',
    },
  });

  if (memberId === '') {
    return <span className="inine-flex items-center">---</span>;
  }

  if (fetching) {
    return <span className="inline-flex items-center">Loading...</span>;
  }

  if (error) {
    return <span className="inline-flex items-center">Missing data</span>;
  }

  if (data?.member) {
    return (
      <span className="inline-flex items-center">
        <Link
          className="hover:underline"
          to={`/members/${data.member.id}`}
        >
          {data.member.displayName ?? data.member.fullName}
        </Link>
      </span>
    );
  }

  return <span className="inline-flex items-center">Deleted member</span>;
};

export { Member };
