import {
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@/components/ui/dropdown-menu';
import { graphql } from '@/gql';
import { IncidentStatusType } from '@/gql/graphql';
import { useCallback } from 'react';
import { useMutation } from 'urql';

const IncidentStatusMenuRadioGroupGql = graphql(`
  mutation IncidentStatusMenuRadioGroupGql(
    $incidentId: String!
    $status: IncidentStatusType!
  ) {
    updateIncidentStatus(input: { incidentId: $incidentId, status: $status }) {
      incident {
        id
        status
        incidentUpdates {
          nodes {
            id
            type
            message
            createdAt
            isSystemMessage
            performedByMember {
              id
              fullName
              displayName
              avatarUrl
            }
            attachments {
              nodes {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`);

type IncidentStatusMenuRadioGroupProps = {
  incidentId: string;
  status: IncidentStatusType;
};

const IncidentStatusMenuRadioGroup = ({
  incidentId,
  status,
}: IncidentStatusMenuRadioGroupProps) => {
  const [, updateStatus] = useMutation(IncidentStatusMenuRadioGroupGql);

  const onStatusChange = useCallback(
    (newValue: string) => {
      if (status !== newValue) {
        updateStatus({
          incidentId,
          status: newValue as IncidentStatusType,
        });
      }
    },
    [updateStatus, incidentId, status],
  );

  return (
    <DropdownMenuRadioGroup
      onValueChange={onStatusChange}
      value={status}
    >
      <DropdownMenuRadioItem value={IncidentStatusType.Open}>
        Open
      </DropdownMenuRadioItem>
      <DropdownMenuRadioItem value={IncidentStatusType.InProgress}>
        In-Progress
      </DropdownMenuRadioItem>
      <DropdownMenuRadioItem value={IncidentStatusType.Closed}>
        Closed
      </DropdownMenuRadioItem>
    </DropdownMenuRadioGroup>
  );
};

export { IncidentStatusMenuRadioGroup };
