import { isEmailAddress, isPhoneNumber } from './utils';
import { object, refine, string } from 'superstruct';
import { type ObjectSchema } from 'superstruct/dist/utils';

const emailAddress = () =>
  refine(string(), 'EmailAddress', (value) => {
    if (isEmailAddress(value)) {
      return true;
    }

    return `Expected valid email address but received ${JSON.stringify(value)}`;
  });

const phoneNumber = () =>
  refine(string(), 'PhoneNumber', (value) => {
    if (isPhoneNumber(value)) {
      return true;
    }

    return `Expected valid phone number but received ${JSON.stringify(value)}`;
  });

const json = () =>
  refine(string(), 'JSON', (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        return `Expected valid JSON: ${error.message}`;
      }

      return 'Expected valid JSON';
    }
  });

const isoDatetime = () =>
  refine(string(), 'ISODatetime', (value) => {
    try {
      // eslint-disable-next-line no-new
      new Date(value);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        return `Expected valid datetime: ${error.message}`;
      }

      return 'Expected valid datetime';
    }
  });

const integerFromInput = (min?: number, max?: number) =>
  refine(string(), 'IntegerFromInput', (value) => {
    try {
      if (value.includes('.')) {
        return 'Unexpected decimal';
      }

      const number = Number.parseInt(value, 10);
      if (
        min !== undefined &&
        max !== undefined &&
        min <= number &&
        number <= max
      ) {
        return true;
      }

      if (max !== undefined && number <= max) {
        return true;
      }

      if (min !== undefined && min <= number) {
        return true;
      }

      return false;
    } catch (error) {
      if (error instanceof Error) {
        return `Expected valid integer: ${error.message}`;
      }

      return 'Expected valid integer';
    }
  });

const anyOf = <S extends ObjectSchema>(schema: S) =>
  refine(object(schema), 'AnyOf', (value) => {
    let atLeastOneTrue = false;
    for (const objectValue of Object.values(value)) {
      if (typeof objectValue === 'boolean') {
        atLeastOneTrue = objectValue || atLeastOneTrue;
      }

      if (typeof objectValue === 'object') {
        for (const subObjectValue of Object.values(objectValue)) {
          if (typeof subObjectValue === 'boolean') {
            atLeastOneTrue = subObjectValue || atLeastOneTrue;
          }
        }
      }
    }

    if (atLeastOneTrue) {
      return true;
    }

    return 'Expected at least one selection';
  });

const oneOf = <S extends ObjectSchema>(schema: S) =>
  refine(object(schema), 'OneOf', (value) => {
    let trueCount = 0;
    for (const objectValue of Object.values(value)) {
      if (typeof objectValue === 'boolean' && objectValue) {
        trueCount++;
      }

      if (typeof objectValue === 'object') {
        for (const subObjectValue of Object.values(objectValue)) {
          if (typeof subObjectValue === 'boolean' && objectValue) {
            trueCount++;
          }
        }
      }
    }

    if (trueCount === 1) {
      return true;
    }

    return 'Expected exactly one selection';
  });

export {
  anyOf,
  emailAddress,
  integerFromInput,
  isoDatetime,
  json,
  oneOf,
  phoneNumber,
};
