import { Spinner } from '@/components/Spinner';
import { Button } from '@/components/ui/button';
import { Link, useSearchParams } from 'react-router-dom';

const Authorize = () => {
  const [searchParameters] = useSearchParams();

  // authorize?error=access_denied&error_description=socket%20hang%20up&state=Q2cta1JHfllYS3VFaG1yOHJEM2lxYldoeUNsQkUzc2JJSl9RSzRSeENsXw%3D%3D
  if (searchParameters.has('error')) {
    return (
      <div className="h-screen flex items-center h-full sm:p-16 dark:bg-gray-50 dark:text-gray-800">
        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-lg">
          <svg
            className="w-40 h-40 dark:text-gray-400"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z"
              fill="currentColor"
            />
            <rect
              fill="currentColor"
              height="32"
              width="176"
              x="168"
              y="320"
            />
            <polygon
              fill="currentColor"
              points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042"
            />
            <polygon
              fill="currentColor"
              points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63"
            />
          </svg>
          <p className="text-3xl">
            {searchParameters.get('error')}:{' '}
            {searchParameters.get('error_description')}
          </p>
          <Link to="/auth/signout">
            <Button variant="default">Back to Sign In</Button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen flex">
      <Spinner
        fullscreen
        logo
      />
    </div>
  );
};

export { Authorize };
