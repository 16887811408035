import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { Loader } from 'lucide-react';
import { useMutation } from 'urql';

const DeletePendingEmailAddressQuery = graphql(`
  mutation DeletePendingEmailAddress {
    currentUserDeletePendingEmailAddress(input: {}) {
      query {
        currentUser {
          id
          pendingEmailAddress
        }
      }
    }
  }
`);

const DeletePendingEmailAddress = () => {
  const [{ fetching }, deletePendingEmailAddress] = useMutation(
    DeletePendingEmailAddressQuery,
  );

  const onSubmit = async () => {
    const response = await deletePendingEmailAddress({});

    if (response.error) {
      toast.error('Problem removing pending email address!', {
        description: response.error.message,
      });
    } else {
      toast.success('Removed pending email address');
    }
  };

  return (
    <Button
      className="h-8"
      disabled={fetching}
      onClick={onSubmit}
      variant="outline"
    >
      {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
      Remove
    </Button>
  );
};

export { DeletePendingEmailAddress };
