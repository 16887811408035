import { Empty } from '@/components';
import { GroupSelect } from '@/components/GroupSelect';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { NewGroupDialog } from '@/features/Members/components/NewGroupDialog';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { useAppStore } from '@/stores';
import { useForm } from 'react-hook-form';
import { boolean, type Infer, object, string } from 'superstruct';
import { useMutation } from 'urql';

const UpdateOrganizationIncidentSettingsGql = graphql(`
  mutation UpdateOrganizationIncidentSettingsGql(
    $input: UpdateOrganizationIncidentSettingsInput!
  ) {
    updateOrganizationIncidentSettings(input: $input) {
      organizationSetting {
        id
        isOwnerNotifiedOnIncident
        isAdministratorNotifiedOnIncident
        isSupervisorNotifiedOnIncident
        incidentSupervisorGroupId
        incidentSupervisorGroup {
          id
          name
        }
      }
    }
  }
`);

const schema = object({
  notifyAdministrator: boolean(),
  notifyOwner: boolean(),
  notifySupervisor: boolean(),
  supervisorGroupId: string(),
});

type IncidentSettingsFormProps = {
  readonly groups: Array<{ id: string; name: string }>;
  readonly incidentSupervisorGroupId?: string | null | undefined;
  readonly isAdministratorNotifiedOnIncident: boolean;
  readonly isOwnerNotifiedOnIncident: boolean;
  readonly isSupervisorNotifiedOnIncident: boolean;
  readonly organizationId: string;
};

const IncidentSettingsForm = ({
  groups,
  incidentSupervisorGroupId,
  isAdministratorNotifiedOnIncident,
  isOwnerNotifiedOnIncident,
  isSupervisorNotifiedOnIncident,
  organizationId,
}: IncidentSettingsFormProps) => {
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);

  const [{ error }, updateSettings] = useMutation(
    UpdateOrganizationIncidentSettingsGql,
  );

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      notifyAdministrator: isAdministratorNotifiedOnIncident,
      notifyOwner: isOwnerNotifiedOnIncident,
      notifySupervisor: isSupervisorNotifiedOnIncident,
      supervisorGroupId: incidentSupervisorGroupId ?? '',
    },
  });

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await updateSettings({
      input: {
        organizationId,
        shouldNotifyAdministrator: values.notifyAdministrator,
        shouldNotifyOwner: values.notifyOwner,
        shouldNotifySupervisor: values.notifySupervisor,
        supervisorGroupId: values.supervisorGroupId,
      },
    });

    if (!response.error) {
      form.reset(values);
      toast.success('Updated Incident Settings', {
        id: 'incident-settings',
      });
    }
  };

  return (
    <Form {...form}>
      <form
        className="space-y-8"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormItem className="space-y-3">
          <FormLabel className="mb-2">Notification Recipients</FormLabel>

          <FormField
            control={form.control}
            name="notifyOwner"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>Send notification to organization owner</FormLabel>
                </div>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="notifyAdministrator"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    Send notification to organization administrator(s)
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="notifySupervisor"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    Send notification to organization supervisor(s)
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
        </FormItem>

        <FormItem>
          <FormField
            control={form.control}
            name="supervisorGroupId"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Grant Incident Supervisor to Group</FormLabel>
                <FormDescription>
                  Grants the supervisor role to the following group. Create a{' '}
                  <NewGroupDialog
                    organizationId={organizationId}
                    type="link"
                  />
                  .
                </FormDescription>

                {(groups.length ?? 0) === 0 ? (
                  <Empty className="w-full border border-dashed flex-col space-y-1">
                    <div>
                      <NewGroupDialog organizationId={organizationId} />
                    </div>
                  </Empty>
                ) : (
                  <GroupSelect
                    {...field}
                    allowEmpty
                    groups={groups}
                  />
                )}
                <FormMessage />
              </FormItem>
            )}
          />
        </FormItem>

        <MutationError error={error} />

        <div className="flex items-center gap-2">
          <Button
            disabled={!isAdmin || !form.formState.isDirty}
            type="submit"
          >
            Update Settings
          </Button>
          <Button
            disabled={!isAdmin || !form.formState.isDirty}
            onClick={() => form.reset()}
            type="reset"
            variant="ghost"
          >
            Reset
          </Button>
        </div>
      </form>
    </Form>
  );
};

export { IncidentSettingsForm };
