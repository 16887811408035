import { Toaster } from '@/components/ui/sonner';
import { TooltipProvider } from '@/components/ui/tooltip';
import { AuthenticationProvider } from '@/providers/AuthenticationProvider';
import { GraphQLProvider } from '@/providers/GraphQlProvider';
import { Outlet } from 'react-router-dom';

const RootLayout = () => {
  return (
    <>
      <AuthenticationProvider>
        <GraphQLProvider>
          <TooltipProvider delayDuration={0}>
            <Outlet />
          </TooltipProvider>
        </GraphQLProvider>
      </AuthenticationProvider>
      <Toaster
        closeButton
        expand={false}
        richColors
      />
    </>
  );
};

export { RootLayout };
