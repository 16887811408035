import { config } from '@/config';

const storagePrefix = config.KEY_PREFIX;

const get = (key: string) => {
  return JSON.parse(
    window.localStorage.getItem(`${storagePrefix}${key}`) as string,
  );
};

const set = (key: string, object: boolean | object | string) => {
  window.localStorage.setItem(`${storagePrefix}${key}`, JSON.stringify(object));
};

const clear = (key: string) => {
  window.localStorage.removeItem(`${storagePrefix}${key}`);
};

const storage = {
  clearToken: () => {
    clear('token');
  },
  getToken: () => {
    return get('token');
  },
  setToken: (token: string) => {
    set('token', token);
  },
};

export { storage };
