import NotFoundImage from '@/assets/images/not-found.svg';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <div className="max-w-2xl m-auto mt-16">
        <div className="text-center px-4">
          <div className="inline-flex mb-8">
            <img
              alt="404 illustration"
              height="300"
              src={NotFoundImage}
              width="300"
            />
          </div>
          <div className="mb-6">
            Hmm...this page doesn't exist. Try searching for something else!
          </div>
          <Link
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            to="/"
          >
            Back To Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
};

export { TermsOfService };
