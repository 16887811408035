import { cn } from '@/lib/utils';
import { forwardRef, type InputHTMLAttributes } from 'react';
import PhoneInput, {
  type Country,
  getCountryCallingCode,
} from 'react-phone-number-input/input';

type PhoneNumberInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange'
> & {
  readonly country?: Country | undefined;
  readonly onChange: (value?: string) => void;
};

const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(
  (props, ref) => {
    const { className, country, onChange, value, ...rest } = props;

    return (
      <div className={cn('flex items-center')}>
        {country && (
          <span className="h-10 rounded-l-md border-l border-t border-b border-input bg-muted pl-3 pr-4 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
            +{getCountryCallingCode(country)}
          </span>
        )}
        <PhoneInput
          {...rest}
          className={cn(
            'flex h-10 w-full rounded-r-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          country={country}
          defaultCountry="US"
          onChange={(newValue) => onChange(newValue as string)}
          ref={ref}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value={value ? (value as any) : undefined}
        />
      </div>
    );
  },
);

export { PhoneNumberInput };
