import { type HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type TableFooterProps = HTMLAttributes<HTMLDivElement>;

const TableFooter = (props: TableFooterProps) => {
  const { className, children, ...extra } = props;

  return (
    <div
      {...extra}
      className={twMerge(
        'px-6 py-4 grid gap-3',
        'md:flex md:justify-between md:items-center',
        'border-t border-gray-200 dark:border-gray-700',
        className,
      )}
    >
      {children}
    </div>
  );
};

export { TableFooter };
