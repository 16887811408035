import { type AppStore, type Membership, type MembershipsSlice } from './types';
import { type StateCreator } from 'zustand';

const createMembershipsSlice: StateCreator<
  AppStore,
  [],
  [],
  MembershipsSlice
> = (set) => ({
  activeMembership: null,
  memberships: [],
  setActiveMembership: (id) => {
    set((state) => {
      const active = state.memberships.find((item) => {
        return item.id === id;
      });

      return {
        ...state,
        activeMembership: active ?? state.activeMembership,
      };
    });
  },
  setMemberships: (memberships) => {
    set((state) => {
      let active: Membership | null = null;

      if (memberships.length > 0) {
        active =
          memberships.find((item) => item.id === state.activeMembership?.id) ??
          memberships[0];
      }

      return {
        ...state,
        activeMembership: active,
        memberships: memberships.sort((a, b) => (a.id > b.id ? -1 : 1)),
      };
    });
  },
});

export { createMembershipsSlice };
