import { Datetime } from '@/components/Datetime';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import {
  dataFromValues,
  type DataSchema,
  fieldFromScope,
  isUISchemaControl,
  isUISchemaGroup,
  isUISchemaLayout,
  type UISchema,
} from '@/lib/incidentType';

type IncidentDataBuilderProps = {
  readonly data: Record<string, unknown>;
  readonly dataSchema: DataSchema;
  readonly path?: string;
  readonly uiSchema: UISchema;
};

// eslint-disable-next-line complexity
const IncidentDataBuilder = ({
  data,
  dataSchema,
  path = '#',
  uiSchema,
}: IncidentDataBuilderProps) => {
  if (isUISchemaLayout(uiSchema)) {
    return (
      <div className="space-y-4">
        {uiSchema.elements.map((item, index) => (
          <IncidentDataBuilder
            data={data}
            dataSchema={dataSchema}
            key={path + '-' + index.toString()}
            path={path + '-' + index.toString()}
            uiSchema={item}
          />
        ))}
      </div>
    );
  } else if (isUISchemaGroup(uiSchema)) {
    return (
      <div className="grid grid-cols-12 items-start pb-4">
        <Label
          className={uiSchema.options?.inline ? 'col-span-12' : 'col-span-3'}
        >
          {uiSchema.label}
        </Label>
        <div
          className={
            uiSchema.options?.inline
              ? 'pt-4 pl-4 col-span-12 space-y-4'
              : 'col-span-9 space-y-4'
          }
        >
          {uiSchema.elements.map((item, index) => (
            <IncidentDataBuilder
              data={data}
              dataSchema={dataSchema}
              key={path + '-' + index.toString()}
              path={path + '-' + index.toString()}
              uiSchema={item}
            />
          ))}
        </div>
      </div>
    );
  } else if (isUISchemaControl(uiSchema)) {
    const fieldType = fieldFromScope(uiSchema.scope, dataSchema);

    let fieldValue = dataFromValues(
      uiSchema.scope.replaceAll('properties/', ''),
      data,
    );

    if (uiSchema.label === 'Subject') {
      return null;
    }

    if (fieldType.default !== undefined && fieldValue === undefined) {
      fieldValue = fieldType.default;
    }

    if (fieldValue === undefined && fieldType.type === 'boolean') {
      fieldValue = false;
    } else if (!fieldValue) {
      fieldValue = '-- No Data --';
    }

    return (
      <>
        {fieldType.type === 'boolean' ? (
          <div className="flex items-center gap-2">
            <Checkbox
              checked={typeof fieldValue === 'boolean' ? fieldValue : false}
              id={uiSchema.scope}
            />
            <label
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              htmlFor={uiSchema.scope}
            >
              {uiSchema.label}
            </label>
          </div>
        ) : (
          <div className="grid grid-cols-12 items-center">
            <Label className="col-span-3">{uiSchema.label}</Label>
            <div className="col-span-9 text-sm">
              {uiSchema.options?.format === 'radio' ? (
                fieldType.oneOf?.find((item) => item.const === fieldValue)
                  ?.title ?? fieldValue?.toString()
              ) : fieldType.type === 'number' ? (
                fieldValue?.toString()
              ) : fieldType.type === 'string' &&
                fieldType.format === 'date-time' ? (
                <Datetime
                  datetime={new Date(fieldValue?.toString())}
                  passthrough
                />
              ) : (
                fieldValue?.toString()
              )}
            </div>
          </div>
        )}
      </>
    );
  }

  return null;
};

export { IncidentDataBuilder };
