import { graphql } from '@/gql';
import { useSubscription } from 'urql';

const IncidentUpdatesGql = graphql(`
  subscription IncidentUpdatesGql($incidentId: String!) {
    incidentUpdates(input: { incidentId: $incidentId }) {
      incident {
        id
        shortId
        organizationId
        updatedAt
        createdAt
        priority
        status
        subject
        description
        additionalData
        incidentCoordinates {
          x
          y
        }
        incidentCoordinatesGeocode {
          id
          address
          plusCode
        }
        submittedByMemberCoordinates {
          x
          y
        }
        submittedByMember {
          id
          fullName
          displayName
          avatarUrl
        }
        contactMemberId
        contactMember {
          id
          fullName
          displayName
          avatarUrl
          emailAddress
          phoneNumber
        }
        ownedByMember {
          id
          fullName
          displayName
          avatarUrl
        }
        ownedByGroup {
          id
          name
        }
        incidentUpdates {
          nodes {
            id
            type
            message
            createdAt
            previousValue
            updatedValue
            isSystemMessage
            performedByMember {
              id
              fullName
              displayName
              avatarUrl
            }
            attachments {
              nodes {
                id
                mimetype
                url
              }
            }
          }
        }
        incidentType {
          id
          name
          dataSchema
          uiSchema
        }
      }
      event
      relatedIds
    }
  }
`);

type IncidentUpdatesProps = { incidentId: string };

const IncidentUpdates = ({ incidentId }: IncidentUpdatesProps) => {
  useSubscription({
    query: IncidentUpdatesGql,
    variables: {
      incidentId,
    },
  });

  return null;
};

export { IncidentUpdates };
