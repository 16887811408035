/* eslint-disable complexity */
import { Group } from './Group';
import {
  IncidentUpdateContainer,
  IncidentUpdateContent,
  IncidentUpdateSubtitle,
  IncidentUpdateTitle,
} from './IncidentUpdateCompontent';
import { Member } from './Member';
import { IncidentUpdateTypeType } from '@/gql/graphql';
import { deepDiffMapper, DiffType } from '@/lib/diff';
import { formatIncidentPriority, formatIncidentStatus } from '@/lib/formatGql';
import {
  type DataSchema,
  fieldFromScope,
  type UISchema,
  uiSchemaFromDataKey,
} from '@/lib/incidentType';
import { formatDatetime } from '@/lib/time';
import {
  ArrowDownUp,
  CircleUser,
  Cpu,
  MessageSquare,
  MessageSquareMore,
  Paperclip,
  Pencil,
  SlidersHorizontal,
  UserPlus,
  Users,
} from 'lucide-react';
import Markdown from 'react-markdown';

type DetailsDifferenceProps = {
  readonly dataSchema: DataSchema;
  readonly incidentUpdateId: string;
  readonly previous: unknown;
  readonly uiSchema: UISchema;
  readonly updated: unknown;
};

const DetailsDifference = ({
  dataSchema,
  incidentUpdateId,
  previous,
  uiSchema,
  updated,
}: DetailsDifferenceProps) => {
  const previousData = previous as {
    additional_data?: Record<string, string> | string;
    description?: string;
    subject?: string;
  };
  const updatedData = updated as {
    additional_data?: Record<string, string>;
    description?: string;
    subject?: string;
  };

  const diff = deepDiffMapper(previousData, updatedData);

  return (
    <>
      {diff.map((item) => {
        const control = uiSchemaFromDataKey(item.key, uiSchema);

        if (!control) {
          return null;
        }

        const field = fieldFromScope(control.scope, dataSchema);

        let oldValue = item.old;
        let newValue = item.new;

        if (field.type === 'string' && field.format === 'date-time') {
          try {
            oldValue = oldValue ? formatDatetime({ datetime: oldValue }) : null;
          } catch {
            oldValue = item.old;
          }

          try {
            newValue = newValue ? formatDatetime({ datetime: newValue }) : null;
          } catch {
            newValue = item.new;
          }
        }

        if (item.key === 'description' || item.key === 'subject') {
          return (
            <p key={`${incidentUpdateId}-audit-${item.key}`}>
              {control.label} updated from{' '}
              <strong className="block my-3 whitespace-pre-wrap ml-4 pl-4 border-l">
                {oldValue || '-- No Content --'}
              </strong>
            </p>
          );
        }

        return (
          <p key={`${incidentUpdateId}-audit-${item.key}`}>
            {item.type === DiffType.VALUE_UPDATED && (
              <>
                {oldValue && newValue && (
                  <>
                    {control.label} updated from <strong>{oldValue}</strong> to{' '}
                    <strong>{newValue}</strong>
                  </>
                )}
                {oldValue && !newValue && (
                  <>
                    {control.label} was <strong>{oldValue}</strong>
                  </>
                )}
                {!oldValue && newValue && (
                  <>
                    {control.label} changed to <strong>{newValue}</strong>
                  </>
                )}
              </>
            )}
            {item.type === DiffType.VALUE_CREATED && (
              <>
                {control.label} changed to <strong>{newValue}</strong>
              </>
            )}
            {item.type === DiffType.VALUE_DELETED && (
              <>
                {control.label} was <strong>{oldValue}</strong>
              </>
            )}
          </p>
        );
      })}
    </>
  );
};

type IncidentUpdateProps = {
  readonly dataSchema: DataSchema;
  readonly uiSchema: UISchema;
  readonly update: {
    attachments: {
      nodes: Array<{
        id: string;
        mimetype: string;
        url?: string | null | undefined;
      }>;
    };
    createdAt: string;
    id: string;
    isSystemMessage: boolean;
    message?: string | null | undefined;
    performedByMember?:
      | {
          avatarUrl?: string | null | undefined;
          displayName?: string | null | undefined;
          fullName: string;
          id: string;
        }
      | null
      | undefined;
    previousValue?: unknown;
    type: IncidentUpdateTypeType;
    updatedValue?: unknown;
  };
};

const IncidentUpdate = ({
  dataSchema,
  uiSchema,
  update,
}: IncidentUpdateProps) => {
  return (
    <>
      {update.type === IncidentUpdateTypeType.PublicNote ? (
        <IncidentUpdateContainer
          asUser
          icon={MessageSquare}
          performedByMember={update.performedByMember}
        >
          <IncidentUpdateTitle>
            <strong>Comment</strong> added
          </IncidentUpdateTitle>
          <IncidentUpdateSubtitle
            createdAt={update.createdAt}
            isSystemMessage={update.isSystemMessage}
            performedByMember={update.performedByMember}
          />
          <IncidentUpdateContent>
            <Markdown className="prose text-sm text-card-foreground">
              {update.message}
            </Markdown>
            {update.attachments.nodes.length > 0 && (
              <div className="mt-2 grid grid-cols-3 gap-2">
                {update.attachments.nodes.map((attachment) => {
                  return (
                    <div key={attachment.id}>
                      {attachment.url && (
                        <a
                          href={attachment.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {attachment.mimetype?.includes('video') && (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            <video>
                              <source
                                src={attachment.url}
                                type={attachment.mimetype}
                              />
                            </video>
                          )}
                          {attachment.mimetype?.includes('image') && (
                            <img
                              alt={attachment.id}
                              className="w-full rounded hover:shadow-lg"
                              src={attachment.url}
                            />
                          )}
                        </a>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </IncidentUpdateContent>
        </IncidentUpdateContainer>
      ) : update.type === IncidentUpdateTypeType.PrivateNote ? (
        <IncidentUpdateContainer
          asUser
          icon={MessageSquareMore}
          performedByMember={update.performedByMember}
        >
          <IncidentUpdateTitle>
            <strong>Private Comment</strong> added
          </IncidentUpdateTitle>
          <IncidentUpdateSubtitle
            createdAt={update.createdAt}
            isSystemMessage={update.isSystemMessage}
            performedByMember={update.performedByMember}
          />
          <IncidentUpdateContent className="border-dotted bg-muted">
            <Markdown className="prose text-sm">{update.message}</Markdown>
            {update.attachments.nodes.length > 0 && (
              <div className="mt-2 grid grid-cols-3 gap-2">
                {update.attachments.nodes.map((attachment) => {
                  return (
                    <div key={attachment.id}>
                      {attachment.url && (
                        <a
                          href={attachment.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {attachment.mimetype?.includes('video') && (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            <video>
                              <source
                                src={attachment.url}
                                type={attachment.mimetype}
                              />
                            </video>
                          )}
                          {attachment.mimetype?.includes('image') && (
                            <img
                              alt={attachment.id}
                              className="w-full rounded hover:shadow-lg"
                              src={attachment.url}
                            />
                          )}
                        </a>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </IncidentUpdateContent>
        </IncidentUpdateContainer>
      ) : update.type === IncidentUpdateTypeType.AttachmentAdded ? (
        <IncidentUpdateContainer
          asUser
          icon={Paperclip}
          performedByMember={update.performedByMember}
        >
          <IncidentUpdateTitle>
            <strong>Attachment</strong> added
          </IncidentUpdateTitle>
          <IncidentUpdateSubtitle
            createdAt={update.createdAt}
            isSystemMessage={update.isSystemMessage}
            performedByMember={update.performedByMember}
          />
          <IncidentUpdateContent>
            <div className="grid grid-cols-3 gap-2">
              {update.attachments.nodes.map((attachment) => {
                return (
                  <div key={attachment.id}>
                    {attachment.url && (
                      <a
                        href={attachment.url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {attachment.mimetype?.includes('video') && (
                          // eslint-disable-next-line jsx-a11y/media-has-caption
                          <video>
                            <source
                              src={attachment.url}
                              type={attachment.mimetype}
                            />
                          </video>
                        )}
                        {attachment.mimetype?.includes('image') && (
                          <img
                            alt={attachment.id}
                            className="w-full rounded hover:shadow-lg"
                            src={attachment.url}
                          />
                        )}
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
            {update.message && (
              <div className="text-sm mt-2">{update.message}</div>
            )}
          </IncidentUpdateContent>
        </IncidentUpdateContainer>
      ) : update.type === IncidentUpdateTypeType.DetailsChange ? (
        <IncidentUpdateContainer icon={Pencil}>
          <IncidentUpdateTitle>
            <strong>Incident Details</strong> updated
          </IncidentUpdateTitle>
          <IncidentUpdateSubtitle
            createdAt={update.createdAt}
            isSystemMessage={update.isSystemMessage}
            performedByMember={update.performedByMember}
          />
          <IncidentUpdateContent className="text-sm">
            <DetailsDifference
              dataSchema={dataSchema}
              incidentUpdateId={update.id}
              previous={update.previousValue}
              uiSchema={uiSchema}
              updated={update.updatedValue}
            />
          </IncidentUpdateContent>
        </IncidentUpdateContainer>
      ) : update.type === IncidentUpdateTypeType.PriorityChange ? (
        <IncidentUpdateContainer icon={ArrowDownUp}>
          <IncidentUpdateTitle>
            <strong>Priority</strong> has been changed from{' '}
            <strong className="text-muted-foreground">
              {formatIncidentPriority(update.previousValue as string)}
            </strong>{' '}
            to{' '}
            <strong className="text-muted-foreground">
              {formatIncidentPriority(update.updatedValue as string)}
            </strong>
          </IncidentUpdateTitle>
          <IncidentUpdateSubtitle
            createdAt={update.createdAt}
            isSystemMessage={update.isSystemMessage}
            performedByMember={update.performedByMember}
          />
        </IncidentUpdateContainer>
      ) : update.type === IncidentUpdateTypeType.StatusChange ? (
        <IncidentUpdateContainer icon={Cpu}>
          <IncidentUpdateTitle>
            <strong>Status</strong> has been changed from{' '}
            <strong className="text-muted-foreground">
              {formatIncidentStatus(update.previousValue as string)}
            </strong>{' '}
            to{' '}
            <strong className="text-muted-foreground">
              {formatIncidentStatus(update.updatedValue as string)}
            </strong>
          </IncidentUpdateTitle>
          <IncidentUpdateSubtitle
            createdAt={update.createdAt}
            isSystemMessage={update.isSystemMessage}
            performedByMember={update.performedByMember}
          />
        </IncidentUpdateContainer>
      ) : update.type === IncidentUpdateTypeType.TypeChange ? (
        <IncidentUpdateContainer icon={SlidersHorizontal}>
          <IncidentUpdateTitle>
            Incident Type changed to{' '}
            <span className="mr-0.5">{update.updatedValue as string}</span>
          </IncidentUpdateTitle>
          <IncidentUpdateSubtitle
            createdAt={update.createdAt}
            isSystemMessage={update.isSystemMessage}
            performedByMember={update.performedByMember}
          />
        </IncidentUpdateContainer>
      ) : update.type === IncidentUpdateTypeType.OwnerChange ? (
        <IncidentUpdateContainer icon={UserPlus}>
          {update.previousValue ? (
            update.updatedValue ? (
              <IncidentUpdateTitle>
                <strong>Agent</strong> has been changed from{' '}
                <strong className="text-muted-foreground">
                  <Member memberId={update.previousValue as string} />
                </strong>{' '}
                to{' '}
                <strong className="text-muted-foreground">
                  <Member memberId={update.updatedValue as string} />
                </strong>
              </IncidentUpdateTitle>
            ) : (
              <IncidentUpdateTitle>
                <strong>Agent</strong> has been removed:{' '}
                <strong className="text-muted-foreground">
                  <Member memberId={update.previousValue as string} />
                </strong>
              </IncidentUpdateTitle>
            )
          ) : (
            <IncidentUpdateTitle>
              <strong>Agent</strong> has been assigned:{' '}
              <strong className="text-muted-foreground">
                <Member memberId={update.updatedValue as string} />
              </strong>
            </IncidentUpdateTitle>
          )}
          <IncidentUpdateSubtitle
            createdAt={update.createdAt}
            isSystemMessage={update.isSystemMessage}
            performedByMember={update.performedByMember}
          />
        </IncidentUpdateContainer>
      ) : update.type === IncidentUpdateTypeType.GroupChange ? (
        <IncidentUpdateContainer icon={Users}>
          {update.previousValue ? (
            update.updatedValue ? (
              <IncidentUpdateTitle>
                <strong>Assigned group</strong> has been changed from{' '}
                <strong className="text-muted-foreground">
                  <Group groupId={update.previousValue as string} />
                </strong>{' '}
                to{' '}
                <strong className="text-muted-foreground">
                  <Group groupId={update.updatedValue as string} />
                </strong>
              </IncidentUpdateTitle>
            ) : (
              <IncidentUpdateTitle>
                <strong>Assigned group</strong> has been removed:{' '}
                <strong className="text-muted-foreground">
                  <Group groupId={update.previousValue as string} />
                </strong>
              </IncidentUpdateTitle>
            )
          ) : (
            <IncidentUpdateTitle>
              <strong>Assigned Group</strong> has been changed to{' '}
              <strong className="text-muted-foreground">
                <Group groupId={update.updatedValue as string} />
              </strong>
            </IncidentUpdateTitle>
          )}

          <IncidentUpdateSubtitle
            createdAt={update.createdAt}
            isSystemMessage={update.isSystemMessage}
            performedByMember={update.performedByMember}
          />
        </IncidentUpdateContainer>
      ) : update.type === IncidentUpdateTypeType.ContactChange ? (
        <IncidentUpdateContainer icon={CircleUser}>
          <IncidentUpdateTitle>
            <strong>Contact</strong> has been changed from{' '}
            <strong className="text-muted-foreground">
              <Member memberId={update.previousValue as string} />
            </strong>{' '}
            to{' '}
            <strong className="text-muted-foreground">
              <Member memberId={update.updatedValue as string} />
            </strong>
          </IncidentUpdateTitle>
          <IncidentUpdateSubtitle
            createdAt={update.createdAt}
            isSystemMessage={update.isSystemMessage}
            performedByMember={update.performedByMember}
          />
        </IncidentUpdateContainer>
      ) : (
        <div>{update.type}</div>
      )}
    </>
  );
};

export { IncidentUpdate };
