import { IncidentSettingsEnabled } from '../components/IncidentSettingsEnabled';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { ChevronRight } from 'lucide-react';

const IncidentTypes = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          Incident Settings <ChevronRight className="h-4 w-4" /> Incident Types
        </CardTitle>
        <CardDescription>
          Configure who will manage Incidents, and choose which incidents will
          be enabled/disabled for your members to report.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <IncidentSettingsEnabled organizationId={organizationId} />
      </CardContent>
    </Card>
  );
};

export { IncidentTypes };
