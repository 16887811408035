import { IncidentTypeSelector } from '../components/IncidentTypeSelector';
import { NewIncidentForm } from '../components/NewIncidentForm';
import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { type IncidentType } from '@/gql/graphql';
import { useGeolocationService } from '@/hooks/useGeolocationService';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils/errors';
import { ChevronLeft } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const EnabledIncidentTypesQuery = graphql(`
  query EnabledIncidentTypesQuery($organizationId: String = "") {
    enabledIncidentTypes(condition: { organizationId: $organizationId }) {
      totalCount
      nodes {
        id
        defaultAssignedGroup {
          id
          name
        }
        incidentType {
          id
          category
          name
          description
          iconSvg
          uiSchema
          dataSchema
        }
      }
    }
    organization(id: $organizationId) {
      id
      members {
        nodes {
          id
          fullName
          displayName
          isEnabled
        }
      }
    }
  }
`);

const isValidIncidentType = (
  item:
    | Pick<
        IncidentType,
        | 'category'
        | 'dataSchema'
        | 'description'
        | 'iconSvg'
        | 'id'
        | 'name'
        | 'uiSchema'
      >
    | null
    | undefined,
): item is Pick<
  IncidentType,
  | 'category'
  | 'dataSchema'
  | 'description'
  | 'iconSvg'
  | 'id'
  | 'name'
  | 'uiSchema'
> => item !== undefined && item !== null;

const IncidentNew = () => {
  const [organizationId, memberId, isIncidentSupervisor] = useAppStore(
    (state) => [
      state.activeMembership?.id,
      state.activeMembership?.memberId,
      state.activeMembership?.isIncidentSupervisor,
    ],
  );

  if (!organizationId || !memberId) {
    throw new OrganizationNotFoundError();
  }

  const currentLocation = useGeolocationService();

  const [selectedIncidentTypeId, setSelectedIncidentTypeId] = useState<
    string | null
  >(null);

  const [{ data }] = useQuery({
    pause: !organizationId,
    query: EnabledIncidentTypesQuery,
    variables: {
      organizationId,
    },
  });

  const selectedIncidentType = data?.enabledIncidentTypes?.nodes.find(
    (item) => item.incidentType?.id === selectedIncidentTypeId,
  )?.incidentType;

  return (
    <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0">
      <div className="flex items-center gap-4">
        <Link to="/incidents">
          <Button
            className="h-7 w-7"
            size="icon"
            variant="outline"
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Back</span>
          </Button>
        </Link>
        <h1 className="flex-1 shrink-0 whitespace-nowrap text-3xl font-bold tracking-tight sm:grow-0 flex items-center gap-2">
          {selectedIncidentType ? (
            <>
              <img
                alt={selectedIncidentType.name}
                className="h-8 w-8"
                src={`data:image/svg+xml;base64,${selectedIncidentType.iconSvg}`}
              />
              {selectedIncidentType.name}
            </>
          ) : (
            'Select an Incident Type'
          )}
        </h1>

        <div className="hidden items-center gap-2 md:ml-auto md:flex">
          <Button
            className="h-8"
            onClick={() => setSelectedIncidentTypeId(null)}
            size="sm"
          >
            Change Incident Type
          </Button>
        </div>
      </div>

      {data?.enabledIncidentTypes && (
        <>
          {selectedIncidentType ? (
            <div className="w-full">
              <NewIncidentForm
                currentLocation={currentLocation}
                currentMemberId={memberId}
                incidentType={selectedIncidentType}
                isIncidentSupervisor={isIncidentSupervisor ?? false}
                members={data.organization?.members.nodes ?? []}
                organizationId={organizationId}
              />
            </div>
          ) : (
            <div className="w-full">
              <IncidentTypeSelector
                incidentTypes={data.enabledIncidentTypes?.nodes
                  .map((item) => item.incidentType)
                  .filter(isValidIncidentType)}
                setSelectedIncidentTypeId={(id) =>
                  setSelectedIncidentTypeId(id)
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export { IncidentNew };
