import {
  AdvancedMarker,
  ControlPosition,
  Map,
  MapBoundsHandler,
  MapControl,
  useMap,
} from '@/components/Map';
import { PlaceAutocomplete } from '@/components/Map/PlaceAutocomplete';
import { Button } from '@/components/ui/button';
import { Form, FormDescription, FormLabel } from '@/components/ui/form';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { type Organization } from '@/gql/graphql';
import { toast } from '@/lib/toast';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { type Infer, number, object } from 'superstruct';
import { useMutation } from 'urql';

const MapSettingsFormGql = graphql(`
  mutation MapSettingsFormGql($input: UpdateOrganizationMapSettingsInput!) {
    updateOrganizationMapSettings(input: $input) {
      organization {
        id
        defaultMapCenter {
          latitude
          longitude
        }
        defaultMapZoom
      }
      query {
        currentUser {
          id
          memberships {
            nodes {
              id
              organization {
                id
                defaultMapCenter {
                  latitude
                  longitude
                }
                defaultMapZoom
              }
            }
          }
        }
      }
    }
  }
`);

const schema = object({
  latitude: number(),
  longitude: number(),
  zoom: number(),
});

type SettingsGeneralProps = {
  readonly defaultMapCenter: {
    lat: number;
    lng: number;
  };
  readonly defaultMapZoom: Organization['defaultMapZoom'];
  readonly organizationId: string;
};

// eslint-disable-next-line complexity
const MapSettingsForm = ({
  defaultMapCenter,
  defaultMapZoom,
  organizationId,
}: SettingsGeneralProps) => {
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  const map = useMap();

  const [{ error, fetching }, updateDetails] = useMutation(MapSettingsFormGql);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      latitude: defaultMapCenter?.lat,
      longitude: defaultMapCenter?.lng,
      zoom: defaultMapZoom,
    },
    resolver: superstructResolver(schema),
  });

  useEffect(() => {
    form.reset({
      latitude: defaultMapCenter?.lat,
      longitude: defaultMapCenter?.lng,
      zoom: defaultMapZoom,
    });
  }, [defaultMapCenter, defaultMapZoom, form]);

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await updateDetails({
      input: {
        latitude: values.latitude,
        longitude: values.longitude,
        organizationId,
        zoom: values.zoom,
      },
    });

    if (response.error) {
      toast.error('Problem updating organization map settings!', {
        description: response.error.message,
      });
    } else {
      toast.success('Organization map settings updated!');

      form.reset(values);
      if (map) {
        map.setCenter({
          lat: values.latitude,
          lng: values.longitude,
        });
        map.setZoom(values.zoom);
      }
    }
  };

  const handleOnPlaceSelect = (
    newValue: google.maps.places.PlaceResult | null,
  ) => {
    if (newValue?.geometry?.location) {
      const lat = newValue?.geometry?.location.lat();
      const lng = newValue?.geometry?.location.lng();
      if (lat && lng) {
        form.setValue('latitude', lat, { shouldDirty: true });
        form.setValue('longitude', lng, { shouldDirty: true });
      }
    }

    setSelectedPlace(newValue);
  };

  const position = { lat: form.watch().latitude, lng: form.watch().longitude };

  return (
    <Form {...form}>
      <form
        className="space-y-8"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="space-y-2">
          <div className="space-y-0.5">
            <FormLabel>Default Map View</FormLabel>
            <FormDescription>
              Sets the default map center and zoom used by maps in the
              organization.
            </FormDescription>
          </div>

          <Map
            className="h-96"
            defaultCenter={defaultMapCenter}
            defaultZoom={defaultMapZoom}
            onClick={(event) => {
              if (event.detail.latLng) {
                form.setValue('latitude', event.detail.latLng.lat, {
                  shouldDirty: true,
                });
                form.setValue('longitude', event.detail.latLng.lng, {
                  shouldDirty: true,
                });

                if (map) {
                  map.setCenter(event.detail.latLng);
                }
              }
            }}
            onZoomChanged={(event) => {
              if (event.detail.zoom) {
                form.setValue('zoom', event.detail.zoom, {
                  shouldDirty: true,
                });
              }
            }}
          >
            <AdvancedMarker
              draggable
              onDragEnd={(event: google.maps.MapMouseEvent) => {
                if (event.latLng) {
                  form.setValue('latitude', event.latLng.lat(), {
                    shouldDirty: true,
                  });
                  form.setValue('longitude', event.latLng.lng(), {
                    shouldDirty: true,
                  });

                  if (map) {
                    map.setCenter(event.latLng);
                  }
                }
              }}
              position={position}
            />
          </Map>

          <MapControl position={ControlPosition.TOP_LEFT}>
            <div className="autocomplete-control p-2">
              <PlaceAutocomplete onPlaceSelect={handleOnPlaceSelect} />
            </div>
          </MapControl>

          <MapBoundsHandler bounds={selectedPlace?.geometry?.viewport} />
        </div>

        <MutationError error={error} />

        <div className="flex items-center gap-2">
          <Button
            disabled={fetching || !form.formState.isDirty}
            type="submit"
          >
            Update
          </Button>
          <Button
            disabled={fetching || !form.formState.isDirty}
            onClick={() => {
              form.reset();
              if (map) {
                map.setCenter(defaultMapCenter);
                map.setZoom(defaultMapZoom);
              }
            }}
            type="reset"
            variant="ghost"
          >
            {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
            Reset
          </Button>
        </div>
      </form>
    </Form>
  );
};

export { MapSettingsForm };
