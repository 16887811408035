import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { MemberRole } from '@/gql/graphql';
import { toast } from '@/lib/toast';
import { useAppStore } from '@/stores';
import { Loader, X } from 'lucide-react';
import { useState } from 'react';
import { useMutation } from 'urql';

const DeleteInvitationDialogGql = graphql(`
  mutation DeleteInvitationDialogGql($id: String!) {
    deleteInvitation(input: { id: $id }) {
      invitation {
        id
        key
      }
      organization {
        id
        invitations {
          totalCount
          nodes {
            id
            key
            organizationRole
            organizationName
            groupIds
          }
        }
      }
    }
  }
`);

type DeleteInvitationProps = {
  readonly identifer: string;
  readonly invitationId: string;
  readonly onComplete?: () => void;
};

const DeleteInvitationDialog = ({
  identifer,
  invitationId,
  onComplete,
}: DeleteInvitationProps) => {
  const [open, setOpen] = useState(false);

  const [{ error, fetching }, deleteInvitation] = useMutation(
    DeleteInvitationDialogGql,
  );

  const organizationRole = useAppStore((state) => state.activeMembership?.role);

  const allowedRoles = [MemberRole.Owner, MemberRole.Administrator];

  if (!organizationRole || !allowedRoles.includes(organizationRole)) {
    return null;
  }

  const handleDelete = async () => {
    const response = await deleteInvitation({ id: invitationId });
    setOpen(false);

    if (response.error) {
      toast.error('Problem deleting invitation!', {
        description: response.error.message,
      });
    } else {
      toast.success('Deleted invitation');
      onComplete?.();
    }
  };

  return (
    <AlertDialog
      onOpenChange={setOpen}
      open={open}
    >
      <AlertDialogTrigger asChild>
        <Button
          size="sm"
          variant="destructive"
        >
          <X className="mr-2 h-4 w-4 " />
          Delete Invitation
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Invitation?</AlertDialogTitle>
          <AlertDialogDescription>
            This will delete the invitation for{' '}
            <span className="font-semibold">{identifer}</span>. This action
            cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <MutationError error={error} />

        <AlertDialogFooter>
          <AlertDialogCancel disabled={fetching}>Cancel</AlertDialogCancel>
          <Button
            disabled={fetching}
            onClick={handleDelete}
            variant="destructive"
          >
            {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { DeleteInvitationDialog };
