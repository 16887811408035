import { UserNotificationItem } from './UserNotificationItem';
import { graphql } from '@/gql';
import { type RefObject, useEffect } from 'react';
import { useQuery } from 'urql';

const AccountNotificationsPageGql = graphql(`
  query AccountNotificationsPageGql($first: Int!, $after: Cursor) {
    currentUser {
      id
      userNotifications(first: $first, after: $after, orderBy: ID_DESC) {
        totalCount
        nodes {
          id
          type
          isRead
          metadata
          createdAt
          organizationId
          organization {
            id
            name
            logoUrl
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`);

type UserNotificationPageProps = {
  acknowledgeNotification: (id: string) => Promise<void>;
  after: string;
  deleteNotification: (id: string) => Promise<void>;
  first: number;
  loadMoreRef: RefObject<HTMLDivElement>;
};

const UserNotificationPage = ({
  loadMoreRef,
  first,
  after,
  acknowledgeNotification,
  deleteNotification,
}: UserNotificationPageProps) => {
  const [{ data, fetching }, executeQuery] = useQuery({
    // We don't want to run the query if we don't have a cursor (in this example, this will never happen)
    pause: !after,
    query: AccountNotificationsPageGql,
    // Initially, we *only* want to display results if, they're cached
    requestPolicy: 'cache-only',
    variables: {
      after,
      first,
    },
  });

  useEffect(() => {
    if (!data) {
      const observer = new IntersectionObserver((entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          executeQuery({ requestPolicy: 'cache-first' });
        }
      });

      const current = loadMoreRef.current;
      if (current) {
        observer.observe(current);
      }

      return () => {
        if (current) {
          observer.unobserve(current);
        }
      };
    }

    return () => {};
  }, [executeQuery, loadMoreRef, data]);

  if (fetching) {
    return <div className="p-2 w-full text-center">Loading more...</div>;
  }

  const userNotifications = data?.currentUser?.userNotifications;

  return (
    <>
      {/* If our query has nodes, we render them here.*/}
      {userNotifications?.nodes.map((item) => (
        <UserNotificationItem
          key={item.id}
          {...item}
          acknowledgeNotification={acknowledgeNotification}
          deleteNotification={deleteNotification}
        />
      ))}

      {/* If we have a next page, we now render it recursively! */}
      {/* As before, the next <UserNotificationPage> will not fetch immediately, but only query from cache */}
      {userNotifications?.pageInfo.hasNextPage && (
        <UserNotificationPage
          acknowledgeNotification={acknowledgeNotification}
          after={userNotifications.pageInfo.endCursor}
          deleteNotification={deleteNotification}
          first={first}
          loadMoreRef={loadMoreRef}
        />
      )}
    </>
  );
};

export { UserNotificationPage };
