import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { Loader } from 'lucide-react';
import { useMutation } from 'urql';

const DeletePendingPhoneNumberQuery = graphql(`
  mutation DeletePendingPhoneNumber {
    currentUserDeletePendingPhoneNumber(input: {}) {
      query {
        currentUser {
          id
          pendingPhoneNumber
        }
      }
    }
  }
`);

const DeletePendingPhoneNumber = () => {
  const [{ fetching }, deletePendingPhoneNumber] = useMutation(
    DeletePendingPhoneNumberQuery,
  );

  const onSubmit = async () => {
    const response = await deletePendingPhoneNumber({});

    if (response.error) {
      toast.error('Problem removing pending phone number!', {
        description: response.error.message,
      });
    } else {
      toast.success('Removed pending phone number');
    }
  };

  return (
    <Button
      className="h-8"
      disabled={fetching}
      onClick={onSubmit}
      variant="outline"
    >
      {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
      Cancel
    </Button>
  );
};

export { DeletePendingPhoneNumber };
