// eslint-disable-next-line import/no-unassigned-import
import 'filepond/dist/filepond.min.css';
// eslint-disable-next-line import/no-unassigned-import
import './css/filepond.css';
import { graphql } from '@/gql';
import {
  type ProcessServerConfigFunction,
  type RevertServerConfigFunction,
} from 'filepond';
import { FilePond } from 'react-filepond';
import { useMutation } from 'urql';

const AttachmentUploadGql = graphql(`
  mutation AttachmentUploadGql($mimetype: String!) {
    uploadAttachment(input: { mimetype: $mimetype }) {
      id
      fields
      key
      url
    }
  }
`);

type AttachmentUploadProps = {
  readonly allowMultiple?: boolean;
  readonly className?: string;
  readonly files: File[];
  readonly setAttachmentIds: React.Dispatch<React.SetStateAction<string[]>>;
  readonly setFiles: React.Dispatch<React.SetStateAction<File[]>>;
};

const AttachmentUpload = ({
  allowMultiple,
  className,
  files,
  setAttachmentIds,
  setFiles,
}: AttachmentUploadProps) => {
  const [, uploadFile] = useMutation(AttachmentUploadGql);

  const handleRevert: RevertServerConfigFunction = (uniqueFileId, load) => {
    // remove attachment id from array
    setAttachmentIds((previous) =>
      previous.filter((item) => item !== uniqueFileId),
    );

    // Should call the load method when done, no parameters required
    load();
  };

  const handleAttachment: ProcessServerConfigFunction = async (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
  ) => {
    // get upload information
    const response = await uploadFile({
      mimetype: file.type,
    });

    if (response.data?.uploadAttachment) {
      const { fields, id, key, url } = response.data.uploadAttachment;

      const formData = new FormData();
      if (!Object.keys(fields).includes('key')) {
        formData.append('key', key);
      }

      const fieldsObject = JSON.parse(fields as string);
      for (const field of Object.keys(fieldsObject)) {
        formData.append(field, fieldsObject[field]);
      }

      formData.append('file', file, file.name);

      const request = new XMLHttpRequest();

      const requestPromise = new Promise((resolve) => {
        request.open('POST', url);

        // Should call the progress method to update the progress to 100% before calling load
        // Setting computable to false switches the loading indicator to infinite mode
        request.upload.onprogress = (event) => {
          progress(event.lengthComputable, event.loaded, event.total);
        };

        // Should call the load method when done and pass the returned server file id
        // this server file id is then used later on when reverting or restoring a file
        // so your server knows which file to return without exposing that info to the client
        request.onload = () => {
          if (request.status >= 200 && request.status < 300) {
            // the load method accepts either a string (id) or an object
            load(id);
            resolve(id);
          } else {
            // Can call the error method if something is wrong, should exit after
            error('oh no');
            resolve('');
          }
        };

        request.send(formData);
      });

      try {
        await requestPromise;
        // update the attachment ids on upload success
        setAttachmentIds((previous) => [...previous, id]);

        // Should expose an abort method so the request can be cancelled
        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            request.abort();

            // Let FilePond know the request has been cancelled
            abort();
          },
        };
      } catch {
        error('File upload failed');
      }
    } else {
      error('File upload attempt failed');
    }

    return {
      abort: () => {
        // Let FilePond know the request has been cancelled
        abort();
      },
    };
  };

  return (
    <div className={className}>
      <FilePond
        allowMultiple={allowMultiple}
        files={files}
        instantUpload
        maxFiles={10}
        maxParallelUploads={1}
        onupdatefiles={(fileItems) => {
          // Set current file objects to this.state
          setFiles(fileItems.map((fileItem) => fileItem.file) as File[]);
        }}
        server={{ process: handleAttachment, revert: handleRevert }}
      />
    </div>
  );
};

export { AttachmentUpload };
