import { cn } from '@/lib/utils';
import {
  Children,
  cloneElement,
  forwardRef,
  type HTMLAttributes,
  isValidElement,
} from 'react';

const List = forwardRef<HTMLUListElement, HTMLAttributes<HTMLUListElement>>(
  (props, ref) => {
    const { children, className, ...other } = props;
    return (
      <ul
        className={cn(
          'w-full overflow-hidden divide-y',
          'text-base-content',
          className,
        )}
        ref={ref}
        {...other}
      >
        {children}
      </ul>
    );
  },
);

const ListItem = forwardRef<HTMLLIElement, HTMLAttributes<HTMLLIElement>>(
  (props, ref) => {
    const { children, className, ...other } = props;
    return (
      <li
        className={cn(
          'w-full flex justify-between items-center truncate tabular-nums py-2',
          className,
        )}
        ref={ref}
        {...other}
      >
        {Children.map(children, (child, index) =>
          index === 0 && isValidElement<HTMLSpanElement>(child) ? (
            cloneElement(child, {
              className: cn('text-muted-foreground', child.props.className),
            })
          ) : (
            <>{child}</>
          ),
        )}
      </li>
    );
  },
);

export { List, ListItem };
