import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { type ComponentType, forwardRef, type HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

type StatProps = HTMLAttributes<HTMLDivElement> & {
  delta?: string;
  fetching?: boolean;
  icon?: ComponentType<{ className?: string }>;
  metric?: number | string;
  title: string;
  to?: string;
};

const Stat = forwardRef<HTMLDivElement, StatProps>((props, ref) => {
  const { delta, fetching, metric, title, to, className, ...extra } = props;

  return (
    <Card
      {...extra}
      className={cn('w-full flex items-center justify-between', className)}
      ref={ref}
    >
      <div className="h-full p-4 flex flex-col items-start">
        <div className="text-md font-bold text-muted-foreground">{title}</div>
        {fetching ? (
          <div className="text-2xl font-extrabold text-foreground animate-pulse">
            ---
          </div>
        ) : (
          <>
            <div className="text-2xl font-extrabold text-foreground">
              {metric}
            </div>
            {delta && (
              <p className="text-md font-bold text-muted-foreground">{delta}</p>
            )}
          </>
        )}
      </div>
      {to && (
        <div className="relative rounded-r-2xl hover:bg-blue-50 p-2 h-full flex items-center">
          <Link
            className="flex items-center text-blue-500 hover:text-blue-700"
            to={to}
          >
            <span className="absolute inset-0" />
            <ChevronRightIcon className="h-6 w-6" />
          </Link>
        </div>
      )}
    </Card>
  );
});

export { Stat, type StatProps };
