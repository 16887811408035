import { CheckIn } from './routes/CheckIn';
import { CheckInReport } from './routes/CheckInReport';
import { CheckInReportNew } from './routes/CheckInReportNew';
import { CheckInReports } from './routes/CheckInReports';
import { CheckIns } from './routes/Checkins';
import { type RouteObject } from 'react-router-dom';

export const checkInRoutes: RouteObject[] = [
  {
    element: <CheckInReportNew />,
    path: `/check-in-reports/New`,
  },
  {
    element: <CheckInReport />,
    path: '/check-in-reports/:id',
  },
  {
    element: <CheckInReports />,
    path: `/check-in-reports`,
  },
  {
    element: <CheckIns />,
    path: '/check-in',
  },
  {
    element: <CheckIn />,
    path: '/check-in/:id',
  },
];
