import { ApplicationFooter } from './ApplicationFooter';
import { ApplicationHeader } from './ApplicationHeader';
import { ApplicationSidebar } from './ApplicationSidebar';
import { CurrentOrganization } from './CurrentOrganization';
import { ErrorBoundaryFallback } from '@/features/Misc';
import { ErrorBoundary } from '@sentry/react';
import { Outlet, useLocation } from 'react-router-dom';

const ApplicationLayout = () => {
  const { pathname } = useLocation();

  return (
    <CurrentOrganization>
      <div className="flex min-h-screen w-full flex-col bg-muted/40 dark:bg-background">
        <ApplicationSidebar />
        <div className="flex flex-1 flex-col md:pt-4 md:pl-14">
          <ApplicationHeader />
          <main className="mt-4 flex-1">
            <ErrorBoundary
              fallback={ErrorBoundaryFallback}
              key={pathname}
            >
              <Outlet />
            </ErrorBoundary>
          </main>
          <ApplicationFooter />
        </div>
      </div>
    </CurrentOrganization>
  );
};

export { ApplicationLayout };
