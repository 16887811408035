import { useTheme } from '@/providers/ThemeProvider';
import { Pin as UpstreamPin } from '@vis.gl/react-google-maps';
import { type ReactNode } from 'react';

const PinSize = {
  avatar: 1.4,
  default: 1,
};

const PinVarant = {
  dark: {
    default: '#FFFFFF',
    primary: '#4F63FF',
  },
  light: {
    default: '#05082C',
    primary: '#4F63FF',
  },
  system: {
    default: '#05082C',
    primary: '#4F63FF',
  },
};

type PinProps = {
  readonly children?: ReactNode | undefined;
  readonly size?: 'avatar' | 'default';
  readonly variant?: 'default' | 'primary';
};

const Pin = ({ children, size = 'default', variant = 'default' }: PinProps) => {
  const { theme } = useTheme();

  let computedTheme = theme;
  if (theme === 'system') {
    computedTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
  }

  return (
    <UpstreamPin
      background={PinVarant[computedTheme][variant]}
      borderColor={PinVarant[computedTheme][variant]}
      glyphColor={PinVarant[computedTheme][variant]}
      scale={PinSize[size]}
    >
      {children}
    </UpstreamPin>
  );
};

Pin.displayName = 'Pin';

export { Pin };
