import { SignIn } from './SignIn';
import { Spinner } from '@/components/Spinner';
import { CurrentUser } from '@/features/Account/components/CurrentUser';
import { useAuth0 } from '@auth0/auth0-react';
import { type ReactNode } from 'react';

type AuthenticationGuardProps = {
  readonly children: ReactNode;
};

const AuthenticationGuard = ({ children }: AuthenticationGuardProps) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (!isAuthenticated && isLoading) {
    return (
      <div className="h-screen flex">
        <Spinner
          fullscreen
          logo
        />
      </div>
    );
  }

  if (!isAuthenticated) {
    // display sign in component
    return <SignIn />;
  }

  // user is authenticated show component
  return <CurrentUser>{children}</CurrentUser>;
};

export { AuthenticationGuard };
