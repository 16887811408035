import { useNewOrganizationStore } from './NewOrganizationStore';
import LogoIcon from '@/assets/images/logo/icon/128_128.svg';
import Logo from '@/assets/images/logo/sisi.svg';
import { Button } from '@/components/ui/button';
import { config } from '@/config';
import { graphql } from '@/gql';
import { X } from 'lucide-react';
import { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useMutation } from 'urql';

const DeleteSubscriptionIntentQuery = graphql(`
  mutation DeleteSubscriptionIntentQuery($subscriptionId: String!) {
    deleteOrganizationSubscriptionIntent(
      input: { subscriptionId: $subscriptionId }
    ) {
      status
    }
  }
`);

const NewOrganizationLayout = () => {
  const [, deleteIntent] = useMutation(DeleteSubscriptionIntentQuery);

  useEffect(() => {
    return () => {
      const reset = useNewOrganizationStore.getState().reset;
      const intentId = useNewOrganizationStore.getState().intent?.id;
      if (intentId) {
        deleteIntent({ subscriptionId: intentId });
      }

      reset();
    };
  }, [deleteIntent]);

  return (
    <div className="lg:min-h-screen flex-col md:flex relative z-0">
      <header className="">
        <div className="flex h-16 items-center px-4 lg:px-6">
          <div className="flex items-center justify-start">
            <div>
              <img
                alt="See It Send It"
                className="h-12"
                src={Logo}
              />
            </div>
          </div>

          <div className="ml-auto flex items-center space-x-4">
            <Link to="/dashboard">
              <Button
                size="icon"
                variant="ghost"
              >
                <X className="h-8 w-8" />
              </Button>
            </Link>
          </div>
        </div>
      </header>

      <main className="relative flex-1 space-y-4 pt-6 md:px-6">
        <h2 className="text-3xl font-extrabold text-foreground sm:text-5xl sm:leading-none sm:tracking-tight lg:text-5xl">
          Create a new Organization
        </h2>

        <Outlet />
      </main>

      <footer>
        <div className="text-sm p-4 md:mt-auto flex items-start justify-between md:items-center md:px-8">
          <div className="flex items-center space-x-4">
            <div className="flex items-center gap-1">
              <img
                alt="See It Send It"
                className="h-10 w-auto md:h-6 md:w-auto"
                src={LogoIcon}
              />
              <span>v{config.VERSION}</span>
            </div>
          </div>
          <div className="flex flex-col text-right space-y-4 md:space-y-0 md:text-left md:flex-row md:items-center md:space-x-4">
            <a
              className="hover:underline"
              href="https://seeitsendit.com/privacy-policy"
              rel="noreferrer noopener"
              target="_blank"
            >
              Privacy Policy
            </a>
            <a
              className="hover:underline"
              href="https://seeitsendit.com/terms-of-use"
              rel="noreferrer noopener"
              target="_blank"
            >
              Terms of Service
            </a>
            <a
              className="hover:underline"
              href="https://seeitsendit.com/contact"
              rel="noreferrer noopener"
              target="_blank"
            >
              Contact Us
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export { NewOrganizationLayout };
