import { Datetime } from '@/components/Datetime';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { format } from 'date-fns';
import { Loader } from 'lucide-react';
import { type ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { type Infer, object, refine, string } from 'superstruct';
import { useMutation } from 'urql';

const GuardMeExtendDialogGql = graphql(`
  mutation GuardMeExtendDialogGql($memberId: String!, $endsAt: Datetime!) {
    updateTimedGuardmeEndsAt(input: { memberId: $memberId, endsAt: $endsAt }) {
      guardme {
        id
        endsAt
        updatedAt
      }
    }
  }
`);

const convertToDatetimeLocal = (input: Date) => {
  return format(input, "yyyy-MM-dd'T'HH:mm");
};

const datetime = (currentEndsAt: Date) =>
  refine(string(), 'datetime', (value) => {
    const newDate = new Date(value);

    if (newDate.valueOf() > currentEndsAt.valueOf()) {
      return true;
    }

    return `New end time must occur after current end time.`;
  });

type GuardMeExtendDialogProps = {
  children?: ReactNode;
  currentEndsAt: Date;
  memberId?: string;
};

const GuardMeExtendDialog = ({
  children,
  currentEndsAt,
  memberId,
}: GuardMeExtendDialogProps) => {
  const [open, setOpen] = useState(false);

  const [{ error, fetching }, updateEndsAt] = useMutation(
    GuardMeExtendDialogGql,
  );

  const schema = object({
    endsAt: datetime(currentEndsAt),
  });

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      endsAt: convertToDatetimeLocal(currentEndsAt),
    },
    resolver: superstructResolver(schema),
  });

  if (!memberId) {
    return null;
  }

  const handleCancel = () => {
    form.reset();
    setOpen(false);
  };

  const onSubmit = async (values: Infer<typeof schema>) => {
    const { error: mutationError } = await updateEndsAt({
      endsAt: new Date(values.endsAt).toISOString(),
      memberId,
    });

    if (!mutationError) {
      setOpen(false);
    }
  };

  return (
    <Dialog
      onOpenChange={setOpen}
      open={open}
    >
      {children && <DialogTrigger asChild>{children}</DialogTrigger>}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Extend GuardMe Session</DialogTitle>
          <DialogDescription>
            Current end time: <Datetime datetime={currentEndsAt} />
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="space-y-8"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="endsAt"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Select a new end time for the session.</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="datetime-local"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <MutationError error={error} />

            <DialogFooter>
              <Button
                disabled={fetching}
                onClick={handleCancel}
                type="reset"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                disabled={fetching}
                type="submit"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Extend Session
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { GuardMeExtendDialog, type GuardMeExtendDialogProps };
