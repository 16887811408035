import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { Loader } from 'lucide-react';
import { useMutation } from 'urql';

const ResendPendingPhoneNumberCodeQuery = graphql(`
  mutation ResendPendingPhoneNumberCode($phoneNumber: String!) {
    currentUserUpdatePhoneNumber(input: { phoneNumber: $phoneNumber }) {
      query {
        currentUser {
          id
          pendingPhoneNumber
        }
      }
    }
  }
`);

type ResendPendingPhoneNumberCodeProps = {
  readonly pending?: string | null;
};

const ResendPendingPhoneNumberCode = ({
  pending,
}: ResendPendingPhoneNumberCodeProps) => {
  const [{ fetching }, verifyPhoneNumber] = useMutation(
    ResendPendingPhoneNumberCodeQuery,
  );

  if (!pending) {
    return null;
  }

  const handleSubmit = async () => {
    const response = await verifyPhoneNumber({
      phoneNumber: pending,
    });

    if (response.error) {
      toast.error('Problem sending verification code!', {
        description: response.error.message,
      });
    } else {
      toast.success('Verification code sent');
    }
  };

  return (
    <Button
      className="h-8"
      disabled={fetching}
      onClick={handleSubmit}
      variant="outline"
    >
      {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
      Resend Code
    </Button>
  );
};

export { ResendPendingPhoneNumberCode };
