import { Circle, Map, Polygon, useIsMapProviderLoaded } from '@/components/Map';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { type Place } from '@/gql/graphql';
import { useAppStore } from '@/stores';
import { Pencil } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

type PlaceDetailProps = Pick<
  Place,
  'center' | 'description' | 'id' | 'name' | 'radius' | 'spatialData'
>;

const PlaceDetail = ({
  center,
  description,
  id,
  name,
  radius,
  spatialData,
}: PlaceDetailProps) => {
  const [isAdmin] = useAppStore((state) => [state.activeMembership?.isAdmin]);
  const navigate = useNavigate();
  const isMapLoaded = useIsMapProviderLoaded();

  if (!isMapLoaded) {
    return <Skeleton className="h-64" />;
  }

  // determine bounds of place
  let bounds: google.maps.LatLngBounds | null = null;

  if (google.maps.Circle && google.maps.LatLngBounds) {
    if (spatialData.geojson.type === 'Point' && google?.maps) {
      let circle: google.maps.Circle | null = new google.maps.Circle({
        center: {
          lat: spatialData.geojson.coordinates[1],
          lng: spatialData.geojson.coordinates[0],
        },
        radius,
      });

      bounds = circle.getBounds();
      circle = null;
    }

    if (spatialData.geojson.type === 'Polygon' && google?.maps) {
      bounds = new google.maps.LatLngBounds();

      for (const outer of spatialData.geojson.coordinates as number[][][]) {
        for (const inner of outer) {
          const [lng, lat] = inner;
          bounds.extend({ lat, lng });
        }
      }
    }
  }

  return (
    <div className="flex items-stretch border">
      <div className="w-1/2">
        <Map
          className="h-64"
          defaultBounds={bounds?.toJSON() ?? undefined}
          defaultCenter={{
            lat: center.latitude,
            lng: center.longitude,
          }}
          defaultZoom={7}
        >
          {spatialData.geojson.type === 'Polygon' && (
            <Polygon
              draggable={false}
              editable={false}
              paths={(spatialData.geojson.coordinates as number[][][]).map(
                (outer) => {
                  return outer.map((inner) => {
                    const [lng, lat] = inner;
                    return { lat, lng };
                  });
                },
              )}
            />
          )}
          {spatialData.geojson.type === 'Point' && (
            <Circle
              center={{
                lat: spatialData.geojson.coordinates[1],
                lng: spatialData.geojson.coordinates[0],
              }}
              draggable={false}
              editable={false}
              radius={radius}
            />
          )}
        </Map>
      </div>
      <div className="w-1/2 p-4 flex flex-col justify-between">
        <div className="space-y-2">
          <h3 className="font-bold text-xl">{name}</h3>
          <p className="">
            {description ? (
              description
            ) : (
              <span className="text-gray-400 italic">No Description</span>
            )}
          </p>
        </div>

        <div className="flex items-center justify-end">
          {isAdmin && (
            <Button
              onClick={() => {
                navigate(`/settings/places/${id}/edit`);
              }}
              variant="secondary"
            >
              <Pencil className="h-4 w-4 mr-2" />
              Edit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export { PlaceDetail };
