import { AssignDefaultGroupToIncidentTypeModal } from './AssignDefaultGroupToIncidentTypeModal';
import { GraphqlError, Spinner } from '@/components';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { useAppStore } from '@/stores';
import { LinkIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

const IncidentSettingsEnabledQuery = graphql(`
  query IncidentSettingsEnabledQuery($organizationId: String!) {
    incidentTypes {
      totalCount
      nodes {
        id
        name
        description
        category
        allowlist
        useAllowlist
        iconSvg
      }
    }
    organization(id: $organizationId) {
      id
      enabledIncidentTypes {
        totalCount
        nodes {
          id
          organizationId
          incidentTypeId
          defaultAssignedGroup {
            id
            name
            members {
              totalCount
            }
          }
        }
      }
      groups {
        nodes {
          id
          name
        }
      }
    }
  }
`);

const EnableIncidentTypeQuery = graphql(`
  mutation EnableIncidentTypeQuery(
    $organizationId: String!
    $incidentTypeId: String!
  ) {
    enableIncidentForOrganization(
      input: {
        organizationId: $organizationId
        incidentTypeId: $incidentTypeId
        defaultAssignedGroupId: ""
      }
    ) {
      query {
        organization(id: $organizationId) {
          id
          enabledIncidentTypes {
            totalCount
            nodes {
              id
              organizationId
              incidentTypeId
              defaultAssignedGroup {
                id
                name
                members {
                  totalCount
                }
              }
            }
          }
        }
      }
    }
  }
`);

const DisableIncidentTypeQuery = graphql(`
  mutation DisableIncidentTypeQuery(
    $organizationId: String!
    $incidentTypeId: String!
  ) {
    disableIncidentForOrganization(
      input: {
        organizationId: $organizationId
        incidentTypeId: $incidentTypeId
      }
    ) {
      query {
        organization(id: $organizationId) {
          id
          enabledIncidentTypes {
            totalCount
            nodes {
              id
              organizationId
              incidentTypeId
              defaultAssignedGroup {
                id
                name
                members {
                  totalCount
                }
              }
            }
          }
        }
      }
    }
  }
`);

type IncidentDetails = Array<{
  assignedGroup: {
    id?: string;
    memberCount: number;
    name?: string;
  };
  category: string;
  description: string;
  enabledIncidentTypeId?: string;
  icon: string;
  id: string;
  isEnabled: boolean;
  name: string;
}>;

type IncidentSettingsEnabledProps = {
  readonly organizationId: string;
};

const IncidentSettingsEnabled = ({
  organizationId,
}: IncidentSettingsEnabledProps) => {
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);

  const [{ data, error, fetching }] = useQuery({
    query: IncidentSettingsEnabledQuery,
    variables: {
      organizationId,
    },
  });

  const [, enableIncidentType] = useMutation(EnableIncidentTypeQuery);
  const [, disableIncidentType] = useMutation(DisableIncidentTypeQuery);

  const incidentNodes: IncidentDetails = [];

  // replace data?.incidentTypes with this to prevent globale admins from
  // seeing all incident type until backend is corrected.
  const globalAdminFix =
    data?.incidentTypes?.nodes.filter(
      (item) => !item.useAllowlist || item.allowlist.includes(organizationId),
    ) || [];

  if (globalAdminFix && data?.organization?.enabledIncidentTypes?.nodes) {
    for (const node of globalAdminFix.sort((a, b) =>
      a.name < b.name ? -1 : 1,
    )) {
      const enabledIncidentType =
        data.organization.enabledIncidentTypes.nodes.find(
          (item) => item.incidentTypeId === node.id,
        );

      incidentNodes.push({
        assignedGroup: {
          id: enabledIncidentType?.defaultAssignedGroup?.id,
          memberCount:
            enabledIncidentType?.defaultAssignedGroup?.members?.totalCount ?? 0,
          name: enabledIncidentType?.defaultAssignedGroup?.name,
        },
        category: node.category,
        description: node.description,
        enabledIncidentTypeId: enabledIncidentType?.id,
        icon: node.iconSvg,
        id: node.id,
        isEnabled: Boolean(enabledIncidentType?.id),
        name: node.name,
      });
    }
  }

  if (fetching) {
    return <Spinner fullscreen />;
  }

  if (error) {
    return <GraphqlError error={error} />;
  }

  const onSwitch = (id: string, name: string) => {
    return async (value: boolean) => {
      if (value) {
        const response = await enableIncidentType({
          incidentTypeId: id,
          organizationId,
        });
        if (response.error) {
          toast.error(`Enable ${name} Incident type failed!`, {
            description: response.error.message,
          });
        } else {
          toast.success(`Enabled ${name} Incident type`);
        }
      } else {
        const response = await disableIncidentType({
          incidentTypeId: id,
          organizationId,
        });
        if (response.error) {
          toast.error(`Disable ${name} Incident type failed!`, {
            description: response.error.message,
          });
        } else {
          toast.success(`Disabled ${name} Incident type`);
        }
      }
    };
  };

  const enabledTotal = incidentNodes.filter((item) => item.isEnabled).length;

  return (
    <>
      <div className="flex items-center justify-end">
        <div className="font-bold">
          {enabledTotal} enabled / {incidentNodes.length}
        </div>
      </div>
      {data?.organization?.enabledIncidentTypes.totalCount === 0 && (
        <Alert variant="info">
          <AlertTitle>No Incidents Enabled!</AlertTitle>
          <AlertDescription>
            There are no incident types enabled for this organization. To allow
            members to create incidents please enable at least one incident
            type.
          </AlertDescription>
        </Alert>
      )}

      <ul className="space-y-4">
        {incidentNodes.map((item) => (
          <li key={item.id}>
            <div className="border rounded-lg p-4 flex items-start">
              <img
                alt={item.name}
                className="h-12 w-12 bg-info rounded-xl p-1.5"
                src={`data:image/svg+xml;base64,${item.icon}`}
              />
              <div className="ml-4">
                <div className="space-y-0.5">
                  <Label
                    className="font-bold text-xl flex items-center"
                    htmlFor={item.id}
                  >
                    {item.name}
                    <Badge className="ml-2 hover:bg-primary">
                      {item.category}
                    </Badge>
                  </Label>
                  <div className="text-sm space-y-2">
                    <p>{item.description}</p>
                    <div className="flex items-center gap-2">
                      <span className="text-muted-foreground">
                        Default group:
                      </span>
                      <div className="w-64">
                        <AssignDefaultGroupToIncidentTypeModal
                          assignedGroupId={item.assignedGroup.id}
                          disabled={!isAdmin}
                          enabledIncidentTypeId={item.enabledIncidentTypeId}
                          groups={data?.organization?.groups.nodes ?? []}
                          incidentTypeId={item.id}
                          organizationId={organizationId}
                        />
                      </div>
                      <div>
                        {item.assignedGroup.id && (
                          <Link
                            className="hover:text-primary"
                            to={`/members/groups/${item.assignedGroup.id}`}
                          >
                            <LinkIcon className="w-4 h-4" />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-auto">
                <Switch
                  checked={item.enabledIncidentTypeId !== undefined}
                  disabled={!isAdmin}
                  id={item.id}
                  onCheckedChange={onSwitch(item.id, item.name)}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export { IncidentSettingsEnabled };
