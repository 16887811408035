import { AuthenticationGuard } from './AuthenticationGuard';
import { Outlet } from 'react-router-dom';

const AuthenticatedLayout = () => {
  return (
    <AuthenticationGuard>
      <Outlet />
    </AuthenticationGuard>
  );
};

export { AuthenticatedLayout };
