import { BatteryLevel } from './BatteryLevel';
import { GuardMeProgress } from './GuardMeProgress';
import { GuardMeStatus } from './GuardMeStatus';
import { GuardMeTimer } from './GuardMeTimer';
import { Datetime } from '@/components/Datetime';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { type Guardme, GuardmeStatus } from '@/gql/graphql';
import { datetime } from '@/utils';

type Props = Partial<
  Pick<
    Guardme,
    | 'batteryLevel'
    | 'completedAt'
    | 'createdAt'
    | 'endsAt'
    | 'notes'
    | 'status'
    | 'updatedAt'
    | 'totalAttachments'
  >
> & {
  readonly className?: string;
  readonly fetching: boolean;
};

const GuardMeDetails = ({
  batteryLevel,
  className,
  completedAt,
  createdAt,
  endsAt,
  fetching,
  notes,
  status,
  totalAttachments,
  updatedAt,
}: Props) => {
  const now = Date.now();
  const isExpired = completedAt ? false : datetime(endsAt).valueOf() < now;

  return (
    <Card className={className}>
      <CardHeader className="pb-2">
        <CardTitle>
          {fetching ? (
            <Skeleton className="h-10 w-1/2" />
          ) : (
            <GuardMeStatus
              className="text-2xl"
              size="lg"
              status={status}
            />
          )}
        </CardTitle>

        {fetching ? (
          <Skeleton className="h-6 w-full" />
        ) : (
          <GuardMeProgress
            completedAt={completedAt}
            createdAt={createdAt}
            endsAt={endsAt}
            status={status}
          />
        )}
      </CardHeader>
      <CardContent className="text-sm grid md:grid-cols-2 md:gap-8 pt-4 items-start">
        <dl className="grid gap-3">
          <div className="flex items-center justify-between">
            <dt className="flex items-center gap-1 text-muted-foreground">
              Started At
            </dt>
            <dd>
              {fetching ? (
                <Skeleton className="h-8 w-48" />
              ) : (
                <Datetime datetime={createdAt} />
              )}
            </dd>
          </div>
          {completedAt === null && (
            <div className="flex items-center justify-between">
              <dt className="flex items-center gap-1 text-muted-foreground">
                Expires At
              </dt>
              <dd>
                {fetching ? (
                  <Skeleton className="h-8 w-48" />
                ) : (
                  <Datetime datetime={endsAt} />
                )}
              </dd>
            </div>
          )}
          {completedAt !== null && (
            <div className="flex items-center justify-between">
              <dt className="flex items-center gap-1 text-muted-foreground">
                Completed At
              </dt>
              <dd>
                {fetching ? (
                  <Skeleton className="h-8 w-48" />
                ) : (
                  <Datetime datetime={completedAt} />
                )}
              </dd>
            </div>
          )}
          <div className="flex items-center justify-between">
            <dt className="flex items-center gap-1 text-muted-foreground">
              Duration
            </dt>
            <dd>
              {fetching ? (
                <Skeleton className="h-8 w-48" />
              ) : completedAt ? (
                <Datetime
                  datetime={createdAt}
                  end={completedAt}
                  variant="duration"
                />
              ) : (
                <GuardMeTimer
                  startTime={createdAt}
                  targetTime={endsAt}
                />
              )}
            </dd>
          </div>
          {completedAt === null && status !== GuardmeStatus.InPanic && (
            <div className="flex items-center justify-between">
              <dt className="flex items-center gap-1 text-muted-foreground">
                {isExpired ? 'Expired For' : 'Expires In'}
              </dt>
              <dd>
                {fetching ? (
                  <Skeleton className="h-8 w-48" />
                ) : completedAt ? (
                  <Datetime
                    datetime={createdAt}
                    end={completedAt}
                    variant="duration"
                  />
                ) : (
                  <GuardMeTimer
                    startTime={isExpired ? endsAt : undefined}
                    targetTime={isExpired ? undefined : endsAt}
                  />
                )}
              </dd>
            </div>
          )}
        </dl>

        <Separator className="my-4 md:hidden" />
        <dl className="grid gap-3">
          <div className="flex items-center justify-between">
            <dt className="flex items-center gap-1 text-muted-foreground">
              Attachments
            </dt>
            <dd>
              {fetching ? <Skeleton className="h-8 w-48" /> : totalAttachments}
            </dd>
          </div>
          <div className="flex items-center justify-between">
            <dt className="flex items-center gap-1 text-muted-foreground">
              Battery Level
            </dt>
            <dd>
              {fetching ? (
                <Skeleton className="h-8 w-48" />
              ) : (
                <BatteryLevel value={batteryLevel} />
              )}
            </dd>
          </div>
          <div className="grid gap-1">
            <dt className="text-muted-foreground">Additional Notes</dt>
            <dd className="text-right">{notes}</dd>
          </div>
        </dl>
      </CardContent>
      <CardFooter className="flex gap-1 items-center border-t bg-muted/50 px-6 py-3">
        <span className="text-xs text-muted-foreground">
          Updated at <Datetime datetime={updatedAt} />
        </span>
      </CardFooter>
    </Card>
  );
};

export { GuardMeDetails };
