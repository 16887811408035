import {
  AdvancedMarker,
  Circle,
  Map,
  MapProvider,
  Pin,
  Polygon,
} from '@/components/Map';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  type GeographyInterface,
  type GeographyPoint,
  type GeometryPoint,
  type Member,
  type Place,
} from '@/gql/graphql';
import { Fragment } from 'react';

type CheckInDetailsMapProps = {
  readonly coordinates?: Pick<GeometryPoint, 'x' | 'y'>;
  readonly member?: Pick<
    Member,
    'avatarUrl' | 'displayName' | 'fullName' | 'id'
  > | null;
  readonly places?: Array<
    Pick<Place, 'id' | 'radius' | 'name'> & {
      center: Pick<GeographyPoint, 'latitude' | 'longitude'>;
      spatialData: Pick<GeographyInterface, 'geojson'>;
    }
  >;
};

const CheckInDetailsMap = ({
  coordinates,
  member,
  places = [],
}: CheckInDetailsMapProps) => {
  if (!coordinates) {
    return null;
  }

  return (
    <div className="h-full">
      <MapProvider>
        <Map
          className="h-full"
          defaultCenter={{
            lat: coordinates.y,
            lng: coordinates.x,
          }}
          defaultZoom={14}
          fullscreenControl={false}
          mapTypeControl={false}
          streetViewControl={false}
        >
          <AdvancedMarker
            position={{
              lat: coordinates.y,
              lng: coordinates.x,
            }}
          >
            <Pin size="avatar">
              <Avatar className="h-8 w-8">
                <AvatarImage
                  alt={
                    member?.displayName ?? member?.fullName ?? 'Deleted Member'
                  }
                  src={member?.avatarUrl ?? undefined}
                />
                <AvatarFallback>
                  {(
                    member?.displayName ??
                    member?.fullName ??
                    'Deleted Member'
                  ).slice(0, 2)}
                </AvatarFallback>
              </Avatar>
            </Pin>
          </AdvancedMarker>

          {places.map((item) => (
            <Fragment key={item.id}>
              {item.spatialData.geojson.type === 'Polygon' && (
                <Polygon
                  draggable={false}
                  editable={false}
                  paths={(
                    item.spatialData.geojson.coordinates as number[][][]
                  ).map((outer) => {
                    return outer.map((inner) => {
                      const [lng, lat] = inner;
                      return { lat, lng };
                    });
                  })}
                />
              )}
              {item.spatialData.geojson.type === 'Point' && (
                <Circle
                  center={{
                    lat: item.spatialData.geojson.coordinates[1],
                    lng: item.spatialData.geojson.coordinates[0],
                  }}
                  draggable={false}
                  editable={false}
                  radius={item.radius}
                />
              )}
            </Fragment>
          ))}
        </Map>
      </MapProvider>
    </div>
  );
};

export { CheckInDetailsMap };
