import { GuardMe } from './routes/GuardMe';
import { GuardMes } from './routes/GuardMes';
import { type RouteObject } from 'react-router-dom';

export * from './components/AttachmentCount';
export * from './components/BatteryLevel';
export * from './components/buttons/GuardMeConcludeDialog';
export * from './components/buttons/GuardMeExtendDialog';
export * from './components/buttons/GuardMePanicDialog';
export * from './components/GuardMeStatus';
export * from './components/GuardMeTimer';
export * from './components/GuardMeUpdates';

export const guardMeRoutes: RouteObject[] = [
  {
    element: <GuardMe />,
    path: '/guardme/:guardMeId',
  },
  {
    element: <GuardMes />,
    path: '/guardme',
  },
];
