import { graphql } from '@/gql';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const GroupComponentGql = graphql(`
  query GroupComponentGql($id: String!) {
    group(id: $id) {
      id
      name
    }
  }
`);

type GroupProps = {
  readonly groupId?: string | null | undefined;
};

const Group = ({ groupId }: GroupProps) => {
  const [{ data, error, fetching }] = useQuery({
    pause: !groupId,
    query: GroupComponentGql,
    variables: {
      id: groupId ?? '',
    },
  });

  if (groupId === '') {
    return <span className="inine-flex items-center">---</span>;
  }

  if (fetching) {
    return <span className="inline-flex items-center">Loading...</span>;
  }

  if (error) {
    return <span className="inline-flex items-center">Missing data</span>;
  }

  if (data?.group) {
    return (
      <span className="inline-flex items-center">
        <Link
          className="hover:underline"
          to={`/members/groups/${data.group.id}`}
        >
          {data.group.name}
        </Link>
      </span>
    );
  }

  return <span className="inline-flex items-center">Deleted group</span>;
};

export { Group };
