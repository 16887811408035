import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { graphql } from '@/gql';
import { Pencil } from 'lucide-react';
import { useState } from 'react';
import { useMutation, useQuery } from 'urql';

const EditIncidentContactDropdownDropdownGql = graphql(`
  mutation EditIncidentContactDropdownDropdownGql(
    $input: UpdateIncidentContactInput!
  ) {
    updateIncidentContact(input: $input) {
      incident {
        id
        contactMemberId
        contactMember {
          id
          fullName
          displayName
          avatarUrl
        }
      }
    }
  }
`);

const AllMemberDropdownGql = graphql(`
  query AllMemberDropdownGql($organizationId: String!) {
    members(condition: { organizationId: $organizationId }) {
      nodes {
        id
        isEnabled
        fullName
        displayName
        avatarUrl
      }
    }
  }
`);

type EditIncidentContactDropdownDropdownProps = {
  readonly incidentId: string;
  readonly memberId: string;
  readonly onComplete?: () => void;
  readonly organizationId: string;
};

const EditIncidentContactDropdown = ({
  incidentId,
  memberId,
  onComplete,
  organizationId,
}: EditIncidentContactDropdownDropdownProps) => {
  const [open, setOpen] = useState(false);

  const [, updateContact] = useMutation(EditIncidentContactDropdownDropdownGql);

  const [{ data }] = useQuery({
    pause: !organizationId,
    query: AllMemberDropdownGql,
    variables: {
      organizationId: organizationId ?? '',
    },
  });

  const handleSelect = async (value: string) => {
    await updateContact({
      input: {
        incidentId,
        memberId: value,
      },
    });

    onComplete?.();

    setOpen(false);
  };

  return (
    <Popover
      onOpenChange={setOpen}
      open={open}
    >
      <PopoverTrigger asChild>
        <Button
          className="h-6 text-xs"
          size="sm"
          variant="ghost"
        >
          <Pencil className="mr-2 h-3 w-3" />
          Change Contact
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="p-0"
        side="bottom"
      >
        <Command>
          <CommandInput placeholder="Filter members..." />
          <CommandList>
            <CommandEmpty>No members found.</CommandEmpty>
            <CommandGroup>
              {data?.members?.nodes
                .filter((item) => item.isEnabled)
                .map((item) => (
                  <CommandItem
                    className={item.id === memberId ? 'bg-accent' : ''}
                    key={item.id}
                    onSelect={async () => await handleSelect(item.id)}
                    value={`${item.id}-${item.displayName}-${item.fullName}`}
                  >
                    <Avatar className="h-5 w-5 mr-2 shrink-0">
                      <AvatarImage
                        alt={
                          item.displayName ?? item.fullName ?? 'Deleted Member'
                        }
                        src={item.avatarUrl ?? undefined}
                      />
                      <AvatarFallback>
                        {(
                          item.displayName ??
                          item.fullName ??
                          'Deleted Member'
                        ).slice(0, 2)}
                      </AvatarFallback>
                    </Avatar>
                    <span>{item.displayName ?? item.fullName}</span>
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export { EditIncidentContactDropdown };
