import IncidentsStartedSfx from '@/assets/sounds/guardme-started.mp3';
import { type DocumentType, graphql } from '@/gql';
import { GraphqlCheckInEvent } from '@/gql/graphql';
import { toast } from '@/lib/toast';
import { useAppStore } from '@/stores';
import { type Cache } from '@urql/exchange-graphcache';
import { Howl } from 'howler';
import { type NavigateFunction } from 'react-router-dom';
import { useSubscription } from 'urql';

const CheckInUpdatesGql = graphql(`
  subscription CheckInUpdatesGql($organizationId: String!) {
    checkInUpdates(input: { organizationId: $organizationId }) {
      checkIn {
        id
        shortId
        createdAt
        coordinates {
          x
          y
        }
        member {
          id
          displayName
          fullName
          avatarUrl
        }
        organizationId
        organization {
          id
          name
          logoUrl
        }
      }
      event
      relatedIds
    }
  }
`);

const checkinUpdatesResolver =
  (navigate: NavigateFunction) =>
  (
    result: DocumentType<typeof CheckInUpdatesGql>,
    _args: never,
    cache: Cache,
  ) => {
    if (!result?.checkInUpdates?.checkIn) {
      // no incidents on object, do nothing
      return;
    }

    if (!result.checkInUpdates.checkIn.organization) {
      // unknown organization
      return;
    }

    const event = result.checkInUpdates.event ?? 'updated';
    const checkInId = result.checkInUpdates.checkIn.id;

    if (event === GraphqlCheckInEvent.New) {
      const sound = new Howl({
        src: IncidentsStartedSfx,
      });

      const soundId = sound.play();

      toast.info(`New Check In (${result.checkInUpdates.checkIn.shortId})`, {
        action: {
          label: 'View',
          onClick: () => {
            useAppStore.setState((state) => ({
              activeMembership:
                state.memberships.find(
                  (org) =>
                    org.id === result.checkInUpdates?.checkIn?.organizationId,
                ) ?? state.activeMembership,
            }));
            navigate(`/check-in/${checkInId}`);
          },
        },
        description: `${
          result.checkInUpdates.checkIn.member?.displayName ??
          result.checkInUpdates.checkIn.member?.fullName
        } check in`,
      });

      setTimeout(() => {
        sound.stop(soundId);
      }, 3_000);
    }

    // invalidate incident query results to cause refetch with new data
    for (const field of cache
      .inspectFields('Query')
      .filter((query) => query.fieldName === 'checkIns')) {
      cache.invalidate('Query', 'checkIns', field.arguments);
    }
  };

type CheckInUpdatesProps = { organizationId: string };

const CheckInUpdates = ({ organizationId }: CheckInUpdatesProps) => {
  useSubscription({
    query: CheckInUpdatesGql,
    variables: {
      organizationId,
    },
  });

  return null;
};

export { CheckInUpdates, checkinUpdatesResolver };
