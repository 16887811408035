import { MutationError } from '../../Misc';
import { LocationSelect } from './LocationSelect';
import { NewIncidentFormInputBuilder } from './NewIncidentFormInputBuilder';
import { AttachmentUpload } from '@/components/AttachmentUpload';
import { MemberSelect } from '@/components/MemberSelect';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { graphql } from '@/gql';
import { IncidentPriorityType, type IncidentType } from '@/gql/graphql';
import {
  type DataSchema,
  incidentTypeDefaultValues,
  incidentTypeSchema,
  type UISchema,
} from '@/lib/incidentType';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { ChevronsDown, ChevronsUp, Flame, Loader, Minus } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  assign,
  enums,
  type Infer,
  nonempty,
  number,
  object,
  string,
} from 'superstruct';
import { useMutation } from 'urql';

const NewIncidentGql = graphql(`
  mutation NewIncidentGql($input: CreateIncidentInput!) {
    createIncident(input: $input) {
      incident {
        id
        incidentUpdates {
          nodes {
            id
            type
            message
            createdAt
            isSystemMessage
            performedByMember {
              id
              fullName
              displayName
              avatarUrl
            }
            attachments {
              nodes {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`);

type NewIncidentFormProps = {
  readonly currentLocation: { lat: number; lng: number };
  readonly currentMemberId: string;
  readonly incidentType: Pick<
    IncidentType,
    | 'category'
    | 'dataSchema'
    | 'description'
    | 'iconSvg'
    | 'id'
    | 'name'
    | 'uiSchema'
  >;
  readonly isIncidentSupervisor: boolean;
  readonly members: Array<{
    displayName?: string | null | undefined;
    fullName: string;
    id: string;
    isEnabled: boolean;
  }>;
  readonly organizationId: string;
};

const NewIncidentForm = ({
  currentLocation,
  currentMemberId,
  incidentType,
  isIncidentSupervisor,
  members,
  organizationId,
}: NewIncidentFormProps) => {
  const [{ error, fetching }, newIncident] = useMutation(NewIncidentGql);

  const navigate = useNavigate();

  const [attachmentIds, setAttachmentIds] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const uiSchema = (incidentType?.uiSchema || {}) as UISchema;
  const dataSchema = (incidentType?.dataSchema || {}) as DataSchema;

  const dynamicSchema = incidentTypeSchema(dataSchema);

  const schema = assign(
    dynamicSchema,
    object({
      contactMemberId: nonempty(string()),
      location: object({ lat: number(), lng: number() }),
      priority: enums([
        IncidentPriorityType.High,
        IncidentPriorityType.Low,
        IncidentPriorityType.Medium,
        IncidentPriorityType.Urgent,
      ]),
    }),
  );

  const defaultValues = incidentTypeDefaultValues(dataSchema);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      ...defaultValues,
      contactMemberId: currentMemberId,
      location: currentLocation,
      priority: IncidentPriorityType.Medium,
    },
    resolver: superstructResolver(schema),
  });

  const watchSubject = form.watch('subject');

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await newIncident({
      input: {
        additionalData: values.additionalData ? values.additionalData : {},
        attachmentIds,
        contactMemberId: values.contactMemberId,
        description: values.description,
        incidentCoordinates: {
          accuracy: 0,
          altitude: 0,
          altitudeAccuracy: 0,
          latitude: values.location.lat,
          longitude: values.location.lng,
        },
        incidentTypeId: incidentType.id,
        organizationId,
        priority: values.priority,
        subject: values.subject,
        submitterCoordinates: {
          accuracy: 0,
          altitude: 0,
          altitudeAccuracy: 0,
          latitude: values.location.lat,
          longitude: values.location.lng,
        },
      },
    });

    if (response.data?.createIncident?.incident?.id) {
      navigate(`/incidents/${response.data.createIncident.incident.id}`);
    }
  };

  return (
    <Form {...form}>
      <form
        className="space-y-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="md:order-2 grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card>
              <CardContent className="p-6 space-y-4">
                <FormField
                  control={form.control}
                  name="contactMemberId"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>Contact</FormLabel>
                      <MemberSelect
                        {...field}
                        disabled={!isIncidentSupervisor}
                        members={members}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="priority"
                  render={({ field: { onChange, value } }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>Priority</FormLabel>
                      <Select
                        defaultValue={value}
                        onValueChange={onChange}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select Priority" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value={IncidentPriorityType.Urgent}>
                            <div className="flex items-center">
                              <Flame className="mr-2 h-4 w-4" />
                              <span>Urgent</span>
                            </div>
                          </SelectItem>
                          <SelectItem value={IncidentPriorityType.High}>
                            <div className="flex items-center">
                              <ChevronsUp className="mr-2 h-4 w-4" />
                              <span>High</span>
                            </div>
                          </SelectItem>
                          <SelectItem value={IncidentPriorityType.Medium}>
                            <div className="flex items-center">
                              <Minus className="mr-2 h-4 w-4" />
                              <span>Medium</span>
                            </div>
                          </SelectItem>
                          <SelectItem value={IncidentPriorityType.Low}>
                            <div className="flex items-center">
                              <ChevronsDown className="mr-2 h-4 w-4" />
                              <span>Low</span>
                            </div>
                          </SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="location"
                  render={({ field: { onChange, value } }) => (
                    <FormItem>
                      <FormLabel>Reported Location</FormLabel>
                      <LocationSelect
                        onChange={onChange}
                        value={value}
                      />
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
          </div>

          <div className="md:order-1 grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card>
              <CardHeader>
                <CardTitle
                  className={
                    watchSubject === '' ? 'text-card-foreground/50' : undefined
                  }
                >
                  {watchSubject || 'Subject required'}
                </CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <NewIncidentFormInputBuilder
                  control={form.control}
                  dataSchema={dataSchema}
                  schema={uiSchema}
                />

                <FormItem>
                  <AttachmentUpload
                    allowMultiple
                    files={files}
                    setAttachmentIds={setAttachmentIds}
                    setFiles={setFiles}
                  />
                </FormItem>

                <MutationError error={error} />
              </CardContent>

              <CardFooter className="flex items-center justify-end gap-2">
                <Button
                  disabled={fetching}
                  onClick={() => {
                    navigate('/incidents');
                  }}
                  type="button"
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button
                  disabled={fetching}
                  type="submit"
                >
                  {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                  Save
                </Button>
              </CardFooter>
            </Card>
          </div>
        </div>
      </form>
    </Form>
  );
};

export { NewIncidentForm };
