import { Datetime } from '@/components/Datetime';
import { Loader } from '@/components/Loader';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { graphql } from '@/gql';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberIncidentListGql = graphql(`
  query MemberIncidentListGql($first: Int!, $contactMemberId: String!) {
    incidents(
      first: $first
      condition: { contactMemberId: $contactMemberId }
      orderBy: ID_DESC
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          shortId
          subject
          description
          status
          priority
          createdAt
          ownedByMember {
            id
            fullName
            displayName
            avatarUrl
          }
          ownedByGroup {
            id
            name
          }
          incidentType {
            id
            name
            iconSvg
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

type MemberIncidentListProps = {
  readonly memberId: string;
};

const MemberIncidentList = ({ memberId }: MemberIncidentListProps) => {
  const [{ data, error, fetching }] = useQuery({
    query: MemberIncidentListGql,
    requestPolicy: 'cache-and-network',
    variables: {
      contactMemberId: memberId,
      first: 5,
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl">Most Recent Incidents</CardTitle>
      </CardHeader>
      <CardContent>
        <Loader
          isLoading={fetching && data === undefined}
          loadingError={error}
        >
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Id</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Priority</TableHead>
                <TableHead>Created</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data?.incidents?.edges.map((item) => (
                <TableRow key={item.node.id}>
                  <TableCell>
                    <Link
                      className="font-bold hover:underline"
                      to={`/check-in/${item.node.id}`}
                    >
                      {item.node.shortId}
                    </Link>
                  </TableCell>
                  <TableCell className="hidden sm:table-cell">
                    {item.node.status}
                  </TableCell>
                  <TableCell className="hidden sm:table-cell">
                    {item.node.priority}
                  </TableCell>
                  <TableCell className="hidden sm:table-cell">
                    <Datetime datetime={item.node.createdAt} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Loader>
      </CardContent>
    </Card>
  );
};

export { MemberIncidentList };
