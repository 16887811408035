import { Progress } from '@/components/ui/progress';
import { type Guardme, GuardmeStatus } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';

type GuardMeProgressProps = Pick<
  Guardme,
  'createdAt' | 'endsAt' | 'completedAt'
> &
  Partial<Pick<Guardme, 'status'>> & {
    readonly className?: string;
  };

const calculateProgress = (
  createdAt: Guardme['createdAt'],
  endsAt: Guardme['endsAt'],
) => {
  // calculate the current progress
  const nowValue = Date.now();
  const endsAtValue = new Date(endsAt).valueOf();
  const createdAtValue = new Date(createdAt).valueOf();
  const totalDuration = endsAtValue - createdAtValue;
  const elapsed = nowValue - createdAtValue;

  return (elapsed / totalDuration) * 100;
};

const GuardMeProgress = ({
  className,
  completedAt,
  createdAt,
  endsAt,
  status,
}: GuardMeProgressProps) => {
  const [progressValue, setProgressValue] = useState(100);

  useEffect(() => {
    setProgressValue(completedAt ? 100 : calculateProgress(createdAt, endsAt));
  }, [completedAt, createdAt, endsAt]);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgressValue(calculateProgress(createdAt, endsAt));
    }, 1_000);
    return () => {
      clearInterval(progressInterval);
    };
  });

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgressValue(calculateProgress(createdAt, endsAt));
    }, 5_000);
    return () => {
      clearInterval(progressInterval);
    };
  });

  if (status === GuardmeStatus.InPanic) {
    return (
      <Progress
        className={cn(
          className,
          'bg-destructive-foreground dark:bg-destructive',
        )}
        indicatorClassName="bg-destructive-foreground dark:bg-destructive"
        value={100}
      />
    );
  }

  if (status === GuardmeStatus.Concluded) {
    return (
      <Progress
        className={className}
        value={100}
      />
    );
  }

  return (
    <Progress
      className={cn(
        progressValue < 100 ? 'bg-info' : 'bg-warning h-[24px] rounded-md',
        className,
      )}
      indicatorClassName={
        progressValue < 100 ? 'bg-info-foreground' : 'bg-warning-foreground'
      }
      value={progressValue < 100 ? progressValue : (100 / progressValue) * 100}
    />
  );
};

export { GuardMeProgress };
