import { AccountLayout } from './components/AccountLayout';
import { AccountDetails } from './routes/AccountDetails';
import { CloseAccount } from './routes/CloseAccount';
import { ContactDetails } from './routes/ContactDetails';
import { Memberships } from './routes/Memberships';
import { Notifications } from './routes/Notifications';
import { Navigate, type RouteObject } from 'react-router-dom';

export * from './components/CurrentUserUpdates';
export * from './components/JoinOrganization';

export const accountRoutes: RouteObject[] = [
  {
    children: [
      {
        element: <Memberships />,
        path: '/account/memberships',
      },
      {
        element: <AccountDetails />,
        path: '/account/profile',
      },
      {
        element: <ContactDetails />,
        path: '/account/contact',
      },
      {
        element: <CloseAccount />,
        path: '/account/close',
      },
      {
        element: <Notifications />,
        path: '/account/notifications',
      },
      {
        element: <Navigate to="/account/details" />,
        path: '/account',
      },
    ],
    element: <AccountLayout />,
  },
];
