import { create } from 'zustand';

type Intent = {
  id: string;
  invoiceTotal: number;
  organizationId: string;
  secret: string;
};

type Organization = {
  memberCount: number;
  metadata: {
    industry: string;
  };
  name: string;
  timezone: string;
};

type Plan = {
  costMonth: number;
  costYear: number;
  description: string;
  features: string[];
  id: string;
  internalName: string;
  name: string;
};

type State = {
  intent: Intent | null;
  organization: Organization | null;
  payAnnually: boolean;
  plan: Plan;
  step: number;
  subscriptionId: string | null;
};

type Actions = {
  changePlan: () => void;
  reset: () => void;
  setOrganizationAndIntent: (values: {
    intent: Intent;
    organization: Organization;
  }) => void;
  setPayAnnually: (value: boolean) => void;
  setPlan: (value: Plan) => void;
  setSubscriptionId: (value: string) => void;
};

const useNewOrganizationStore = create<Actions & State>()((set) => {
  return {
    changePlan: () => set((previous) => ({ ...previous, step: 1 })),
    intent: null,
    organization: null,
    payAnnually: true,
    plan: {
      costMonth: 0,
      costYear: 0,
      description: '',
      features: [],
      id: '',
      internalName: '',
      name: '',
    },
    reset: () =>
      set({
        intent: null,
        organization: null,
        payAnnually: true,
        plan: {
          costMonth: 0,
          costYear: 0,
          description: '',
          features: [],
          id: '',
          internalName: '',
          name: '',
        },
        step: 1,
        subscriptionId: '',
      }),
    setOrganizationAndIntent: ({ organization, intent }) =>
      set((previous) => ({ ...previous, intent, organization, step: 3 })),
    setPayAnnually: (payAnnually) =>
      set((previous) => ({ ...previous, payAnnually })),
    setPlan: (plan) => set((previous) => ({ ...previous, plan, step: 2 })),
    setSubscriptionId: (subscriptionId) =>
      set((previous) => ({ ...previous, subscriptionId })),
    step: 1,
    subscriptionId: null,
  };
});

export { type Intent, type Organization, type Plan, useNewOrganizationStore };
