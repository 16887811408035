import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { cn } from '@/lib/utils';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Check, Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { type Infer, object, string } from 'superstruct';
import { useMutation } from 'urql';

const VerifyPendingPhoneNumberQuery = graphql(`
  mutation VerifyPendingPhonenumber($input: VerifyPhoneNumberInput!) {
    verifyPhoneNumber(input: $input) {
      query {
        currentUser {
          id
          phoneNumber
          pendingPhoneNumber
        }
      }
    }
  }
`);

const schema = object({
  phoneNumber: string(),
  verificationCode: string(),
});

type VerifyPendingPhoneNumberProps = {
  readonly className?: string;
  readonly loginRequired: boolean;
  readonly pending?: string | null | undefined;
};

const VerifyPendingPhoneNumber = ({
  className,
  loginRequired,
  pending,
}: VerifyPendingPhoneNumberProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [{ error, fetching }, verifyPhoneNumber] = useMutation(
    VerifyPendingPhoneNumberQuery,
  );

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      phoneNumber: pending ?? '',
      verificationCode: '',
    },
    resolver: superstructResolver(schema),
  });

  if (!pending) {
    return null;
  }

  const onCancel = () => {
    form.reset();
    setOpen(false);
  };

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await verifyPhoneNumber({
      input: {
        phoneNumber: values.phoneNumber,
        verificationCode: values.verificationCode,
      },
    });

    if (!response.error) {
      toast.success('Updated phone number');
      onCancel();
    }

    if (loginRequired) {
      navigate('/auth/signout');
    }
  };

  return (
    <Dialog
      onOpenChange={setOpen}
      open={open}
    >
      <DialogTrigger asChild>
        <Button
          className={cn('whitespace-nowrap h-8', className)}
          variant="default"
        >
          <Check className="h-4 w-4 mr-2" />
          Verify
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Phone Number Verification</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form
            className="space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="verificationCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Verification Code</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <MutationError error={error} />

            <DialogFooter>
              <Button
                disabled={fetching}
                onClick={onCancel}
                type="reset"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                disabled={fetching}
                type="submit"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Verify
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { VerifyPendingPhoneNumber };
