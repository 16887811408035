import { cn } from '@/lib/utils';
import * as React from 'react';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    return (
      <input
        className={cn(
          'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          'dark:[color-scheme:dark]',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

const convertDateToDatetimeLocal = (input?: string) => {
  if (!input) {
    return '';
  }

  try {
    const date = new Date(input);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date.toISOString().slice(0, 16);
  } catch {
    return '';
  }
};

export type DatetimeInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  readonly onValueChange?: (value: string) => void;
};

const DatetimeInput = React.forwardRef<HTMLInputElement, DatetimeInputProps>(
  ({ className, onValueChange, value, ...props }, ref) => {
    const [internalValue, setInternalValue] = React.useState(
      convertDateToDatetimeLocal(value?.toString()),
    );

    return (
      <input
        className={cn(
          'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          'dark:[color-scheme:dark]',
          className,
        )}
        onChange={(event) => {
          setInternalValue(event.target.value);
          onValueChange?.(new Date(event.target.value).toISOString());
        }}
        ref={ref}
        type="datetime-local"
        value={internalValue}
        {...props}
      />
    );
  },
);
Input.displayName = 'DatetimeInput';

export { DatetimeInput, Input };
