import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { cn } from '@/lib/utils';

const faq = [
  {
    answer: [
      'A GuardMe is a safety monitoring session where a member requests remote monitoring agents to observe their location and safety status for a configurable amount of time. Photos, videos, and notes attached to the session provide contextual data about the situation. ',
      'Your organization will be notified if your session expires to ensure someone will follow up with you. At any time, during a session or within the app, you can initiate an emergency by pressing the panic button to notify your organization.',
      'If your organization has the 911 integration, when you initiate a panic, the closest emergency services center will be notified with your contact details and location information.',
    ],
    id: 1,
    question: 'What is a GuardMe?',
  },
  {
    answer: [
      'A Check In is a quick and easy way to report your current location to your organization. This is usually to indicate to your organization where you are and that you are safe.',
      'A Check In Request allows you to request members or groups of members to check in, either by a certain time or at a pre-configured Place. This can be used for setting mustering points in emergency situations, or simply to request members to give an update on their safety.',
    ],
    id: 2,
    question: 'What is a Check In?',
  },
  {
    answer: [
      'An Incident is a report that is submitted to your organization by one of your members. See It Send It comes with a set of pre-configured Incident types and working with support we can facilitate custom incidents relevant to your organization.',
      'Incident Types can be assigned to supervisor groups to ensure the correct personnel are assigned to relevant incidents.',
    ],
    id: 3,
    question: 'What is an Incident?',
  },
  {
    answer: [
      'A Place is a geofence designated by your organization. A Place can be combined with Check In Requests to require members to Check In within specific geofence. Your Places will also be tagged in an Check In, Incident, or GuardMe if any reported location is within a geofence.',
    ],
    id: 4,
    question: 'What is a Place?',
  },
];

type NewUserFrequentlyAskedQuestionsProps = {
  readonly titleClassName?: string;
};

const NewUserFrequentlyAskedQuestions = ({
  titleClassName,
}: NewUserFrequentlyAskedQuestionsProps) => {
  return (
    <div>
      <h2
        className={cn(
          'text-lg font-semibold tracking-tight text-gray-900 md:text-4xl text-center',
          titleClassName,
        )}
      >
        Frequently asked questions
      </h2>
      <p className="mt-4 text-muted-foreground text-center">
        Can't find the answer you're looking for? Reach out to our{' '}
        <a
          className="font-medium text-indigo-600 hover:text-indigo-500"
          href="https://www.seeitsendit.com/contact"
        >
          customer support
        </a>{' '}
        team.
      </p>
      <div className="px-4">
        <Accordion
          className="w-full"
          collapsible
          type="single"
        >
          {faq.map((item) => (
            <AccordionItem
              key={item.id}
              value={item.id.toString()}
            >
              <AccordionTrigger>{item.question}</AccordionTrigger>
              <AccordionContent>{item.answer} </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export { faq, NewUserFrequentlyAskedQuestions };
