import { ErrorBoundaryFallback } from '../../Misc';
import { ProfileAccessToken } from './ProfileAccessToken';
import { buttonVariants } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { ErrorBoundary } from '@sentry/react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

export const accountNavItems = [
  {
    title: 'Profile',
    to: '/account/profile',
  },
  {
    title: 'Contact Details',
    to: '/account/contact',
  },
  {
    title: 'Memberships',
    to: '/account/memberships',
  },
  {
    title: 'Notifications',
    to: '/account/notifications',
  },
  {
    title: 'Close Account',
    to: '/account/close',
  },
];

const AccountLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-5">
      <div className="w-full col-span-full flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 md:gap-8">
        <div className="md:hidden">
          <Select
            onValueChange={(value) => navigate(value)}
            value={pathname}
          >
            <SelectTrigger className="md:hidden w-full">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {accountNavItems.map((item) => (
                <SelectItem
                  key={item.to}
                  value={item.to}
                >
                  {item.title}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="mx-auto grid w-full gap-2">
          <h1 className="text-3xl font-semibold">Account</h1>
        </div>

        <div className="mx-auto grid w-full items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
          <nav className="grid text-sm text-muted-foreground">
            {accountNavItems.map((item) => (
              <Link
                className={cn(
                  buttonVariants({ variant: 'ghost' }),
                  pathname === item.to
                    ? 'bg-muted hover:bg-muted'
                    : 'hover:bg-transparent hover:underline',
                  'justify-start',
                )}
                key={item.to}
                to={item.to}
              >
                <span>{item.title}</span>
              </Link>
            ))}
            <div className="mt-4">
              <ProfileAccessToken />
            </div>
          </nav>

          <div className="grid gap-6">
            <ErrorBoundary
              fallback={ErrorBoundaryFallback}
              key={pathname}
            >
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AccountLayout };
