import { GraphqlError } from './Error';
import { Spinner } from './Spinner';
import { type HTMLAttributes, type ReactNode } from 'react';
import { type CombinedError } from 'urql';

type Props = Pick<HTMLAttributes<HTMLDivElement>, 'children'> & {
  readonly isLoading?: boolean;
  readonly loadingError?: CombinedError;
};

const Loader = ({ children, isLoading, loadingError }: Props): ReactNode => {
  if (loadingError) {
    return <GraphqlError error={loadingError} />;
  }

  if (isLoading) {
    return (
      <div className="h-full flex items-center py-6">
        <Spinner fullscreen />
      </div>
    );
  }

  return children;
};

export { Loader };
