import { forwardRef, type HTMLAttributes, type ReactElement } from 'react';

type TooltipProps = HTMLAttributes<HTMLSpanElement> & {
  children: ReactElement;
  message: string;
};

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const { message, children } = props;

  return (
    <div
      className="relative flex items-center group"
      ref={ref}
    >
      {children}
      <div className="absolute left-3/4 flex items-center hidden ml-6 group-hover:flex">
        <div className="w-3 h-3 -mr-2 rotate-45 bg-black" />
        <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-black rounded-lg shadow-lg">
          {message}
        </span>
      </div>
    </div>
  );
});

export { Tooltip, type TooltipProps };
