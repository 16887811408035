import {
  Circle,
  ControlPosition,
  Map,
  MapBoundsHandler,
  MapControl,
  Polygon,
  useDrawingManager,
  useIsMapProviderLoaded,
} from '@/components/Map';
import { PermissionDeniedError } from '@/lib/error';
import { useAppStore } from '@/stores';
import { type MutableRefObject, useEffect, useState } from 'react';

type EditPlaceMapProps = {
  readonly currentShapeRef: MutableRefObject<
    google.maps.Circle | google.maps.Polygon | null
  >;
  readonly geoJson: {
    coordinates: number[] | number[][][];
    radius: number;
    type: 'Point' | 'Polygon';
  };
  readonly onCircleComplete: (circle: google.maps.Circle) => void;
  readonly onPolygonComplete: (polygon: google.maps.Polygon) => void;
};

const EditPlaceMap = ({
  currentShapeRef,
  geoJson,
  onCircleComplete,
  onPolygonComplete,
}: EditPlaceMapProps) => {
  const activeMembership = useAppStore((state) => state.activeMembership);

  if (!activeMembership?.isAdmin) {
    throw new PermissionDeniedError();
  }

  const drawingManager = useDrawingManager();
  const isMapLoaded = useIsMapProviderLoaded();

  const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);

  useEffect(() => {
    if (drawingManager) {
      drawingManager.setOptions({
        drawingControlOptions: {
          drawingModes: [
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
          ],
        },
      });

      // clear old listeners
      google.maps.event.clearListeners(drawingManager, 'circlecomplete');
      google.maps.event.clearListeners(drawingManager, 'polygoncomplete');

      // add new listeners
      drawingManager.addListener('circlecomplete', onCircleComplete);
      drawingManager.addListener('polygoncomplete', onPolygonComplete);
    }

    return () => {
      if (drawingManager) {
        google.maps.event.clearListeners(drawingManager, 'circlecomplete');
        google.maps.event.clearListeners(drawingManager, 'polygoncomplete');
      }
    };
  }, [drawingManager, onCircleComplete, onPolygonComplete]);

  // determine bounds of place

  useEffect(() => {
    if (isMapLoaded) {
      if (geoJson.type === 'Point' && google?.maps) {
        let circle: google.maps.Circle | null = new google.maps.Circle({
          center: {
            lat: geoJson.coordinates[1] as number,
            lng: geoJson.coordinates[0] as number,
          },
          radius: geoJson.radius,
        });

        setBounds(circle.getBounds());
        circle = null;
      }

      if (geoJson.type === 'Polygon' && google?.maps) {
        const polygonBounds = new google.maps.LatLngBounds();

        for (const outer of geoJson.coordinates as number[][][]) {
          for (const inner of outer) {
            const [lng, lat] = inner;
            polygonBounds.extend({ lat, lng });
          }
        }

        setBounds(polygonBounds);
      }
    }
  }, [geoJson, isMapLoaded]);

  return (
    <>
      <Map
        className="h-96"
        defaultCenter={{
          lat: activeMembership.defaultMapCenter.latitude,
          lng: activeMembership.defaultMapCenter.longitude,
        }}
        defaultZoom={activeMembership.defaultMapZoom}
        gestureHandling="greedy"
      >
        {geoJson.type === 'Point' && (
          <Circle
            center={{
              lat: geoJson.coordinates[1] as number,
              lng: geoJson.coordinates[0] as number,
            }}
            radius={geoJson.radius}
            ref={(element) => (currentShapeRef.current = element)}
          />
        )}

        {geoJson.type === 'Polygon' && (
          <Polygon
            paths={(geoJson.coordinates as number[][][]).map((outer) => {
              return outer.map((inner) => {
                const [lng, lat] = inner;
                return { lat, lng };
              });
            })}
            ref={(element) => (currentShapeRef.current = element)}
          />
        )}
      </Map>

      <MapControl position={ControlPosition.TOP_CENTER} />

      <MapBoundsHandler bounds={bounds ?? undefined} />
    </>
  );
};

export { EditPlaceMap };
