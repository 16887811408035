import { cn } from '@/lib/utils';
import {
  BatteryFull,
  BatteryLow,
  BatteryMedium,
  BatteryWarning,
} from 'lucide-react';

type Props = {
  readonly value?: number | null;
};

const BatteryLevel = ({ value }: Props) => {
  // eslint-disable-next-line canonical/id-match
  let Icon = BatteryWarning;
  let classes = 'bg-destructive text-destructive-foreground';
  let message = '---';

  if (value !== null && value !== undefined) {
    const level = value < 0 ? 0 : value;

    if (level >= 0 && level <= 25) {
      Icon = BatteryWarning;
      classes = 'bg-destructive text-destructive-foreground';
      message = `${level}%`;
    }

    if (level > 25 && level <= 50) {
      Icon = BatteryLow;
      classes = 'bg-warning text-warning-foreground';
      message = `${level}%`;
    }

    if (level > 50 && level <= 75) {
      Icon = BatteryMedium;
      classes = 'bg-info text-info-foreground';
      message = `${level}%`;
    }

    if (level > 75) {
      Icon = BatteryFull;
      classes = 'bg-success text-success-foreground';
      message = `${level}%`;
    }
  }

  return (
    <div
      className={cn(
        'inline-flex items-center gap-1 rounded py-1 px-2 text-xs',
        classes,
      )}
    >
      <Icon className="w-5 h-5" /> {message}
    </div>
  );
};

export { BatteryLevel };
