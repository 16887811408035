import { cn } from '@/lib/utils';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { type HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

type BreadcrumbsProps = {
  breadcrumbs: Array<{
    label: string;
    to?: string;
  }>;
  className?: string;
};

const Breadcrumbs = ({ className, breadcrumbs }: BreadcrumbsProps) => {
  return (
    <nav
      aria-label="Breadcrumb"
      className={cn('flex my-2', className)}
    >
      <ol className="inline-flex items-center space-x-1 rtl:space-x-reverse">
        {breadcrumbs.map((item, index) => (
          <li
            aria-current={index === breadcrumbs.length && 'page'}
            key={item.label}
          >
            <div className="flex items-center">
              {index > 0 && (
                <ChevronRightIcon
                  aria-hidden="true"
                  className="rtl:rotate-180 w-4 h-4 text-gray-400"
                />
              )}
              {item.to ? (
                <Link
                  className={cn(
                    index > 0 && 'ms-1',
                    'text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white',
                  )}
                  to={item.to}
                >
                  {item.label}
                </Link>
              ) : (
                <span
                  className={cn(
                    index > 0 && 'ms-1',
                    'text-sm font-medium text-gray-500 dark:text-gray-400',
                  )}
                >
                  {item.label}
                </span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

const Header = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { noBorder?: boolean }
>(({ className, noBorder, ...props }, ref) => {
  return (
    <header
      className={cn(
        'RouteHeader grid gap-2 md:gap-0 md:grid-cols-[minmax(0px,_1fr)_auto] px-4 pb-4 md:pb-0 md:px-8 pt-4',
        !noBorder && 'border-b',
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});

const Title = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ children, className, ...props }, ref) => {
  return (
    <h1
      className={cn('font-bold text-2xl flex items-center gap-4', className)}
      ref={ref}
      {...props}
    >
      {children}
    </h1>
  );
});

const Subtitle = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ children, className, ...props }, ref) => {
  return (
    <p
      className={cn('font-medium text-md text-muted-foreground', className)}
      ref={ref}
      {...props}
    >
      {children}
    </p>
  );
});

const Description = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ children, className, ...props }, ref) => {
  return (
    <p
      className={cn('font-medium text-md', className)}
      ref={ref}
      {...props}
    >
      {children}
    </p>
  );
});

const Content = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { noBackground?: boolean }
>(({ className, noBackground, ...props }, ref) => {
  return (
    <div
      className={cn(
        'px-4 md:px-8 py-5 grid grid-cols-[minmax(0px,_1fr)_auto] grid-rows-[1fr] flex-1',
        !noBackground && 'bg-white',
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});

export { Breadcrumbs, Content, Description, Header, Subtitle, Title };
