import { Skeleton } from '../ui/skeleton';
import { config } from '@/config';
import { useTheme } from '@/providers/ThemeProvider';
import {
  APIProvider as UpstreamAPIProvider,
  type APIProviderProps as UpstreamAPIProviderProps,
  Map as UpstreamMap,
  type MapProps as UpstreamMapProps,
  useApiIsLoaded,
  useMap,
} from '@vis.gl/react-google-maps';
import { useEffect } from 'react';

type MapProviderProps = Omit<
  React.PropsWithChildren<UpstreamAPIProviderProps>,
  'apiKey'
>;

const MapProvider = (props: MapProviderProps) => {
  return (
    <UpstreamAPIProvider
      apiKey={config.GOOGLE_MAPS_KEY}
      {...props}
    >
      {props.children}
    </UpstreamAPIProvider>
  );
};

type MapProps = React.PropsWithChildren<UpstreamMapProps>;

const Map = ({
  children,
  className,
  fullscreenControl = false,
  mapId,
  mapTypeControl = false,
  streetViewControl = false,
  ...props
}: MapProps) => {
  const { theme } = useTheme();
  const apiIsLoaded = useApiIsLoaded();

  let computedTheme = theme;
  if (theme === 'system') {
    computedTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
  }

  if (!apiIsLoaded) {
    return <Skeleton className={className} />;
  }

  return (
    <UpstreamMap
      className={className}
      fullscreenControl={fullscreenControl}
      isFractionalZoomEnabled={false}
      mapId={
        mapId || computedTheme === 'light'
          ? config.GOOGLE_MAPS_MAP_ID_LIGHTMODE
          : config.GOOGLE_MAPS_MAP_ID_DARKMODE
      }
      mapTypeControl={mapTypeControl}
      streetViewControl={streetViewControl}
      {...props}
    >
      {children}
    </UpstreamMap>
  );
};

Map.displayName = 'Map';

type MapBoundsHandlerProps = {
  readonly bounds: google.maps.LatLngBounds | undefined;
};

const MapBoundsHandler = ({ bounds }: MapBoundsHandlerProps) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !bounds) return;

    if (bounds) {
      map.fitBounds(bounds);
    }
  }, [bounds, map]);

  return null;
};

type MapZoomToProps = {
  readonly position: google.maps.LatLng | google.maps.LatLngLiteral | undefined;
  readonly zoom?: number;
};

const MapZoomTo = ({ position, zoom = 14 }: MapZoomToProps) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !position) return;

    if (position) {
      map.setCenter(position);
      map.setZoom(zoom);
    }
  }, [map, position, zoom]);

  return null;
};

export { Map, MapBoundsHandler, type MapProps, MapProvider, MapZoomTo };
