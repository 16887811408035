import { Button } from './ui/button';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { type Member } from '@/gql/graphql';
import { CheckCircleIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { forwardRef } from 'react';

type MemberSelectProps = {
  readonly allowEmpty?: boolean;
  readonly disabled?: boolean;
  readonly includeDisabled?: boolean;
  readonly members?: Array<
    Pick<Member, 'avatarUrl' | 'displayName' | 'fullName' | 'id' | 'isEnabled'>
  > | null;
  readonly onChange: (newValue: string) => void | Promise<void>;
  readonly readonly?: boolean;
  readonly value: string;
};

const MemberSelect = forwardRef<HTMLButtonElement, MemberSelectProps>(
  (props, ref) => {
    const {
      allowEmpty = false,
      disabled = false,
      includeDisabled = false,
      members,
      onChange,
      readonly = false,
      value,
    } = props;

    const member = members?.find((item) => item.id === value);

    if (readonly) {
      return (
        <div className="inline-flex items-center justify-center rounded-md text-sm font-medium h-9 rounded-md px-3 border border-input bg-background">
          {member ? (
            <span className="flex items-center">
              <Avatar className="h-6 w-6 mr-2 shrink-0">
                <AvatarImage
                  alt={
                    member.displayName ?? member.fullName ?? 'Deleted Member'
                  }
                  src={member.avatarUrl ?? undefined}
                />
                <AvatarFallback>
                  {(
                    member.displayName ??
                    member.fullName ??
                    'Deleted Member'
                  ).slice(0, 2)}
                </AvatarFallback>
              </Avatar>
              {member.displayName ?? member.fullName}
            </span>
          ) : (
            <>Select member...</>
          )}
        </div>
      );
    }

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            className="justify-between group"
            disabled={disabled}
            ref={ref}
            size="sm"
            variant="outline"
          >
            {member ? (
              <span className="flex items-center">
                <Avatar className="h-6 w-6 mr-2 shrink-0">
                  <AvatarImage
                    alt={
                      member.displayName ?? member.fullName ?? 'Deleted Member'
                    }
                    src={member.avatarUrl ?? undefined}
                  />
                  <AvatarFallback>
                    {(
                      member.displayName ??
                      member.fullName ??
                      'Deleted Member'
                    ).slice(0, 2)}
                  </AvatarFallback>
                </Avatar>
                {member.displayName ?? member.fullName}
              </span>
            ) : (
              <>Select member...</>
            )}
            <ChevronUpDownIcon className="ml-4 h-4 w-4 shrink-0 opacity-25 group-hover:opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          className="p-0 PopoverContent"
          side="bottom"
        >
          <Command>
            <CommandInput placeholder="Filter members..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {allowEmpty && (
                  <CommandItem
                    className={member ? '' : 'bg-accent/50'}
                    onSelect={async () => await onChange('')}
                    value=""
                  >
                    <span>-- None --</span>
                    {!member && (
                      <CheckCircleIcon className="ml-auto h-6 w-6 shrink-0 fill-primary" />
                    )}
                  </CommandItem>
                )}
                {members
                  ?.filter((item) => includeDisabled || item.isEnabled)
                  .map((item) => (
                    <CommandItem
                      className={item.id === member?.id ? 'bg-accent/50' : ''}
                      key={item.id}
                      onSelect={async () => {
                        if (allowEmpty && item.id === member?.id) {
                          await onChange('');
                        } else {
                          await onChange(item.id);
                        }
                      }}
                      value={`${item.id}`}
                    >
                      <Avatar className="h-6 w-6 mr-2 shrink-0">
                        <AvatarImage
                          alt={
                            item.displayName ??
                            item.fullName ??
                            'Deleted Member'
                          }
                          src={item.avatarUrl ?? undefined}
                        />
                        <AvatarFallback>
                          {(
                            item.displayName ??
                            item.fullName ??
                            'Deleted Member'
                          ).slice(0, 2)}
                        </AvatarFallback>
                      </Avatar>
                      <span>{item.displayName ?? item.fullName}</span>
                      {item.id === member?.id && (
                        <CheckCircleIcon className="ml-auto h-6 w-6 shrink-0 fill-primary" />
                      )}
                    </CommandItem>
                  ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

export { MemberSelect, type MemberSelectProps };
