import { MutationError } from '../../Misc';
import { PlaceCreateMap } from '../components/PlaceCreateMap';
import { MapProvider } from '@/components/Map';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import {
  geometryFromShape,
  OrganizationNotFoundError,
  PermissionDeniedError,
} from '@/utils';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Check, Loader } from 'lucide-react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { type Infer, nonempty, object, string } from 'superstruct';
import { useMutation } from 'urql';

const createPlaceQuery = graphql(`
  mutation createPlaceQuery(
    $organizationId: String!
    $name: String!
    $description: String!
    $geojson: JSON!
    $radius: Int!
  ) {
    createPlace(
      input: {
        organizationId: $organizationId
        name: $name
        description: $description
        geojson: $geojson
        radius: $radius
      }
    ) {
      place {
        id
        name
        description
        radius
        spatialData {
          geojson
        }
      }
    }
  }
`);

const schema = object({
  description: string(),
  geojson: string(),
  name: nonempty(string()),
});

const PlaceCreate = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  if (!isAdmin) {
    throw new PermissionDeniedError();
  }

  const navigate = useNavigate();

  const shapeRef = useRef<google.maps.Circle | google.maps.Polygon | null>(
    null,
  );

  const [{ error, fetching }, createPlace] = useMutation(createPlaceQuery);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      description: '',
      geojson: '',
      name: '',
    },
    resolver: superstructResolver(schema),
  });

  const onSubmit = async (values: Infer<typeof schema>) => {
    if (!shapeRef.current) {
      form.setError('geojson', {
        message: 'Missing spatial data',
        type: 'custom',
      });
      return;
    }

    const shapeType = shapeRef.current instanceof google.maps.Circle;

    const geometry = geometryFromShape(
      shapeType ? 'circle' : 'polygon',
      shapeRef.current,
    );

    const response = await createPlace({
      description: values.description,
      geojson: {
        coordinates: geometry.coordinates,
        type: geometry.type,
      },
      name: values.name,
      organizationId,
      radius: geometry.radius,
    });

    if (!response.error) {
      form.reset();
      navigate('/settings/places');
    }
  };

  return (
    <Form {...form}>
      <form
        className="space-y-6"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Card>
          <CardHeader>
            <CardTitle>New Place</CardTitle>
            <CardDescription>
              Places allow your organization to geofence and name a particular
              area. These can be configured to identify and tag key locations
              for a Check In, Incident, or GuardMe.
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormItem>
              <FormLabel>Geofence</FormLabel>
              <MapProvider>
                <PlaceCreateMap
                  onCircleComplete={(circle) => {
                    shapeRef.current?.setMap(null);
                    shapeRef.current = circle;
                  }}
                  onPolygonComplete={(polygon) => {
                    shapeRef.current?.setMap(null);
                    shapeRef.current = polygon;
                  }}
                />
              </MapProvider>
            </FormItem>

            <MutationError error={error} />
          </CardContent>
          <CardFooter>
            <Button
              disabled={fetching}
              type="submit"
            >
              {fetching ? (
                <Loader className="h-6 w-6 animate-spin mr-2" />
              ) : (
                <Check className="h-6 w-6 mr-2" />
              )}
              Save
            </Button>
            <Button
              onClick={() => {
                navigate('/settings/places');
              }}
              variant="ghost"
            >
              Cancel
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

export { PlaceCreate };
