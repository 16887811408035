import { Button } from './button';
import { Skeleton } from './skeleton';
import { TableCell, TableRow } from './table';
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination';
import { getPaginationRange } from '@/lib/pagination';
import { cn } from '@/lib/utils';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import * as React from 'react';

const TableWrapper = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn(
      'w-full relative shadow-md sm:rounded-lg overflow-hidden border',
      className,
    )}
    ref={ref}
    {...props}
  />
));
TableWrapper.displayName = 'TableWrapper';

const TableControls = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn(
      'flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-3',
      className,
    )}
    ref={ref}
    {...props}
  />
));
TableControls.displayName = 'TableControls';

const TableControlSection = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn(
      'w-full md:w-auto flex items-center space-x-2 first:flex-1',
      className,
    )}
    ref={ref}
    {...props}
  />
));
TableControlSection.displayName = 'TableControlSection';

type TablePaginationProps = React.HTMLAttributes<HTMLDivElement> & {
  readonly currentPage: number;
  readonly getSearchParameters: (page: number) => string;
  readonly pageSize: number;
  readonly size?: 'sm' | 'md';
  readonly totalCount?: number;
};

const TablePagnination = React.forwardRef<
  HTMLTableElement,
  TablePaginationProps
>(
  (
    {
      className,
      currentPage,
      getSearchParameters,
      pageSize,
      size = 'md',
      totalCount = 0,
      ...props
    },
    ref,
  ) => {
    const pagninationRange = getPaginationRange({
      currentPage,
      pageSize,
      totalCount,
    });

    if (totalCount === 0) {
      return null;
    }

    return (
      <div
        className={cn(
          'w-full flex flex-col items-start justify-between space-y-3 md:flex-row md:items-center md:space-y-0 text-sm',
          size === 'sm' && 'text-xs',
          className,
        )}
        ref={ref}
        {...props}
      >
        <div className="mx-auto md:mx-0 text-sm text-muted-foreground">
          Showing
          <span className="mx-1 font-medium text-foreground">
            {(currentPage - 1) * pageSize + 1}-
            {Math.min((currentPage - 1) * pageSize + pageSize, totalCount)}
          </span>
          of
          <span className="ml-1 font-medium text-foreground">{totalCount}</span>
        </div>
        {pagninationRange.length > 1 && (
          <Pagination>
            <PaginationContent>
              {currentPage - 1 > 0 && (
                <PaginationItem>
                  <PaginationPrevious
                    to={{
                      search: getSearchParameters(currentPage - 1),
                    }}
                  />
                </PaginationItem>
              )}
              {pagninationRange.map((item) => (
                <PaginationItem
                  id={item.toString()}
                  key={item}
                >
                  {item === -1 || item === -2 ? (
                    <PaginationEllipsis />
                  ) : (
                    <PaginationLink
                      className={size === 'sm' ? 'text-xs' : undefined}
                      isActive={item === currentPage}
                      to={{ search: getSearchParameters(item) }}
                    >
                      {item}
                    </PaginationLink>
                  )}
                </PaginationItem>
              ))}
              {}
              {pagninationRange[pagninationRange.length - 1] > currentPage && (
                <PaginationItem>
                  <PaginationNext
                    className={size === 'sm' ? 'text-xs' : undefined}
                    to={{
                      search: getSearchParameters(currentPage + 1),
                    }}
                  />
                </PaginationItem>
              )}
            </PaginationContent>
          </Pagination>
        )}
      </div>
    );
  },
);
TablePagnination.displayName = 'TablePagnination';

type ControlledTablePaginationProps = React.HTMLAttributes<HTMLDivElement> & {
  readonly currentPage: number;
  readonly pageSize: number;
  readonly setPage: (page: number) => void;
  readonly size?: 'sm' | 'md';
  readonly totalCount?: number;
};

const ControlledTablePagnination = React.forwardRef<
  HTMLTableElement,
  ControlledTablePaginationProps
>(
  (
    {
      className,
      currentPage,
      pageSize,
      setPage,
      size = 'md',
      totalCount = 0,
      ...props
    },
    ref,
  ) => {
    const pagninationRange = getPaginationRange({
      currentPage,
      pageSize,
      totalCount,
    });

    if (totalCount === 0) {
      return null;
    }

    return (
      <div
        className={cn(
          'w-full flex flex-col items-start justify-between space-y-3 md:flex-row md:items-center md:space-y-0 text-sm',
          size === 'sm' && 'text-xs',
          className,
        )}
        ref={ref}
        {...props}
      >
        <div className="mx-auto md:mx-0 text-sm text-muted-foreground">
          Showing
          <span className="mx-1 font-medium text-foreground">
            {(currentPage - 1) * pageSize + 1}-
            {Math.min((currentPage - 1) * pageSize + pageSize, totalCount)}
          </span>
          of
          <span className="ml-1 font-medium text-foreground">{totalCount}</span>
        </div>
        {pagninationRange.length > 1 && (
          <Pagination>
            <PaginationContent>
              {currentPage - 1 > 0 && (
                <PaginationItem>
                  <Button
                    aria-label="Go to previous page"
                    className={cn(
                      'gap-1 pl-2.5',
                      size === 'sm' ? 'text-xs' : undefined,
                      className,
                    )}
                    onClick={() => {
                      setPage(currentPage - 1);
                    }}
                    size="default"
                    variant="ghost"
                  >
                    <ChevronLeft className="h-4 w-4" />
                    <span>Previous</span>
                  </Button>
                </PaginationItem>
              )}
              {pagninationRange.map((item) => (
                <PaginationItem
                  id={item.toString()}
                  key={item}
                >
                  {item === -1 || item === -2 ? (
                    <PaginationEllipsis />
                  ) : (
                    <Button
                      aria-current={item === currentPage ? 'page' : undefined}
                      className={size === 'sm' ? 'text-xs' : undefined}
                      onClick={() => {
                        setPage(item);
                      }}
                      size="icon"
                      variant={item === currentPage ? 'outline' : 'ghost'}
                    >
                      {item}
                    </Button>
                  )}
                </PaginationItem>
              ))}
              {}
              {pagninationRange[pagninationRange.length - 1] > currentPage && (
                <PaginationItem>
                  <Button
                    aria-label="Go to next page"
                    className={cn(
                      'gap-1 pl-2.5',
                      size === 'sm' ? 'text-xs' : undefined,
                      className,
                    )}
                    onClick={() => {
                      setPage(currentPage + 1);
                    }}
                    size="default"
                    variant="ghost"
                  >
                    <span>Next</span>
                    <ChevronRight className="h-4 w-4" />
                  </Button>
                </PaginationItem>
              )}
            </PaginationContent>
          </Pagination>
        )}
      </div>
    );
  },
);
TablePagnination.displayName = 'TablePagnination';

const TableRowSkeleton = ({
  cols,
  rows,
}: {
  readonly cols: number;
  readonly rows: number;
}) => {
  const colsArray = [...Array.from({ length: cols }).keys()];
  const rowsArray = [...Array.from({ length: rows }).keys()];

  return (
    <>
      {rowsArray.map((row) => (
        <TableRow
          className="hover:bg-inherit"
          key={row}
        >
          {colsArray.map((col) => (
            <TableCell
              className="text-center"
              key={`${row}-${col}`}
            >
              <Skeleton className="h-6 w-full" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export {
  ControlledTablePagnination,
  TableControls,
  TableControlSection,
  TablePagnination,
  TableRowSkeleton,
  TableWrapper,
};
