import {
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@/components/ui/dropdown-menu';
import { graphql } from '@/gql';
import { IncidentPriorityType } from '@/gql/graphql';
import { useCallback } from 'react';
import { useMutation } from 'urql';

const IncidentPriorityMenuRadioGroupGql = graphql(`
  mutation IncidentPriorityMenuRadioGroupGql(
    $incidentId: String!
    $priority: IncidentPriorityType!
  ) {
    updateIncidentPriority(
      input: { incidentId: $incidentId, priority: $priority }
    ) {
      incident {
        id
        priority
        incidentUpdates {
          nodes {
            id
            type
            message
            createdAt
            isSystemMessage
            performedByMember {
              id
              fullName
              displayName
              avatarUrl
            }
            attachments {
              nodes {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`);

type IncidentPriorityMenuRadioGroupProps = {
  incidentId: string;
  priority: IncidentPriorityType;
};

const IncidentPriorityMenuRadioGroup = ({
  incidentId,
  priority,
}: IncidentPriorityMenuRadioGroupProps) => {
  const [, updatePriority] = useMutation(IncidentPriorityMenuRadioGroupGql);

  const onPriorityChange = useCallback(
    (newValue: string) => {
      if (priority !== newValue) {
        updatePriority({
          incidentId,
          priority: newValue as IncidentPriorityType,
        });
      }
    },
    [updatePriority, incidentId, priority],
  );

  return (
    <DropdownMenuRadioGroup
      onValueChange={onPriorityChange}
      value={priority}
    >
      <DropdownMenuRadioItem value={IncidentPriorityType.Urgent}>
        Urgent
      </DropdownMenuRadioItem>
      <DropdownMenuRadioItem value={IncidentPriorityType.High}>
        High
      </DropdownMenuRadioItem>
      <DropdownMenuRadioItem value={IncidentPriorityType.Medium}>
        Medium
      </DropdownMenuRadioItem>
      <DropdownMenuRadioItem value={IncidentPriorityType.Low}>
        Low
      </DropdownMenuRadioItem>
    </DropdownMenuRadioGroup>
  );
};

export { IncidentPriorityMenuRadioGroup };
