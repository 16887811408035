import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { config } from '@/config';
import { logger } from '@/utils';
import { ArrowUpFromLine } from 'lucide-react';
import { useEffect, useState } from 'react';

type VersionCheckProps = {
  readonly isCollapsed: boolean;
};

const VersionCheck = ({ isCollapsed }: VersionCheckProps) => {
  const [newVersion, setNewVersion] = useState<string | null>(null);

  // setup interval to run version check every 5 minutes (1000 * 60 * 5)
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const checkVersion = async () => {
      if (config.VERSION_UPDATE_CHECK) {
        await fetch(`${config.WEB_URL}/version.json`)
          .then(async (response) => {
            const { version } = (await response.json()) as { version: string };
            return version;
          })
          .then(
            (result) => {
              // if the current version doesnt match what is available online
              // set the new version number and set the software as out of date
              if (result === config.VERSION) {
                timeout = setTimeout(() => checkVersion(), 300_000);
              } else {
                setNewVersion(result);
              }
            },
            (error) => logger.error(`[VersionUpdateCheck] ${error}`),
          );
      }

      return false;
    };

    checkVersion();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [setNewVersion]);

  if (!newVersion) {
    return null;
  }

  return isCollapsed ? (
    <Tooltip delayDuration={0}>
      <TooltipTrigger asChild>
        <Button
          className="h-10 w-10 text-muted/75 hover:text-white hover:bg-white/20 animate-bounce hover:animate-none"
          onClick={() => {
            window.location.reload();
          }}
          size="icon"
          variant="ghost"
        >
          <ArrowUpFromLine className="h-6 w-6" />
          <span className="sr-only">New Version</span>
        </Button>
      </TooltipTrigger>
      <TooltipContent
        className="flex items-center gap-4 bg-white"
        side="right"
      >
        New Version Available
        <span className="ml-auto text-muted-foreground">{newVersion}</span>
      </TooltipContent>
    </Tooltip>
  ) : (
    <Button
      className="h-10 w-10 hover:text-primary-foreground hover:bg-primary justify-start w-full"
      onClick={() => {
        window.location.reload();
      }}
      variant="outline"
    >
      <ArrowUpFromLine className="mr-4 h-6 w-6" />
      New Version!
    </Button>
  );
};

export { VersionCheck };
