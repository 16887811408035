import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { Check, Loader } from 'lucide-react';
import { type ReactNode, useState } from 'react';
import { useMutation } from 'urql';

const GuardMeConcludeDocumentQuery = graphql(`
  mutation GuardMeConclude($guardmeId: String!) {
    endGuardme(input: { guardmeId: $guardmeId }) {
      guardme {
        id
        completedAt
        endsAt
        isActive
        isExpired
        isSafe
        updatedAt
        completedAt
      }
    }
  }
`);

type GuardMeConcludeDialogProps = {
  children?: ReactNode;
  guardmeId: string;
};

const GuardMeConcludeDialog = ({
  children,
  guardmeId,
}: GuardMeConcludeDialogProps) => {
  const [open, setOpen] = useState(false);

  const [{ error, fetching }, conclude] = useMutation(
    GuardMeConcludeDocumentQuery,
  );

  const handleCancel = () => {
    setOpen(false);
  };

  const handleClick = async () => {
    const response = await conclude({
      guardmeId,
    });

    if (!response.error) {
      setOpen(false);
    }
  };

  return (
    <AlertDialog
      onOpenChange={setOpen}
      open={open}
    >
      {children && <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Conclude GuardMe Session?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. You should only conclude this GuardMe
            session with consent from the member being monitored.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <MutationError error={error} />

        <AlertDialogFooter>
          <AlertDialogCancel
            disabled={fetching}
            onClick={handleCancel}
          >
            Cancel
          </AlertDialogCancel>
          <Button
            disabled={fetching}
            onClick={handleClick}
            variant="destructive"
          >
            {fetching ? (
              <Loader className="h-6 w-6 animate-spin mr-2" />
            ) : (
              <Check className="h-6 w-6 mr-2" />
            )}
            Conclude Session
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { GuardMeConcludeDialog, type GuardMeConcludeDialogProps };
