import { Datetime } from '@/components/Datetime';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  type AccountNotificationsPageGqlQuery,
  UserNotificationType,
} from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores';
import { fmtPhoneNumber, logger } from '@/utils';
import {
  Fingerprint,
  Info,
  MailOpen,
  Megaphone,
  Minus,
  ShieldCheck,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

type GuardMeMetadata = {
  guardmeId: string;
  guardmeTimestamp: Date;
  member: {
    avatarUrl: string | null;
    id: string;
    name: string;
  } | null;
};

const normalizeUserNotification = (
  id: string,
  type: string,
  metadata: Record<string, string>,
  createdAt: string,
) => {
  switch (type) {
    case UserNotificationType.GuardmePanic: {
      const meta = metadata as unknown as GuardMeMetadata;
      return {
        createdAt,
        icon: ShieldCheck,
        id,
        member: meta.member,
        message: meta.member
          ? `${meta.member.name} requested assistance during a GuardMe session.`
          : 'A GuardMe session has transitioned to panic.',
        to: `/guardme/${metadata.guardmeId}`,
        type: 'GuardMe',
      };
    }

    case UserNotificationType.GuardmeExpired: {
      const meta = metadata as unknown as GuardMeMetadata;
      return {
        createdAt,
        icon: ShieldCheck,
        id,
        member: meta.member,
        message: meta.member
          ? `${meta.member.name}'s GuardMe Session has expired.`
          : 'A GuardMe session has expired.',
        to: `/guardme/${metadata.guardmeId}`,
        type: 'GuardMe',
      };
    }

    case UserNotificationType.GuardmeConcluded: {
      const meta = metadata as unknown as GuardMeMetadata;
      return {
        createdAt,
        icon: ShieldCheck,
        id,
        member: meta.member,
        message: meta.member
          ? `${meta.member.name} has concluded a GuardMe Session.`
          : 'A GuardMe session has concluded.',
        to: `/guardme/${metadata.guardmeId}`,
        type: 'GuardMe',
      };
    }

    case UserNotificationType.GuardmeStarted: {
      const meta = metadata as unknown as GuardMeMetadata;
      return {
        createdAt,
        icon: ShieldCheck,
        id,
        member: meta.member,
        message: meta.member
          ? `${meta.member.name} has started a GuardMe Session.`
          : 'A GuardMe session has started.',
        to: `/guardme/${metadata.guardmeId}`,
        type: 'GuardMe',
      };
    }

    case UserNotificationType.UserWelcome: {
      return {
        createdAt,
        icon: Megaphone,
        id,
        message: (
          <>
            Welcome to See It Send It.
            <br />
            Powered By Cloudscann: See It Send It provides a wealth of
            notification, verification, dispatch, and response tools that
            improve security, enhance communications, and provide a unique level
            of situational awareness.
          </>
        ),
        to: null,
        type: 'VisualC3',
      };
    }

    case UserNotificationType.UserAddedEmailAddress: {
      return {
        createdAt,
        icon: Fingerprint,
        id,
        message: `Added new email address '${metadata.emailAddress}' to your account.`,
        to: null,
        type: 'VisualC3',
      };
    }

    case UserNotificationType.UserAddedPhoneNumber: {
      return {
        createdAt,
        icon: Fingerprint,
        id,
        message: `Added new phone number '${fmtPhoneNumber(
          metadata.phoneNumber,
        )}' to your account.`,
        to: null,
        type: 'VisualC3',
      };
    }

    case UserNotificationType.UserRemovedEmailAddress: {
      return {
        createdAt,
        icon: Fingerprint,
        id,
        message: `Removed email address '${metadata.emailAddress}' from your account.`,
        to: null,
        type: 'VisualC3',
      };
    }

    case UserNotificationType.UserRemovedPhoneNumber: {
      return {
        createdAt,
        icon: Fingerprint,
        id,
        message: `Removed phone number '${fmtPhoneNumber(
          metadata.phoneNumber,
        )}' from your account.`,
        to: null,
        type: 'VisualC3',
      };
    }

    case UserNotificationType.MembershipRequest: {
      return {
        createdAt,
        icon: Info,
        id,
        message: 'A user is requesting membership to your organization.',
        to: '/members',
        type: 'VisualC3',
      };
    }

    default: {
      logger.log('Unhandled notification type', {
        metadata,
      });

      return {
        createdAt,
        id,
        message: 'Expired notification.',
        to: null,
        type,
      };
    }
  }
};

type UserNotificationNode = NonNullable<
  AccountNotificationsPageGqlQuery['currentUser']
>['userNotifications']['nodes'][0];

type UserNotificationItemProps = UserNotificationNode & {
  readonly acknowledgeNotification: (id: string) => Promise<void>;
  readonly deleteNotification: (id: string) => Promise<void>;
};

const UserNotificationItem = ({
  acknowledgeNotification,
  createdAt,
  deleteNotification,
  id,
  isRead,
  metadata,
  organization,
  type,
}: UserNotificationItemProps) => {
  const navigate = useNavigate();
  const notification = normalizeUserNotification(id, type, metadata, createdAt);

  return (
    <li>
      <div
        className={cn(
          'flex items-center relative group',
          'md:hover:bg-muted first:hover:rounded-t-lg last:hover:rounded-b-lg',
          isRead ? 'font-normal' : 'font-semibold',
        )}
      >
        <button
          className="p-2 w-full h-full flex flex-col-reverse md:flex-row md:items-center relative"
          onClick={async (event) => {
            event.preventDefault();
            await acknowledgeNotification(id);
            if (notification.to) {
              if (organization?.id) {
                useAppStore.setState((state) => ({
                  activeMembership:
                    state.memberships.find(
                      (org) => org.id === organization.id,
                    ) ?? state.activeMembership,
                }));
              }

              navigate(notification.to);
            }
          }}
          type="button"
        >
          <span className="flex items-start">
            {notification.member ? (
              <Avatar className="h-8 w-8 mr-2">
                <AvatarImage
                  alt={notification.member?.name}
                  src={notification.member?.avatarUrl ?? undefined}
                />
                <AvatarFallback>
                  {notification.member?.name.slice(0, 2)}
                </AvatarFallback>
              </Avatar>
            ) : (
              notification.icon && (
                <notification.icon className="text-muted-foreground h-8 w-8 mr-2" />
              )
            )}
            <div className="text-left flex flex-col-reverse md:flex-col">
              <div className="text-base">{notification.message}</div>
              <div className="text-xs flex items-center text-muted-foreground/80">
                <span>{notification.type}</span>
                <span className="px-1">&#8226;</span>
                <span>{organization?.name ?? notification.id}</span>{' '}
              </div>
            </div>
          </span>
          <span className="text-sm mb-1 md:mb-0 ml-auto flex items-center justify-end">
            <span className="flex flex-row-reverse md:flex-row items-center relative gap-1">
              <span className="pr-2">
                <Datetime
                  datetime={notification.createdAt}
                  format="MMM dd"
                />
              </span>
              <span className="w-3">
                {!isRead && (
                  <svg
                    className="h-1.5 w-1.5 fill-primary"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="50"
                      cy="50"
                      r="50"
                    />
                  </svg>
                )}
              </span>
            </span>
          </span>
        </button>

        <span className="hidden absolute right-0 top-0 bottom-0 pl-6 pr-2 items-center justify-end gap-2 group-hover:flex bg-muted">
          <Button
            className="border"
            onClick={async () => await acknowledgeNotification(id)}
            size="sm"
            variant="outline"
          >
            <MailOpen className="h-4 w-4" />
            <span className="sr-only">Mark as Read</span>
          </Button>
          <Button
            onClick={async () => await deleteNotification(id)}
            size="sm"
            variant="destructive"
          >
            <Minus className="h-4 w-4 mr-2" />
            Delete
          </Button>
        </span>
      </div>
    </li>
  );
};

export { UserNotificationItem };
