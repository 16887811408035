import { NewGroupDialog } from './NewGroupDialog';
import { NewInivtationDialog } from './NewInivtationDialog';
import { List, ListItem } from '@/components/List';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { TableRowSkeleton } from '@/components/ui/TableWrapper';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { MemberRole } from '@/gql/graphql';
import { formatMemberRole } from '@/lib/formatGql';
import { cn, timeIsRecent } from '@/lib/utils';
import { Check, X } from 'lucide-react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberListGql = graphql(`
  query MemberListGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      memberMetadataFields
      groups {
        totalCount
        nodes {
          id
          name
          members {
            totalCount
          }
        }
      }
      invitations {
        totalCount
        nodes {
          id
          key
          organizationRole
        }
      }
      membershipRequests {
        totalCount
        nodes {
          id
          fullName
          avatarUrl
          phoneNumber
          emailAddress
          membershipCode {
            id
            organizationRole
          }
        }
      }
      members {
        totalCount
        nodes {
          id
          createdAt
          fullName
          displayName
          avatarUrl
          organizationRole
          lastActiveAt
          isEnabled
          emailAddress
          phoneNumber
        }
      }
    }
  }
`);

type MemberListProps = {
  readonly organizationId: string;
};

const MemberList = ({ organizationId }: MemberListProps) => {
  const [search, setSearch] = useState('');
  const [hideDisabled, setHideDisabled] = useState(false);

  const navigate = useNavigate();

  const [{ data, error, fetching }] = useQuery({
    query: MemberListGql,
    requestPolicy: 'cache-and-network',
    variables: {
      organizationId,
    },
  });

  const memberData =
    data?.organization?.members.nodes
      .map((member) => ({
        avatarUrl: member.avatarUrl ?? '',
        createdAt: member.createdAt,
        displayName: member.displayName ?? '',
        emailAddress: member.emailAddress ?? '',
        fullname: member.fullName ?? 'User deleted account',
        id: member.id,
        isActive: timeIsRecent(member.lastActiveAt),
        isEnabled: member.isEnabled,
        phoneNumber: member.phoneNumber ?? '',
        role: member.organizationRole,
        search:
          member.displayName?.toLowerCase() +
          member.fullName?.toLowerCase() +
          member.emailAddress?.toLowerCase() +
          member.phoneNumber?.toLowerCase(),
      }))
      .filter((item) => !hideDisabled || item.isEnabled)
      .filter((item) => item.search.includes(search.toLowerCase()))
      .sort((a, b) => (a.fullname < b.fullname ? -1 : 1)) ?? [];

  const handleRowClick = (entityId: string) => {
    navigate(`/members/${entityId}`);
  };

  return (
    <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0 lg:gap-8 lg:grid-cols-5">
      <div className="grid auto-rows-max items-start gap-4 lg:gap-8 lg:col-span-3">
        <Card className="sm:col-span-2">
          <CardHeader className="rounded-t-lg flex flex-row items-center justify-between">
            <CardTitle>Members ({memberData.length})</CardTitle>
            <CardDescription>
              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={hideDisabled}
                  id="hideDisabled"
                  onCheckedChange={() => {
                    setHideDisabled((previous) => !previous);
                  }}
                />
                <label
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  htmlFor="hideDisabled"
                >
                  Hide disabled members
                </label>
              </div>
            </CardDescription>
          </CardHeader>
          <CardContent className="py-4 grid gap-4">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between md:space-x-4">
              <Input
                className="h-8"
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Filter Member..."
                value={search}
              />
            </div>

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead className="hidden sm:table-cell">Status</TableHead>
                  <TableHead className="hidden md:table-cell">Role</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {error && <MutationError error={error} />}
                {fetching && !data && (
                  <TableRowSkeleton
                    cols={3}
                    rows={6}
                  />
                )}
                {memberData.map((item) => (
                  <TableRow
                    key={item.id}
                    onClick={() => handleRowClick(item.id)}
                  >
                    <TableCell>
                      <div className="flex items-center space-x-4">
                        <div className="relative">
                          <Avatar className="h-8 w-8">
                            <AvatarImage src={item.avatarUrl} />
                            <AvatarFallback>
                              {item.fullname.slice(0, 2)}
                            </AvatarFallback>
                          </Avatar>
                          {item.isActive && (
                            <span className="flex absolute h-2.5 w-2.5 top-0 right-0 -mt-0.5 -mr-0.5">
                              <span className="relative inline-flex rounded-full h-2.5 w-2.5 bg-green-500" />
                            </span>
                          )}
                        </div>
                        <div>
                          <Link
                            className="hover:underline"
                            to={`/members/${item.id}`}
                          >
                            {item.displayName || item.fullname}
                          </Link>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {item.isEnabled ? (
                        <Badge variant="success">
                          <Check className="h-4 w-4" />
                          Enabled
                        </Badge>
                      ) : (
                        <Badge variant="destructive">
                          <X className="h-4 w-4" />
                          Disabled
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell>
                      <Badge variant="secondary">
                        {formatMemberRole(item.role)}
                      </Badge>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
      <div className="lg:col-span-2 grid gap-4 lg:gap-8">
        <Card>
          <CardHeader className="bg-muted rounded-t-lg">
            <CardTitle className="text-xl flex items-center justify-between">
              <div>Pending Invitations</div>
            </CardTitle>
            <CardDescription>
              Invited members will be prompted to accept the invitation once
              they log in.
            </CardDescription>
          </CardHeader>
          <List>
            {fetching && (
              <>
                <ListItem className="relative px-6 border-t">
                  <Skeleton className="h-8 w-full" />
                </ListItem>
                <ListItem className="relative px-6 border-t">
                  <Skeleton className="h-8 w-full" />
                </ListItem>
              </>
            )}
            {data?.organization?.invitations.nodes.map((item) => (
              <ListItem
                className="relative px-6 border-t"
                key={item.id}
              >
                <span>
                  <Link
                    className="hover:underline"
                    to={`/members/invitations/${item.id}`}
                  >
                    <span className="absolute inset-0" />
                    {item.key}
                  </Link>
                </span>
                <span className="flex items-center">
                  {formatMemberRole(item.organizationRole)}
                </span>
              </ListItem>
            ))}
          </List>
          <CardFooter className="justify-end py-3 border-t">
            <NewInivtationDialog
              groups={data?.organization?.groups.nodes}
              memberMetadataFields={data?.organization?.memberMetadataFields}
              organizationId={organizationId}
            />
          </CardFooter>
        </Card>

        <Card>
          <CardHeader className="bg-muted rounded-t-lg">
            <CardTitle className="text-xl flex items-center">
              Membership Requests
            </CardTitle>
            <CardDescription>
              Manage your{' '}
              <Link
                className="text-primary hover:underline"
                to="/settings/membership-codes"
              >
                membership codes
              </Link>{' '}
              from settings.
            </CardDescription>
          </CardHeader>

          <List>
            {fetching && (
              <>
                <ListItem className="relative px-6 border-t">
                  <Skeleton className="h-8 w-full" />
                </ListItem>
                <ListItem className="relative px-6 border-t">
                  <Skeleton className="h-8 w-full" />
                </ListItem>
              </>
            )}

            {data?.organization?.membershipRequests.nodes.map((item) => (
              <ListItem
                className="relative px-6 border-t"
                key={item.id}
              >
                <span>
                  <Link
                    className="hover:underline"
                    to={`/members/requests/${item.id}`}
                  >
                    <span className="absolute inset-0" />
                    {item.fullName}
                  </Link>
                </span>
                <span className="flex items-center">
                  {formatMemberRole(
                    item.membershipCode?.organizationRole ?? MemberRole.Member,
                  )}
                </span>
              </ListItem>
            ))}
          </List>
        </Card>

        <Card>
          <CardHeader
            className={cn(
              'bg-muted rounded-t-lg',
              (data?.organization?.groups.totalCount ?? 0) === 0 &&
                'rounded-b-lg',
            )}
          >
            <CardTitle className="text-xl flex items-center justify-between">
              Groups
            </CardTitle>
          </CardHeader>
          <List>
            {fetching && (
              <>
                <ListItem className="relative px-6 border-t">
                  <Skeleton className="h-8 w-full" />
                </ListItem>
                <ListItem className="relative px-6 border-t">
                  <Skeleton className="h-8 w-full" />
                </ListItem>
              </>
            )}
            {data?.organization?.groups.nodes.map((item) => (
              <ListItem
                className="relative px-6"
                key={item.id}
              >
                <div className="flex items-center gap-2">
                  <Avatar className="h-6 w-6">
                    <AvatarFallback className="text-xs">
                      {item.name.toUpperCase().slice(0, 1)}
                    </AvatarFallback>
                  </Avatar>
                  <Link
                    className="hover:underline"
                    to={`/members/groups/${item.id}`}
                  >
                    <span className="absolute inset-0" />
                    {item.name}
                  </Link>
                </div>
                <div className="flex items-center">
                  {item.members.totalCount} members
                </div>
              </ListItem>
            ))}
          </List>
          <CardFooter className="justify-end py-3 border-t">
            <NewGroupDialog
              className="h-8"
              members={data?.organization?.members.nodes}
              organizationId={organizationId}
            />
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export { MemberList };
