import { TableBody } from './TableBody';
import { TableCell } from './TableCell';
import { TableFooter } from './TableFooter';
import { TableHead } from './TableHead';
import { TableHeaderCell } from './TableHeaderCell';
import { TableRow } from './TableRow';
import { cn } from '@/lib/utils';
import { forwardRef, type TableHTMLAttributes } from 'react';

const Table = forwardRef<
  HTMLTableElement,
  TableHTMLAttributes<HTMLTableElement>
>((props, ref) => {
  const { children, className, ...other } = props;

  return (
    <table
      className={cn('w-full text-sm text-left ', className)}
      ref={ref}
      {...other}
    >
      {children}
    </table>
  );
});

const ComplexComponent = Object.assign(Table, {
  Body: TableBody,
  Cell: TableCell,
  Footer: TableFooter,
  Head: TableHead,
  HeaderCell: TableHeaderCell,
  Row: TableRow,
});

export { ComplexComponent as Table };
