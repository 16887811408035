import { config } from '@/config';
import { toast } from '@/lib/toast';
import { logger } from '@/utils';
import { useEffect, useState } from 'react';

const VERSION_URL = `${config.WEB_URL}/version.json`;

const VersionUpdateCheck = () => {
  const [newVersion, setNewVersion] = useState('');
  const [intervalRef, setIntervalRef] = useState<NodeJS.Timeout>();
  const [isUpToDate, setIsUpToDate] = useState(true);

  const checkVersion = async () => {
    if (config.VERSION_UPDATE_CHECK) {
      await fetch(VERSION_URL)
        .then(async (response) => {
          const { version } = (await response.json()) as { version: string };

          return version;
        })
        .then(
          (result) => {
            // if the current version doesnt match what is available online
            // set the new version number and set the software as out of date
            if (result !== config.VERSION) {
              setNewVersion(result);
              setIsUpToDate(false);
            }
          },
          (error) => {
            logger.error(
              `[VersionUpdateCheck] Problem getting version from web. ${error}`,
            );
          },
        );
    }
  };

  useEffect(() => {
    // if we are not longer up to date show a notification to the user
    if (!isUpToDate) {
      toast('New Version Available!', {
        action: {
          label: 'Reload',
          onClick: () => window.location.reload(),
        },
        description: `v${newVersion}`,
        duration: Number.POSITIVE_INFINITY,
        id: 'version-check-update',
      });

      // make sure we disabled the interval
      if (intervalRef) {
        clearInterval(intervalRef);
      }
    }
  }, [intervalRef, isUpToDate, newVersion]);

  // setup interval to run version check every 5 minutes (1000 * 60 * 5)
  useEffect(() => {
    const asyncFunction = async () => {
      await checkVersion();
    };

    void asyncFunction();

    const interval = setInterval(() => {
      void checkVersion();
    }, 1_000 * 60 * 5);

    setIntervalRef(interval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return null;
};

export { VersionUpdateCheck };
