import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { parseMetadata } from '@/lib/metadata';
import { type MetadataFieldDefinitions } from '@/utils/metadata';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { type Infer } from 'superstruct';
import { useMutation } from 'urql';

const EditMetadataDialogGql = graphql(`
  mutation EditMetadataDialogGql($memberId: String!, $metadata: JSON!) {
    updateMemberMetadata(input: { memberId: $memberId, metadata: $metadata }) {
      member {
        id
        metadata
      }
    }
  }
`);

type EditMetadataDialogProps = {
  readonly disabled?: boolean;
  readonly memberId: string;
  readonly metadata: Record<string, boolean | string>;
  readonly metadataFields: MetadataFieldDefinitions | null | undefined;
};

const EditMetadataDialog = ({
  disabled,
  memberId,
  metadata,
  metadataFields,
}: EditMetadataDialogProps) => {
  const [open, setOpen] = useState(false);

  const [{ error, fetching }, updateMetadata] = useMutation(
    EditMetadataDialogGql,
  );

  const dynamicSchema = parseMetadata(metadataFields, metadata);
  const schema = dynamicSchema.schema;

  const form = useForm<Infer<typeof schema>>({
    defaultValues: dynamicSchema.defaultValues,
    resolver: superstructResolver(schema),
  });

  const onCancel = () => {
    setOpen(false);
    form.reset();
  };

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await updateMetadata({
      memberId,
      metadata: values,
    });

    if (!response.error) {
      setOpen(false);
    }
  };

  return (
    <Dialog
      onOpenChange={setOpen}
      open={open}
    >
      <DialogTrigger asChild>
        <Button
          disabled={disabled}
          variant="outline"
        >
          Edit Metadata
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Member Metadata</DialogTitle>
          <DialogDescription>Manage team member metadata.</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            className="space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            {dynamicSchema.formFields.map((item) => (
              <FormField
                control={form.control}
                key={item.id}
                name={item.name}
                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                  <FormItem>
                    {typeof value === 'string' ? (
                      <>
                        <FormLabel>{item.name}</FormLabel>
                        <FormControl>
                          <Input
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            ref={ref}
                            value={value}
                          />
                        </FormControl>
                        <FormMessage />
                      </>
                    ) : (
                      <>
                        <FormLabel>{item.name}</FormLabel>
                        <FormControl>
                          <Checkbox
                            checked={value}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            ref={ref}
                          />
                        </FormControl>
                        <FormMessage />
                      </>
                    )}
                  </FormItem>
                )}
              />
            ))}

            <MutationError error={error} />

            <DialogFooter>
              <Button
                disabled={fetching}
                onClick={onCancel}
                type="reset"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                disabled={fetching}
                type="submit"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Save
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { EditMetadataDialog };
