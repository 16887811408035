import { type MetadataField } from './NewCustomMetadataFieldDialog';
import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { Trash } from 'lucide-react';
import { useMutation } from 'urql';

const DeleteCustomMemberMetadataQuery = graphql(`
  mutation DeleteCustomMemberMetadata(
    $organizationId: String!
    $fields: JSON!
  ) {
    updateOrganizationMemberMetadataFields(
      input: { organizationId: $organizationId, fields: $fields }
    ) {
      organization {
        id
        memberMetadataFields
      }
    }
  }
`);

type DeleteCustomMetadataFieldDialogProps = {
  fieldName: string;
  isAdmin?: boolean;
  memberMetadataFields: Record<string, MetadataField>;
  organizationId: string;
};

const DeleteCustomMetadataFieldDialog = ({
  fieldName,
  isAdmin,
  memberMetadataFields,
  organizationId,
}: DeleteCustomMetadataFieldDialogProps) => {
  const [, updateMetadataFields] = useMutation(DeleteCustomMemberMetadataQuery);

  if (!isAdmin) {
    return null;
  }

  const handleDelete = async () => {
    const fields = memberMetadataFields;
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete fields[fieldName];

    const response = await updateMetadataFields({
      fields,
      organizationId,
    });

    if (response.error) {
      toast.error('Problem deleting custom member metadata field', {
        description: response.error.message,
      });
    } else {
      toast.success(`Removed custom member metadata field '${fieldName}'.`);
    }
  };

  return (
    <Button
      className="text-red-500 hover:text-red-800"
      onClick={handleDelete}
      variant="link"
    >
      <Trash className="h-4 w-4" />
    </Button>
  );
};

export { DeleteCustomMetadataFieldDialog };
