import { Datetime } from '@/components/Datetime';
import { Loader } from '@/components/Loader';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { GuardMeStatus } from '@/features/GuardMe/components/GuardMeStatus';
import { graphql } from '@/gql';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberGuardMeListGql = graphql(`
  query MemberGuardMeListGql($first: Int!, $memberId: String!) {
    guardmes(
      first: $first
      condition: { memberId: $memberId }
      orderBy: ID_DESC
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          shortId
          status
          isSafe
          batteryLevel
          createdAt
          completedAt
          endsAt
          isActive
          isExpired
          guardmeAttachments {
            totalCount
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

type GuardMeListProps = {
  readonly memberId: string;
};

const MemberGuardMeList = ({ memberId }: GuardMeListProps) => {
  const [{ data, error, fetching }] = useQuery({
    query: MemberGuardMeListGql,
    requestPolicy: 'cache-and-network',
    variables: {
      first: 5,
      memberId,
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl">Most Recent GuardMe Sessions</CardTitle>
      </CardHeader>
      <CardContent>
        <Loader
          isLoading={fetching && data === undefined}
          loadingError={error}
        >
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Id</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Created</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data?.guardmes?.edges.map((item) => (
                <TableRow key={item.node.id}>
                  <TableCell>
                    <Link
                      className="font-bold hover:underline"
                      to={`/guardme/${item.node.id}`}
                    >
                      {item.node.shortId}
                    </Link>
                  </TableCell>
                  <TableCell className="hidden sm:table-cell">
                    <GuardMeStatus status={item.node.status} />
                  </TableCell>
                  <TableCell className="hidden sm:table-cell">
                    <Datetime datetime={item.node.createdAt} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Loader>
      </CardContent>
    </Card>
  );
};

export { MemberGuardMeList };
