import { GuardMeExtendDialog } from './GuardMeExtendDialog';
import { Button } from '@/components/ui/button';
import { TimerReset } from 'lucide-react';

type Props = {
  readonly currentEndsAt: Date;
  readonly memberId?: string;
};

const GuardMeExtendButton = ({ currentEndsAt, memberId }: Props) => {
  return (
    <GuardMeExtendDialog
      currentEndsAt={currentEndsAt}
      memberId={memberId}
    >
      <Button
        size="sm"
        variant="outline"
      >
        <TimerReset className="h-5 w-5 mr-2" />
        Extend
      </Button>
    </GuardMeExtendDialog>
  );
};

export { GuardMeExtendButton };
