import { useEffect, useState } from 'react';

type useGeolocationServiceProps = {
  defaultPosition?: { lat: number; lng: number };
};

const defaultParameters = { defaultPosition: { lat: 42.15, lng: -95.25 } };

const useGeolocationService = ({
  defaultPosition,
}: useGeolocationServiceProps = defaultParameters) => {
  const [position, setPosition] = useState(
    defaultPosition || { lat: 42.15, lng: -95.25 },
  );

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (newPosition: GeolocationPosition) => {
          setPosition({
            lat: newPosition.coords.latitude,
            lng: newPosition.coords.longitude,
          });
        },
      );
    }
  }, []);

  return position;
};

export { useGeolocationService };
