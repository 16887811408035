import { NewOrganizationLayout } from './components/NewOrganizationLayout';
import { type RouteObject } from 'react-router-dom';

export const newOrganizationRoutes: RouteObject[] = [
  {
    children: [
      {
        lazy: async () => ({
          Component: (await import('./routes/NewOrganization')).NewOrganization,
        }),
        path: '/organization/new',
      },
    ],
    element: <NewOrganizationLayout />,
  },
];
