import { StartMembershipRequest } from './membershipRequest/StartMembershipRequest';
import { SubmitMembershipRequest } from './membershipRequest/SubmitMembershipRequest';
import { type FoundOrganization } from './membershipRequest/Types';
import { useState } from 'react';

const JoinOrganization = () => {
  const [organization, setOrganization] = useState<FoundOrganization | null>(
    null,
  );

  if (organization) {
    return (
      <SubmitMembershipRequest
        code={organization.code}
        logoUrl={organization.logoUrl}
        name={organization.name}
        onReset={() => {
          setOrganization(null);
        }}
        publicFields={organization.publicFields}
      />
    );
  }

  return <StartMembershipRequest setOrganization={setOrganization} />;
};

export { JoinOrganization };
