import { ApplicationRole } from '../gql/graphql';
import { type AppStore, type CurrentUserSlice } from './types';
import { fmtPhoneNumber } from '@/utils/formatters';
import { type StateCreator } from 'zustand';

const createCurrentUserSlice: StateCreator<
  AppStore,
  [],
  [],
  CurrentUserSlice
> = (set) => ({
  currentUser: {
    avatarUrl: '',
    emailAddress: '',
    fullName: '',
    id: '',
    phoneNumber: '',
    role: 'VISITOR',
    timezone: '',
  },
  isGlobalAdmin: false,
  setCurrentUser: ({
    id,
    avatarUrl,
    fullName,
    role,
    emailAddress,
    phoneNumber,
    timezone,
  }) =>
    set((state) => ({
      ...state,
      currentUser: {
        avatarUrl,
        emailAddress,
        fullName,
        id,
        phoneNumber: fmtPhoneNumber(phoneNumber),
        role,
        timezone,
      },
      isGlobalAdmin: role === ApplicationRole.GlobalAdmin,
    })),
});

export { createCurrentUserSlice };
