class VisualC3Error extends Error {
  public code: number;

  public constructor(message?: string, code: number = 500) {
    super(message);
    this.code = code;
  }
}

class NotFoundError extends VisualC3Error {
  public constructor(message: string = 'Does not exist') {
    super(message, 404);
  }
}

class OrganizationNotFoundError extends VisualC3Error {
  public constructor() {
    super('Organization does not exist', 404);
  }
}

class PermissionDeniedError extends VisualC3Error {
  public constructor() {
    super('Insufficent permission', 401);
  }
}

export {
  NotFoundError,
  OrganizationNotFoundError,
  PermissionDeniedError,
  VisualC3Error,
};
