/**
 * Returns a the specified url or if none suppliied will generate a
 * random avatar using letters.noticeable.io. The color for the avatar
 * will also be randomly chosen. If no name is supplied the default CU
 * moniker will be used.
 */
const createLogoUrl = (name: string) => {
  const initals = name?.slice(0, 2).toUpperCase() ?? 'CU';
  return `https://letters.noticeable.io/${initals}17.png`;
};

export { createLogoUrl };
