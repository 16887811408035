import { type Color } from '../constants';
import { type ElementType, type HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

type ColorClasses = {
  background: string;
  border: string;
  text: string;
};

const calloutColors: Record<Color, ColorClasses> = {
  black: {
    background: 'bg-gray-600',
    border: 'border-gray-900',
    text: 'text-gray-50',
  },
  blue: {
    background: 'bg-blue-50',
    border: 'border-blue-700',
    text: 'text-blue-600',
  },
  gray: {
    background: 'bg-gray-100',
    border: 'border-gray-400',
    text: 'text-gray-500',
  },
  green: {
    background: 'bg-green-50',
    border: 'border-green-700',
    text: 'text-green-600',
  },
  red: {
    background: 'bg-rose-50',
    border: 'border-rose-700',
    text: 'text-rose-600',
  },
  yellow: {
    background: 'bg-yellow-50',
    border: 'border-yellow-700',
    text: 'text-yellow-600',
  },
} as const;

type CalloutProps = HTMLAttributes<HTMLDivElement> & {
  color?: Color;
  icon?: ElementType;
  title?: string;
};

const Callout = forwardRef<HTMLDivElement, CalloutProps>((props, ref) => {
  const { title, icon, color = 'blue', className, children, ...extra } = props;

  const Icon = icon;
  return (
    <div
      {...extra}
      className={twMerge(
        'flex flex-col overflow-hidden',
        'text-sm rounded-md border-l-4',
        'py-3 pr-3 pl-4',
        calloutColors[color].background,
        calloutColors[color].border,
        calloutColors[color].text,
        className,
      )}
      ref={ref}
    >
      <div className="flex items-start gap-2">
        {Icon ? (
          <Icon
            aria-hidden="true"
            className="flex-none h-5 w-5"
          />
        ) : null}
        {title && <h4 className="text-elem font-semibold">{title}</h4>}
      </div>
      <p className={twMerge('overflow-y-auto', title && 'mt-2')}>{children}</p>
    </div>
  );
});

export { Callout, type CalloutProps };
