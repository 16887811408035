import { forwardRef, type HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type TableHeaderCellProps = HTMLAttributes<HTMLTableCellElement>;

const TableHeaderCell = forwardRef<HTMLTableCellElement, TableHeaderCellProps>(
  (props, ref) => {
    const { children, className, ...extra } = props;

    return (
      <th
        {...extra}
        className={twMerge('px-4 py-3 font-bold', className)}
        ref={ref}
        scope="col"
      >
        {children}
      </th>
    );
  },
);

export { TableHeaderCell };
