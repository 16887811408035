import { Callout } from '@/components';
import { type CombinedError } from 'urql';

type Props = {
  readonly error?: CombinedError | null;
};

const MutationError = ({ error }: Props) => {
  if (error?.networkError) {
    return (
      <Callout
        color="red"
        title="Network Error"
      >
        Network Error: {error.networkError.message}
      </Callout>
    );
  }

  if (error?.graphQLErrors) {
    return (
      <Callout
        color="red"
        title="GraphQL Error"
      >
        {error.graphQLErrors.map((item) => (
          <span key={item.name}>{item.message}</span>
        ))}
      </Callout>
    );
  }

  return null;
};

export { MutationError };
