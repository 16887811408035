import { formatPhoneNumberIntl } from 'react-phone-number-input';

const fmtPhoneNumber = (phoneNumber?: string | null): string => {
  if (!phoneNumber) {
    return '---';
  }

  return formatPhoneNumberIntl(phoneNumber);
};

const currency = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits: 2,

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2,

  style: 'currency',
});

// eslint-disable-next-line @typescript-eslint/unbound-method
const fmtCurrency = currency.format;

export { fmtCurrency, fmtPhoneNumber };
