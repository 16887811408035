import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

const DeleteMemberDialogGql = graphql(`
  mutation DeleteMemberDialogGql($memberId: String!) {
    deleteMember(input: { id: $memberId }) {
      member {
        id
      }
    }
  }
`);

type DeleteMemberDialogProps = {
  readonly disabled?: boolean;
  readonly displayName?: string | null | undefined;
  readonly fullName: string;
  readonly memberId: string;
};

const DeleteMemberDialog = ({
  disabled,
  displayName,
  fullName,
  memberId,
}: DeleteMemberDialogProps) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const [{ error, fetching }, deleteMember] = useMutation(
    DeleteMemberDialogGql,
  );

  const handleOnClick = async () => {
    await deleteMember({ memberId });
    setOpen(false);
    toast.success(`Deleted ${displayName ?? fullName}`);
    navigate('/members');
  };

  if (!disabled) {
    return null;
  }

  return (
    <AlertDialog
      onOpenChange={setOpen}
      open={open}
    >
      <AlertDialogTrigger asChild>
        <Button variant="destructive">Delete Member</Button>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Delete Member {displayName ?? fullName}?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Deleting a member will remove them and all data related to them from
            the organization. This action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <MutationError error={error} />

        <AlertDialogFooter>
          <AlertDialogCancel disabled={fetching}>Cancel</AlertDialogCancel>
          <Button
            disabled={fetching}
            onClick={handleOnClick}
            variant="destructive"
          >
            {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
            Delete Member
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { DeleteMemberDialog };
