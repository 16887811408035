import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { cn } from '@/lib/utils';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Check, Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { type Infer, object, string } from 'superstruct';
import { useMutation } from 'urql';

const VerifyPendingEmailAddressQuery = graphql(`
  mutation VerifyCurrentUserEmailAddress($input: VerifyEmailAddressInput!) {
    verifyEmailAddress(input: $input) {
      query {
        currentUser {
          id
          emailAddress
          pendingEmailAddress
        }
      }
    }
  }
`);

const schema = object({
  emailAddress: string(),
  verificationCode: string(),
});

type VerifyPendingEmailAddressProps = {
  readonly className?: string;
  readonly pending?: string | null | undefined;
};

const VerifyPendingEmailAddress = ({
  className,
  pending,
}: VerifyPendingEmailAddressProps) => {
  const [open, setOpen] = useState(false);

  const [{ error, fetching }, verifyEmailAddress] = useMutation(
    VerifyPendingEmailAddressQuery,
  );

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      emailAddress: pending ?? '',
      verificationCode: '',
    },
    resolver: superstructResolver(schema),
  });

  if (!pending) {
    return null;
  }

  const onCancel = () => {
    form.reset();
    setOpen(false);
  };

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await verifyEmailAddress({
      input: {
        emailAddress: values.emailAddress,
        verificationCode: values.verificationCode,
      },
    });

    if (!response.error) {
      toast.success('Updated email address');
      onCancel();
    }
  };

  return (
    <Dialog
      onOpenChange={setOpen}
      open={open}
    >
      <DialogTrigger asChild>
        <Button
          className={cn('whitespace-nowrap h-8', className)}
          variant="default"
        >
          <Check className="h-4 w-4 mr-2" />
          Verify
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Email Address Verification</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form
            className="space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="verificationCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Verification Code</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <MutationError error={error} />

            <DialogFooter>
              <Button
                disabled={fetching}
                onClick={onCancel}
                type="reset"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                disabled={fetching}
                type="submit"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Verify
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { VerifyPendingEmailAddress };
