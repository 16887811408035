import { type Size } from '../constants';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import {
  type ElementType,
  type InputHTMLAttributes,
  type JSXElementConstructor,
} from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const inputSizes = {
  lg: 'py-3 px-4 sm:p-5',
  md: 'py-3 px-4',
  sm: 'py-2 px-3',
  xl: 'py-3 px-4 sm:p-5',
  xs: 'py-1.5 px-2.5',
} as const;

type TextInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  error?: string;
  icon?: ElementType | JSXElementConstructor<unknown>;
  loading?: boolean;
  size?: Size;
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    placeholder = 'Type...',
    icon,
    error = false,
    disabled = false,
    className,
    loading = false,
    size = 'md',
    readOnly = false,
    value,
    ...extra
  } = props;

  const Icon = icon;

  return (
    <div className={twMerge('relative w-full', className)}>
      <input
        {...extra}
        className={twMerge(
          'block w-full border-gray-200 rounded-md text-sm',
          'focus:border-blue-500 focus:ring-blue-500',
          'dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400',
          inputSizes[size],
          disabled && 'opacity-60 pointer-events-none',
        )}
        disabled={disabled}
        placeholder={placeholder}
        readOnly={readOnly || loading}
        ref={ref}
        type="text"
        value={loading ? 'Loading...' : value}
      />

      {Icon && !error && (
        <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
          <Icon
            aria-hidden="true"
            className="h-5 w-5"
          />
        </div>
      )}

      {error && (
        <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
          <ExclamationCircleIcon
            aria-hidden="true"
            className="h-5 w-5 text-red-500"
          />
        </div>
      )}

      {error && <div className="text-xs text-red-500">{error}</div>}
    </div>
  );
});

export { TextInput, type TextInputProps };
