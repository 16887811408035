import { cn } from '@/lib/utils';
import { forwardRef, type HTMLAttributes } from 'react';

type TableHeadProps = HTMLAttributes<HTMLTableSectionElement>;

const TableHead = forwardRef<HTMLTableSectionElement, TableHeadProps>(
  (props, ref) => {
    const { children, className, ...extra } = props;

    return (
      <thead
        {...extra}
        className={cn(
          'text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400',
          className,
        )}
        ref={ref}
      >
        {children}
      </thead>
    );
  },
);

export { TableHead };
