import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useMutation } from 'urql';

const DisableMemberDialogGql = graphql(`
  mutation DisableMemberDialogGql($memberId: String!) {
    disableMember(input: { id: $memberId }) {
      member {
        id
        isEnabled
      }
    }
  }
`);

type DisableMemberDialogProps = {
  readonly disabled?: boolean;
  readonly memberId: string;
};

const DisableMemberDialog = ({
  disabled,
  memberId,
}: DisableMemberDialogProps) => {
  const [open, setOpen] = useState(false);

  const [{ error, fetching }, disableMember] = useMutation(
    DisableMemberDialogGql,
  );

  if (disabled) {
    return null;
  }

  return (
    <AlertDialog
      onOpenChange={setOpen}
      open={open}
    >
      <AlertDialogTrigger asChild>
        <Button
          disabled={disabled}
          variant="warning"
        >
          Disable Member
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Disable Member?</AlertDialogTitle>
          <AlertDialogDescription>
            This will prevent the member from being able to access resources in
            this organization.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <MutationError error={error} />

        <AlertDialogFooter>
          <AlertDialogCancel disabled={fetching}>Cancel</AlertDialogCancel>
          <Button
            disabled={fetching}
            onClick={async () => {
              await disableMember({ memberId });
              setOpen(false);
              toast.success(`Disabled member`);
            }}
            variant="destructive"
          >
            {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
            Disable Member
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { DisableMemberDialog };
