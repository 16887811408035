import { config } from '../config';

/**
 * Signature of a logging function
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LogFunction = (message?: any, ...optionalParameters: any[]) => void;

/**
 * Basic logger interface
 */
export type Logger = {
  debug: LogFunction;
  error: LogFunction;
  log: LogFunction;
  warn: LogFunction;
};

/**
 * Log levels
 */
export type LogLevel = 'debug' | 'error' | 'log' | 'warn';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const NO_OP: LogFunction = (message?: any, ...optionalParameters: any[]) => {};

/**
 * Logger which outputs to the browser console
 */
export class ConsoleLogger implements Logger {
  public readonly log: LogFunction;

  public readonly warn: LogFunction;

  public readonly error: LogFunction;

  public readonly debug: LogFunction;

  public constructor(options?: { level?: LogLevel }) {
    const { level } = options ?? {};

    // eslint-disable-next-line no-console
    this.error = console.error.bind(console);

    if (level === 'error') {
      this.warn = NO_OP;
      this.log = NO_OP;
      this.debug = NO_OP;

      return;
    }

    // eslint-disable-next-line no-console
    this.warn = console.warn.bind(console);

    if (level === 'warn') {
      this.log = NO_OP;
      this.debug = NO_OP;

      return;
    }

    // eslint-disable-next-line no-console
    this.log = console.log.bind(console);

    // eslint-disable-next-line no-console
    this.debug = console.debug.bind(console);
  }
}

const logger = new ConsoleLogger({
  level: (config.LOG_LEVEL as LogLevel) ?? 'warn',
});

export { logger };
