import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { MailOpen } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const PendingInvitationsCalloutGql = graphql(`
  query PendingInvitationsCalloutGql {
    currentUser {
      id
      invitations {
        totalCount
      }
    }
  }
`);

const PendingInvitationsCallout = () => {
  const [{ data }] = useQuery({
    query: PendingInvitationsCalloutGql,
  });

  const invitationCount = data?.currentUser?.invitations.totalCount ?? 0;

  if (invitationCount === 0) {
    return null;
  }

  return (
    <Link
      className="relative inline-flex flex-shrink-0 justify-center items-center text-muted-foreground hover:text-gray-700"
      to="/account/memberships"
    >
      <Button variant="secondary">
        <MailOpen className="h-4 w-4 mr-2" />
        <span className="italic">Pending Invitation</span>
      </Button>
      <span className="flex absolute h-3 w-3 top-0 right-0 -mt-0.5 -mr-0.5">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75" />
        <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-500" />
      </span>
    </Link>
  );
};

export { PendingInvitationsCallout };
