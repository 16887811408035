import { CheckInDetailsMap } from '../components/CheckInDetailsMap';
import { ClientError, Loader } from '@/components';
import { Datetime } from '@/components/Datetime';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Toggle } from '@/components/ui/toggle';
import { graphql } from '@/gql';
import { Eye, EyeOffIcon, SquareUser } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const CheckInGql = graphql(`
  query CheckInGql($id: String!) {
    checkIn(id: $id) {
      id
      shortId
      createdAt
      coordinates {
        x
        y
      }
      places {
        nodes {
          id
          name
          spatialData {
            geojson
          }
          radius
          center {
            longitude
            latitude
          }
        }
      }
      geocode {
        id
        address
        plusCode
      }
      memberId
      member {
        id
        fullName
        displayName
        avatarUrl
        lastActiveAt
      }
      organization {
        id
        places {
          nodes {
            id
            name
            spatialData {
              geojson
            }
            radius
            center {
              longitude
              latitude
            }
          }
        }
      }
    }
  }
`);

const CheckIn = () => {
  const { id } = useParams() as { id: string };

  const [places, setPlaces] = useState<string[]>([]);

  const [{ data, error, fetching }] = useQuery({
    query: CheckInGql,
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (data?.checkIn?.places.nodes) {
      setPlaces(data?.checkIn?.places.nodes.map((item) => item.id));
    }
  }, [data]);

  const togglePlaceVisibility = (placeId: string) => {
    setPlaces((previous) => {
      if (previous.includes(placeId)) {
        return previous.filter((item) => item !== placeId);
      }

      return [...previous, placeId];
    });
  };

  return (
    <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3">
      <div className="flex flex-col items-start gap-8">
        <Card className="w-full">
          <CardHeader className="pb-6">
            <CardDescription>
              Check In #{data?.checkIn?.shortId.split('-')[1]}
            </CardDescription>
            <CardTitle className="flex items-center gap-2">
              <Avatar className="h-8 w-8">
                <AvatarImage
                  src={data?.checkIn?.member?.avatarUrl ?? undefined}
                />
                <AvatarFallback>
                  {data?.checkIn?.member?.fullName.slice(0, 2)}
                </AvatarFallback>
              </Avatar>
              <span>
                {data?.checkIn?.member?.displayName ??
                  data?.checkIn?.member?.fullName}
              </span>
              <Link
                className="ml-auto"
                to={`/members/${data?.checkIn?.member?.id}`}
              >
                <Button
                  className="rounded-full"
                  size="icon"
                  variant="ghost"
                >
                  <SquareUser className="h-4 w-4" />
                </Button>
              </Link>
            </CardTitle>
          </CardHeader>

          <CardContent className="text-sm">
            <div className="grid gap-3">
              <Separator className="mb-2" />
              <div className="font-semibold">Reported</div>
              <div>
                <Datetime datetime={data?.checkIn?.createdAt} />
              </div>

              <Separator className="my-2" />
              <div className="font-semibold">Address</div>
              <div>{data?.checkIn?.geocode?.address}</div>

              <Separator className="my-2" />
              <div className="font-semibold">Coordinates</div>
              <ul className="grid gap-3">
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">Latitude</span>
                  <span>{data?.checkIn?.coordinates.y}</span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">Longitude</span>
                  <span>{data?.checkIn?.coordinates.x}</span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">
                    Google Plus Code
                  </span>
                  <span>{data?.checkIn?.geocode?.plusCode}</span>
                </li>
              </ul>

              <Separator className="my-2" />
              <div className="font-semibold">Organization Places</div>
              <ul className="grid gap-1">
                {data?.checkIn?.places.nodes
                  .sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
                  )
                  .map((item) => (
                    <li
                      className="flex items-center justify-between"
                      key={item.id}
                    >
                      <span className="text-muted-foreground flex items-center gap-2">
                        {item.name}
                        <Badge
                          className="px-1.5 py-0.25"
                          variant="secondary"
                        >
                          Related
                        </Badge>
                      </span>
                      <span>
                        <Toggle
                          aria-label="Toggle visible"
                          className="h-6"
                          onPressedChange={() => togglePlaceVisibility(item.id)}
                          pressed={places.includes(item.id)}
                          size="sm"
                        >
                          {places.includes(item.id) ? (
                            <Eye className="h-4 w-4" />
                          ) : (
                            <EyeOffIcon className="h-4 w-4" />
                          )}
                        </Toggle>
                      </span>
                    </li>
                  ))}

                {data?.checkIn?.organization?.places.nodes
                  .filter(
                    (a) =>
                      !data.checkIn?.places.nodes
                        .map((place) => place.id)
                        .includes(a.id),
                  )
                  .sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
                  )
                  .map((item) => (
                    <li
                      className="flex items-center justify-between"
                      key={item.id}
                    >
                      <span className="text-muted-foreground flex items-center gap-2">
                        {item.name}
                        {data.checkIn?.places.nodes
                          .map((place) => place.id)
                          .includes(item.id) && (
                          <Badge
                            className="px-1.5 py-0.25"
                            variant="secondary"
                          >
                            Related
                          </Badge>
                        )}
                      </span>
                      <span>
                        <Toggle
                          aria-label="Toggle visible"
                          className="h-6"
                          onPressedChange={() => togglePlaceVisibility(item.id)}
                          pressed={places.includes(item.id)}
                          size="sm"
                        >
                          {places.includes(item.id) ? (
                            <Eye className="h-4 w-4" />
                          ) : (
                            <EyeOffIcon className="h-4 w-4" />
                          )}
                        </Toggle>
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="h-full col-span-2">
        <Loader
          isLoading={fetching}
          loadingError={error}
        >
          {data?.checkIn ? (
            <CheckInDetailsMap
              coordinates={data.checkIn.coordinates}
              member={data.checkIn.member}
              places={data.checkIn.organization?.places.nodes.filter((item) =>
                places.includes(item.id),
              )}
            />
          ) : (
            <ClientError
              code={404}
              message="Check In does not exist"
            />
          )}
        </Loader>
      </div>
    </div>
  );
};

export { CheckIn };
