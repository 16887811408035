import { IncidentPriorityType } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { ChevronsDown, ChevronsUp, Flame, Minus } from 'lucide-react';

const classes =
  'inline-flex items-center gap-2 text-xs px-2 py-1 rounded whitespace-nowrap';

type IncidentPriorityProps = {
  readonly className?: string;
  readonly iconOnly?: boolean;
  readonly noColor?: boolean;
  readonly priority: IncidentPriorityType;
};

const IncidentPriority = ({
  className,
  iconOnly = false,
  noColor = false,
  priority,
}: IncidentPriorityProps) => {
  if (priority === IncidentPriorityType.Urgent) {
    return (
      <span
        className={cn(
          classes,
          !noColor && 'bg-destructive text-destructive-foreground',
          className,
        )}
      >
        <Flame className="h-4 w-4" /> {!iconOnly && 'Urgent'}
      </span>
    );
  }

  if (priority === IncidentPriorityType.High) {
    return (
      <span
        className={cn(
          classes,
          !noColor && 'bg-warning text-warning-foreground',
          className,
        )}
      >
        <ChevronsUp className="h-4 w-4" /> {!iconOnly && 'High'}
      </span>
    );
  }

  if (priority === IncidentPriorityType.Medium) {
    return (
      <span
        className={cn(
          classes,
          !noColor && 'bg-info text-info-foreground',
          className,
        )}
      >
        <Minus className="h-4 w-4" /> {!iconOnly && 'Medium'}
      </span>
    );
  }

  if (priority === IncidentPriorityType.Low) {
    return (
      <span
        className={cn(
          classes,
          !noColor && 'bg-success text-success-foreground',
          className,
        )}
      >
        <ChevronsDown className="h-4 w-4" /> {!iconOnly && 'Low'}
      </span>
    );
  }

  return null;
};

export { IncidentPriority };
