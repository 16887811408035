import { DeleteMemberDialog } from './DeleteMemberDialog';
import { DisableMemberDialog } from './DisableMemberDialog';
import { EditDisplayNameDialog } from './EditDisplayNameDialog';
import { EditMetadataDialog } from './EditMetadataDialog';
import { EditRoleDialog } from './EditRoleDialog';
import { EnableMemberDialog } from './EnableMemberDialog';
import { MemberRole } from '@/gql/graphql';
import { useAppStore } from '@/stores';
import { type MetadataFieldDefinitions } from '@/utils/metadata';

type MemberActionsProps = {
  readonly disabled: boolean;
  readonly displayName?: string | null | undefined;
  readonly fullName: string;
  readonly memberId: string;
  readonly metadata: Record<string, boolean | string>;
  readonly metadataFields: MetadataFieldDefinitions;
  readonly role: string;
};

const MemberActions = ({
  disabled,
  displayName,
  fullName,
  memberId,
  metadata,
  metadataFields,
  role,
}: MemberActionsProps) => {
  const organizationRole = useAppStore((state) => state.activeMembership?.role);

  const allowedRoles = [MemberRole.Owner, MemberRole.Administrator];

  if (!organizationRole || !allowedRoles.includes(organizationRole)) {
    return null;
  }

  return (
    <div className="flex items-center gap-2">
      <EditDisplayNameDialog
        displayName={displayName}
        fullName={fullName}
        memberId={memberId}
      />
      <EditMetadataDialog
        memberId={memberId}
        metadata={metadata}
        metadataFields={metadataFields}
      />
      <EditRoleDialog
        memberId={memberId}
        role={role}
      />
      <DisableMemberDialog
        disabled={disabled}
        memberId={memberId}
      />
      <EnableMemberDialog
        disabled={disabled}
        memberId={memberId}
      />
      <DeleteMemberDialog
        disabled={disabled}
        displayName={displayName}
        fullName={fullName}
        memberId={memberId}
      />
    </div>
  );
};

export { MemberActions };
