import { BivyStickActions } from '../components/bivy-stick/BivyStickActions';
import { Empty, Loader, Spinner } from '@/components';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils/errors';
import { RefreshCw } from 'lucide-react';
import { useState } from 'react';
import { useQuery } from 'urql';

const OrganizationBivySticksGql = graphql(`
  query OrganizationBivySticksGql($id: String!) {
    organization(id: $id) {
      id
      bivySticks {
        totalCount
        nodes {
          id
          imei
          member {
            id
            fullName
            displayName
            avatarUrl
          }
          isRegistered
          createdAt
          updatedAt
        }
      }
    }
  }
`);

const BivySticks = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [search, setSearch] = useState('');

  const [{ data, error, fetching }, refetch] = useQuery({
    query: OrganizationBivySticksGql,
    variables: {
      id: organizationId,
    },
  });

  const bivySticks =
    data?.organization?.bivySticks?.nodes.filter((item) =>
      item.id.includes(search),
    ) ?? [];

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Bivy Stick Integration</CardTitle>
        <div className="flex items-center gap-2">
          <Input
            className="h-8"
            onChange={(event) => setSearch(event.currentTarget.value)}
            placeholder="Search..."
            value={search}
          />
          <Button
            className="h-8"
            onClick={() => refetch({ requestPolicy: 'network-only' })}
            size="icon"
            variant="outline"
          >
            <RefreshCw className="h-4 w-4" />
          </Button>
        </div>
      </CardHeader>
      <CardContent className="space-y-4">
        <Loader
          isLoading={!data}
          loadingError={error}
        >
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>IMEI</TableHead>
                <TableHead>Member</TableHead>
                <TableHead>Registered</TableHead>
                <TableHead>
                  <span className="sr-only">Actions</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {fetching ? (
                <TableRow>
                  <TableCell
                    className="h-24 text-center"
                    colSpan={5}
                  >
                    <Spinner title="Loading..." />
                  </TableCell>
                </TableRow>
              ) : bivySticks.length > 0 ? (
                bivySticks.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className="font-bold">{item.imei}</TableCell>
                    <TableCell>
                      {item.member?.displayName ?? item.member?.fullName}
                    </TableCell>
                    <TableCell>{item.isRegistered && 'Enabled'}</TableCell>
                    <TableCell className="text-right">
                      <BivyStickActions
                        id={item.id}
                        imei={item.imei}
                        memberId={item.member?.id}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Empty message="No Bivy Sticks registered to your organization at this time." />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Loader>
      </CardContent>
    </Card>
  );
};

export { BivySticks };
