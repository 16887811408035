import { useTheme } from '@/providers/ThemeProvider';
import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme();

  return (
    <Sonner
      className="toaster group"
      theme={theme}
      toastOptions={{
        classNames: {
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton:
            'group-[.toast]:!bg-muted group-[.toast]:!text-muted-foreground',
          description:
            'group-[.toast]:!text-muted-foreground dark:group-[.toast]:!text-foreground/85',
          error:
            'group toast group-[.toaster]:!bg-error group-[.toaster]:!text-error-foreground group-[.toaster]:shadow-lg',
          info: 'group toast group-[.toaster]:!bg-info group-[.toaster]:!text-info-foreground group-[.toaster]:shadow-lg',
          success:
            'group toast group-[.toaster]:!bg-success group-[.toaster]:!text-success-foreground group-[.toaster]:shadow-lg',
          toast:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          warning:
            'group toast group-[.toaster]:!bg-warning group-[.toaster]:!text-warning-foreground group-[.toaster]:shadow-lg',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
