import { Datetime } from '@/components/Datetime';
import { Empty } from '@/components/Empty';
import { Loader } from '@/components/Loader';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from '@/components/ui/pagination';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { GuardmeStatus, type Member } from '@/gql/graphql';
import { toDatetime } from '@/lib/time';
import { sub } from 'date-fns/sub';
import {
  ChevronLeft,
  ChevronRight,
  Dot,
  Flag,
  MapPin,
  ShieldCheck,
} from 'lucide-react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const RecentGql = graphql(`
  query RecentGql($gte: Datetime!) {
    guardmes(orderBy: ID_DESC, condition: { createdAt: { gte: $gte } }) {
      nodes {
        id
        shortId
        organization {
          id
          name
        }
        member {
          id
          avatarUrl
          displayName
          fullName
        }
        status
        updatedAt
      }
    }
    checkIns(orderBy: ID_DESC, condition: { createdAt: { gte: $gte } }) {
      nodes {
        id
        shortId
        organization {
          id
          name
        }
        member {
          id
          avatarUrl
          fullName
          displayName
        }
        createdAt
      }
    }
    incidents(orderBy: ID_DESC, condition: { createdAt: { gte: $gte } }) {
      nodes {
        id
        shortId
        subject
        updatedAt
        organization {
          id
          name
        }
        incidentType {
          id
          name
        }
        contactMember {
          id
          avatarUrl
          displayName
          fullName
        }
      }
    }
  }
`);

type RecentEntry = {
  icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  id: string;
  location: string;
  member?: Pick<Member, 'id' | 'avatarUrl' | 'fullName' | 'displayName'> | null;
  timestamp: Date;
  title: string;
  to: string;
  tooltip: string;
};

const Recent = () => {
  const first = 6;
  const [offset, setOffset] = useState(0);
  const cutoff = useMemo(() => sub(Date.now(), { days: 7 }), []);

  const [{ data, error, fetching }] = useQuery({
    query: RecentGql,
    variables: {
      gte: cutoff,
    },
  });

  // eslint-disable-next-line complexity
  const recentItems = useMemo(() => {
    const items: RecentEntry[] = [];

    for (const guardme of data?.guardmes?.nodes ?? []) {
      let title = '';
      if (guardme.status === GuardmeStatus.Concluded) {
        title = `GuardMe ${guardme.shortId} has concluded`;
      }

      if (guardme.status === GuardmeStatus.Expired) {
        title = `GuardMe ${guardme.shortId} has expired`;
      }

      if (guardme.status === GuardmeStatus.InPanic) {
        title = `GuardMe ${guardme.shortId} requires immediate help`;
      }

      if (guardme.status === GuardmeStatus.InProgress) {
        title = `GuardMe ${guardme.shortId} is in progress`;
      }

      items.push({
        icon: ShieldCheck,
        id: guardme.id,
        location: 'in GuardMe Sessions',
        member: guardme?.member,
        timestamp: toDatetime(guardme.updatedAt),
        title,
        to: `/guardme/${guardme.id}`,
        tooltip: `${guardme.organization?.name} / GuardMe / ${guardme.shortId}`,
      });
    }

    for (const checkIn of data?.checkIns?.nodes ?? []) {
      items.push({
        icon: MapPin,
        id: checkIn.id,
        location: "in Check In's",
        member: checkIn?.member,
        timestamp: toDatetime(checkIn.createdAt),
        title: `Check In ${checkIn.shortId} submitted`,
        to: `/check-in/${checkIn.id}`,
        tooltip: `${checkIn.organization?.name} / Check-In / ${checkIn.shortId}`,
      });
    }

    for (const incident of data?.incidents?.nodes ?? []) {
      items.push({
        icon: Flag,
        id: incident.id,
        location: 'in Incidents',
        member: incident?.contactMember,
        timestamp: toDatetime(incident.updatedAt),
        title: `New Incident ${incident.shortId} - ${incident.incidentType?.name}`,
        to: `/incidents/${incident.id}`,
        tooltip: `${incident.organization?.name} / Incidents / ${incident.shortId}`,
      });
    }

    items.sort((a, b) =>
      a.timestamp.valueOf() < b.timestamp.valueOf() ? 1 : -1,
    );

    return items;
  }, [data]);

  const handleNextPage = () => {
    setOffset((previous) => Math.min(previous + first, recentItems.length));
  };

  const handlePreviousPage = () => {
    setOffset((previous) => Math.max(previous - first, 0));
  };

  return (
    <Card className="h-full flex flex-col">
      <CardHeader className="p-4">
        <CardTitle className="text-lg">Recent Activity</CardTitle>
      </CardHeader>

      <Loader loadingError={error}>
        <CardContent className="grid items-start pb-0">
          {fetching &&
            [...Array.from({ length: 6 }).keys()].map((item) => (
              <Skeleton
                className="mb-4 h-12 w-full"
                key={item}
              />
            ))}

          {!fetching && recentItems.length === 0 && (
            <Empty className="flex items-center justify-center h-full">
              <p>No Recent Activity</p>
            </Empty>
          )}

          {!fetching &&
            recentItems
              .slice(offset, Math.min(offset + first, recentItems.length))
              .map((item) => (
                <div
                  className="mt-3 pb-3 border-b last:border-none flex items-center"
                  key={item.id}
                >
                  <Avatar className="h-9 w-9">
                    <AvatarImage
                      alt="Avatar"
                      src={item.member?.avatarUrl ?? undefined}
                    />
                    <AvatarFallback>OM</AvatarFallback>
                  </Avatar>
                  <div className="ml-4 grid">
                    <p className="text-sm font-medium">{item.title}</p>
                    <p className="mt-0.5 text-sm text-muted-foreground flex items-center">
                      {item.member?.displayName ?? item.member?.fullName}
                      <Dot className="h-4 w-4" />
                      <Datetime
                        className="font-normal text-sm text-muted-foreground text-ellipsis"
                        datetime={item.timestamp}
                      />
                    </p>
                  </div>
                  <div className="ml-auto font-medium">
                    <Link to={item.to}>
                      <Button variant="secondary">More</Button>
                    </Link>
                  </div>
                </div>
              ))}
        </CardContent>
        <CardFooter className="mt-auto flex flex-row items-center justify-between border-t bg-muted/50 px-6 py-3">
          <div className="text-xs text-muted-foreground">
            {recentItems.length.toLocaleString()} Events
          </div>
          <div className="ml-auto flex text-xs items-center">
            {recentItems.length > 0 && (
              <div className="mr-2 text-muted-foreground">
                {offset + 1} to {offset + first}
              </div>
            )}
            <Pagination className="mx-0 w-auto">
              <PaginationContent>
                <PaginationItem>
                  <Button
                    className="h-6 w-6"
                    disabled={offset === 0}
                    onClick={handlePreviousPage}
                    size="icon"
                    variant="outline"
                  >
                    <ChevronLeft className="h-3.5 w-3.5" />
                    <span className="sr-only">Previous Order</span>
                  </Button>
                </PaginationItem>
                <PaginationItem>
                  <Button
                    className="h-6 w-6"
                    disabled={offset + first >= recentItems.length - 1}
                    onClick={handleNextPage}
                    size="icon"
                    variant="outline"
                  >
                    <ChevronRight className="h-3.5 w-3.5" />
                    <span className="sr-only">Next Order</span>
                  </Button>
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        </CardFooter>
      </Loader>
    </Card>
  );
};

export { Recent };
