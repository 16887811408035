import { Group } from './routes/Group';
import { Member } from './routes/Member';
import { MemberInvitation } from './routes/MemberInvitation';
import { Members } from './routes/Members';
import { MembershipRequest } from './routes/MembershipRequest';
import { type RouteObject } from 'react-router-dom';

export * from './components/GroupSelect';
export * from './components/GroupsSelect';

export const membersRoutes: RouteObject[] = [
  {
    element: <MemberInvitation />,
    path: '/members/invitations/:id',
  },
  {
    element: <MembershipRequest />,
    path: '/members/requests/:id',
  },
  {
    element: <Group />,
    path: '/members/groups/:groupId',
  },
  {
    element: <Member />,
    path: '/members/:memberId',
  },
  {
    element: <Members />,
    path: '/members',
  },
];
