import { MutationError } from '../../Misc';
import { Empty } from '@/components';
import { GroupSelect } from '@/components/GroupSelect';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { NewGroupDialog } from '@/features/Members/components/NewGroupDialog';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { useAppStore } from '@/stores';
import { useForm } from 'react-hook-form';
import { boolean, type Infer, nonempty, object, string } from 'superstruct';
import { useMutation } from 'urql';

const UpdateOrganizationGuardMeSettingsGql = graphql(`
  mutation UpdateOrganizationGuardMeSettingsGql(
    $input: UpdateOrganizationGuardmeSettingsInput!
  ) {
    updateOrganizationGuardmeSettings(input: $input) {
      organizationSetting {
        id
        isOwnerNotifiedOnGuardme
        isAdministratorNotifiedOnGuardme
        isSupervisorNotifiedOnGuardme
        guardmeIsStartedNotificationEnabled
        guardmeIsConcludedNotificationEnabled
        guardmeIsExpiredNotificationEnabled
        guardmeIsPanicNotificationEnabled
        guardmeIsMetadataIncludedInNotification
        guardmeSupervisorGroupId
        guardmeSupervisorGroup {
          id
          name
        }
      }
    }
  }
`);

const schema = object({
  includeMetadata: boolean(),
  notifyAdministrator: boolean(),
  notifyOwner: boolean(),
  notifySupervisor: boolean(),
  onConclude: boolean(),
  onExpire: boolean(),
  onPanic: boolean(),
  onStart: boolean(),
  supervisorGroupId: nonempty(string()),
});

type GuardMeSettingsFormProps = {
  readonly groups: Array<{ id: string; name: string }>;
  readonly guardmeIsConcludedNotificationEnabled: boolean;
  readonly guardmeIsExpiredNotificationEnabled: boolean;
  readonly guardmeIsMetadataIncludedInNotification: boolean;
  readonly guardmeIsPanicNotificationEnabled: boolean;
  readonly guardmeIsStartedNotificationEnabled: boolean;
  readonly guardmeSupervisorGroupId?: string | null | undefined;
  readonly isAdministratorNotifiedOnGuardme: boolean;
  readonly isOwnerNotifiedOnGuardme: boolean;
  readonly isSupervisorNotifiedOnGuardme: boolean;
  readonly organizationId: string;
};

const GuardMeSettingsForm = ({
  groups,
  guardmeIsConcludedNotificationEnabled,
  guardmeIsExpiredNotificationEnabled,
  guardmeIsMetadataIncludedInNotification,
  guardmeIsPanicNotificationEnabled,
  guardmeIsStartedNotificationEnabled,
  guardmeSupervisorGroupId,
  isAdministratorNotifiedOnGuardme,
  isOwnerNotifiedOnGuardme,
  isSupervisorNotifiedOnGuardme,
  organizationId,
}: GuardMeSettingsFormProps) => {
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);

  const [{ error }, updateSettings] = useMutation(
    UpdateOrganizationGuardMeSettingsGql,
  );

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      includeMetadata: guardmeIsMetadataIncludedInNotification,
      notifyAdministrator: isAdministratorNotifiedOnGuardme,
      notifyOwner: isOwnerNotifiedOnGuardme,
      notifySupervisor: isSupervisorNotifiedOnGuardme,
      onConclude: guardmeIsConcludedNotificationEnabled,
      onExpire: guardmeIsExpiredNotificationEnabled,
      onPanic: guardmeIsPanicNotificationEnabled,
      onStart: guardmeIsStartedNotificationEnabled,
      supervisorGroupId: guardmeSupervisorGroupId ?? '',
    },
  });

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await updateSettings({
      input: {
        includeMemberMetadata: values.includeMetadata,
        organizationId,
        sendConcludedNotification: values.onConclude,
        sendExpiredNotification: values.onExpire,
        sendPanicNotification: values.onPanic,
        sendStartedNotification: values.onStart,
        shouldNotifyAdministrator: values.notifyAdministrator,
        shouldNotifyOwner: values.notifyOwner,
        shouldNotifySupervisor: values.notifySupervisor,
        supervisorGroupId: values.supervisorGroupId,
      },
    });

    if (!response.error) {
      form.reset(values);
      toast.success('Updated GuardMe Settings', {
        id: 'guardme-settings',
      });
    }
  };

  return (
    <Form {...form}>
      <form
        className="space-y-8"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormItem className="space-y-3">
          <FormLabel className="mb-2">Notification Recipients</FormLabel>

          <FormField
            control={form.control}
            name="notifyOwner"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>Send notification to organization owner</FormLabel>
                </div>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="notifyAdministrator"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    Send notification to organization administrator(s)
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="notifySupervisor"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    Send notification to organization supervisor(s)
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
        </FormItem>

        <FormItem className="space-y-3">
          <FormLabel className="mb-2">Notification Options</FormLabel>

          <FormField
            control={form.control}
            name="includeMetadata"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>Include member metadata in notification</FormLabel>
                </div>
              </FormItem>
            )}
          />
        </FormItem>

        <FormItem className="space-y-3">
          <FormLabel className="mb-2">Notification Events</FormLabel>

          <FormField
            control={form.control}
            name="onStart"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>Send notification when session starts</FormLabel>
                </div>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="onExpire"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>Send notification when session expires</FormLabel>
                </div>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="onConclude"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    Send notification when session concludes
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="onPanic"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>Send notification when session panics</FormLabel>
                </div>
              </FormItem>
            )}
          />
        </FormItem>

        <FormItem>
          <FormField
            control={form.control}
            name="supervisorGroupId"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Grant GuardMe Supervisor to Group</FormLabel>
                <FormDescription>
                  Grants the supervisor role to the following group. Create a{' '}
                  <NewGroupDialog
                    organizationId={organizationId}
                    type="link"
                  />
                  .
                </FormDescription>

                {(groups.length ?? 0) === 0 ? (
                  <Empty className="w-full border border-dashed flex-col space-y-1">
                    <div>
                      <NewGroupDialog organizationId={organizationId} />
                    </div>
                  </Empty>
                ) : (
                  <GroupSelect
                    {...field}
                    allowEmpty
                    groups={groups}
                  />
                )}
                <FormMessage />
              </FormItem>
            )}
          />
        </FormItem>

        <MutationError error={error} />

        <div className="flex items-center gap-2">
          <Button
            disabled={!isAdmin || !form.formState.isDirty}
            type="submit"
          >
            Update Settings
          </Button>
          <Button
            disabled={!isAdmin || !form.formState.isDirty}
            onClick={() => form.reset()}
            type="reset"
            variant="ghost"
          >
            Reset
          </Button>
        </div>
      </form>
    </Form>
  );
};

export { GuardMeSettingsForm };
