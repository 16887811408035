import { AssignBivyStickFormDialog } from './AssignBivyStickFormDialog';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useAppStore } from '@/stores';
import { MoreHorizontal } from 'lucide-react';
import { useState } from 'react';

type MemberActionsProps = {
  id: string;
  imei: string;
  memberId?: string;
};

const BivyStickActions = ({ id, imei, memberId }: MemberActionsProps) => {
  const [organizationId, isAdmin] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.isAdmin ?? false,
  ]);

  const [showAssign, setShowAssign] = useState(false);

  if (!organizationId || !isAdmin) {
    return null;
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
            variant="ghost"
          >
            <MoreHorizontal className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onSelect={() => setShowAssign(true)}>
            Assign to member
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <AssignBivyStickFormDialog
        id={id}
        imei={imei}
        memberId={memberId}
        open={showAssign}
        organizationId={organizationId}
        setOpen={setShowAssign}
      />
    </>
  );
};

export { BivyStickActions };
