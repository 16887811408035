import {
  ApplicationRole,
  IncidentPriorityType,
  IncidentStatusType,
  MemberRole,
} from '@/gql/graphql';

const memberRole: Record<string, string> = {
  [MemberRole.Administrator]: 'Admin',
  [MemberRole.ExternalMonitoringAgent]: 'External',
  [MemberRole.Member]: 'Member',
  [MemberRole.Owner]: 'Owner',
  [MemberRole.Supervisor]: 'Supervisor',
  [MemberRole.Visualc3]: 'VisualC3',
};

const applicationRole: Record<string, string> = {
  [ApplicationRole.GlobalAdmin]: 'Admin',
  [ApplicationRole.User]: 'User',
};

const incidentPriority: Record<string, string> = {
  [IncidentPriorityType.High]: 'High',
  [IncidentPriorityType.Low]: 'Low',
  [IncidentPriorityType.Medium]: 'Medium',
  [IncidentPriorityType.Urgent]: 'Urgent',
};

const incidentStatus: Record<string, string> = {
  [IncidentStatusType.Closed]: 'Closed',
  [IncidentStatusType.InProgress]: 'In-Progress',
  'IN-PROGRESS': 'In-Progress',
  [IncidentStatusType.Open]: 'Open',
};

const formatMemberRole = (input: MemberRole | string) => {
  return memberRole[input.toUpperCase()] ?? '-invalid role-';
};

const formatApplicationRole = (input: ApplicationRole | string) => {
  return applicationRole[input.toUpperCase()] ?? '-invalid role-';
};

const formatIncidentPriority = (input: IncidentPriorityType | string) => {
  return incidentPriority[input.toUpperCase()] ?? '-invalid priority-';
};

const formatIncidentStatus = (input: IncidentStatusType | string) => {
  return incidentStatus[input.toUpperCase()] ?? '-invalid status-';
};

export {
  formatApplicationRole,
  formatIncidentPriority,
  formatIncidentStatus,
  formatMemberRole,
};
