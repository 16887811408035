import { RapidSosIntegrationForm } from '../components/RapidSosIntegrationForm';
import { Badge } from '@/components/ui/badge';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { OrganizationNotFoundError } from '@/lib/error';
import { useAppStore } from '@/stores';
import { ChevronRight } from 'lucide-react';

const RapidSosIntegration = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          GuardMe Settings <ChevronRight className="h-4 w-4" /> 911 Integration{' '}
          <Badge>Premium Feature</Badge>
        </CardTitle>
        <CardDescription>
          With 911 integration enabled a panic during a GuardMe session will
          allow 911 Emergency Services to be automatically connected with the
          individual in distress.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <RapidSosIntegrationForm organizationId={organizationId} />
      </CardContent>
    </Card>
  );
};

export { RapidSosIntegration };
