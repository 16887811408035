import { type FoundOrganization } from './Types';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import {
  boolean,
  type Infer,
  number,
  object,
  string,
  type Struct,
} from 'superstruct';
import { useMutation } from 'urql';

const SubmitMembershipRequestQuery = graphql(`
  mutation SubmitMembershipRequest($code: String!, $metadata: JSON!) {
    membershipRequestSubmit(input: { code: $code, publicMetadata: $metadata }) {
      membershipRequest {
        id
        organizationName
        createdAt
        updatedAt
      }
      query {
        currentUser {
          membershipRequests {
            totalCount
            nodes {
              id
              organizationName
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`);

type SubmitMembershipRequestProps = FoundOrganization & {
  onReset: () => void;
};

const SubmitMembershipRequest = ({
  code,
  logoUrl,
  name,
  publicFields,
  onReset,
}: SubmitMembershipRequestProps) => {
  const [{ error, fetching }, submitMembershipRequest] = useMutation(
    SubmitMembershipRequestQuery,
  );

  const dynamicSchema: Record<
    string,
    Struct<boolean, null> | Struct<number, null> | Struct<string, null>
  > = {};
  const defaultValues: Record<string, boolean | number | string> = {};

  for (const field of publicFields) {
    switch (field.type) {
      case 'BOOLEAN':
        dynamicSchema[field.name] = boolean();
        defaultValues[field.name] = field.defaultValue;
        break;
      case 'NUMBER':
        dynamicSchema[field.name] = number();
        defaultValues[field.name] = field.defaultValue ?? 0;
        break;
      case 'STRING':
        dynamicSchema[field.name] = string();
        defaultValues[field.name] = field.defaultValue ?? '';
        break;
      default:
      // noop
    }
  }

  const schema = object(dynamicSchema);

  const form = useForm<Infer<typeof schema>>({
    defaultValues,
    resolver: superstructResolver(schema),
  });

  const onSubmit = async (values: Infer<typeof schema>) => {
    await submitMembershipRequest({
      code,
      metadata: values,
    });

    form.reset();
    onReset();
  };

  return (
    <div className="mt-6">
      <div className="flex gap-2 items-center justify-start mb-4">
        <Avatar className="h-5 w-5">
          <AvatarImage
            alt={name}
            src={logoUrl}
          />
          <AvatarFallback>{name.slice(0, 1)}</AvatarFallback>
        </Avatar>
        <span className="text-2xl">{name}</span>
      </div>

      {publicFields.length > 0 ? (
        <p>
          This organization has required that you complete the following fields
          to request access. If you have any issue please contact an
          organization administrator.
        </p>
      ) : (
        <p>
          Select submit request to request access. If you have any issue please
          contact an organization administrator.
        </p>
      )}

      <Form {...form}>
        <form
          className="space-y-6"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          {publicFields.map((publicField) => {
            if (publicField.type === 'BOOLEAN') {
              return (
                <FormField
                  control={form.control}
                  key={publicField.name}
                  name={publicField.name}
                  render={({ field: { onChange, value } }) => (
                    <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md py-2">
                      <FormControl>
                        <Checkbox
                          checked={value as boolean}
                          onCheckedChange={onChange}
                        />
                      </FormControl>
                      <div className="space-y-1 leading-none">
                        <FormLabel>{publicField.name}</FormLabel>
                      </div>
                    </FormItem>
                  )}
                />
              );
            }

            if (publicField.type === 'STRING') {
              return (
                <FormField
                  control={form.control}
                  key={publicField.name}
                  name={publicField.name}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <FormItem>
                      <FormLabel>Username</FormLabel>
                      <FormControl>
                        <Input
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value as string}
                        />
                      </FormControl>
                      <FormDescription>
                        This is your public display name.
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              );
            }

            return null;
          })}

          <MutationError error={error} />

          <div className="flex items-center gap-2">
            <Button
              disabled={fetching}
              onClick={onReset}
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              disabled={fetching}
              type="submit"
            >
              {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
              Submit Request
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export { SubmitMembershipRequest };
