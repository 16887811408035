import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

const formatPhoneNumber = (input?: string | null | undefined): string => {
  if (!input) {
    return '--';
  }

  const cleaned = input.replaceAll(/\D/gu, '');
  // eslint-disable-next-line regexp/no-useless-quantifier, regexp/no-empty-alternative
  const match = /^(1|)?(\d{3})(\d{3})(\d{4})$/u.exec(cleaned);
  if (!match) {
    return '--';
  }

  const intlCode = match[1] ? '+1 ' : '';
  return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
};

const timeIsRecent = (
  input?: Date | number | string,
  withinSeconds: number = 300,
): boolean => {
  if (!input) {
    return false;
  }

  const date = input instanceof Date ? input : new Date(input);
  const now = new Date();
  return now.valueOf() - date.valueOf() < 1_000 * withinSeconds;
};

const phoneMatcher = /^\+[1-9]\d{1,14}$/u;

const emailMatcher =
  // eslint-disable-next-line unicorn/no-unsafe-regex
  /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/u;

const isEmailAddress = (input: string) => {
  if (input.length > 320) return false;
  return emailMatcher.test(input);
};

const isPhoneNumber = (input: string) => {
  if (input.length > 16) return false;
  return phoneMatcher.test(input);
};

const formatBytes = (bytes: number, decimals = 2) => {
  if (!Number(bytes)) return '0 Bytes';

  const kilo = 1_024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const index = Math.floor(Math.log(bytes) / Math.log(kilo));

  return `${Number.parseFloat((bytes / kilo ** index).toFixed(dm))} ${
    sizes[index]
  }`;
};

export {
  cn,
  formatBytes,
  formatPhoneNumber,
  isEmailAddress,
  isPhoneNumber,
  timeIsRecent,
};
