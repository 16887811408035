import { GoogleMapsContext, useMapsLibrary } from '@vis.gl/react-google-maps';
import {
  forwardRef,
  type Ref,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

type PolylineEventProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  readonly onClick?: (event: google.maps.MapMouseEvent) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly onDrag?: (event: google.maps.MapMouseEvent) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly onDragEnd?: (event: google.maps.MapMouseEvent) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly onDragStart?: (event: google.maps.MapMouseEvent) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly onMouseOut?: (event: google.maps.MapMouseEvent) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly onMouseOver?: (event: google.maps.MapMouseEvent) => void;
};

type PolylineCustomProps = {
  /**
   * this is an encoded string for the path, will be decoded and used as a path
   */
  // eslint-disable-next-line react/no-unused-prop-types
  readonly encodedPath?: string;
};

export type PolylineProps = google.maps.PolylineOptions &
  PolylineEventProps &
  PolylineCustomProps;

export type PolylineRef = Ref<google.maps.Polyline | null>;

const usePolyline = (props: PolylineProps) => {
  const {
    encodedPath,
    onClick,
    onDrag,
    onDragEnd,
    onDragStart,
    onMouseOut,
    onMouseOver,
    ...polylineOptions
  } = props;
  // This is here to avoid triggering the useEffect below when the callbacks change (which happen if the user didn't memoize them)
  const callbacks = useRef<Record<string, (event: unknown) => void>>({});
  Object.assign(callbacks.current, {
    onClick,
    onDrag,
    onDragEnd,
    onDragStart,
    onMouseOut,
    onMouseOver,
  });

  const geometryLibrary = useMapsLibrary('geometry');

  const polyline = useRef(new google.maps.Polyline()).current;
  // update PolylineOptions (note the dependencies aren't properly checked
  // here, we just assume that setOptions is smart enough to not waste a
  // lot of time updating values that didn't change)
  useMemo(() => {
    polyline.setOptions(polylineOptions);
  }, [polyline, polylineOptions]);

  const map = useContext(GoogleMapsContext)?.map;

  // update the path with the encodedPath
  useMemo(() => {
    if (!encodedPath || !geometryLibrary) return;
    const path = geometryLibrary.encoding.decodePath(encodedPath);
    polyline.setPath(path);
  }, [encodedPath, geometryLibrary, polyline]);

  // create polyline instance and add to the map once the map is available
  useEffect(() => {
    if (!map) {
      if (map === undefined)
        // eslint-disable-next-line no-console
        console.error('<Polyline> has to be inside a Map component.');

      return () => {};
    }

    polyline.setMap(map);

    return () => {
      polyline.setMap(null);
    };
  }, [map, polyline]);

  // attach and re-attach event-handlers when any of the properties change
  useEffect(() => {
    if (!polyline) return () => {};

    // Add event listeners
    const gme = google.maps.event;
    for (const [eventName, eventCallback] of [
      ['click', 'onClick'],
      ['drag', 'onDrag'],
      ['dragstart', 'onDragStart'],
      ['dragend', 'onDragEnd'],
      ['mouseover', 'onMouseOver'],
      ['mouseout', 'onMouseOut'],
    ]) {
      gme.addListener(
        polyline,
        eventName,
        (event: google.maps.MapMouseEvent) => {
          const callback = callbacks.current[eventCallback];
          if (callback) callback(event);
        },
      );
    }

    return () => {
      gme.clearInstanceListeners(polyline);
    };
  }, [polyline]);

  return polyline;
};

/**
 * Component to render a Google Maps polyline on a map
 */
export const Polyline = forwardRef((props: PolylineProps, ref: PolylineRef) => {
  const polyline = usePolyline(props);

  useImperativeHandle(ref, () => polyline, [polyline]);

  return null;
});
