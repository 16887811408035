import { Incident } from './routes/Incident';
import { IncidentNew } from './routes/IncidentNew';
import { Incidents } from './routes/Incidents';
import { type RouteObject } from 'react-router-dom';

export * from './components/IncidentsUpdates';
export * from './components/menu/IncidentPriorityMenuRadioGroup';
export * from './components/menu/IncidentStatusMenuRadioGroup';

export const incidentsRoutes: RouteObject[] = [
  {
    element: <Incidents />,
    path: '/incidents',
  },
  {
    element: <Incident />,
    path: '/incidents/:id',
  },
  {
    element: <IncidentNew />,
    path: '/incidents/new',
  },
];
