import { useState } from 'react';

const useIncidentsAssignedToMeTableControls = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const offset = (page - 1) * pageSize;

  return {
    offset,
    page,
    pageSize,
    setPage,
    setPageSize,
  };
};

export { useIncidentsAssignedToMeTableControls };
