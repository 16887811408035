import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { graphql } from '@/gql';
import { IncidentPriorityType } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import {
  ChevronDown,
  ChevronsDown,
  ChevronsUp,
  Flame,
  Minus,
} from 'lucide-react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

const EditIncidentPriorityDropdownGql = graphql(`
  mutation EditIncidentPriorityDropdownGql(
    $input: UpdateIncidentPriorityInput!
  ) {
    updateIncidentPriority(input: $input) {
      incident {
        id
        priority
      }
    }
  }
`);

type Priority = {
  icon: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & { title?: string; titleId?: string }
  >;
  label: string;
  value: IncidentPriorityType;
};

const priorities: Priority[] = [
  {
    icon: Flame,
    label: 'Urgent',
    value: IncidentPriorityType.Urgent,
  },
  {
    icon: ChevronsUp,
    label: 'High',
    value: IncidentPriorityType.High,
  },
  {
    icon: Minus,
    label: 'Medium',
    value: IncidentPriorityType.Medium,
  },
  {
    icon: ChevronsDown,
    label: 'Low',
    value: IncidentPriorityType.Low,
  },
];

type EditIncidentPriorityDropdownProps = {
  readonly incidentId: string;
  readonly onComplete?: () => void;
  readonly priority: IncidentPriorityType;
  readonly readonly: boolean;
};

const EditIncidentPriorityDropdown = ({
  incidentId,
  onComplete,
  priority,
  readonly,
}: EditIncidentPriorityDropdownProps) => {
  const [open, setOpen] = useState(false);
  const selectedPriority = priorities.find((item) => item.value === priority);

  const [, updatePriority] = useMutation(EditIncidentPriorityDropdownGql);

  const handleSelect = useCallback(
    async (newValue: IncidentPriorityType) => {
      if (priority !== newValue) {
        await updatePriority({
          input: {
            incidentId,
            priority: newValue,
          },
        });

        onComplete?.();
      }

      setOpen(false);
    },
    [incidentId, onComplete, priority, setOpen, updatePriority],
  );

  if (readonly) {
    return (
      <div className="inline-flex items-center justify-center rounded-md text-sm font-medium h-9 rounded-md px-3 border border-input bg-background">
        {selectedPriority ? (
          <span className="flex items-center">
            <selectedPriority.icon className="mr-1 h-4 w-4 shrink-0" />
            {selectedPriority.label}
          </span>
        ) : (
          <>+ Set priority</>
        )}
      </div>
    );
  }

  return (
    <Popover
      onOpenChange={setOpen}
      open={open}
    >
      <PopoverTrigger asChild>
        <Button
          className="justify-between group"
          size="sm"
          variant="outline"
        >
          {selectedPriority ? (
            <span className="flex items-center">
              <selectedPriority.icon className="mr-1 h-4 w-4 shrink-0" />
              {selectedPriority.label}
            </span>
          ) : (
            <>+ Set priority</>
          )}
          <ChevronDown className="ml-4 h-4 w-4 shrink-0 opacity-25 group-hover:opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="p-0"
        side="bottom"
      >
        <Command>
          <CommandInput placeholder="Filter priority..." />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {priorities.map((item) => (
                <CommandItem
                  key={item.value}
                  onSelect={async () => await handleSelect(item.value)}
                  value={item.label}
                >
                  <item.icon
                    className={cn(
                      'mr-2 h-4 w-4',
                      item.value === selectedPriority?.value
                        ? 'opacity-100'
                        : 'opacity-40',
                    )}
                  />
                  <span>{item.label}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export { EditIncidentPriorityDropdown };
