import { Empty } from '@/components/Empty';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  type EmergencyContact,
  type Member,
  type Organization,
} from '@/gql/graphql';
import { parseMemberMetadata } from '@/lib/metadata';
import { fmtPhoneNumber } from '@/utils';
import { Eye, Mail, Phone } from 'lucide-react';

type GuardMeMemberDetailsProps = Pick<
  Member,
  | 'avatarUrl'
  | 'displayName'
  | 'emailAddress'
  | 'fullName'
  | 'phoneNumber'
  | 'metadata'
  | 'timezone'
> & {
  className?: string;
  emergencyContacts: {
    nodes: Array<Pick<EmergencyContact, 'description' | 'id' | 'phoneNumber'>>;
  };
  fetching: boolean;
  memberMetadataFields: Pick<Organization, 'memberMetadataFields'>;
};

const GuardMeMemberDetails = ({
  avatarUrl,
  className,
  displayName,
  emailAddress,
  emergencyContacts,
  fetching,
  fullName,
  memberMetadataFields,
  metadata,
  phoneNumber,
  timezone,
}: Partial<GuardMeMemberDetailsProps>) => {
  const metadataData = parseMemberMetadata(memberMetadataFields, metadata);

  return (
    <Card className={className}>
      <CardHeader className="pb-2">
        <CardDescription>Member Info</CardDescription>
        <CardTitle className="flex">
          {fetching ? (
            <>
              <Skeleton className="h-10 w-10 rounded-full mr-2" />
              <Skeleton className="h-10 w-48" />
            </>
          ) : (
            <>
              <Avatar className="mr-2">
                <AvatarImage src={avatarUrl ?? undefined} />
                <AvatarFallback>
                  {(displayName ?? fullName ?? 'Deleted Member').slice(0, 2)}
                </AvatarFallback>
              </Avatar>
              <span className="text-4xl">
                {displayName ?? fullName ?? 'Deleted Member'}
              </span>
            </>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent className="text-sm pt-4">
        <div className="grid gap-4 lg:gap-8 lg:grid-cols-2">
          <div className="flex flex-col gap-3">
            <div className="text-sm font-semibold">Contact Information</div>
            <dl className="grid gap-3">
              <div className="flex items-center justify-between">
                <dt className="flex items-center gap-1 text-muted-foreground">
                  <Mail className="h-4 w-4" /> Email
                </dt>
                <dd>
                  {fetching ? (
                    <Skeleton className="h-8 w-48" />
                  ) : (
                    <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                  )}
                </dd>
              </div>
              <div className="flex items-center justify-between">
                <dt className="flex items-center gap-1 text-muted-foreground">
                  <Phone className="h-4 w-4" />
                  Phone
                </dt>
                <dd>
                  {fetching ? (
                    <Skeleton className="h-8 w-48" />
                  ) : (
                    <a href={`tel:${phoneNumber}`}>
                      {fmtPhoneNumber(phoneNumber)}
                    </a>
                  )}
                </dd>
              </div>
            </dl>

            <Separator />

            <div className="text-sm font-semibold">Emergency Contacts</div>

            {fetching && <Skeleton className="h-12 w-full" />}

            {!fetching &&
              emergencyContacts &&
              emergencyContacts.nodes.length === 0 && (
                <Empty className="text-muted-foreground">No Contacts</Empty>
              )}

            {!fetching &&
              emergencyContacts &&
              emergencyContacts.nodes.length > 0 && (
                <dl className="grid gap-3">
                  {emergencyContacts?.nodes.map((item) => (
                    <div
                      className="flex items-center justify-between"
                      key={item.id}
                    >
                      <dt className="flex items-center gap-1 text-muted-foreground">
                        {item.description}
                      </dt>
                      <dd>
                        <a href={`tel:${item.phoneNumber}`}>
                          {fmtPhoneNumber(item.phoneNumber)}
                        </a>
                      </dd>
                    </div>
                  ))}
                </dl>
              )}
          </div>

          <div className="flex flex-col gap-3">
            <div className="text-sm font-semibold">Metadata</div>
            <dl className="grid gap-3">
              <div className="flex items-center justify-between">
                <dt className="flex items-center gap-1 text-muted-foreground">
                  Timezone
                </dt>
                <dd>{timezone}</dd>
              </div>
              {metadataData.map((item) => (
                <div
                  className="flex items-center justify-between"
                  key={item.id}
                >
                  <dt className="flex items-center gap-1 text-muted-foreground">
                    {item.name}
                    {item.isPrivate && (
                      <Tooltip>
                        <TooltipTrigger className="ml-1">
                          <Eye className="h-4 w-4 text-info" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Private field</p>
                        </TooltipContent>
                      </Tooltip>
                    )}
                  </dt>
                  <dd>{item.value || '---'}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export { GuardMeMemberDetails };
