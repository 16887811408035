import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { GuardMeStatus } from '@/features/GuardMe';
import { GuardMeProgress } from '@/features/GuardMe/components/GuardMeProgress';
import { graphql } from '@/gql';
import { GuardmeStatus } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { fmtPhoneNumber } from '@/utils';
import {
  ArrowRight,
  BatteryFull,
  CircleAlert,
  Paperclip,
  Phone,
  ShieldAlert,
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const ActiveGuardMeListGql = graphql(`
  query ActiveGuardMeListGql($organizationId: String!) {
    activeGuardmes(organizationId: $organizationId) {
      nodes {
        id
        shortId
        memberId
        member {
          id
          avatarUrl
          displayName
          fullName
          phoneNumber
        }
        status
        createdAt
        endsAt
        updatedAt
        batteryLevel
        totalAttachments
      }
    }
  }
`);

type Props = {
  readonly organizationId: string;
};

const ActiveGuardMeList = ({ organizationId }: Props) => {
  const [{ data }] = useQuery({
    query: ActiveGuardMeListGql,
    variables: {
      organizationId,
    },
  });

  let totalCount = 0;
  let expiredCount = 0;
  let panicCount = 0;
  for (const node of data?.activeGuardmes?.nodes ?? []) {
    if (node.status === GuardmeStatus.Expired) {
      expiredCount += 1;
    } else if (node.status === GuardmeStatus.InPanic) {
      panicCount += 1;
    }

    totalCount += 1;
  }

  return (
    <Card>
      <CardHeader className="p-4">
        <CardTitle className="flex items-baseline">
          Active GuardMe Sessions
          <div className="ml-auto text-base flex items-center">
            <Tooltip>
              <TooltipTrigger asChild>
                <div
                  className={cn(
                    'flex items-center gap-1 px-2',
                    expiredCount > 1 &&
                      'rounded-xl border bg-warning text-warning-foreground',
                  )}
                >
                  <CircleAlert className="w-4 h-4" />
                  {expiredCount.toLocaleString()}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p className="font-normal">Expired</p>
              </TooltipContent>
            </Tooltip>

            <Separator
              className="mx-2 h-6 w-px"
              orientation="vertical"
            />

            <Tooltip>
              <TooltipTrigger asChild>
                <div
                  className={cn(
                    'flex items-center gap-1 px-2',
                    panicCount > 0 &&
                      'rounded-xl border bg-destructive text-destructive-foreground',
                  )}
                >
                  <ShieldAlert className="w-4 h-4" />
                  {panicCount.toLocaleString()}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p className="font-normal">In Panic</p>
              </TooltipContent>
            </Tooltip>
          </div>
        </CardTitle>
        <CardDescription>
          There {totalCount === 1 ? 'is' : 'are'} currently{' '}
          {totalCount.toLocaleString()} active{' '}
          {totalCount === 1 ? 'session' : 'sessions'}.
        </CardDescription>
      </CardHeader>
      {totalCount > 0 && (
        <CardContent className="grid gap-6">
          {data?.activeGuardmes?.nodes.map((item) => (
            <div
              className="flex items-center gap-3"
              key={item.id}
            >
              <Avatar className="h-16 w-16">
                <AvatarImage src={item.member?.avatarUrl ?? undefined} />
                <AvatarFallback>
                  {item.member?.fullName.slice(0, 2)}
                </AvatarFallback>
              </Avatar>

              <div className="grid auto-rows-min gap-2 w-full">
                <div className="flex items-baseline gap-1 text-xl font-bold tabular-nums leading-none">
                  <div className="flex items-center gap-2">
                    {item.member?.displayName ?? item.member?.fullName}
                  </div>
                  <span className="ml-2 text-sm font-normal text-muted-foreground flex">
                    <div className="flex items-center gap-1">
                      <Phone className="h-3 w-3" />
                      {fmtPhoneNumber(item.member?.phoneNumber)}
                    </div>

                    <div className="ml-3 flex items-center gap-1">
                      <BatteryFull className="h-4 w-4" />{' '}
                      {item.batteryLevel ?? '?'}
                    </div>
                    <div className="ml-3 flex items-center gap-1">
                      <Paperclip className="h-3 w-3" />{' '}
                      {item.totalAttachments ?? '0'}
                    </div>
                  </span>
                  <div className="ml-auto text-sm font-normal">
                    <Link
                      className="flex items-center group hover:text-primary border rounded-xl px-2"
                      to={`/guardme/${item.id}`}
                    >
                      {item.shortId}
                      <ArrowRight className="ml-1 group-hover:ml-1.5 h-4 w-4" />
                    </Link>
                  </div>
                </div>
                <div className="flex">
                  {item.status === GuardmeStatus.InProgress ? (
                    <GuardMeProgress
                      className="h-[28px] rounded-lg"
                      createdAt={item.createdAt}
                      endsAt={item.endsAt}
                      status={item.status}
                    />
                  ) : (
                    <GuardMeStatus
                      className="w-full"
                      endsAt={item.endsAt}
                      status={item.status}
                      updatedAt={item.updatedAt}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </CardContent>
      )}
    </Card>
  );
};

export { ActiveGuardMeList };
