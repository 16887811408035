import { useCheckInTableControls } from '../hooks/useCheckInTableControls';
import { DateRangePicker } from '@/components/DateRangePicker';
import { Datetime } from '@/components/Datetime';
import { Loader } from '@/components/Loader';
import { Content, Header, Title } from '@/components/Route';
import { TableSearch } from '@/components/TableSearch';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  TableControls,
  TableControlSection,
  TablePagnination,
  TableRowSkeleton,
  TableWrapper,
} from '@/components/ui/TableWrapper';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { FileCheck } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

const CheckInsGql = graphql(`
  query CheckInsGql(
    $first: Int!
    $offset: Int!
    $organizationId: String!
    $shortId: String
    $createdAt_gte: Datetime
    $createdAt_lte: Datetime
    $memberId: String
  ) {
    checkIns(
      first: $first
      offset: $offset
      condition: {
        createdAt: { gte: $createdAt_gte, lte: $createdAt_lte }
        organizationId: $organizationId
        shortId: $shortId
        memberId: $memberId
      }
      orderBy: ID_DESC
    ) {
      totalCount
      nodes {
        id
        shortId
        createdAt
        memberId
        member {
          id
          fullName
          displayName
          avatarUrl
        }
        places {
          nodes {
            id
            name
            spatialData {
              geojson
            }
            radius
            center {
              longitude
              latitude
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

const CheckIns = () => {
  const [organizationId, memberId, isSupervisor] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.memberId,
    state.activeMembership?.isCheckInSupervisor,
  ]);

  if (!organizationId || !memberId) {
    throw new OrganizationNotFoundError();
  }

  const navigate = useNavigate();

  const controls = useCheckInTableControls();

  const [{ data, error, fetching }] = useQuery({
    query: CheckInsGql,
    requestPolicy: 'cache-and-network',
    variables: {
      createdAt_gte: controls.from?.toISOString(),
      createdAt_lte: controls.to?.toISOString(),
      first: controls.pageSize,
      memberId: undefined,
      offset: controls.offset,
      organizationId,
      shortId: controls.search,
    },
  });

  const handleRowClick = (entityId: string) => {
    navigate(`/check-in/${entityId}`);
  };

  return (
    <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
      <div>
        <div className="flex items-center">
          <TableSearch
            className="h-8"
            placeholder="Search by Id..."
            setSearchValue={controls.setSearch}
          />
          <div className="ml-auto flex items-center gap-2">
            <DateRangePicker
              className="h-8"
              dateRange={controls.dateRange}
              setDateRange={controls.setDateRange}
            />
            {isSupervisor && (
              <Link to="/check-in-reports">
                <Button
                  className="h-8 gap-1"
                  size="sm"
                >
                  <FileCheck className="h-3.5 w-3.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Check In Reports
                  </span>
                </Button>
              </Link>
            )}
          </div>
        </div>

        <div className="mt-2">
          <Card x-chunk="guardme-sessions">
            <CardHeader>
              <CardTitle>Check In</CardTitle>
              <CardDescription>
                View your organization member's reported locations.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow className="hover:bg-inherit">
                    <TableHead>Id</TableHead>
                    <TableHead>Member</TableHead>
                    <TableHead>Created</TableHead>
                    <TableHead>Place</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {fetching && !data && (
                    <TableRowSkeleton
                      cols={4}
                      rows={controls.pageSize}
                    />
                  )}
                  {!fetching && data?.checkIns?.nodes.length === 0 && (
                    <TableRow className="last:border-none hover:bg-inherit">
                      <TableCell
                        className="text-center h-24"
                        colSpan={8}
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                  {!fetching &&
                    data?.checkIns?.nodes.map((item) => (
                      <TableRow
                        key={item.id}
                        onClick={() => handleRowClick(item.id)}
                      >
                        <TableCell className="font-bold whitespace-nowrap">
                          <Link
                            className="hover:underline"
                            to={`/check-in/${item.id}`}
                          >
                            {item.shortId}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center gap-2">
                            <Avatar className="h-6 w-6">
                              <AvatarImage
                                src={item.member?.avatarUrl ?? undefined}
                              />
                              <AvatarFallback>
                                {item.member?.fullName.slice(0, 2)}
                              </AvatarFallback>
                            </Avatar>
                            <div className="text-sm">
                              {item.member?.displayName ??
                                item.member?.fullName}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Datetime datetime={item.createdAt} />
                        </TableCell>
                        <TableCell>
                          {item.places.nodes.map((place) => (
                            <Badge
                              key={place.id}
                              variant="secondary"
                            >
                              {place.name}
                            </Badge>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </CardContent>
            <CardFooter>
              <TablePagnination
                currentPage={controls.page}
                getSearchParameters={controls.getPageSearchParameters}
                pageSize={controls.pageSize}
                totalCount={data?.checkIns?.totalCount}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Header>
        <div>
          <Title>Check In</Title>
        </div>
        <div />
      </Header>

      <Content>
        <section>
          <Loader loadingError={error}>
            <TableControls>
              <TableControlSection>
                <TableSearch setSearchValue={controls.setSearch} />
              </TableControlSection>
              <TableControlSection className="justify-end">
                <DateRangePicker
                  dateRange={controls.dateRange}
                  setDateRange={controls.setDateRange}
                />
              </TableControlSection>
            </TableControls>

            <TableWrapper />
          </Loader>
        </section>
      </Content>
    </>
  );
};

export { CheckIns };
