import { MemberList } from '../components/MemberList';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';

const Members = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  return <MemberList organizationId={organizationId} />;
};

export { Members };
