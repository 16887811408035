import { forwardRef, type HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type TableRowProps = HTMLAttributes<HTMLTableRowElement>;

const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  (props, ref) => {
    const { children, className, ...extra } = props;

    return (
      <tr
        {...extra}
        className={twMerge(className)}
        ref={ref}
      >
        {children}
      </tr>
    );
  },
);

export { TableRow };
