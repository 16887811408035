import { GuardMeConcludeDialog } from './GuardMeConcludeDialog';
import { Button } from '@/components/ui/button';
import { Check } from 'lucide-react';

type Props = {
  guardmeId: string;
};

const GuardMeConcludeButton = ({ guardmeId }: Props) => {
  return (
    <GuardMeConcludeDialog guardmeId={guardmeId}>
      <Button
        size="sm"
        variant="default"
      >
        <Check className="h-5 w-5 mr-2" />
        Conclude
      </Button>
    </GuardMeConcludeDialog>
  );
};

export { GuardMeConcludeButton };
