import {
  AdvancedMarker,
  Map,
  MapBoundsHandler,
  MapProvider,
} from '@/components/Map';
import { PlaceAutocomplete } from '@/components/Map/PlaceAutocomplete';
import { ControlPosition, MapControl } from '@vis.gl/react-google-maps';
import { useState } from 'react';

type LocationSelectProps = {
  onChange: (newValue: { lat: number; lng: number }) => void;
  value: { lat: number; lng: number };
};

const LocationSelect = ({ value: position, onChange }: LocationSelectProps) => {
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  const handleOnPlaceSelect = (
    newValue: google.maps.places.PlaceResult | null,
  ) => {
    if (newValue?.geometry?.location) {
      onChange({
        lat: newValue?.geometry?.location.lat(),
        lng: newValue?.geometry?.location.lng(),
      });
    }

    setSelectedPlace(newValue);
  };

  const handleOnDragEnd = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      onChange({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    }
  };

  return (
    <div>
      <MapProvider>
        <Map
          className="h-96"
          defaultCenter={position}
          defaultZoom={16}
        >
          <AdvancedMarker
            draggable
            onDragEnd={handleOnDragEnd}
            position={position}
          />
        </Map>

        <MapControl position={ControlPosition.TOP_LEFT}>
          <div className="autocomplete-control p-2">
            <PlaceAutocomplete onPlaceSelect={handleOnPlaceSelect} />
          </div>
        </MapControl>

        <MapBoundsHandler bounds={selectedPlace?.geometry?.viewport} />
      </MapProvider>
    </div>
  );
};

export { LocationSelect };
