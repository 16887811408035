import { ApplicationBreadcrumbs } from './ApplicationBreadcrumbs';
import { NotificationsMenuItem } from './NotificationsMenuItem';
import { PendingInvitationsCallout } from './PendingInvitationsCallout';
import { ThemeToggleDropdown } from './ThemeToggleDropdown';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { AccountMenu } from '@/features/Account/components/AccountMenu';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores';
import {
  Flag,
  Home,
  Map,
  PanelLeft,
  Pin,
  Settings,
  ShieldBan,
  ShieldCheck,
  Users,
} from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const ApplicationHeader = () => {
  const [
    currentUser,
    isGlobalAdmin,
    organizationId,
    isOrganizationAdmin,
    isCheckInSupervisor,
  ] = useAppStore((store) => [
    store.currentUser,
    store.isGlobalAdmin,
    store.activeMembership?.id,
    store.activeMembership?.isAdmin,
    store.activeMembership?.isCheckInSupervisor,
  ]);

  const pathname = useLocation().pathname;

  return (
    <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 md:static md:h-auto md:border-0 md:bg-transparent md:px-6">
      <Sheet>
        <SheetTrigger asChild>
          <Button
            className="md:hidden"
            size="icon"
            variant="outline"
          >
            <PanelLeft className="h-5 w-5" />
            <span className="sr-only">Toggle Menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent
          className="md:max-w-xs"
          side="left"
        >
          <nav className="grid gap-6 text-lg font-medium">
            <AccountMenu
              className="h-9 w-9 md:h-8 md:w-8"
              isCollapsed={false}
            />

            <NotificationsMenuItem isCollapsed />

            <Link
              className={cn(
                'flex items-center gap-4 px-2.5',
                pathname === '/home'
                  ? 'text-foreground'
                  : 'text-muted-foreground hover:text-foreground',
              )}
              to="/home"
            >
              <Home className="h-5 w-5" />
              Dashboard
            </Link>
            {organizationId && (
              <>
                <Link
                  className={cn(
                    'flex items-center gap-4 px-2.5',
                    pathname === '/guardme'
                      ? 'text-foreground'
                      : 'text-muted-foreground hover:text-foreground',
                  )}
                  to="/guardme"
                >
                  <ShieldCheck className="h-5 w-5" />
                  GuardMe
                </Link>
                <Link
                  className={cn(
                    'flex items-center gap-4 px-2.5',
                    pathname === '/incidents'
                      ? 'text-foreground'
                      : 'text-muted-foreground hover:text-foreground',
                  )}
                  to="/incidents"
                >
                  <Flag className="h-5 w-5" />
                  Incidents
                </Link>
                <Link
                  className={cn(
                    'flex items-center gap-4 px-2.5',
                    pathname === '/check-in'
                      ? 'text-foreground'
                      : 'text-muted-foreground hover:text-foreground',
                  )}
                  to="/check-in"
                >
                  <Pin className="h-5 w-5" />
                  Check In
                </Link>
                {isCheckInSupervisor && (
                  <Link
                    className={cn(
                      'flex items-center gap-4 px-2.5',
                      pathname === '/check-in-reports'
                        ? 'text-foreground'
                        : 'text-muted-foreground hover:text-foreground',
                    )}
                    to="/check-in-reports"
                  >
                    <Map className="h-5 w-5" />
                    Check In Reports
                  </Link>
                )}
                <Link
                  className={cn(
                    'flex items-center gap-4 px-2.5',
                    pathname === '/members'
                      ? 'text-foreground'
                      : 'text-muted-foreground hover:text-foreground',
                  )}
                  to="/members"
                >
                  <Users className="h-5 w-5" />
                  Members
                </Link>
                {isOrganizationAdmin && (
                  <Link
                    className={cn(
                      'flex items-center gap-4 px-2.5',
                      pathname === '/settings'
                        ? 'text-foreground'
                        : 'text-muted-foreground hover:text-foreground',
                    )}
                    to="/settings"
                  >
                    <Settings className="h-5 w-5" />
                    Settings
                  </Link>
                )}
              </>
            )}
            {isGlobalAdmin && (
              <Link
                className={cn(
                  'flex items-center gap-4 px-2.5',
                  pathname === '/administration'
                    ? 'text-foreground'
                    : 'text-muted-foreground hover:text-foreground',
                )}
                to="/administration"
              >
                <ShieldBan className="h-5 w-5" />
                Administration
              </Link>
            )}
          </nav>
        </SheetContent>
      </Sheet>
      <ApplicationBreadcrumbs />
      <div className="relative ml-auto flex-1 md:grow-0">
        {/* 
        <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
          placeholder="Search..."
          type="search"
        />
        */}
      </div>
      <PendingInvitationsCallout />
      <ThemeToggleDropdown />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="overflow-hidden rounded-full p-0"
            size="icon"
            variant="outline"
          >
            <Avatar className="h-9 w-9 rounded-full">
              <AvatarImage
                alt={currentUser.fullName}
                src={currentUser.avatarUrl}
              />
              <AvatarFallback>
                {currentUser.fullName.slice(0, 2)}
              </AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>My Account</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem asChild>
            <Link to="/account/profile">Profile</Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/account/contact">Contact Details</Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/account/memberships">Memberships</Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/account/notifications">Notifications</Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem asChild>
            <Link to="/auth/signout">Logout</Link>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </header>
  );
};

export { ApplicationHeader };
