import { config } from '../config';
import { Auth0Provider } from '@auth0/auth0-react';
import { type ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

const AuthenticationProvider = (props: Props) => {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      authorizationParams={{
        audience: config.AUTH0_AUDIENCE,
        redirect_uri: config.AUTH0_REDIRECT_URI,
        scope: config.AUTH0_SCOPE,
      }}
      cacheLocation="localstorage"
      clientId={config.AUTH0_CLIENT_ID}
      domain={config.AUTH0_DOMAIN}
      onRedirectCallback={(appState) => {
        navigate(appState?.returnTo ?? '/dashboard');
      }}
      useRefreshTokens
    >
      {props.children}
    </Auth0Provider>
  );
};

export { AuthenticationProvider };
