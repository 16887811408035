import { GuardMeConcludeButton } from './buttons/GuardMeConcludeButton';
import { GuardMeExtendButton } from './buttons/GuardMeExtendButton';
import { GuardMePanicButton } from './buttons/GuardMePanicButton';
import { graphql } from '@/gql';
import { datetime } from '@/utils';
import { useQuery } from 'urql';

const GuardMeActionsQuery = graphql(`
  query GuardMeActions($id: String!) {
    guardme(id: $id) {
      id
      endsAt
      isActive
      isSafe
      isExpired
      memberId
      organization {
        id
        settingIsRapidsosPanicButtonFlowEnabled
        settingIsRapidsosOperatorFlowEnabled
        settingRapidsosSocPhoneNumber
      }
    }
  }
`);

type GuardMeActionsProps = {
  readonly guardmeId: string;
};

const GuardMeActions = ({ guardmeId }: GuardMeActionsProps) => {
  const [{ data, fetching }] = useQuery({
    query: GuardMeActionsQuery,
    variables: {
      id: guardmeId,
    },
  });

  if (fetching) {
    return null;
  }

  let status = 'Concluded';
  if (data?.guardme?.isActive) {
    status = 'In Progress';
  }

  if (data?.guardme?.isActive && !data?.guardme?.isSafe) {
    status = 'Panic';
  }

  if (
    data?.guardme?.isActive &&
    data?.guardme?.isSafe &&
    data?.guardme?.isExpired
  ) {
    status = 'Expired';
  }

  const showConcludeButton = !['Concluded'].includes(status);

  const showExtendButton = ['In Progress', 'Expired'].includes(status);

  const showPanicButton = ['In Progress', 'Expired'].includes(status);

  if (!showConcludeButton && !showExtendButton && !showPanicButton) {
    return null;
  }

  return (
    <>
      {showExtendButton && (
        <GuardMeExtendButton
          currentEndsAt={datetime(data?.guardme?.endsAt)}
          memberId={data?.guardme?.memberId}
        />
      )}
      {showConcludeButton && <GuardMeConcludeButton guardmeId={guardmeId} />}
      {showPanicButton && (
        <GuardMePanicButton
          guardmeId={guardmeId}
          organization={data?.guardme?.organization}
        />
      )}
    </>
  );
};

export { GuardMeActions };
