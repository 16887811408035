import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { Trash } from 'lucide-react';
import { useMutation } from 'urql';

const DeleteMembershipRequestsCodeDialogGql = graphql(`
  mutation DeleteMembershipRequestsCodeDialogGql($id: String!) {
    deleteMembershipCode(input: { id: $id }) {
      organization {
        id
        membershipCodes {
          totalCount
          nodes {
            id
            organizationRole
            groupIds
            code
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`);

type Props = {
  id: string;
  isAdmin?: boolean;
};

const DeleteMembershipRequestsCodeDialog = ({ id, isAdmin }: Props) => {
  const [, deleteCode] = useMutation(DeleteMembershipRequestsCodeDialogGql);

  if (!isAdmin) {
    return null;
  }

  const handleDelete = async () => {
    await deleteCode({
      id,
    });
  };

  return (
    <Button
      className="text-red-500 hover:text-red-800"
      onClick={handleDelete}
      variant="link"
    >
      <Trash className="h-4 w-4" />
    </Button>
  );
};

export { DeleteMembershipRequestsCodeDialog };
