import { type Size } from '../constants';
import LogoIcon from '@/assets/images/logo/icon/128_128.svg';
import { cn } from '@/lib/utils';
import { forwardRef, type HtmlHTMLAttributes } from 'react';

const spinnerSizes: Record<Size, string> = {
  lg: 'h-32 w-32',
  md: 'h-8 w-8',
  sm: 'h-6 w-6',
  xl: 'h-48 w-48',
  xs: 'h-4 w-4',
} as const;

type SpinnerProps = HtmlHTMLAttributes<HTMLDivElement> & {
  readonly fullscreen?: boolean;
  readonly logo?: boolean;
  readonly size?: Size;
};

const Spinner = forwardRef<HTMLDivElement, SpinnerProps>((props, ref) => {
  const {
    className,
    fullscreen = false,
    logo = false,
    size = 'sm',
    ...extra
  } = props;

  if (logo && fullscreen) {
    return (
      <div
        {...extra}
        className="grow flex items-center justify-center"
        ref={ref}
      >
        <img
          alt="loading..."
          className={cn('animate-pulse inline-block', 'bg-card', 'w-32 h-32')}
          src={LogoIcon}
        />
      </div>
    );
  }

  if (logo) {
    return (
      <div
        {...extra}
        ref={ref}
      >
        <img
          alt="loading..."
          className={cn(
            'animate-pulse inline-block',
            'bg-card',
            spinnerSizes[size],
          )}
          src={LogoIcon}
        />
      </div>
    );
  }

  if (fullscreen) {
    return (
      <div
        {...extra}
        className="grow flex items-center justify-center"
        ref={ref}
      >
        <div
          className={cn(
            'w-32 h-32',
            'border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-primary',
            className,
          )}
          {...extra}
        >
          <span className="sr-only">Loading</span>
        </div>
      </div>
    );
  }

  return (
    <div
      {...extra}
      className="mx-auto flex gap-2 items-center justify-center"
      ref={ref}
    >
      <div
        className={cn(
          spinnerSizes[size],
          'border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-primary',
          className,
        )}
      />
      <span className="sr-only">Loading</span>
    </div>
  );
});

export { Spinner };
