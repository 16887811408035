import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { MemberRole } from '@/gql/graphql';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader, Plus } from 'lucide-react';
import { type HTMLAttributes, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  array,
  boolean,
  type Infer,
  nonempty,
  object,
  size,
  string,
} from 'superstruct';
import { useMutation } from 'urql';

const NewMembershipCodeGql = graphql(`
  mutation NewMembershipCodeGql(
    $organizationId: String!
    $code: String!
    $organizationRole: MemberRole!
    $groupsIds: [String!]!
    $requiresApproval: Boolean!
  ) {
    createMembershipCode(
      input: {
        organizationId: $organizationId
        code: $code
        organizationRole: $organizationRole
        groupIds: $groupsIds
        requiresApproval: $requiresApproval
      }
    ) {
      organization {
        id
        membershipCodes {
          totalCount
          nodes {
            id
            organizationRole
            groupIds
            code
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`);

const schema = object({
  code: size(string(), 3, 14),
  groupIds: array(string()),
  requiresApproval: boolean(),
  role: nonempty(string()),
});

type AddMembershipCodeDialogProps = HTMLAttributes<HTMLDivElement> & {
  readonly groups: Array<{
    id: string;
    name: string;
  }>;
  readonly isAdmin?: boolean;
  readonly onComplete?: () => void;
  readonly organizationId: string;
};

const NewMembershipCodeDialog = ({
  groups,
  isAdmin,
  onComplete,
  organizationId,
}: AddMembershipCodeDialogProps) => {
  const [open, setOpen] = useState(false);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      code: '',
      groupIds: [],
      requiresApproval: true,
      role: MemberRole.Member,
    },
    resolver: superstructResolver(schema),
  });

  const [{ error, fetching }, createMembershipCode] =
    useMutation(NewMembershipCodeGql);

  const onCancel = () => {
    form.reset();
    setOpen(false);
  };

  const onSubmit = async (values: Infer<typeof schema>) => {
    if (organizationId) {
      const response = await createMembershipCode({
        code: values.code,
        groupsIds: values.groupIds,
        organizationId,
        organizationRole: values.role as MemberRole,
        requiresApproval: values.requiresApproval,
      });

      if (!response.error) {
        form.reset();
        setOpen(false);
        onComplete?.();
      }
    }
  };

  return (
    <Dialog
      onOpenChange={setOpen}
      open={open}
    >
      <DialogTrigger asChild>
        <Button disabled={!isAdmin}>
          <Plus className="h-6 w-6 mr-2" /> New Membership Code
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>New Membership Code</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            className="space-y-6"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Membership Code</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="code"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    3-14 characters, avoiding special characters. Codes are case
                    insensitive.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            {groups.length > 0 && (
              <FormField
                control={form.control}
                name="groupIds"
                render={() => (
                  <FormItem>
                    <FormLabel className="mb-2">Groups</FormLabel>
                    {groups.map((item) => (
                      <FormField
                        control={form.control}
                        key={item.id}
                        name="groupIds"
                        render={({ field }) => {
                          return (
                            <FormItem
                              className="flex flex-row items-start space-x-3 space-y-0"
                              key={item.id}
                            >
                              <FormControl>
                                <Checkbox
                                  checked={field.value?.includes(item.id)}
                                  onCheckedChange={(checked) => {
                                    return checked
                                      ? field.onChange([
                                          ...field.value,
                                          item.id,
                                        ])
                                      : field.onChange(
                                          field.value?.filter(
                                            (value) => value !== item.id,
                                          ),
                                        );
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="font-normal">
                                {item.name}
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                    ))}
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="mb-2">Membership Role</FormLabel>
                  <FormControl>
                    <RadioGroup
                      className="flex flex-col space-y-0"
                      defaultValue={field.value}
                      // eslint-disable-next-line react/jsx-handler-names
                      onValueChange={field.onChange}
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={MemberRole.Member} />
                        </FormControl>
                        <FormLabel className="font-normal">Member</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={MemberRole.Supervisor} />
                        </FormControl>
                        <FormLabel className="font-normal">
                          Supervisor
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={MemberRole.Administrator} />
                        </FormControl>
                        <FormLabel className="font-normal">
                          Administrator
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="requiresApproval"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      // eslint-disable-next-line react/jsx-handler-names
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Administrator Approval Required</FormLabel>
                    <FormDescription>
                      Organization administrator must approve members before
                      they are allowed to join.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />

            <MutationError error={error} />

            <DialogFooter>
              <Button
                disabled={fetching}
                onClick={onCancel}
                type="reset"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                disabled={fetching}
                type="submit"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Save
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { NewMembershipCodeDialog };
