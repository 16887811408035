import { MapSettingsForm } from '../components/MapSettingsForm';
import { Loader } from '@/components';
import { MapProvider } from '@/components/Map';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { ChevronRight } from 'lucide-react';
import { useQuery } from 'urql';

const MapSettingsGql = graphql(`
  query MapSettingsGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      defaultMapCenter {
        longitude
        latitude
      }
      defaultMapZoom
    }
  }
`);

const MapSettings = () => {
  const [organizationId] = useAppStore((state) => [state.activeMembership?.id]);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }] = useQuery({
    query: MapSettingsGql,
    variables: {
      organizationId,
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          General
          <ChevronRight className="h-4 w-4" />
          Map Settings
        </CardTitle>
        <CardDescription>
          Configure the default map used by your organization.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Loader
          isLoading={fetching}
          loadingError={error}
        >
          {data?.organization && (
            <MapProvider>
              <MapSettingsForm
                defaultMapCenter={{
                  lat: data.organization.defaultMapCenter.latitude,
                  lng: data.organization.defaultMapCenter.longitude,
                }}
                defaultMapZoom={data.organization.defaultMapZoom}
                organizationId={organizationId}
              />
            </MapProvider>
          )}
        </Loader>
      </CardContent>
    </Card>
  );
};

export { MapSettings };
