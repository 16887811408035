import { type DocumentType, graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { useSubscription } from 'urql';

const CurrentUserUpdatesDocumentQuery = graphql(`
  subscription CurrentUserUpdates {
    currentUserUpdates {
      user {
        id
        fullName
        emailAddress
        pendingEmailAddress
        phoneNumber
        pendingPhoneNumber
        applicationRole
        avatarUrl
        metadata
        memberships {
          nodes {
            id
            organizationId
            organizationRole
            organization {
              id
              name
              logoUrl
            }
          }
        }
      }
      event
      relatedIds
    }
  }
`);

const currentUserUpdatesResolver = (
  result: DocumentType<typeof CurrentUserUpdatesDocumentQuery>,
) => {
  if (!result?.currentUserUpdates?.user) {
    // no user on object, do nothing
    return;
  }

  const event = result.currentUserUpdates.event ?? 'updated';
  // const userId = result.currentUserUpdates.user.id;

  if (event === 'membership_added') {
    toast.success('Organization Joined!');
  }
};

const CurrentUserUpdates = () => {
  useSubscription({
    query: CurrentUserUpdatesDocumentQuery,
  });

  return null;
};

export { CurrentUserUpdates, currentUserUpdatesResolver };
