import { ThemeToggleDropdown } from '../ThemeToggleDropdown';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { cn } from '@/lib/utils';
import { ArrowLeft, Menu, Package2 } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const links = [
  {
    title: 'Overview',
    to: '/administration/overview',
  },
  {
    title: 'Plans',
    to: '/administration/plans',
  },
  {
    title: 'Organizations',
    to: '/administration/organizations',
  },
  { title: 'Users', to: '/administration/users' },
  {
    title: 'Incident Types',
    to: '/administration/incident-types',
  },
  {
    title: 'Bivy Sticks',
    to: '/administration/bivy-sticks',
  },
  {
    title: 'Client Tools',
    to: '/administration/client-simulation',
  },
  { title: 'Color List', to: '/administration/colors' },
];

const AdministrationHeader = () => {
  const pathname = useLocation().pathname;

  return (
    <header className="sticky z-10 top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
      <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
        <Link
          className="flex items-center gap-2 text-lg font-semibold md:text-base"
          to="/"
        >
          <ArrowLeft className="h-6 w-6" />
          <span className="sr-only">Administration</span>
        </Link>
        {links.map((item) => (
          <Link
            className={cn(
              'whitespace-nowrap transition-colors hover:text-foreground',
              pathname === item.to
                ? 'text-foreground'
                : 'text-muted-foreground',
            )}
            key={item.title}
            to={item.to}
          >
            {item.title}
          </Link>
        ))}
      </nav>
      <Sheet>
        <SheetTrigger asChild>
          <Button
            className="shrink-0 md:hidden"
            size="icon"
            variant="outline"
          >
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <nav className="grid gap-6 text-lg font-medium">
            <Link
              className="flex items-center gap-2 text-lg font-semibold"
              to="#"
            >
              <Package2 className="h-6 w-6" />
              <span className="sr-only">Acme Inc</span>
            </Link>
            <Link
              className="hover:text-foreground"
              to="#"
            >
              Dashboard
            </Link>
            <Link
              className="text-muted-foreground hover:text-foreground"
              to="#"
            >
              Orders
            </Link>
            <Link
              className="text-muted-foreground hover:text-foreground"
              to="#"
            >
              Products
            </Link>
            <Link
              className="text-muted-foreground hover:text-foreground"
              to="#"
            >
              Customers
            </Link>
            <Link
              className="text-muted-foreground hover:text-foreground"
              to="#"
            >
              Analytics
            </Link>
          </nav>
        </SheetContent>
      </Sheet>
      <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
        <div className="ml-auto" />
        <ThemeToggleDropdown />
      </div>
    </header>
  );
};

export { AdministrationHeader };
