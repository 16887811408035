import { type Struct } from 'superstruct';
import { boolean, object, string } from 'superstruct';

type BooleanFiedDefinition = {
  defaultValue: boolean;
  isPrivate: boolean;
  isRequired: boolean;
  name: string;
  type: 'BOOLEAN';
};

type NumberFieldDefinition = {
  defaultValue: number | null;
  isPrivate: boolean;
  isRequired: boolean;
  name: string;
  type: 'NUMBER';
};

type StringFieldDefinition = {
  defaultValue: string | null;
  isPrivate: boolean;
  isRequired: boolean;
  name: string;
  type: 'STRING';
};

type MetadataFieldDefinition =
  | BooleanFiedDefinition
  | NumberFieldDefinition
  | StringFieldDefinition;

type MetadataFieldDefinitions = {
  [name: string]: MetadataFieldDefinition;
};

const parseMemberMetadata = (
  metadataFields: MetadataFieldDefinitions | null | undefined,
  memberMetadata?: Record<string, boolean | string>,
) => {
  if (!metadataFields) {
    return [];
  }

  return Object.values(metadataFields)
    .map((item) => {
      const value = memberMetadata?.[item.name] ?? item.defaultValue ?? '';
      return {
        id: item.name.toLowerCase(),
        isPrivate: item.isPrivate,
        name: item.name,
        value: value.toString(),
      };
    })
    .sort((a, b) => (a.id < b.id ? -1 : 1));
};

const parseMetadata = (
  metadataFields?: MetadataFieldDefinitions | null | undefined,
  memberMetadata?: Record<string, boolean | string>,
) => {
  const defaultValues: Record<string, boolean | string> = {};
  const dynamicSchema: Record<
    string,
    Struct<boolean, null> | Struct<string, null>
  > = {};

  if (!metadataFields) {
    return {
      defaultValues,
      formFields: [],
      schema: object(dynamicSchema),
    };
  }

  const formFields = Object.values(metadataFields)
    .map((item) => ({
      id: item.name.toLowerCase(),
      isPrivate: item.isPrivate,
      name: item.name,
      type: item.type,
    }))
    .sort((a, b) => (a.id < b.id ? -1 : 1));

  for (const field of Object.values(metadataFields)) {
    switch (field.type) {
      case 'BOOLEAN':
        dynamicSchema[field.name] = boolean();
        defaultValues[field.name] = field.defaultValue;
        break;
      case 'NUMBER':
        dynamicSchema[field.name] = string();
        defaultValues[field.name] = field.defaultValue?.toString() ?? '0';
        break;
      case 'STRING':
        dynamicSchema[field.name] = string();
        defaultValues[field.name] = field.defaultValue ?? '';
        break;
      default:
      // noop
    }
  }

  // remove undefined fields from the memberMetadta (Member)
  // based on the available metadataFields (Organization defined)
  const filteredMemberMetadata = memberMetadata ?? {};

  if (memberMetadata) {
    const validKeys = Object.keys(metadataFields);
    for (const key of Object.keys(memberMetadata)) {
      if (!validKeys.includes(key)) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete filteredMemberMetadata[key];
      }
    }
  }

  return {
    defaultValues: {
      ...defaultValues,
      ...filteredMemberMetadata,
    },
    formFields,
    schema: object(dynamicSchema),
  };
};

export { parseMemberMetadata, parseMetadata };
