import { AcceptInvitation } from './AcceptInvitation';
import { CancelMembershipReqeust } from './membershipRequest/CancelMembershipReqeust';
import { Datetime } from '@/components/Datetime';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { type MemberRole } from '@/gql/graphql';
import { formatMemberRole } from '@/lib/formatGql';
import { useAppStore } from '@/stores';
import { CircleChevronRight } from 'lucide-react';

type MembershipListProps = {
  readonly invitations: Array<{
    id: string;
    key: string;
    organization?:
      | {
          __typename?: 'Organization' | undefined;
          id: string;
          logoUrl: string;
        }
      | null
      | undefined;
    organizationName?: string | null | undefined;
    organizationRole: MemberRole;
  }>;
  readonly membershipRequests: Array<{
    createdAt: string;
    id: string;
    organizationId: string;
    organizationName?: string | null | undefined;
  }>;
  readonly memberships: Array<{
    createdAt: string;
    id: string;
    organization?:
      | {
          id: string;
          logoUrl: string;
          name: string;
        }
      | null
      | undefined;
    organizationRole: MemberRole;
  }>;
};

const MembershipList = ({
  invitations,
  membershipRequests,
  memberships,
}: MembershipListProps) => {
  const [activeMembership, setActiveMembership] = useAppStore((state) => [
    state.activeMembership,
    state.setActiveMembership,
  ]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>My Organizations</CardTitle>
        <CardDescription>
          Manage the organizations you belong to.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Organization Name</TableHead>
              <TableHead>Role</TableHead>
              <TableHead>Joined</TableHead>
              <TableHead>
                <span className="sr-only">Actions</span>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {invitations.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <div className="flex gap-2 items-center justify-start">
                    <Avatar className="w-6 h-6 mr-2">
                      <AvatarImage
                        alt={item.organizationName ?? 'Unknown Organization'}
                        src={item.organization?.logoUrl}
                      />
                      <AvatarFallback>
                        {item.organizationName?.slice(0, 1) ?? 'O'}
                      </AvatarFallback>
                    </Avatar>
                    {item.organizationName ?? 'Unknown Organization'}
                  </div>
                </TableCell>
                <TableCell>{formatMemberRole(item.organizationRole)}</TableCell>
                <TableCell colSpan={2}>
                  <AcceptInvitation
                    invitationId={item.id}
                    organizationName={item.organizationName}
                  />
                </TableCell>
              </TableRow>
            ))}

            {membershipRequests.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <div className="flex gap-2 items-center justify-start">
                    {item.organizationName ?? 'Unknown Organization'}
                  </div>
                </TableCell>
                <TableCell />
                <TableCell colSpan={2}>
                  <CancelMembershipReqeust
                    membershipRequestId={item.id}
                    organizationName={item.organizationName}
                  />
                </TableCell>
              </TableRow>
            ))}

            {memberships.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <div className="flex gap-2 items-center justify-start">
                    <Avatar className="w-6 h-6 rounded-md mr-2">
                      <AvatarImage
                        alt={item.organization?.name ?? 'Unknown Organization'}
                        src={item.organization?.logoUrl}
                      />
                      <AvatarFallback>
                        {item.organization?.name.slice(0, 1) ?? 'O'}
                      </AvatarFallback>
                    </Avatar>
                    {item.organization?.name ?? 'Unknown Organization'}
                  </div>
                </TableCell>
                <TableCell>{formatMemberRole(item.organizationRole)}</TableCell>
                <TableCell>
                  <Datetime datetime={item.createdAt} />
                </TableCell>
                <TableCell>
                  {activeMembership?.id === item.organization?.id ? (
                    <Badge>Active Organization</Badge>
                  ) : (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            className="rounded-full h-8 w-8"
                            onClick={() => {
                              if (item.organization?.id) {
                                setActiveMembership(item.organization?.id);
                              }
                            }}
                            size="icon"
                            variant="ghost"
                          >
                            <CircleChevronRight className="w-6 h-6" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Switch to organization</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export { MembershipList };
