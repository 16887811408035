import { Datetime } from '@/components/Datetime';
import { AdvancedMarker, InfoWindow, Pin } from '@/components/Map';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { type GeometryPoint, GuardmeStatus, type Member } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import {
  CircleAlert,
  Info,
  Play,
  ShieldAlert,
  ShieldCheck,
  X,
} from 'lucide-react';
import { type ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  readonly coordinates?: Pick<GeometryPoint, 'x' | 'y'>;
  readonly createdAt: string;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly icon?: string | undefined;
  readonly id: string;
  readonly member?: Pick<
    Member,
    'fullName' | 'displayName' | 'avatarUrl'
  > | null;
  readonly shortId: string;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly status?: string | undefined;
  readonly zIndex: number;
};

type MapMarkerProps = {
  readonly avatarUrl: string | undefined;
  readonly children: ReactNode;
  readonly createdAt: string;
  readonly name: string;
  readonly position: { lat: number; lng: number };
  readonly title: string;
  readonly to: string;
  readonly zIndex: number;
};

const MapMarker = ({
  avatarUrl,
  children,
  createdAt,
  name,
  position,
  title,
  to,
  zIndex,
}: MapMarkerProps) => {
  const navigate = useNavigate();

  const [infowindowShown, setInfowindowShown] = useState(false);

  const toggleInfoWindow = () =>
    setInfowindowShown((previousState) => !previousState);

  const closeInfoWindow = () => setInfowindowShown(false);

  return (
    <AdvancedMarker
      onClick={toggleInfoWindow}
      position={position}
      zIndex={infowindowShown ? zIndex * 1_000 : zIndex}
    >
      {infowindowShown ? (
        <InfoWindow
          headerDisabled
          onCloseClick={closeInfoWindow}
          position={position}
        >
          <div className="transition-all ease-in-out flex items-center h-8 bg-none h-full text-black text-base">
            <div className="space-y-2">
              <div className="w-full flex items-center justify-between gap-4">
                <div className="font-semibold">{title}</div>
                <div>
                  <button
                    className="h-6 w-6 transition-all ease-in-out text-gray-300 hover:text-black hover:bg-gray-100 flex items-center justify-center rounded-full"
                    onClick={closeInfoWindow}
                    type="button"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              </div>

              <div className="w-full flex items-center justify-between gap-4">
                <div className="mt-0.5 flex items-center gap-2 text-sm">
                  <Avatar className="h-8 w-8">
                    <AvatarImage
                      alt={name}
                      src={avatarUrl}
                    />
                    <AvatarFallback>{name.slice(0, 2)}</AvatarFallback>
                  </Avatar>
                  <div className="flex flex-col">
                    <div>{name}</div>
                    <div>
                      <Datetime
                        datetime={createdAt}
                        format="MM/dd/yy, hh:mm aaa"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="h-12 w-12 transition-all ease-in-out hover:bg-gray-100 flex items-center justify-center rounded-full"
                    onClick={() => {
                      navigate(to);
                    }}
                    type="button"
                  >
                    <Info className="h-8 w-8" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </InfoWindow>
      ) : (
        <Pin size="avatar">{children}</Pin>
      )}
    </AdvancedMarker>
  );
};

const GuardMeMarker = ({
  coordinates,
  createdAt,
  id,
  member,
  shortId,
  status,
  zIndex,
}: Props) => {
  if (!coordinates) {
    return null;
  }

  const name = member?.displayName ?? member?.fullName ?? 'Deleted Member';
  const position = {
    lat: coordinates.y,
    lng: coordinates.x,
  };

  let classes = 'bg-secondary text-secondary-foreground';
  let Icon = ShieldCheck;

  if (status === GuardmeStatus.Concluded) {
    classes = 'bg-secondary text-secondary-foreground';
    Icon = ShieldCheck;
  } else if (status === GuardmeStatus.Expired) {
    classes = 'bg-warning text-warning-foreground';
    Icon = CircleAlert;
  } else if (status === GuardmeStatus.InPanic) {
    classes = 'bg-destructive text-destructive-foreground';
    Icon = ShieldAlert;
  } else if (status === GuardmeStatus.InProgress) {
    classes = 'bg-info text-info-foreground';
    Icon = Play;
  }

  return (
    <MapMarker
      avatarUrl={member?.avatarUrl ?? undefined}
      createdAt={createdAt}
      name={name}
      position={position}
      title={`GuardMe ${shortId}`}
      to={`/guardme/${id}`}
      zIndex={zIndex}
    >
      <div
        className={cn(
          'flex items-center content-center rounded-full p-1',
          classes,
        )}
      >
        <Icon className="h-6 w-6" />
        <span className="sr-only">GuardMe</span>
      </div>
    </MapMarker>
  );
};

const IncidentMarker = ({
  coordinates,
  createdAt,
  icon,
  id,
  member,
  shortId,
  zIndex,
}: Props) => {
  if (!coordinates) {
    return null;
  }

  const name = member?.displayName ?? member?.fullName ?? 'Deleted Member';
  const position = {
    lat: coordinates.y,
    lng: coordinates.x,
  };

  return (
    <MapMarker
      avatarUrl={member?.avatarUrl ?? undefined}
      createdAt={createdAt}
      name={name}
      position={position}
      title={`Incident ${shortId}`}
      to={`/incidents/${id}`}
      zIndex={zIndex}
    >
      <div className="flex items-center content-center rounded-full p-1 bg-secondary text-secondary-foreground">
        <img
          alt="incident"
          className="h-6 w-6"
          src={`data:image/svg+xml;base64,${icon}`}
        />
        <span className="sr-only">Incident</span>
      </div>
    </MapMarker>
  );
};

const CheckInMarker = ({
  coordinates,
  createdAt,
  id,
  member,
  shortId,
  zIndex,
}: Props) => {
  if (!coordinates) {
    return null;
  }

  const name = member?.displayName ?? member?.fullName ?? 'Deleted Member';
  const position = {
    lat: coordinates.y,
    lng: coordinates.x,
  };

  return (
    <MapMarker
      avatarUrl={member?.avatarUrl ?? undefined}
      createdAt={createdAt}
      name={name}
      position={position}
      title={`Check In ${shortId}`}
      to={`/check-in/${id}`}
      zIndex={zIndex}
    >
      <Avatar className="h-8 w-8">
        <AvatarImage
          alt={name}
          src={member?.avatarUrl ?? undefined}
        />
        <AvatarFallback>{name.slice(0, 2)}</AvatarFallback>
      </Avatar>
    </MapMarker>
  );
};

enum RecentMapMarkerType {
  'CHECK_IN' = 1,
  'GUARDME' = 2,
  'INCIDENT' = 3,
}

type RecentMapMarkerProps = Props & {
  readonly type: RecentMapMarkerType;
};

const RecentMapMarker = ({ type, ...rest }: RecentMapMarkerProps) => {
  if (type === RecentMapMarkerType.CHECK_IN) {
    return <CheckInMarker {...rest} />;
  } else if (type === RecentMapMarkerType.GUARDME) {
    return <GuardMeMarker {...rest} />;
  } else if (type === RecentMapMarkerType.INCIDENT) {
    return <IncidentMarker {...rest} />;
  }

  return null;
};

export { RecentMapMarker, type RecentMapMarkerProps, RecentMapMarkerType };
