import { ClientError } from '@/components';
import { config } from '@/config';
import { VisualC3Error } from '@/utils';

type ErrorBoundaryFallbackProps = {
  readonly componentStack: string;
  readonly error: unknown;
  readonly eventId: string;
  readonly resetError: () => void;
};

const ErrorBoundaryFallback = (props: ErrorBoundaryFallbackProps) => {
  const { componentStack, error, resetError } = props;

  const stack = config.STAGE === 'local' ? componentStack : null;

  if (error instanceof VisualC3Error) {
    return (
      <div
        className="w-full pt-8 px-12"
        id={props.eventId}
      >
        <ClientError
          code={error.code}
          message={error.message}
          resetError={resetError}
          stack={stack}
        />
      </div>
    );
  }

  if (error instanceof Error) {
    return (
      <div
        className="w-full pt-8 px-12"
        id={props.eventId}
      >
        <ClientError
          code={500}
          message={error.message}
          resetError={resetError}
          stack={stack}
        />
      </div>
    );
  }

  return (
    <div
      className="w-full pt-8 px-12"
      id={props.eventId}
    >
      <ClientError
        code={500}
        message="Internal Error"
        resetError={resetError}
        stack={stack}
      />
    </div>
  );
};

export { ErrorBoundaryFallback };
