import { MemberSelect } from '@/components/MemberSelect';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { type Infer, object, string } from 'superstruct';
import { useMutation } from 'urql';

const AddMemberToGroupQuery = graphql(`
  mutation AddMemberToGroupQuery($groupId: String!, $memberId: String!) {
    addMemberToGroup(input: { groupId: $groupId, memberId: $memberId }) {
      mapGroupsMember {
        organizationId
        groupId
        memberId
      }
    }
  }
`);

const schema = object({
  memberId: string(),
});

type GroupAddMemberProps = {
  readonly groupId: string;
  readonly members?: Array<{
    displayName?: string | null | undefined;
    fullName: string;
    id: string;
    isEnabled: boolean;
  }>;
  readonly onComplete: () => void;
};

const GroupAddMember = ({
  groupId,
  members = [],
  onComplete,
}: GroupAddMemberProps) => {
  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      memberId: '',
    },
  });

  const [{ error, fetching }, addMemberToGroup] = useMutation(
    AddMemberToGroupQuery,
  );

  const onSubmit = async (values: Infer<typeof schema>) => {
    await addMemberToGroup({
      groupId,
      memberId: values.memberId,
    });

    form.reset();
    onComplete();
  };

  const onCancel = () => {
    form.reset();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card>
          <CardHeader>
            <CardTitle className="text-xl">Add Member </CardTitle>
            <CardDescription>Assigns a member to the group.</CardDescription>
          </CardHeader>
          <CardContent className="space-y-8">
            <FormField
              control={form.control}
              name="memberId"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Member</FormLabel>
                  <FormControl>
                    <MemberSelect
                      {...field}
                      members={members}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <MutationError error={error} />
          </CardContent>

          <CardFooter className="flex items-center gap-2">
            <Button
              disabled={fetching}
              type="submit"
            >
              {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
              Add
            </Button>
            <Button
              disabled={fetching}
              onClick={onCancel}
              type="reset"
              variant="outline"
            >
              Reset
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

export { GroupAddMember };
