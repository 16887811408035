import { PrivacyPolicy } from './routes/PrivacyPolicy';
import { TermsOfService } from './routes/TermsOfService';
import { type RouteObject } from 'react-router-dom';

export * from './components/ErrorBoundaryFallback';
export * from './components/LoadingError';
export * from './components/MutationError';
export * from './components/VersionUpdateCheck';

export const miscRoutes: RouteObject[] = [
  {
    element: <PrivacyPolicy />,
    path: '/privacy-policy',
  },
  {
    element: <TermsOfService />,
    path: '/terms-of-service',
  },
];
