import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip';
import { formatDatetime } from '@/lib/time';
import { isValid } from 'date-fns';
import { forwardRef, type HTMLAttributes } from 'react';

type DatetimeProps = HTMLAttributes<HTMLTimeElement> & {
  readonly datetime?: Date | string | null;
  readonly end?: Date | string | null;
  readonly format?: string;
  readonly passthrough?: boolean;
  readonly side?: 'bottom' | 'left' | 'right' | 'top';
  readonly variant?: 'datetime' | 'distance' | 'duration';
};

const Datetime = forwardRef<HTMLTimeElement, DatetimeProps>(
  (
    {
      datetime,
      end,
      format = 'MMM dd, yy, hh:mm aaa',
      passthrough = false,
      side,
      variant = 'datetime',
      ...props
    },
    ref,
  ) => {
    let formattedDatetime = '--';

    try {
      formattedDatetime = formatDatetime({
        datetime,
        end,
        format,
        variant,
      });
    } catch (error) {
      if (passthrough) {
        if (datetime) {
          if (datetime instanceof Date) {
            return isValid(datetime)
              ? datetime.toISOString()
              : datetime.toString();
          } else {
            return datetime;
          }
        }

        return null;
      }

      throw error;
    }

    if (variant === 'datetime') {
      return (
        <time
          className="text-inherit lining-nums tabular-nums whitespace-nowrap"
          dateTime={datetime ? datetime.toString() : new Date().toString()}
          ref={ref}
          {...props}
        >
          {formattedDatetime}
        </time>
      );
    }

    if (variant === 'distance') {
      return (
        <Tooltip>
          <TooltipTrigger>
            <time
              className="text-inherit lining-nums tabular-nums whitespace-nowrap"
              ref={ref}
              {...props}
            >
              {formattedDatetime}
            </time>
            {variant === 'distance' && (
              <span className={props.className}>&nbsp;ago</span>
            )}
          </TooltipTrigger>
          <TooltipContent side={side}>
            {formatDatetime({
              datetime,
              end,
              format: 'MMM dd, yy, hh:mm aaa',
              variant: 'datetime',
            })}
          </TooltipContent>
        </Tooltip>
      );
    }

    return (
      <time
        className="text-inherit lining-nums tabular-nums whitespace-nowrap"
        ref={ref}
        {...props}
      >
        {formattedDatetime}
      </time>
    );
  },
);

export { Datetime };
