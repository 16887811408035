import { useIncidentTableControls } from '../hooks/useIncidentTableControls';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import {
  Activity,
  Check,
  ChevronDown,
  ChevronsDown,
  ChevronsUp,
  Filter,
  Flame,
  Lock,
  LockOpen,
  Minus,
} from 'lucide-react';
import { useState } from 'react';

type IncidentListFilterProps = {
  readonly className?: string;
};

const IncidentListFilter = ({ className }: IncidentListFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    clearFilter,
    hasFilter,
    priority,
    setPriorityFilter,
    setStatusFilter,
    status,
  } = useIncidentTableControls();

  return (
    <Popover
      onOpenChange={setIsOpen}
      open={isOpen}
    >
      <PopoverTrigger asChild>
        <Button
          className={className}
          variant="outline"
        >
          {hasFilter.priority || hasFilter.status ? (
            <Filter className="mr-2 h-4 w-4 text-primary fill-primary" />
          ) : (
            <Filter className="mr-2 h-4 w-4" />
          )}
          Filter
          <ChevronDown className={cn('ml-2 h-4 w-4', isOpen && 'rotate-180')} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="md:w-[200px] p-0"
      >
        <Command>
          <CommandList>
            <CommandGroup heading="Status">
              <CommandItem
                onSelect={() => setStatusFilter('open', !status.open)}
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    status.open
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className="h-4 w-4" />
                </div>
                <span className="flex items-center gap-1">
                  <LockOpen className="h-3 w-3" /> Open
                </span>
              </CommandItem>
              <CommandItem
                onSelect={() =>
                  setStatusFilter('inProgress', !status.inProgress)
                }
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    status.inProgress
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className="h-4 w-4" />
                </div>
                <span className="flex items-center gap-1">
                  <Activity className="h-3 w-3" />
                  In Progress
                </span>
              </CommandItem>
              <CommandItem
                onSelect={() => setStatusFilter('closed', !status.closed)}
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    status.closed
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className="h-4 w-4" />
                </div>
                <span className="flex items-center gap-1">
                  <Lock className="h-3 w-3" />
                  Closed
                </span>
              </CommandItem>
            </CommandGroup>

            <CommandGroup heading="Priority">
              <CommandItem
                onSelect={() => setPriorityFilter('urgent', !priority.urgent)}
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    priority.urgent
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className="h-4 w-4" />
                </div>
                <span className="flex items-center gap-1">
                  <Flame className="h-3 w-3" />
                  Urgent
                </span>
              </CommandItem>
              <CommandItem
                onSelect={() => setPriorityFilter('high', !priority.high)}
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    priority.high
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className="h-4 w-4" />
                </div>
                <span className="flex items-center gap-1">
                  <ChevronsUp className="h-3 w-3" />
                  High
                </span>
              </CommandItem>
              <CommandItem
                onSelect={() => setPriorityFilter('medium', !priority.medium)}
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    priority.medium
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className="h-4 w-4" />
                </div>
                <span className="flex items-center gap-1">
                  <Minus className="h-3 w-3" />
                  Medium
                </span>
              </CommandItem>
              <CommandItem
                onSelect={() => setPriorityFilter('low', !priority.low)}
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    priority.low
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className="h-4 w-4" />
                </div>
                <span className="flex items-center gap-1">
                  <ChevronsDown className="h-3 w-3" />
                  Low
                </span>
              </CommandItem>
            </CommandGroup>
          </CommandList>

          <CommandSeparator />
          <CommandGroup>
            <CommandItem
              className="justify-center text-center"
              disabled={!(hasFilter.priority || hasFilter.status)}
              onSelect={() => clearFilter()}
            >
              Clear filters
            </CommandItem>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export { IncidentListFilter };
