import { graphql } from '@/gql';
import { useSubscription } from 'urql';

const CheckInReportUpdatesGql = graphql(`
  subscription CheckInReportUpdatesGql($checkInReportId: String!) {
    checkInReportUpdates(input: { checkInReportId: $checkInReportId }) {
      checkInReport {
        id
        shortId
        createdAt
        cutoffAt
        organization {
          id
          places {
            nodes {
              id
              name
              spatialData {
                geojson
              }
              radius
            }
          }
        }
        place {
          id
          name
          description
        }
        requestorMember {
          id
          fullName
          displayName
          avatarUrl
        }
        checkInRequests {
          totalCount
          nodes {
            id
            checkInId
            checkIn {
              id
              shortId
              createdAt
              coordinates {
                x
                y
              }
            }
            member {
              id
              displayName
              fullName
              avatarUrl
            }
          }
        }
      }
      event
      relatedIds
    }
  }
`);

type CheckInReportUpdatesProps = { checkInReportId: string };

const CheckInReportUpdates = ({
  checkInReportId,
}: CheckInReportUpdatesProps) => {
  useSubscription({
    query: CheckInReportUpdatesGql,
    variables: {
      checkInReportId,
    },
  });

  return null;
};

export { CheckInReportUpdates };
