import { Datetime } from '@/components/Datetime';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { cn } from '@/lib/utils';
import {
  type ForwardRefExoticComponent,
  type HTMLAttributes,
  type SVGProps,
} from 'react';
import { Link } from 'react-router-dom';

type IncidentUpdateContainerProps = Pick<
  HTMLAttributes<HTMLDivElement>,
  'children'
> & {
  readonly asUser?: boolean;
  readonly icon: ForwardRefExoticComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    }
  >;
  readonly performedByMember?:
    | {
        avatarUrl?: string | null | undefined;
        displayName?: string | null | undefined;
        fullName: string;
        id: string;
      }
    | null
    | undefined;
};

const IncidentUpdateContainer = ({
  asUser,
  children,
  icon: Icon,
  performedByMember,
}: IncidentUpdateContainerProps) => {
  return (
    <>
      <div>
        <div className="relative px-1">
          {asUser ? (
            <>
              <Avatar className="h-10 w-10">
                <AvatarImage src={performedByMember?.avatarUrl ?? undefined} />
                <AvatarFallback>
                  {performedByMember?.fullName.slice(0, 2) ?? 'M'}
                </AvatarFallback>
              </Avatar>
              <span className="absolute -bottom-0.5 -right-1 bg-muted text-muted-foreground rounded-tl px-0.5 py-px">
                <Icon
                  aria-hidden="true"
                  className="h-4 w-4 text-gray-400"
                />
              </span>
            </>
          ) : (
            <div className="h-8 w-8 bg-muted text-muted-foreground rounded-full flex items-center justify-center">
              <Icon
                aria-hidden="true"
                className="h-5 w-5"
              />
            </div>
          )}
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0 text-muted-foreground">
        {children}
      </div>
    </>
  );
};

type IncidentUpdateTitleProps = HTMLAttributes<HTMLDivElement>;

const IncidentUpdateTitle = ({
  children,
  className,
  ...props
}: IncidentUpdateTitleProps) => {
  return (
    <div
      className={cn('text-sm', className)}
      {...props}
    >
      {children}
    </div>
  );
};

type IncidentUpdateSubtitleProps = HTMLAttributes<HTMLDivElement> & {
  readonly createdAt: string;
  readonly isSystemMessage?: boolean;
  readonly performedByMember?:
    | {
        avatarUrl?: string | null | undefined;
        displayName?: string | null | undefined;
        fullName: string;
        id: string;
      }
    | null
    | undefined;
};

const IncidentUpdateSubtitle = ({
  className,
  createdAt,
  isSystemMessage,
  performedByMember,
  ...props
}: IncidentUpdateSubtitleProps) => {
  return (
    <div
      className={cn('mt-0.5 text-xs text-muted-foreground', className)}
      {...props}
    >
      <span className="">
        {isSystemMessage && 'System'}
        {performedByMember && (
          <Link
            className="hover:underline"
            to={`/members/${performedByMember.id}`}
          >
            {performedByMember.displayName ??
              performedByMember.fullName ??
              'Unknown Member'}
          </Link>
        )}
      </span>
      <span className="mx-1.5 text-muted-foreground">&#x25CF;</span>
      <span className="whitespace-nowrap">
        <Datetime
          datetime={createdAt}
          side="right"
          variant="distance"
        />
      </span>
    </div>
  );
};

type IncidentUpdateContentProps = HTMLAttributes<HTMLDivElement>;

const IncidentUpdateContent = ({
  children,
  className,
  ...props
}: IncidentUpdateContentProps) => {
  return (
    <div
      className={cn(
        'mt-2 p-2 rounded-lg border bg-card text-card-foreground shadow-sm',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export {
  IncidentUpdateContainer,
  IncidentUpdateContent,
  IncidentUpdateSubtitle,
  IncidentUpdateTitle,
};
