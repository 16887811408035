import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { RefreshCw } from 'lucide-react';
import { useMutation } from 'urql';

const AcceptInvitationGql = graphql(`
  mutation AcceptInvitationGql($id: String!) {
    acceptInvitation(input: { id: $id }) {
      query {
        currentUser {
          id
          memberships {
            totalCount
            nodes {
              id
              organizationRole
              createdAt
              organization {
                id
                name
                logoUrl
              }
            }
          }
          invitations {
            totalCount
            nodes {
              id
              key
              organizationRole
              organizationName
              organization {
                id
                logoUrl
              }
            }
          }
        }
      }
    }
  }
`);

type AcceptInvitationProps = {
  invitationId: string;
  organizationName?: string | null;
};

const AcceptInvitation = ({
  invitationId,
  organizationName,
}: AcceptInvitationProps) => {
  const [{ fetching }, acceptInvitation] = useMutation(AcceptInvitationGql);

  if (!organizationName) {
    return null;
  }

  const handleClick = async () => {
    const response = await acceptInvitation({ id: invitationId });

    if (response.error) {
      toast.error(`Could not join ${organizationName}`);
    } else {
      toast.success(`Joined ${organizationName}`);
    }
  };

  return (
    <Button
      className="h-8"
      disabled={fetching}
      onClick={handleClick}
    >
      {fetching ? (
        <>
          <RefreshCw className="animate-spin h-4 w-4" /> Accepting...
        </>
      ) : (
        'Accept Invitation'
      )}
    </Button>
  );
};

export { AcceptInvitation };
