import { logger } from './logger';
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  type Duration as DurationType,
  intervalToDuration,
  parseISO,
  startOfMonth,
  startOfYear,
} from 'date-fns';

export type Datetime = Date;
export type Duration = DurationType;

const duration = (args: { end?: Datetime; start?: Datetime }): Duration => {
  return intervalToDuration({
    end: args.end ?? new Date(),
    start: args.start ?? new Date(),
  });
};

const isActive = (timestamp: Date, now: Date, max: number = 15): boolean => {
  const difference = differenceInMinutes(now, timestamp);
  return difference >= 0 && difference < max;
};

const datetime = (input?: string | null): Date => {
  if (!input) {
    return new Date();
  }

  try {
    return parseISO(input);
  } catch {
    logger.warn('could not parse date from string');
    return new Date();
  }
};

const addTime = (input: {
  current?: Datetime;
  hours: number;
  minutes: number;
}) => {
  const { current = new Date(), minutes, hours } = input;

  return addHours(addMinutes(current, minutes), hours);
};

const startOfNextMonth = () => {
  const now = new Date();
  now.setMonth(now.getMonth() + 1);

  return startOfMonth(now);
};

const startOfNextYear = () => {
  const now = new Date();
  now.setFullYear(now.getFullYear() + 1);

  return startOfYear(now);
};

export {
  addTime,
  datetime,
  duration,
  isActive,
  startOfNextMonth,
  startOfNextYear,
};

export { addHours, getTime, startOfToday, subHours } from 'date-fns';
