import { anyOf, isoDatetime, oneOf } from './superstruct';
import {
  boolean,
  max,
  min,
  nonempty,
  number,
  object,
  optional,
  size,
  string,
  type Struct,
} from 'superstruct';

type UISchemaControl = {
  label: string;
  options?: {
    format?: 'radio';
    multiline?: true;
    readonly?: true;
  };
  scope: string;
  type: 'Control';
};

type UISchemaGroup = {
  elements: Array<UISchemaControl | UISchemaGroup | UISchemaLayout>;
  label: string;
  options?: {
    controlName?: string;
    inline?: boolean;
    required?: boolean;
  };
  type: 'Group';
};

type UISchemaLayout = {
  elements: Array<UISchemaControl | UISchemaGroup | UISchemaLayout>;
  type: 'HorizontalLayout' | 'VerticalLayout';
};

type UISchema = UISchemaControl | UISchemaGroup | UISchemaLayout;

const isUISchemaControl = (schema: UISchema): schema is UISchemaControl =>
  schema.type === 'Control';
const isUISchemaGroup = (schema: UISchema): schema is UISchemaGroup =>
  schema.type === 'Group';
const isUISchemaLayout = (schema: UISchema): schema is UISchemaLayout =>
  schema.type === 'HorizontalLayout' || schema.type === 'VerticalLayout';

const fieldFromScope = (
  scope: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSchema: any,
  controlName: string = '',
  required: string[] = [],
): { name: string } & (
  | DataSchemaBoolean
  | DataSchemaNumber
  | DataSchemaString
) => {
  const parsedScope = scope.slice(scope.startsWith('#/') ? 2 : 0);
  const nodes = parsedScope.split('/');

  const computedName = controlName + nodes[0];

  const computedNameArray = computedName.split('.');

  if (nodes.length > 1) {
    return fieldFromScope(
      parsedScope.slice(nodes[0].length + 1),
      dataSchema[nodes[0]],
      computedName + '.',
      dataSchema.required,
    );
  } else {
    return {
      isRequired: required.includes(
        computedNameArray[computedNameArray.length - 1],
      ),
      name: computedName.replaceAll('properties.', ''),
      ...dataSchema[parsedScope],
    };
  }
};

const searchUISchemaForScope = (
  scope: string,
  schema: UISchema,
): UISchemaControl | null => {
  if (isUISchemaControl(schema)) {
    if (schema.scope === scope) {
      return schema;
    } else {
      return null;
    }
  } else if (isUISchemaLayout(schema) || isUISchemaGroup(schema)) {
    let possible: UISchemaControl | null = null;
    for (const child of schema.elements) {
      possible = searchUISchemaForScope(scope, child) ?? possible;
    }

    return possible;
  }

  return null;
};

const uiSchemaFromDataKey = (
  key: string,
  schema: UISchema,
): UISchemaControl | null => {
  // convert key to scope
  const path = key
    .replaceAll('additional_data', 'additionalData')
    .replaceAll('.', '/properties/');

  // search through all elements in the UISchema for a scope that ends in
  const scope = '#/properties/' + path;

  return searchUISchemaForScope(scope, schema);
};

const dataFromValues = (
  scope: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>,
): boolean | number | string => {
  const path = scope.slice(scope.startsWith('#/') ? 2 : 0);
  const nodes = path.split('/');

  const key = nodes[0];

  if (nodes.length > 1) {
    return dataFromValues(path.slice(nodes[0].length + 1), data[key]);
  } else {
    return data[key];
  }
};

export type DataSchemaString = {
  default?: string;
  description?: string;
  format?: 'date-time';
  isRequired?: boolean;
  maxLength?: number;
  minLength?: number;
  oneOf?: Array<{ const: string; title: string }>;
  type: 'string';
};

export type DataSchemaNumber = {
  default?: number;
  description?: string;
  isRequired?: boolean;
  maximum?: number;
  minimum?: number;
  oneOf?: Array<{ const: string; title: string }>;
  type: 'number';
};

export type DataSchemaBoolean = {
  default?: boolean;
  description?: string;
  isRequired?: boolean;
  oneOf?: Array<{ const: string; title: string }>;
  type: 'boolean';
};

type DataSchemaObject = {
  additionalProperties?: boolean;
  anyOf?: Array<{ required: string[] }>;
  oneOf?: Array<{ required: string[] }>;
  properties: Record<
    string,
    DataSchemaBoolean | DataSchemaNumber | DataSchemaObject | DataSchemaString
  >;
  required?: string[];
  type: 'object';
};

type DataSchema = DataSchemaObject;
/*
{
  additionalProperties?: boolean;
  properties: {
    additionalData?: DataSchemaObject;
    description: DataSchemaString;
    subject: DataSchemaString;
  };
  required: string[];
  type: 'object';
};
*/

// eslint-disable-next-line complexity
const incidentTypeSchema = (dataSchema?: DataSchema) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const returnObject: Record<string, Struct<any, any>> = {};

  for (const [key, value] of Object.entries(dataSchema?.properties ?? {})) {
    const isRequired = dataSchema?.required?.includes(key);

    if (value.type === 'string') {
      if (value.format && value.format === 'date-time') {
        returnObject[key] = isRequired
          ? isoDatetime()
          : optional(isoDatetime());
      } else if (
        value.minLength !== undefined ||
        value.maxLength !== undefined
      ) {
        returnObject[key] = isRequired
          ? size(string(), value.minLength ?? 0, value.maxLength ?? undefined)
          : optional(
              size(
                string(),
                value.minLength ?? 0,
                value.maxLength ?? undefined,
              ),
            );
      } else {
        returnObject[key] = isRequired
          ? nonempty(string())
          : optional(string());
      }
    } else if (value.type === 'number') {
      if (value.minimum && value.maximum) {
        returnObject[key] = isRequired
          ? max(min(number(), value.minimum), value.maximum)
          : optional(max(min(number(), value.minimum), value.maximum));
      } else if (value.minimum) {
        returnObject[key] = isRequired
          ? min(number(), value.minimum)
          : optional(min(number(), value.minimum));
      } else if (value.maximum) {
        returnObject[key] = isRequired
          ? max(number(), value.maximum)
          : optional(max(number(), value.maximum));
      } else {
        returnObject[key] = isRequired ? number() : optional(number());
      }
    } else if (value.type === 'boolean') {
      returnObject[key] = isRequired ? boolean() : optional(boolean());
    } else if (value.type === 'object') {
      returnObject[key] = isRequired
        ? incidentTypeSchema(value)
        : optional(incidentTypeSchema(value));
    }
  }

  if (dataSchema?.anyOf) {
    return anyOf(returnObject);
  } else if (dataSchema?.oneOf) {
    return oneOf(returnObject);
  } else {
    return object(returnObject);
  }
};

const incidentTypeDefaultValues = (dataSchema?: DataSchema) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const returnObject: Record<string, any> = {};

  for (const [key, value] of Object.entries(dataSchema?.properties ?? {})) {
    if (value.type === 'string') {
      if (value.format === 'date-time') {
        returnObject[key] = new Date().toISOString();
      } else {
        returnObject[key] = value.default ?? '';
      }
    } else if (value.type === 'number') {
      returnObject[key] = value.default ?? 0;
    } else if (value.type === 'boolean') {
      returnObject[key] = value.default ?? false;
    } else if (value.type === 'object') {
      returnObject[key] = incidentTypeDefaultValues(value);
    }
  }

  return returnObject;
};

export {
  dataFromValues,
  type DataSchema,
  fieldFromScope,
  incidentTypeDefaultValues,
  incidentTypeSchema,
  isUISchemaControl,
  isUISchemaGroup,
  isUISchemaLayout,
  type UISchema,
  type UISchemaControl,
  uiSchemaFromDataKey,
  type UISchemaGroup,
  type UISchemaLayout,
};
