import { CloseAccountDialog } from '../components/CloseAccountDialog';
import { Loader } from '@/components/Loader';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { AlertTriangle } from 'lucide-react';

const CloseAccount = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Close Account</CardTitle>
        <CardDescription>
          This will permanently remove all associated data for your user.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Loader isLoading={false}>
          <Alert variant="destructive">
            <AlertTriangle className="h-4 w-4" />
            <AlertTitle>Are you sure?</AlertTitle>
            <AlertDescription>
              Closing your account is permanent and cannot be undone!
            </AlertDescription>
          </Alert>
        </Loader>
      </CardContent>
      <CardFooter className="flex gap-1 items-center border-t bg-muted/50 px-6 py-3">
        <CloseAccountDialog />
      </CardFooter>
    </Card>
  );
};

export { CloseAccount };
