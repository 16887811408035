import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { graphql } from '@/gql';
import { IncidentStatusType } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { Activity, ChevronDown, Lock, LockOpen } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

const EditIncidentStatusDropdownGql = graphql(`
  mutation EditIncidentStatusDropdownGql($input: UpdateIncidentStatusInput!) {
    updateIncidentStatus(input: $input) {
      incident {
        id
        status
      }
    }
  }
`);

type Status = {
  icon: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & { title?: string; titleId?: string }
  >;
  label: string;
  value: IncidentStatusType;
};

const statuses: Status[] = [
  {
    icon: LockOpen,
    label: 'Open',
    value: IncidentStatusType.Open,
  },
  {
    icon: Activity,
    label: 'In Progress',
    value: IncidentStatusType.InProgress,
  },
  {
    icon: Lock,
    label: 'Closed',
    value: IncidentStatusType.Closed,
  },
];

type EditIncidentStatusDropdownProps = {
  readonly incidentId: string;
  readonly onComplete?: () => void;
  readonly readonly: boolean;
  readonly status: IncidentStatusType;
};

const EditIncidentStatusDropdown = ({
  incidentId,
  onComplete,
  readonly,
  status,
}: EditIncidentStatusDropdownProps) => {
  const [open, setOpen] = useState(false);
  const selectedStatus = statuses.find((item) => item.value === status);

  const [, updateStatus] = useMutation(EditIncidentStatusDropdownGql);

  const handleSelect = useCallback(
    async (newValue: IncidentStatusType) => {
      if (status !== newValue) {
        await updateStatus({
          input: {
            incidentId,
            status: newValue,
          },
        });

        onComplete?.();
      }

      setOpen(false);
    },
    [incidentId, onComplete, setOpen, status, updateStatus],
  );

  if (readonly) {
    return (
      <div className="inline-flex items-center justify-center rounded-md text-sm font-medium h-9 rounded-md px-3 border border-input bg-background">
        {selectedStatus ? (
          <span className="flex items-center">
            <selectedStatus.icon className="mr-1 h-4 w-4 shrink-0" />
            {selectedStatus.label}
          </span>
        ) : (
          <>+ Set status</>
        )}
      </div>
    );
  }

  return (
    <Popover
      onOpenChange={setOpen}
      open={open}
    >
      <PopoverTrigger asChild>
        <Button
          className="justify-between group"
          size="sm"
          variant="outline"
        >
          {selectedStatus ? (
            <span className="flex items-center">
              <selectedStatus.icon className="mr-1 h-4 w-4 shrink-0" />
              {selectedStatus.label}
            </span>
          ) : (
            <>+ Set status</>
          )}
          <ChevronDown className="ml-4 h-4 w-4 shrink-0 opacity-25 group-hover:opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="p-0"
        side="bottom"
      >
        <Command>
          <CommandInput placeholder="Filter status..." />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {statuses.map((item) => (
                <CommandItem
                  key={item.value}
                  onSelect={async () => await handleSelect(item.value)}
                >
                  <item.icon
                    className={cn(
                      'mr-2 h-4 w-4',
                      item.value === selectedStatus?.value
                        ? 'opacity-100'
                        : 'opacity-40',
                    )}
                  />
                  <span>{item.label}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export { EditIncidentStatusDropdown };
