import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { type IncidentType } from '@/gql/graphql';

type IncidentTypeSelectorProps = {
  readonly incidentTypes: Array<
    Pick<
      IncidentType,
      | 'category'
      | 'dataSchema'
      | 'description'
      | 'iconSvg'
      | 'id'
      | 'name'
      | 'uiSchema'
    >
  >;
  readonly setSelectedIncidentTypeId: (id: string) => void;
};

const IncidentTypeSelector = ({
  incidentTypes,
  setSelectedIncidentTypeId,
}: IncidentTypeSelectorProps) => {
  const categories = new Set(incidentTypes.map((item) => item.category));

  return (
    <div className="space-y-6">
      {[...categories]
        .sort((a, b) => ((a ?? '') < (b ?? '') ? -1 : 1))
        .map((category) => (
          <Card key={category}>
            <CardHeader>
              <CardTitle className="uppercase">{category} category</CardTitle>
            </CardHeader>
            <CardContent className="grid md:grid-cols-2 lg:grid-cols-3 gap-2">
              {incidentTypes
                .filter((item) => item.category === category)
                .map((item) => (
                  <div key={item.id}>
                    <Button
                      className="w-full h-full flex flex-row items-center justify-start text-left gap-3 rounded-xl"
                      onClick={() => setSelectedIncidentTypeId(item.id)}
                      variant="ghost"
                    >
                      <img
                        alt={item.name}
                        className="h-12 w-12 bg-info rounded-xl p-1.5"
                        src={`data:image/svg+xml;base64,${item.iconSvg}`}
                      />
                      <div>
                        <div className="text-lg font-bold">{item.name}</div>
                        <div className="text-sm text-wrap">
                          {item.description}
                        </div>
                      </div>
                    </Button>
                  </div>
                ))}
            </CardContent>
          </Card>
        ))}
    </div>
  );
};

export { IncidentTypeSelector };
