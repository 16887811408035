import { type InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

type FileInputProps = InputHTMLAttributes<HTMLInputElement>;

const FileInput = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
  const { accept = 'image/*', className, ...extra } = props;

  return (
    <label className="block">
      <span className="sr-only">Choose profile photo</span>
      <input
        {...extra}
        accept={accept}
        className={twMerge(
          'block w-full text-sm text-gray-500',
          'file:mr-4 file:py-2 file:px-4',
          'file:rounded-md file:border-0',
          'file:text-sm file:font-semibold',
          'file:bg-blue-500 file:text-white',
          'hover:file:bg-blue-600',
          className,
        )}
        ref={ref}
        type="file"
      />
    </label>
  );
});

export { FileInput, type FileInputProps };
