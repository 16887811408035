import LogoIcon from '@/assets/images/logo/icon/128_128.svg';
import { config } from '@/config';

const ApplicationFooter = () => {
  return (
    <footer>
      <div className="text-sm p-4 md:mt-auto flex items-start justify-between md:items-center md:px-8">
        <div className="flex items-center space-x-4">
          <div className="flex items-center gap-1">
            <img
              alt="See It Send It"
              className="h-10 w-auto md:h-6 md:w-auto"
              src={LogoIcon}
            />
            <span>v{config.VERSION}</span>
          </div>
        </div>
        <div className="flex flex-col text-right space-y-4 md:space-y-0 md:text-left md:flex-row md:items-center md:space-x-4">
          <a
            className="hover:text-primary underline"
            href="https://seeitsendit.com/privacy-policy"
            rel="noreferrer noopener"
            target="_blank"
          >
            Privacy Policy
          </a>
          <a
            className="hover:text-primary underline"
            href="https://seeitsendit.com/terms-of-use"
            rel="noreferrer noopener"
            target="_blank"
          >
            Terms of Service
          </a>
          <a
            className="hover:text-primary underline"
            href="https://seeitsendit.com/contact"
            rel="noreferrer noopener"
            target="_blank"
          >
            Contact Us
          </a>
        </div>
      </div>
    </footer>
  );
};

export { ApplicationFooter };
