import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { graphql } from '@/gql';
import { type Group } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores';
import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { useMutation, useQuery } from 'urql';

const EditIncidentGroupDropdownGql = graphql(`
  mutation EditIncidentGroupDropdownGql($input: UpdateIncidentGroupInput!) {
    updateIncidentGroup(input: $input) {
      incident {
        id
        ownedByGroupId
        ownedByGroup {
          id
          name
        }
      }
    }
  }
`);

const AllGroupDropdownGql = graphql(`
  query AllGroupDropdownGql($organizationId: String!) {
    groups(condition: { organizationId: $organizationId }) {
      nodes {
        id
        name
      }
    }
  }
`);

type EditIncidentGroupDropdownProps = {
  readonly className?: string;
  readonly group?: Pick<Group, 'id' | 'name'> | null;
  readonly incidentId: string;
  readonly onComplete?: () => void;
  readonly readonly: boolean;
};

const EditIncidentGroupDropdown = ({
  className,
  group,
  incidentId,
  onComplete,
  readonly,
}: EditIncidentGroupDropdownProps) => {
  const [open, setOpen] = useState(false);

  const organizationId = useAppStore((state) => state.activeMembership?.id);

  const [, updateGroup] = useMutation(EditIncidentGroupDropdownGql);

  const [{ data }] = useQuery({
    pause: !organizationId,
    query: AllGroupDropdownGql,
    variables: {
      organizationId: organizationId ?? '',
    },
  });

  const handleSelect = async (value: string) => {
    const groupId = group?.id === value ? '' : value;

    await updateGroup({
      input: {
        groupId,
        incidentId,
      },
    });

    onComplete?.();

    setOpen(false);
  };

  if (readonly) {
    return (
      <div
        className={cn(
          'inline-flex items-center justify-center rounded-md text-sm font-medium h-9 rounded-md px-3 border border-input bg-background',
          className,
        )}
      >
        {group ? (
          <span className="flex items-center">{group.name}</span>
        ) : (
          <>No Group</>
        )}
      </div>
    );
  }

  return (
    <Popover
      onOpenChange={setOpen}
      open={open}
    >
      <PopoverTrigger asChild>
        <Button
          className={cn('justify-between group', className)}
          disabled={readonly}
          size="sm"
          variant="outline"
        >
          {group ? (
            <span className="flex items-center">{group.name}</span>
          ) : (
            <>No Group</>
          )}
          {!readonly && (
            <ChevronDown className="ml-4 h-4 w-4 shrink-0 opacity-25 group-hover:opacity-50" />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="p-0"
        side="bottom"
      >
        <Command>
          <CommandInput placeholder="Filter groups..." />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {data?.groups?.nodes.map((item) => (
                <CommandItem
                  className={item.id === group?.id ? 'bg-accent/90' : undefined}
                  key={item.id}
                  onSelect={async () => await handleSelect(item.id)}
                  value={`${item.id}-${item.name}`}
                >
                  <span>{item.name}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export { EditIncidentGroupDropdown };
