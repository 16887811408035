import { MutationError } from '../../Misc';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader, Plus } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { boolean, enums, type Infer, object, string, union } from 'superstruct';
import { useMutation } from 'urql';

const NewCustomMetadataFieldGql = graphql(`
  mutation NewCustomMetadataFieldGql($organizationId: String!, $fields: JSON!) {
    updateOrganizationMemberMetadataFields(
      input: { organizationId: $organizationId, fields: $fields }
    ) {
      organization {
        id
        memberMetadataFields
      }
    }
  }
`);

const schema = object({
  defaultValue: union([string(), boolean()]),
  isPrivate: boolean(),
  isRequired: boolean(),
  name: string(),
  type: enums(['BOOLEAN', 'STRING']),
});

type MetadataField = {
  defaultValue: boolean | number | string;
  isPrivate: boolean;
  isRequired: boolean;
  name: string;
  type: string;
  value: string;
};

type NewCustomMetadataFieldDialogProps = {
  readonly existingFields: Record<string, MetadataField>;
  readonly isAdmin?: boolean;
  readonly onComplete?: () => void;
  readonly organizationId: string;
};

const NewCustomMetadataFieldDialog = ({
  existingFields,
  isAdmin,
  onComplete,
  organizationId,
}: NewCustomMetadataFieldDialogProps) => {
  const [open, setOpen] = useState(false);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      defaultValue: '',
      isPrivate: false,
      isRequired: false,
      name: '',
      type: 'STRING',
    },
    resolver: superstructResolver(schema),
  });

  const [{ error, fetching }, updateMetadata] = useMutation(
    NewCustomMetadataFieldGql,
  );

  const onCancel = () => {
    form.reset();
    setOpen(false);
  };

  const onSubmit = async (values: Infer<typeof schema>) => {
    const updatedFields: Record<string, MetadataField> = existingFields;

    let defaultValues: boolean | string;
    const deprecatedValue: string =
      values.type === 'BOOLEAN' && values.defaultValue === ''
        ? 'false'
        : values.defaultValue.toString();

    switch (values.type) {
      case 'BOOLEAN':
        defaultValues = false;
        break;
      case 'STRING':
        defaultValues = '';
        break;
      default:
        defaultValues = '';
    }

    updatedFields[values.name] = {
      defaultValue: defaultValues,
      isPrivate: values.isPrivate,
      isRequired: values.isRequired,
      name: values.name,
      type: values.type,
      value: deprecatedValue,
    };

    const response = await updateMetadata({
      fields: updatedFields,
      organizationId,
    });

    if (!response.error) {
      form.reset();
      setOpen(false);
      toast.success(`Added custom member metadata field '${values.name}'.`);
      onComplete?.();
    }
  };

  return (
    <Dialog
      onOpenChange={setOpen}
      open={open}
    >
      <DialogTrigger asChild>
        <Button disabled={!isAdmin}>
          <Plus className="h-6 w-6 mr-2" /> New Custom Metadata Field
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>New Custom Metadata Field</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            className="space-y-6"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Field Name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="unique name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="isPrivate"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      // eslint-disable-next-line react/jsx-handler-names
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Is Private?</FormLabel>
                    <FormDescription>
                      Field is hidden from non-supervisors.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="isRequired"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      // eslint-disable-next-line react/jsx-handler-names
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Is Required?</FormLabel>
                    <FormDescription>
                      Field will be marked as requied during onboarding.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="mb-2">Field Type</FormLabel>
                  <FormControl>
                    <RadioGroup
                      className="flex flex-col space-y-0"
                      defaultValue={field.value}
                      // eslint-disable-next-line react/jsx-handler-names
                      onValueChange={field.onChange}
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="STRING" />
                        </FormControl>
                        <FormLabel className="font-normal">String</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="BOOLEAN" />
                        </FormControl>
                        <FormLabel className="font-normal">Boolean</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <MutationError error={error} />

            <DialogFooter>
              <Button
                disabled={fetching}
                onClick={onCancel}
                type="reset"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                disabled={fetching}
                type="submit"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Save
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { type MetadataField, NewCustomMetadataFieldDialog };
