import { Callout } from '../Callout';
import { type HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { type CombinedError } from 'urql';

type GraphqlErrorProps = HTMLAttributes<HTMLDivElement> & {
  error?: CombinedError | null;
};

const GraphqlError = forwardRef<HTMLDivElement, GraphqlErrorProps>(
  (props, ref) => {
    const { error, ...extra } = props;

    if (!error) return null;

    return (
      <Callout
        {...extra}
        color="red"
        ref={ref}
        title={error?.networkError ? 'Network Error' : 'Error'}
      >
        {error?.networkError && (
          <span>Network Error: {error.networkError.message}</span>
        )}
        {error?.graphQLErrors && (
          <ul>
            {error.graphQLErrors.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={`error_${index}`}>{item.message}</li>
            ))}
          </ul>
        )}
        {!error?.networkError && !error?.graphQLErrors && (
          <span>An internal error has occured</span>
        )}
      </Callout>
    );
  },
);

export { GraphqlError };
