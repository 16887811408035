import { Button } from '@/components/ui/button';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { type ElementType, forwardRef, type HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type NotificationProps = HTMLAttributes<HTMLDivElement> & {
  description?: string;
  icon?: ElementType;
  onClose?: () => void;
  subtitle?: string;
  title: string;
  type?: 'error' | 'info' | 'success' | 'warning';
};

const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  (props, ref) => {
    const {
      children,
      className,
      description,
      title = 'info',
      subtitle,
      type = 'info',
      onClose,
      icon,
      ...extra
    } = props;

    // info by default
    let Icon: ElementType = InformationCircleIcon;
    let iconColors = 'text-blue-400 w-10 h-10';

    if (type === 'success') {
      Icon = CheckCircleIcon;
      iconColors = 'text-green-400 w-10 h-10';
    }

    if (type === 'error') {
      Icon = XCircleIcon;
      iconColors = 'text-red-400 w-10 h-10';
    }

    if (type === 'warning') {
      Icon = ExclamationTriangleIcon;
      iconColors = 'text-yellow-400 w-10 h-10';
    }

    if (icon) {
      Icon = icon;
    }

    return (
      <div
        {...extra}
        className={twMerge(
          'flex items-start justify-content-start',
          'relative w-full sm:max-w-lg gap-2 p-4',
          'bg-white border shadow-sm rounded-md',
          type === 'success' && 'border-green-400',
          type === 'error' && 'border-red-400',
          type === 'warning' && 'border-yellow-400',
          type === 'info' && 'border-blue-400',
          'dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7] dark:text-gray-400',
          className,
        )}
        ref={ref}
      >
        <div className="w-12">
          <Icon className={iconColors} />
        </div>

        <div className="grow">
          <div className="flex flex-col gap-2">
            <p className="text-md font-semibold">{title}</p>
            {subtitle && (
              <p className="text-sm uppercase font-light">{subtitle}</p>
            )}
            {description && <p>{description}</p>}
            {children && (
              <div className="flex gap-2 justify-start items-align">
                {children}
              </div>
            )}
          </div>
        </div>
        {onClose && (
          <div>
            <Button onClick={onClose} />
          </div>
        )}
      </div>
    );
  },
);

export { Notification, type NotificationProps };
