import { Datetime } from '@/components/Datetime';
import { Empty } from '@/components/Empty';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { useQuery } from 'urql';

const GuardMeAttachmentsGql = graphql(`
  query GuardMeAttachmentsGql($id: String!) {
    guardme(id: $id) {
      id
      guardmeAttachments {
        nodes {
          id
          comment
          createdAt
          attachments {
            nodes {
              id
              url
              mimetype
            }
          }
        }
      }
    }
  }
`);

type GuardMeAttachmentsProps = {
  readonly className?: string;
  readonly fetching: boolean;
  readonly guardmeId: string;
};

const GuardMeAttachments = ({
  className,
  fetching,
  guardmeId,
}: GuardMeAttachmentsProps) => {
  const [{ data, error, fetching: dataFetching }] = useQuery({
    query: GuardMeAttachmentsGql,
    variables: {
      id: guardmeId,
    },
  });

  if (error) {
    return (
      <Card className={className}>
        <CardHeader className="px-7">
          <CardTitle>Attachments</CardTitle>
          <CardDescription>
            Recent attachments related to this GuardMe session.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <MutationError error={error} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={className}>
      <CardHeader className="px-7">
        <CardTitle>Attachments</CardTitle>
        <CardDescription>
          Recent attachments related to this GuardMe session.
        </CardDescription>
      </CardHeader>
      <CardContent>
        {fetching || dataFetching ? (
          <div className="w-full grid grid-cols-4 gap-2">
            <Skeleton className="h-24 w-full" />
            <Skeleton className="h-24 w-full" />
            <Skeleton className="h-24 w-full" />
            <Skeleton className="h-24 w-full" />
          </div>
        ) : (
          <div className="w-full divide-y">
            {data?.guardme?.guardmeAttachments.nodes.length === 0 && (
              <Empty className="text-muted-foreground">No Attachments</Empty>
            )}
            {data?.guardme?.guardmeAttachments.nodes.map((node) => {
              return (
                <div
                  className="py-4"
                  key={node.id}
                >
                  <p className="mb-1 text-sm italic">
                    Uploaded <Datetime datetime={node.createdAt} />
                  </p>
                  {node.comment && <p className="mb-2">{node.comment}</p>}
                  <div className="grid grid-cols-4 gap-2 mx-auto">
                    {node.attachments.nodes.map((attachment) => {
                      return (
                        <div key={attachment.id}>
                          {attachment.url && (
                            <a
                              href={attachment.url}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {attachment.mimetype?.includes('video') && (
                                // eslint-disable-next-line jsx-a11y/media-has-caption
                                <video>
                                  <source
                                    src={attachment.url}
                                    type={attachment.mimetype}
                                  />
                                </video>
                              )}
                              {attachment.mimetype?.includes('image') && (
                                <img
                                  alt={attachment.id}
                                  className="w-full rounded hover:shadow-lg"
                                  src={attachment.url}
                                />
                              )}
                            </a>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export { GuardMeAttachments };
