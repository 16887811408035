import { Empty } from '@/components/Empty';
import { Loader } from '@/components/Loader';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from '@/components/ui/pagination';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { IncidentPriority } from '@/features/Incidents/components/IncidentPriority';
import { IncidentStatus } from '@/features/Incidents/components/IncidentStatus';
import { graphql } from '@/gql';
import { ChevronLeft, ChevronRight, Plus } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const MyIncidentsGql = graphql(`
  query MyIncidentsGql($after: Cursor, $first: Int!) {
    currentUser {
      id
      incidents(after: $after, first: $first, orderBy: [ID_DESC]) {
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            id
            subject
            shortId
            status
            priority
            incidentType {
              id
              name
              iconSvg
            }
            organization {
              id
              name
              logoUrl
            }
          }
        }
      }
    }
  }
`);

const MyIncidents = () => {
  const [after, setAfter] = useState<Array<string | null>>([null]);
  const first = 6;

  const [{ data, error, fetching }] = useQuery({
    query: MyIncidentsGql,
    variables: {
      after: after[after.length - 1],
      first,
    },
  });

  const handleNextPage = () => {
    if (data?.currentUser?.incidents?.pageInfo.endCursor) {
      setAfter((previous) => [
        ...previous,
        data?.currentUser?.incidents?.pageInfo.endCursor,
      ]);
    }
  };

  const handlePreviousPage = () => {
    setAfter((previous) => previous.slice(0, previous.length - 1));
  };

  return (
    <Card className="h-full flex flex-col">
      <CardHeader className="p-4">
        <CardTitle className="text-lg flex items-center">
          My Incidents
          {(data?.currentUser?.incidents?.totalCount ?? 0) > 0 && (
            <span className="ml-1 text-sm font-normal text-muted-foreground">
              ({data?.currentUser?.incidents.totalCount.toLocaleString()})
            </span>
          )}
          <Link
            className="ml-auto"
            to="/incidents/new"
          >
            <Button
              className="h-6"
              variant="link"
            >
              <Plus className="h-4 w-4" /> New
            </Button>
          </Link>
        </CardTitle>
      </CardHeader>

      <Loader loadingError={error}>
        <CardContent className="grid items-start pb-0">
          {fetching &&
            [...Array.from({ length: 6 }).keys()].map((item) => (
              <Skeleton
                className="mb-4 h-12 w-full"
                key={item}
              />
            ))}

          {!fetching && data?.currentUser?.incidents?.edges.length === 0 && (
            <Empty className="flex items-center justify-center h-full">
              <p>No Incidents</p>
            </Empty>
          )}

          {!fetching &&
            data?.currentUser?.incidents?.edges.map(({ node: item }) => (
              <div
                className="mt-3 pb-3 border-b last:border-none flex items-center"
                key={item.id}
              >
                <Avatar className="h-9 w-9 rounded-none">
                  <AvatarImage
                    alt={item.incidentType?.name}
                    src={`data:image/svg+xml;base64,${item.incidentType?.iconSvg}`}
                  />
                  <AvatarFallback>
                    {item.incidentType?.name.slice(0, 2)}
                  </AvatarFallback>
                </Avatar>

                <div className="ml-4 grid">
                  <p className="text-sm font-medium leading-none flex items-center">
                    <Link
                      className="hover:underline"
                      to={`/incidents/${item.id}`}
                    >
                      {item.subject || '--No Subject--'}
                    </Link>
                  </p>

                  <div className="mt-0.5 flex items-center text-sm text-muted-foreground">
                    {item.shortId}
                    <Separator
                      className="mx-2 h-4 w-px"
                      orientation="vertical"
                    />
                    {item.organization?.name}
                    <Separator
                      className="mx-2 h-4 w-px"
                      orientation="vertical"
                    />
                    <IncidentStatus status={item.status} />
                    <Separator
                      className="mx-2 h-4 w-px"
                      orientation="vertical"
                    />
                    <IncidentPriority priority={item.priority} />
                  </div>
                </div>
              </div>
            ))}
        </CardContent>
        <CardFooter className="mt-auto flex flex-row items-center justify-between border-t bg-muted/50 px-6 py-3">
          <div className="text-xs text-muted-foreground">
            {data?.currentUser?.incidents?.totalCount.toLocaleString()}{' '}
            Incidents
          </div>
          <div className="ml-auto flex text-xs items-center">
            {(data?.currentUser?.incidents?.totalCount ?? 0) > 0 && (
              <div className="mr-2 text-muted-foreground">
                {(after.length - 1) * first + 1} to{' '}
                {(after.length - 1) * first + first}
              </div>
            )}
            <Pagination className="mx-0 w-auto">
              <PaginationContent>
                <PaginationItem>
                  <Button
                    className="h-6 w-6"
                    disabled={
                      !data?.currentUser?.incidents?.pageInfo.hasPreviousPage
                    }
                    onClick={handlePreviousPage}
                    size="icon"
                    variant="outline"
                  >
                    <ChevronLeft className="h-3.5 w-3.5" />
                    <span className="sr-only">Previous Order</span>
                  </Button>
                </PaginationItem>
                <PaginationItem>
                  <Button
                    className="h-6 w-6"
                    disabled={
                      !data?.currentUser?.incidents?.pageInfo.hasNextPage
                    }
                    onClick={handleNextPage}
                    size="icon"
                    variant="outline"
                  >
                    <ChevronRight className="h-3.5 w-3.5" />
                    <span className="sr-only">Next Order</span>
                  </Button>
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        </CardFooter>
      </Loader>
    </Card>
  );
};

export { MyIncidents };
