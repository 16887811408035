import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import { graphql } from '@/gql';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores';
import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons';
import { forwardRef } from 'react';
import { useQuery } from 'urql';

const GroupSelectQuery = graphql(`
  query GroupSelectQuery($organizationId: String = "") {
    organization(id: $organizationId) {
      id
      groups {
        totalCount
        nodes {
          id
          name
        }
      }
    }
  }
`);

type GroupSelectProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value'
> & {
  defaultValue: string;
  isLoading?: boolean;
  onValueChange: (...event: any[]) => void;
};

const GroupSelect = forwardRef<HTMLInputElement, GroupSelectProps>(
  (props, ref) => {
    const { defaultValue, onValueChange, isLoading } = props;
    const organizationId = useAppStore((state) => state.activeMembership?.id);

    const [{ fetching, data }] = useQuery({
      pause: !organizationId,
      query: GroupSelectQuery,
      requestPolicy: 'network-only',
      variables: {
        organizationId,
      },
    });

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            className={cn(
              'flex h-10 w-[400px] items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
              !defaultValue && 'text-muted-foreground',
            )}
            role="combobox"
            variant="outline"
          >
            {fetching || isLoading
              ? 'Loading...'
              : defaultValue
              ? data?.organization?.groups?.nodes.find(
                  (item) => item.id === defaultValue,
                )?.name
              : 'Select Group'}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[400px] p-0">
          <Command>
            <CommandInput placeholder="Search groups..." />
            <ScrollArea className="max-h-96">
              <CommandEmpty>No groups found.</CommandEmpty>
              <CommandGroup>
                {data?.organization?.groups?.nodes
                  .sort((a, b) => (a.name < b.name ? 1 : -1))
                  .map((item) => (
                    <CommandItem
                      key={item.id}
                      onSelect={() => {
                        onValueChange(item.id);
                      }}
                      value={item.id}
                    >
                      <CheckIcon
                        className={cn(
                          'mr-2 h-4 w-4',
                          item.id === defaultValue
                            ? 'opacity-100'
                            : 'opacity-0',
                        )}
                      />
                      <Avatar className="mr-2 w-6 h-6">
                        <AvatarFallback>{item.name.slice(0, 1)}</AvatarFallback>
                      </Avatar>
                      {item.name}
                    </CommandItem>
                  ))}
              </CommandGroup>
            </ScrollArea>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

export { GroupSelect };
