import { CheckInSettingsForm } from '../components/CheckInSettingsForm';
import { Loader } from '@/components/Loader';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { useQuery } from 'urql';

const CheckInSettingsGql = graphql(`
  query CheckInSettingsGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      groups {
        nodes {
          id
          name
        }
      }
      settings {
        id
        isOwnerNotifiedOnCheckIn
        isAdministratorNotifiedOnCheckIn
        isSupervisorNotifiedOnCheckIn
        checkInSupervisorGroupId
        checkInSupervisorGroup {
          id
          name
        }
      }
    }
  }
`);

const CheckInSettings = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }] = useQuery({
    query: CheckInSettingsGql,
    variables: {
      organizationId,
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Check In Settings</CardTitle>
        <CardDescription>
          You can add custom fields to your members to capture additional
          information.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <Loader
          isLoading={fetching && data !== undefined}
          loadingError={error}
        >
          {data?.organization?.settings && (
            <CheckInSettingsForm
              groups={data.organization.groups.nodes}
              organizationId={organizationId}
              {...data.organization?.settings}
            />
          )}
        </Loader>
      </CardContent>
    </Card>
  );
};

export { CheckInSettings };
