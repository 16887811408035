import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { formatDatetime } from '@/lib/time';
import { cn } from '@/lib/utils';
import { logger } from '@/utils';
import {
  Calendar,
  Check,
  CircleAlert,
  Info,
  Paperclip,
  Pencil,
  Plus,
  ShieldCheck,
  TriangleAlert,
} from 'lucide-react';
import { useQuery } from 'urql';

type Event = {
  content: string;
  extra: string;
  icon: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    }
  >;
  iconBackground: string;
  id: string;
  timestamp: string;
};

// eslint-disable-next-line complexity
const timelineEntry = (
  log: {
    createdAt: string;
    details: Record<string, string>;
    id: string;
    performedById: string;
    performedByName: string;
    type: string;
  },
  member: {
    id: string;
    name: string;
  },
): Event | null => {
  const adminName =
    member.id === log.performedById ? null : log.performedByName;
  const memberName = member.name;
  const id = log.id;
  const timestamp = formatDatetime({ datetime: log.createdAt });
  let content = '';
  let extra = '';
  let icon: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    }
  >;
  let iconBackground: string;

  switch (log.type) {
    case 'started':
      if (adminName) {
        content = `${adminName} started GuardMe session on ${memberName}'s behalf`;
      } else {
        content = `${memberName} started GuardMe session`;
      }

      icon = Plus;
      iconBackground = 'info';
      break;

    case 'started_unset_safe':
      if (adminName) {
        content = `${adminName} requested assistance on ${memberName}'s behalf`;
      } else {
        content = `${memberName} requested assistance`;
      }

      icon = CircleAlert;
      iconBackground = 'destructive';
      break;

    case 'concluded':
      if (adminName) {
        content = `${adminName} concluded ${memberName}'s GuardMe session`;
      } else {
        content = `${memberName} concluded the GuardMe session`;
      }

      icon = Check;
      iconBackground = 'info';
      break;

    case 'added_attachment':
      content = `${memberName} uploaded new attachment`;
      extra = log.details?.filename;
      icon = Paperclip;
      iconBackground = 'success';
      break;

    case 'notes_added':
      content = `${memberName} added a note: ${log.details?.new}`;
      extra = '';
      icon = Pencil;
      iconBackground = 'muted';
      break;

    case 'notes_removed':
      content = `${memberName} removed note`;
      extra = '';
      icon = Pencil;
      iconBackground = 'muted';
      break;

    case 'notes_changed':
      content = `${memberName} changed note to: ${log.details?.new}`;
      extra = '';
      icon = Pencil;
      iconBackground = 'muted';
      break;

    case 'unset_safe':
      if (adminName) {
        content = `${adminName} requested assistance on ${memberName}'s behalf`;
      } else {
        content = `${memberName} requested assistance`;
      }

      icon = TriangleAlert;
      iconBackground = 'destructive';
      break;

    case 'set_safe':
      if (adminName) {
        content = `${adminName} reported ${memberName} as safe`;
      } else {
        content = `${memberName} self-reported as safe`;
      }

      icon = ShieldCheck;
      iconBackground = 'success';
      break;

    case 'extended':
      if (adminName) {
        content = `${adminName} extended ${memberName}'s session`;
      } else {
        content = `${memberName} extended session`;
      }

      extra = `Ends at: ${formatDatetime({ datetime: log.details?.new })}`;
      icon = Calendar;
      iconBackground = 'info';
      break;

    case 'expired':
      content = 'GuardMe session expired';
      extra = log.details?.expiredAt
        ? `Expired at: ${formatDatetime({ datetime: log.details.expiredAt })}`
        : '';
      icon = CircleAlert;
      iconBackground = 'warning';
      break;

    case 'rapidsos_cloudscann_v1_flow_create':
      content = 'RapidSOS emergency panic button workflow initiated.';
      icon = Info;
      iconBackground = 'info';
      break;

    case 'rapidsos_cloudscann_v1_trigger_received':
      content =
        'RapidSOS confirmed receipt of emergency panic button help request.';
      icon = Info;
      iconBackground = 'info';
      break;

    case 'rapidsos_cloudscann_v1_monitor_notified':
      content = `RapidSOS notified the emergency call center nearest to ${memberName}.`;
      icon = Info;
      iconBackground = 'info';
      break;

    case 'rapidsos_cloudscann_v1_flow_complete':
      content = 'The RapidSOS emergency panic button workflow completed.';
      icon = Info;
      iconBackground = 'info';
      break;

    case 'rapidsos_cloudscann_operator_v1_flow_create':
      content = 'RapidSOS emergency operator workflow initiated.';
      icon = Info;
      iconBackground = 'info';
      break;

    case 'rapidsos_cloudscann_operator_v1_trigger_received':
      content = 'RapidSOS confirmed receipt of emergency operator workflow.';
      icon = Info;
      iconBackground = 'info';
      break;

    case 'rapidsos_cloudscann_operator_v1_calling_contact':
      content =
        'RapidSOS is calling operator to confirm the emergency before initiating RapidSOS emergency operator workflow.';
      icon = Info;
      iconBackground = 'info';
      break;

    case 'rapidsos_cloudscann_operator_v1_operator_confirmed':
      content = `The operator confirmed the RapidSOS emergency operator workflow, and will be connected to the emergency call center nearest to ${memberName}.`;
      icon = Info;
      iconBackground = 'info';
      break;

    case 'rapidsos_cloudscann_operator_v1_operator_declined':
      content =
        'The operator terminated the RapidSOS emergency operator workflow.';
      icon = Info;
      iconBackground = 'info';
      break;

    case 'rapidsos_cloudscann_operator_v1_operator_no_response':
      content = 'RapidSOS received no response when the operator was called.';
      icon = Info;
      iconBackground = 'info';
      break;

    case 'rapidsos_cloudscann_operator_v1_operator_call_911_ended':
      content = 'The operator’s call with 911 ended.';
      icon = Info;
      iconBackground = 'info';
      break;

    case 'rapidsos_cloudscann_operator_v1_flow_complete':
      content = 'The RapidSOS emergency operator workflow has completed.';
      icon = Info;
      iconBackground = 'info';
      break;

    default:
      logger.warn(`Unknown audit log entry found: ${log.type}`);
      return null;
  }

  return {
    content,
    extra,
    icon,
    iconBackground,
    id,
    timestamp,
  };
};

const GuardMeAuditLogQueryDocument = graphql(`
  query GuardMeAuditLog($id: String!) {
    guardme(id: $id) {
      id
      memberId
      member {
        id
        fullName
        displayName
      }
      guardmeAuditLogs {
        nodes {
          id
          type
          details
          performedBy {
            id
            fullName
            displayName
          }
          performedById
          createdAt
        }
      }
    }
  }
`);

type GuardMeAuditLogProps = {
  readonly fetching: boolean;
  readonly guardmeId: string;
};

const GuardMeAuditLog = ({ fetching, guardmeId }: GuardMeAuditLogProps) => {
  const [{ data, fetching: dataFetching }] = useQuery({
    query: GuardMeAuditLogQueryDocument,
    variables: {
      id: guardmeId,
    },
  });

  const events =
    (data?.guardme?.guardmeAuditLogs?.nodes

      .map((node) => {
        return timelineEntry(
          {
            createdAt: node.createdAt,
            details: node.details,
            id: node.id,
            performedById: node.performedById ?? data.guardme?.memberId ?? '',
            performedByName:
              node.performedBy?.displayName ??
              node.performedBy?.fullName ??
              'Admin',
            type: node.type,
          },
          {
            id: data.guardme?.memberId ?? '',
            name:
              data?.guardme?.member?.displayName ??
              data?.guardme?.member?.fullName ??
              'Member',
          },
        );
      })
      .filter((node) => {
        return node !== null;
      }) as Event[]) ?? [];

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg">Events</CardTitle>
      </CardHeader>
      <CardContent>
        {(fetching || dataFetching) && (
          <div className="grid grid-cols-4 gap-2 mx-auto">
            <Skeleton className="h-24 w-full" />
            <Skeleton className="h-24 w-full" />
            <Skeleton className="h-24 w-full" />
            <Skeleton className="h-24 w-full" />
          </div>
        )}

        <div className="flow-root">
          <ul className="-mb-8">
            {events.map((event, index) => {
              return (
                <li key={event.id}>
                  <div className="relative pb-8">
                    {index === events.length - 1 ? null : (
                      <span
                        aria-hidden="true"
                        className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-muted"
                      />
                    )}
                    <div className="relative flex space-x-3">
                      <div className="md:mr-2">
                        <span
                          className={cn(
                            `text-${event.iconBackground}-foreground bg-${event.iconBackground}`,
                            'ring ring-muted',
                            'h-10 w-10 rounded-full flex items-center justify-center',
                          )}
                        >
                          <event.icon
                            aria-hidden="true"
                            className="h-6 w-6"
                          />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1 flex flex-col justify-between">
                        <p className="text-xs font-mono whitespace-nowrap text-muted-foreground">
                          {event.timestamp}
                        </p>
                        <p className="text-sm text-foreground">
                          {event.content}
                        </p>
                        <p className="text-sm text-foreground/90">
                          {event.extra}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </CardContent>
    </Card>
  );
};

export { GuardMeAuditLog };
