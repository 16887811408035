import { type DocumentType, graphql } from '@/gql';
import { GraphqlOrganizationUpdateEvent } from '@/gql/graphql';
import { type Cache } from '@urql/exchange-graphcache';
import { useSubscription } from 'urql';

const OrganizationUpdatesGql = graphql(`
  subscription OrganizationUpdatesGql($organizationId: String!) {
    organizationUpdates(input: { id: $organizationId }) {
      organization {
        id
        name
        logoUrl
      }
      event
      relatedIds
    }
  }
`);

const organizationUpdatesResolver = (
  result: DocumentType<typeof OrganizationUpdatesGql>,
  _args: never,
  cache: Cache,
) => {
  if (!result?.organizationUpdates?.organization) {
    // unknown organization
    return;
  }

  const event = result.organizationUpdates.event ?? 'updated';
  const organizationId = result.organizationUpdates.organization.id;

  if (event === GraphqlOrganizationUpdateEvent.Updated) {
    // invalidate any metrics query
    cache.invalidate('Query', 'organizationMetric', { organizationId });
  }
};

type Props = { readonly organizationId: string };

const OrganizationUpdates = ({ organizationId }: Props) => {
  useSubscription({
    query: OrganizationUpdatesGql,
    variables: {
      organizationId,
    },
  });

  return null;
};

export {
  OrganizationUpdates,
  type Props as OrganizationUpdatesProps,
  organizationUpdatesResolver,
};
