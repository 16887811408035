import { PlaceDetail } from '../components/PlaceDetail';
import { Loader } from '@/components/Loader';
import { MapProvider } from '@/components/Map';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { Plus } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const PlacesGql = graphql(`
  query PlacesGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      places {
        totalCount
        nodes {
          id
          name
          description
          updatedAt
          createdAt
          radius
          center {
            longitude
            latitude
            srid
          }
          spatialData {
            geojson
            srid
          }
        }
      }
    }
  }
`);

const Places = () => {
  const [organizationId, isAdmin] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.isAdmin,
  ]);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }] = useQuery({
    query: PlacesGql,
    requestPolicy: 'cache-and-network',
    variables: {
      organizationId,
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Places</CardTitle>
        <CardDescription>
          Places allow your organization to geofence and name a particular area.
          These can be configured to identify and tag key locations for a Check
          In, Incident, or GuardMe.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <Loader
          isLoading={fetching}
          loadingError={error}
        >
          {data?.organization && (
            <>
              <Link
                className="inline-flex"
                to="/settings/places/new"
              >
                <Button disabled={!isAdmin}>
                  <Plus className="w-4 h-4 mr-1" />
                  New Place
                </Button>
              </Link>

              <div className="space-y-4">
                <MapProvider>
                  {data?.organization?.places.nodes.map((item) => (
                    <PlaceDetail
                      center={item.center}
                      description={item.description}
                      id={item.id}
                      key={`place_${item.id}`}
                      name={item.name}
                      radius={item.radius}
                      spatialData={item.spatialData}
                    />
                  ))}
                </MapProvider>
              </div>
            </>
          )}
        </Loader>
      </CardContent>
    </Card>
  );
};

export { Places };
