import { GuardMeSettingsForm } from '../components/GuardMeSettingsForm';
import { Loader } from '@/components/Loader';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { useQuery } from 'urql';

const GuardMeSettingsGql = graphql(`
  query GuardMeSettingsGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      groups {
        nodes {
          id
          name
        }
      }
      settings {
        id
        isOwnerNotifiedOnGuardme
        isAdministratorNotifiedOnGuardme
        isSupervisorNotifiedOnGuardme
        guardmeIsStartedNotificationEnabled
        guardmeIsConcludedNotificationEnabled
        guardmeIsExpiredNotificationEnabled
        guardmeIsPanicNotificationEnabled
        guardmeIsMetadataIncludedInNotification
        guardmeSupervisorGroupId
        guardmeSupervisorGroup {
          id
          name
        }
      }
    }
  }
`);

const GuardMeSettings = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }] = useQuery({
    query: GuardMeSettingsGql,
    variables: {
      organizationId,
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>GuardMe Settings</CardTitle>
        <CardDescription>
          Configure who will monitor GuardMe sessions, which events they should
          receive notifications for, whether to include custom metadata fields
          in event notifications, and configuring your RapidSOS integration.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <Loader
          isLoading={fetching && data !== undefined}
          loadingError={error}
        >
          {data?.organization?.settings && (
            <GuardMeSettingsForm
              groups={data.organization.groups.nodes}
              organizationId={organizationId}
              {...data.organization?.settings}
            />
          )}
        </Loader>
      </CardContent>
    </Card>
  );
};

export { GuardMeSettings };
