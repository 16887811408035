import { MutationError } from '../../Misc';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

const DeleteGroupQuery = graphql(`
  mutation DeleteGroup($groupId: String!) {
    deleteGroup(input: { id: $groupId }) {
      group {
        id
        organizationId
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`);

type GroupDeleteProps = {
  readonly groupId: string;
};

const GroupDelete = ({ groupId }: GroupDeleteProps) => {
  const navigate = useNavigate();

  const [{ error }, deleteGroup] = useMutation(DeleteGroupQuery);

  const onSubmit = async () => {
    const { data } = await deleteGroup({
      groupId,
    });

    if (data?.deleteGroup?.group?.name) {
      toast.success(
        `${data.deleteGroup.group.name} group deleted from organization.`,
      );

      navigate('/members');
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl">Delete Group</CardTitle>
        <CardDescription>
          This may affect organization members' access.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Button
          onClick={onSubmit}
          variant="destructive"
        >
          Delete
        </Button>

        <MutationError error={error} />
      </CardContent>
    </Card>
  );
};

export { GroupDelete };
