import { IncidentListFilter } from '../components/IncidentListFilter';
import { IncidentPriority } from '../components/IncidentPriority';
import { IncidentStatus } from '../components/IncidentStatus';
import { IncidentViewFilter } from '../components/IncidentViewFilter';
import { useIncidentTableControls } from '../hooks/useIncidentTableControls';
import { DateRangePicker } from '@/components/DateRangePicker';
import { Datetime } from '@/components/Datetime';
import { TableSearch } from '@/components/TableSearch';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  TablePagnination,
  TableRowSkeleton,
} from '@/components/ui/TableWrapper';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { Plus } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

const IncidentsGql = graphql(`
  query IncidentsGql(
    $first: Int!
    $offset: Int!
    $organizationId: String!
    $search: String
    $createdAt_gte: Datetime
    $createdAt_lte: Datetime
    $contactMemberId: String
    $status: IncidentsStatusConditionInput
    $priority: IncidentsPriorityConditionInput
    $assignedToMeOrMyGroups: Boolean
    $types: [String!]
  ) {
    incidents(
      first: $first
      offset: $offset
      condition: {
        contactMemberId: $contactMemberId
        organizationId: $organizationId
        createdAt: { gte: $createdAt_gte, lte: $createdAt_lte }
        search: $search
        status: $status
        priority: $priority
        assignedToMeOrMyGroups: $assignedToMeOrMyGroups
        types: $types
      }
      orderBy: ID_DESC
    ) {
      totalCount
      nodes {
        id
        shortId
        subject
        description
        status
        priority
        createdAt
        updatedAt
        contactMember {
          id
          fullName
          displayName
          avatarUrl
        }
        ownedByMember {
          id
          fullName
          displayName
          avatarUrl
        }
        ownedByGroup {
          id
          name
        }
        incidentType {
          id
          name
          iconSvg
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`);

const Incidents = () => {
  const [organizationId, memberId] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.memberId,
  ]);

  if (!organizationId || !memberId) {
    throw new OrganizationNotFoundError();
  }

  const navigate = useNavigate();

  const controls = useIncidentTableControls();

  const [{ data, error, fetching }] = useQuery({
    query: IncidentsGql,
    variables: {
      assignedToMeOrMyGroups: controls.view === 'assignedToMyGroup',
      contactMemberId: controls.view === 'submittedByMe' ? memberId : undefined,
      createdAt_gte: controls.from?.toISOString(),
      createdAt_lte: controls.to?.toISOString(),
      first: controls.pageSize,
      offset: controls.offset,
      organizationId,
      priority: controls.hasFilter.priority ? controls.priority : null,
      search: controls.search,
      status: controls.hasFilter.status ? controls.status : null,
    },
  });

  const handleRowClick = (entityId: string) => {
    navigate(`/incidents/${entityId}`);
  };

  return (
    <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
      <div className="grid">
        <div className="mb-4 flex items-end justify-between">
          <h1 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">
            Incidents
          </h1>
          <Link to="/incidents/new">
            <Button
              className="gap-1"
              size="sm"
            >
              <Plus className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                New Incident
              </span>
            </Button>
          </Link>
        </div>

        <TableSearch
          containerClassName="md:max-w-full mb-2"
          placeholder="Search by Id, Subject, Description, or IncidentType..."
          setSearchValue={controls.setSearch}
        />

        <div className="flex items-center">
          <div className="flex items-center gap-1">
            <IncidentViewFilter />
            <IncidentListFilter />
            <DateRangePicker
              dateRange={controls.dateRange}
              setDateRange={controls.setDateRange}
            />
          </div>

          <div className="ml-auto flex items-center gap-2">
            <div className="mx-auto md:mx-0 text-sm text-muted-foreground">
              Showing
              <span className="mx-1 font-medium text-foreground">
                {(controls.page - 1) * controls.pageSize + 1}-
                {Math.min(
                  (controls.page - 1) * controls.pageSize + controls.pageSize,
                  data?.incidents?.totalCount ?? 0,
                )}
              </span>
              of
              <span className="ml-1 font-medium text-foreground">
                {data?.incidents?.totalCount}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <Card>
            <CardContent className="p-0">
              <Table>
                <TableHeader>
                  <TableRow className="hover:bg-inherit">
                    <TableHead>Status</TableHead>
                    <TableHead>Details</TableHead>
                    <TableHead>Priority</TableHead>
                    <TableHead className="text-center">Contact</TableHead>
                    <TableHead>Last Updated</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <MutationError error={error} />
                  {fetching && (
                    <TableRowSkeleton
                      cols={6}
                      rows={controls.pageSize / 3}
                    />
                  )}
                  {!fetching && data?.incidents?.nodes.length === 0 && (
                    <TableRow className="last:border-none hover:bg-inherit">
                      <TableCell
                        className="text-center h-24"
                        colSpan={8}
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                  {!fetching &&
                    data?.incidents?.nodes.map((item) => (
                      <TableRow
                        key={item.id}
                        onClick={() => handleRowClick(item.id)}
                      >
                        <TableCell className="align-top">
                          <IncidentStatus status={item.status} />
                        </TableCell>
                        <TableCell className="align-top">
                          <div className="flex items-center gap-2">
                            <Link
                              className="font-bold hover:underline"
                              to={`/incidents/${item.id}`}
                            >
                              {item.shortId}
                            </Link>
                            <Badge variant="secondary">
                              <img
                                alt={item.incidentType?.name}
                                className="h-4 w-4 mr-2"
                                src={`data:image/svg+xml;base64,${item.incidentType?.iconSvg}`}
                              />
                              {item.incidentType?.name}
                            </Badge>
                          </div>
                          <div className="mt-1">{item.subject}</div>
                        </TableCell>
                        <TableCell className="align-top">
                          <IncidentPriority priority={item.priority} />
                        </TableCell>
                        <TableCell className="align-top text-center">
                          <Tooltip>
                            <TooltipTrigger>
                              <Avatar className="h-6 w-6">
                                <AvatarImage
                                  src={
                                    item.contactMember?.avatarUrl ?? undefined
                                  }
                                />
                                <AvatarFallback>
                                  {item.contactMember?.fullName.slice(0, 2)}
                                </AvatarFallback>
                              </Avatar>
                            </TooltipTrigger>
                            <TooltipContent side="bottom">
                              {item.contactMember?.displayName ??
                                item.contactMember?.fullName}
                            </TooltipContent>
                          </Tooltip>
                        </TableCell>
                        <TableCell className="align-top">
                          <Datetime datetime={item.updatedAt} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </CardContent>
            <CardFooter className="p-4 border-t">
              <TablePagnination
                currentPage={controls.page}
                getSearchParameters={controls.getPageSearchParameters}
                pageSize={controls.pageSize}
                totalCount={data?.incidents?.totalCount}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export { Incidents };
