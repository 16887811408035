import { Spinner } from '@/components/Spinner';
import { Card, CardHeader, CardTitle } from '@/components/ui/card';
import { config } from '@/config';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const SignOut = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({
      logoutParams: {
        returnTo: config.WEB_URL,
      },
    });
  }, [logout]);

  return (
    <div className="flex flex-col md:min-h-screen outline-none pt-0">
      <main className="flex flex-col flex-1 items-center justify-center">
        <Card className="mx-auto max-w-sm border-0">
          <CardHeader>
            <Spinner
              logo
              size="xl"
            />
            <CardTitle className="text-center text-xl">
              Signing Out...
            </CardTitle>
          </CardHeader>
        </Card>
      </main>
    </div>
  );
};

export { SignOut };
