import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';

const useDrawingManager = (
  initialValue: google.maps.drawing.DrawingManager | null = null,
) => {
  const map = useMap();
  const drawing = useMapsLibrary('drawing');

  const [drawingManager, setDrawingManager] =
    useState<google.maps.drawing.DrawingManager | null>(initialValue);

  useEffect(() => {
    if (!map || !drawing) return () => {};

    // https://developers.google.com/maps/documentation/javascript/reference/drawing
    const newDrawingManager = new drawing.DrawingManager({
      circleOptions: {
        editable: true,
      },
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [
          google.maps.drawing.OverlayType.MARKER,
          google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.POLYGON,
          google.maps.drawing.OverlayType.POLYLINE,
          google.maps.drawing.OverlayType.RECTANGLE,
        ],
        position: google.maps.ControlPosition.TOP_CENTER,
      },
      drawingMode: google.maps.drawing.OverlayType.CIRCLE,
      map,
      markerOptions: {
        draggable: true,
      },
      polygonOptions: {
        draggable: true,
        editable: true,
        fillColor: '#BBDEFB',
        strokeColor: '#1565C0',
      },
      polylineOptions: {
        draggable: true,
        editable: true,
        strokeColor: '#1565C0',
      },
      rectangleOptions: {
        draggable: true,
        editable: true,
        fillColor: '#BBDEFB',
        strokeColor: '#1565C0',
      },
    });

    setDrawingManager(newDrawingManager);

    return () => {
      newDrawingManager.setMap(null);
    };
  }, [drawing, map]);

  return drawingManager;
};

export { useDrawingManager };
