import { Button } from '@/components/ui/button';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { forwardRef, type HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const errorMessages: Record<number, string> = {
  400: 'Your client sent a bad request.',
  401: 'You are not authorized.',
  403: 'You do not have the correct permissions.',
  404: 'This page does not exist.',
};

type ClientErrorProps = HTMLAttributes<HTMLDivElement> & {
  code?: number;
  message?: string;
  resetError?: () => void;
  stack?: string | null;
};

const ClientError = forwardRef<HTMLDivElement, ClientErrorProps>(
  (props, ref) => {
    const {
      stack,
      code = 400,
      message,
      className,
      resetError,
      ...extra
    } = props;

    const navigate = useNavigate();

    return (
      <div
        {...extra}
        className={twMerge(
          'h-full w-full flex flex-col items-center justify-center',
          className,
        )}
        ref={ref}
      >
        <div className="max-w-xl text-center">
          <h1 className="block text-7xl font-bold text-gray-800 sm:text-9xl dark:text-white">
            {code.toString()}
          </h1>

          {message ? (
            <p className="text-gray-600 dark:text-gray-400">{message}</p>
          ) : (
            <p className="mt-3 text-gray-600 dark:text-gray-400">
              {errorMessages[code] ?? errorMessages[500]}
            </p>
          )}
          <div className="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
            <Button
              onClick={() => {
                navigate(-1);
                resetError?.();
              }}
            >
              <ArrowLeftIcon className="h-4 w-4" />
              Back
            </Button>
          </div>
        </div>

        {stack && (
          <div className="mt-8 font-mono text-xs">
            {stack
              .split('\n')
              .map((line, index) => ({
                id: `stack_line_${index}`,
                text: line,
              }))
              .map((item, index) => (
                <p key={item.id}>
                  {index}: {item.text}
                </p>
              ))}
          </div>
        )}
      </div>
    );
  },
);

export { ClientError, type ClientErrorProps };
