import { Card, CardContent } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { graphql } from '@/gql';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberCountGql = graphql(`
  query MemberCountGql($organizationId: String!) {
    organizationMetric(organizationId: $organizationId) {
      nodeId
      invitations
      members
      membershipRequests
    }
  }
`);

type Props = {
  readonly organizationId: string;
};

const MemberCount = ({ organizationId }: Props) => {
  const [{ data }] = useQuery({
    query: MemberCountGql,
    variables: {
      organizationId,
    },
  });

  return (
    <Card>
      <CardContent className="flex flex-row items-baseline gap-4 p-2">
        <div className="flex w-full items-center gap-2">
          <div className="grid flex-1 auto-rows-min gap-0.5">
            <div className="text-xs text-muted-foreground flex items-center">
              <Link
                className="underline hover:text-primary"
                to="/members"
              >
                Members
              </Link>
            </div>
            <div className="flex items-baseline gap-1 text-2xl font-bold tabular-nums leading-none">
              {data?.organizationMetric?.members.toLocaleString()}
            </div>
          </div>
          <Separator
            className="mx-2 h-10 w-px"
            orientation="vertical"
          />
          <div className="grid flex-1 auto-rows-min gap-0.5">
            <div className="text-xs text-muted-foreground">Requests</div>
            <div className="flex items-baseline gap-1 text-2xl font-bold tabular-nums leading-none">
              {data?.organizationMetric?.membershipRequests.toLocaleString()}
            </div>
          </div>
          <Separator
            className="mx-2 h-10 w-px"
            orientation="vertical"
          />
          <div className="grid items-baseline flex-1 auto-rows-min gap-0.5">
            <div className="text-xs text-muted-foreground">Invites</div>
            <div className="flex items-baseline gap-1 text-2xl font-bold tabular-nums leading-none">
              {data?.organizationMetric?.invitations.toLocaleString()}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export { MemberCount };
