import { useIncidentTableControls } from '../hooks/useIncidentTableControls';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { Check, ChevronDown } from 'lucide-react';
import { useState } from 'react';

type Props = {
  readonly className?: string;
};

export const IncidentViewFilter = ({ className }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setViewFilter, view } = useIncidentTableControls();

  return (
    <Popover
      onOpenChange={setIsOpen}
      open={isOpen}
    >
      <PopoverTrigger asChild>
        <Button
          className={cn('border', className)}
          variant="outline"
        >
          {view === null && <>All Incidents</>}
          {view === 'assignedToMyGroup' && <>Assigned to My Group</>}
          {view === 'submittedByMe' && <>My Incidents</>}
          <ChevronDown className={cn('ml-2 h-4 w-4', isOpen && 'rotate-180')} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="w-[200px] p-0"
      >
        <Command>
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              <CommandItem onSelect={() => setViewFilter('all')}>
                <span>All Incidents</span>
                <div
                  className={cn(
                    'ml-auto flex',
                    view === null
                      ? 'text-primary'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className={cn('h-4 w-4')} />
                </div>
              </CommandItem>
              <CommandItem onSelect={() => setViewFilter('submittedByMe')}>
                <span>My Incidents</span>
                <div
                  className={cn(
                    'ml-auto flex',
                    view === 'submittedByMe'
                      ? 'text-primary'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className={cn('h-4 w-4')} />
                </div>
              </CommandItem>
              <CommandItem onSelect={() => setViewFilter('assignedToMyGroup')}>
                <span>Assigned To My Group</span>
                <div
                  className={cn(
                    'ml-auto flex',
                    view === 'assignedToMyGroup'
                      ? 'text-primary'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className={cn('h-4 w-4')} />
                </div>
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
