import { useCheckInReportTableControls } from '../hooks/useCheckInReportTableControls';
import { DateRangePicker } from '@/components/DateRangePicker';
import { Datetime } from '@/components/Datetime';
import { TableSearch } from '@/components/TableSearch';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  TablePagnination,
  TableRowSkeleton,
} from '@/components/ui/TableWrapper';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError, PermissionDeniedError } from '@/utils';
import { Plus } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

const CheckInReporsGql = graphql(`
  query CheckInReporsGql(
    $first: Int!
    $offset: Int!
    $organizationId: String!
    $shortId: String
    $createdAt_gte: Datetime
    $createdAt_lte: Datetime
    $requestorMemberId: String
  ) {
    checkInReports(
      first: $first
      offset: $offset
      condition: {
        createdAt: { gte: $createdAt_gte, lte: $createdAt_lte }
        organizationId: $organizationId
        requestorMemberId: $requestorMemberId
        shortId: $shortId
      }
      orderBy: ID_DESC
    ) {
      totalCount
      nodes {
        id
        shortId
        createdAt
        cutoffAt
        requestorMember {
          id
          fullName
          displayName
          avatarUrl
        }
        checkInRequests {
          totalCount
        }
        place {
          id
          name
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`);

const CheckInReports = () => {
  const [organizationId, memberId, isSupervisor] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.memberId,
    state.activeMembership?.isCheckInSupervisor,
  ]);

  if (!organizationId || !memberId) {
    throw new OrganizationNotFoundError();
  }

  if (!isSupervisor) {
    throw new PermissionDeniedError();
  }

  const navigate = useNavigate();

  const controls = useCheckInReportTableControls();

  const [{ data, error, fetching }] = useQuery({
    query: CheckInReporsGql,
    requestPolicy: 'cache-and-network',
    variables: {
      createdAt_gte: controls.from?.toISOString(),
      createdAt_lte: controls.to?.toISOString(),
      first: controls.pageSize,
      offset: controls.offset,
      organizationId,
      requestorMemberId: undefined,
      shortId: controls.search,
    },
  });

  const handleRowClick = (entityId: string) => {
    navigate(`/check-in-reports/${entityId}`);
  };

  return (
    <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
      <div>
        <div className="flex items-center">
          <TableSearch
            className="h-8"
            placeholder="Search by Id..."
            setSearchValue={controls.setSearch}
          />
          <div className="ml-auto flex items-center gap-2">
            <DateRangePicker
              className="h-8"
              dateRange={controls.dateRange}
              setDateRange={controls.setDateRange}
            />
            {isSupervisor && (
              <Link to="/check-in-reports/new">
                <Button
                  className="h-8 gap-1"
                  size="sm"
                >
                  <Plus className="h-3.5 w-3.5" />{' '}
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    New Check In Report
                  </span>{' '}
                </Button>
              </Link>
            )}
          </div>
        </div>

        <div className="mt-2">
          <Card x-chunk="guardme-sessions">
            <CardHeader>
              <CardTitle>Check In Reports</CardTitle>
              <CardDescription>
                View your organization Check In Reports.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow className="hover:bg-inherit">
                    <TableHead>Id</TableHead>
                    <TableHead>Members</TableHead>
                    <TableHead>Requested By</TableHead>
                    <TableHead>Created</TableHead>
                    <TableHead>Check In Cutoff</TableHead>
                    <TableHead>Place</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {error && <MutationError error={error} />}
                  {fetching && !data && (
                    <TableRowSkeleton
                      cols={6}
                      rows={controls.pageSize}
                    />
                  )}
                  {!fetching && data?.checkInReports?.nodes.length === 0 && (
                    <TableRow className="last:border-none hover:bg-inherit">
                      <TableCell
                        className="text-center h-24"
                        colSpan={8}
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                  {!fetching &&
                    data?.checkInReports?.nodes.map((item) => (
                      <TableRow
                        key={item.id}
                        onClick={() => handleRowClick(item.id)}
                      >
                        <TableCell className="font-bold whitespace-nowrap">
                          <Link
                            className="hover:underline"
                            to={`/check-in-reports/${item.id}`}
                          >
                            {item.shortId}
                          </Link>
                        </TableCell>
                        <TableCell>{item.checkInRequests.totalCount}</TableCell>
                        <TableCell>
                          <div className="flex items-center gap-2">
                            <Avatar className="h-6 w-6">
                              <AvatarImage
                                src={
                                  item.requestorMember?.avatarUrl ?? undefined
                                }
                              />
                              <AvatarFallback>
                                {item.requestorMember?.fullName.slice(0, 2)}
                              </AvatarFallback>
                            </Avatar>
                            <div className="text-sm">
                              {item.requestorMember?.displayName ??
                                item.requestorMember?.fullName}
                            </div>
                          </div>
                        </TableCell>

                        <TableCell>
                          <Datetime datetime={item.createdAt} />
                        </TableCell>
                        <TableCell>
                          <Datetime datetime={item.cutoffAt} />
                        </TableCell>
                        <TableCell>
                          {item.place && <Badge>{item.place?.name}</Badge>}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </CardContent>
            <CardFooter>
              <TablePagnination
                currentPage={controls.page}
                getSearchParameters={controls.getPageSearchParameters}
                pageSize={controls.pageSize}
                totalCount={data?.checkInReports?.totalCount}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export { CheckInReports };
