const percentChange = (a: number, b: number): number => {
  let percent;
  // eslint-disable-next-line no-negated-condition
  if (b !== 0) {
    // eslint-disable-next-line no-negated-condition
    if (a !== 0) {
      percent = ((b - a) / a) * 100;
    } else {
      percent = b * 100;
    }
  } else {
    percent = -a * 100;
  }

  return Math.floor(percent);
};

export { percentChange };
