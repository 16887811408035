import { cn } from '@/lib/utils';
import { forwardRef, type HTMLAttributes } from 'react';

type EmptyProps = HTMLAttributes<HTMLDivElement> & {
  border?: boolean;
  children?: React.ReactNode;
  message?: string;
  visible?: boolean;
};

const Empty = forwardRef<HTMLDivElement, EmptyProps>((props, ref) => {
  const {
    border,
    className,
    children,
    message = 'No data to display',
    visible = true,
    ...extra
  } = props;

  if (!visible) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex items-center justify-center h-full py-4',
        border && 'border border-dashed',
        className,
      )}
      ref={ref}
      {...extra}
    >
      {children ? (
        <>{children}</>
      ) : (
        <div className="text-center space-y-1">
          <span className="italic">{message}</span>
        </div>
      )}
    </div>
  );
});

export { Empty, type EmptyProps };
