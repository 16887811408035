import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useMutation } from 'urql';

const EnableMemberDialogGql = graphql(`
  mutation EnableMemberDialogGql($memberId: String!) {
    enableMember(input: { id: $memberId }) {
      member {
        id
        isEnabled
      }
    }
  }
`);

type EnableMemberDialogProps = {
  readonly disabled?: boolean;
  readonly memberId: string;
};

const EnableMemberDialog = ({
  disabled,
  memberId,
}: EnableMemberDialogProps) => {
  const [open, setOpen] = useState(false);

  const [{ error, fetching }, enableMember] = useMutation(
    EnableMemberDialogGql,
  );

  if (!disabled) {
    return null;
  }

  return (
    <AlertDialog
      onOpenChange={setOpen}
      open={open}
    >
      <AlertDialogTrigger asChild>
        <Button variant="info">Enable Member</Button>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Enable Member?</AlertDialogTitle>
          <AlertDialogDescription>
            This will grant access to this organization to the member in
            question and may impact your payment.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <MutationError error={error} />

        <AlertDialogFooter>
          <AlertDialogCancel disabled={fetching}>Cancel</AlertDialogCancel>
          <Button
            disabled={fetching}
            onClick={async () => {
              await enableMember({ memberId });
              setOpen(false);
              toast.success(`Enabled member`);
            }}
          >
            {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
            Enable Member
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { EnableMemberDialog };
