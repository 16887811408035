import { MutationError } from '../../Misc';
import { EditPlaceMap } from './EditPlaceMap';
import { PlaceDelete } from './PlaceDelete';
import { MapProvider } from '@/components/Map';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { graphql } from '@/gql';
import { type Geometry, geometryFromShape } from '@/utils';
import { RefreshCw } from 'lucide-react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { type Infer, nonempty, object, string } from 'superstruct';
import { useMutation } from 'urql';

const updatePlaceQuery = graphql(`
  mutation updatePlaceQuery(
    $id: String!
    $name: String!
    $description: String!
    $geojson: JSON!
    $radius: Int!
  ) {
    updatePlace(
      input: {
        id: $id
        name: $name
        description: $description
        geojson: $geojson
        radius: $radius
      }
    ) {
      place {
        id
        name
        description
        radius
        spatialData {
          geojson
        }
        center {
          longitude
          latitude
        }
      }
    }
  }
`);

const schema = object({
  description: string(),
  geoJson: string(),
  name: nonempty(string()),
});

type EditPlaceFormProps = {
  readonly description: string;
  readonly geoJson: Geometry;
  readonly name: string;
  readonly placeId: string;
};

const EditPlaceForm = ({
  description,
  geoJson,
  name,
  placeId,
}: EditPlaceFormProps) => {
  const navigate = useNavigate();

  const currentShapeRef = useRef<
    google.maps.Circle | google.maps.Polygon | null
  >(null);
  const newShapeRef = useRef<google.maps.Circle | google.maps.Polygon | null>(
    null,
  );

  const [{ error, fetching }, updatePlace] = useMutation(updatePlaceQuery);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      description,
      geoJson: '',
      name,
    },
  });

  const onSubmit = async (values: Infer<typeof schema>) => {
    let updatedGeometry: Geometry;

    if (newShapeRef.current) {
      updatedGeometry = geometryFromShape(
        newShapeRef.current instanceof google.maps.Circle
          ? 'circle'
          : 'polygon',
        newShapeRef.current,
      );
    } else {
      // noop use existing geoJSON
      updatedGeometry = geoJson;
    }

    const response = await updatePlace({
      description: values.description,
      geojson: {
        coordinates: updatedGeometry.coordinates,
        type: updatedGeometry.type,
      },
      id: placeId,
      name: values.name,
      radius: updatedGeometry.radius,
    });

    if (!response.error) {
      navigate(`/settings/places`);
    }
  };

  return (
    <Form {...form}>
      <form
        className="space-y-6"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormItem>
          <FormLabel htmlFor="geoJson">Geofence</FormLabel>
          <MapProvider>
            <EditPlaceMap
              currentShapeRef={currentShapeRef}
              geoJson={geoJson}
              onCircleComplete={(circle) => {
                currentShapeRef.current?.setVisible(false);

                newShapeRef.current?.setMap(null);
                newShapeRef.current = circle;
              }}
              onPolygonComplete={(polygon) => {
                currentShapeRef.current?.setVisible(false);

                newShapeRef.current?.setMap(null);
                newShapeRef.current = polygon;
              }}
            />
          </MapProvider>
          <FormMessage />
        </FormItem>

        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <MutationError error={error} />

        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <Button
              disabled={fetching}
              type="submit"
            >
              {fetching && <RefreshCw className="h-6 w-6 animate-spin mr-2" />}
              Update
            </Button>
            <Button
              disabled={fetching}
              onClick={() => {
                navigate(`/settings/places`);
              }}
              variant="ghost"
            >
              Cancel
            </Button>
          </div>

          <PlaceDelete placeId={placeId} />
        </div>
      </form>
    </Form>
  );
};

export { EditPlaceForm };
