import { useMap } from '@/components/Map';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { MapPin } from 'lucide-react';

type ZoomToButtonProps = {
  readonly lat: number;
  readonly lng: number;
  readonly zoom?: number;
};

const ZoomToButton = ({ lat, lng, zoom = 14 }: ZoomToButtonProps) => {
  const map = useMap();

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          className="hover:text-info-foreground"
          onClick={() => {
            if (map) {
              map.setCenter({ lat, lng });
              map.setZoom(zoom);
            }
          }}
          type="button"
        >
          <MapPin className="h-5 w-5" />
        </button>
      </TooltipTrigger>
      <TooltipContent side="bottom">View Location</TooltipContent>
    </Tooltip>
  );
};

export { ZoomToButton };
